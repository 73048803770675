@import '~perfect-scrollbar/css/perfect-scrollbar.css';

$lineColor: #eaeaea;
$taxillaDarkGreen: #00a651;
$taxillaRedColor: #d81844;
$taxillaOrangeColor: #af7813;
$taxillaLightGreen: #a4d5a7;
$significantRegFontColor: #363636;
$submitButtonColor: $taxillaDarkGreen;
$headerItemBorderColor: #f7f7f7;
$curtainBoxShadowColor: #c1c2c1;
$headerHeight: 44px;
$footerHeight: 32px;
$settingsPageBackgroundColor: #f0f0f0;
$placeholderHintColor: #565656;
$fontFieldHindColor: rgba(0, 0, 0, 0.4);
$cancelButtonBgColor: #fff;
$newTaxillaLighterGreen: #ddfede;
$lightGreyColor: #a2a2a2;
$lightRedColor: rgba(255, 0, 0, 0.5);
$hoverRedColor: rgba(255, 0, 0, 0.7);
$matIconGreyColor: #616161;
$oddItemBgColor: $headerItemBorderColor;
$evenItemBgColor: #f7f9ff; //Little Bluish
$sectionSeperator: #f4fef9; //A little more bluish
$inProgressColor: #6e6eff; //Medium Bluish
$canvasGridColor: #e3e3e3;
$whiteColor: #fff;
$borderColorGrey: #cccccc;
$fontFamily: Lato, San Francisco, Proxima Nova, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Ubuntu, Droid Sans, Helvetica Neue,
    Open Sans, sans-serif;

.mat-form-field-appearance-legacy .mat-form-field-label-wrapper .mat-form-field-label {
    -webkit-transform: none;
    transform: none;
}

html,
body {
    height: 100%;
}

.enComplyMainContainer {
    #preLoginContainer,
    #profileHeaderContainer,
    .app-responsive .ForgotpasswordPage {
        height: 100%;
    }

    footer {
        padding: 8px 0px 0px 8px;
        background: $cancelButtonBgColor;
        position: fixed;
        bottom: 5px;
        left: 5px;
        z-index: 10;
        visibility: 'visible';
        height: 30px;
        width: 31px;
        border-radius: 25px;
        margin: 0px 8px;
        transition: width 0.3s;

        .logo {
            padding: 0px 5px 8px 0px;
            height: 25px;
        }

        .content {
            font-size: 11px;
            margin-left: 0px;
        }

        .taxillaLogo {
            display: none;
        }

        &:hover {
            width: 160px;

            & .taxillaLogo {
                display: block;
            }

            & .tLogo {
                display: none;
            }
        }
    }

    .routerOutlet {
        height: calc(100vh - #{$headerHeight});
    }

    img.passwordHelperIcons {
        -ms-interpolation-mode: nearest-neighbor;
        image-rendering: -webkit-optimize-contrast;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-pixelated;
        image-rendering: pixelated;
    }

    .mat-badge-content,
    .mat-h1,
    .mat-headline,
    .mat-typography h1,
    .mat-h2,
    .mat-title,
    .mat-typography h2,
    .mat-h3,
    .mat-subheading-2,
    .mat-typography h3,
    .mat-h4,
    .mat-subheading-1,
    .mat-typography h4,
    .mat-h5,
    .mat-typography h5,
    .mat-h6,
    .mat-typography h6,
    .mat-body-2,
    .mat-body-strong,
    .mat-body,
    .mat-body-1,
    .mat-typography,
    .mat-caption,
    .mat-small,
    .mat-display-4,
    .mat-typography .mat-display-4,
    .mat-display-3,
    .mat-typography .mat-display-3,
    .mat-display-2,
    .mat-typography .mat-display-2,
    .mat-display-1,
    .mat-typography .mat-display-1,
    .mat-bottom-sheet-container,
    .mat-button,
    .mat-fab,
    .mat-flat-button,
    .mat-icon-button,
    .mat-mini-fab,
    .mat-raised-button,
    .mat-stroked-button,
    .mat-button-toggle,
    .mat-card,
    .mat-checkbox,
    .mat-table,
    .mat-calendar,
    .mat-dialog-title,
    .mat-expansion-panel-header,
    .mat-expansion-panel-content,
    .mat-form-field,
    .mat-menu-item,
    .mat-paginator,
    .mat-paginator-page-size .mat-select-trigger,
    .mat-radio-button,
    .mat-select,
    .mat-slide-toggle-content,
    .mat-slider-thumb-label-text,
    .mat-stepper-horizontal,
    .mat-stepper-vertical,
    .mat-tab-group,
    .mat-tab-label,
    .mat-tab-link,
    .mat-toolbar,
    .mat-toolbar h1,
    .mat-toolbar h2,
    .mat-toolbar h3,
    .mat-toolbar h4,
    .mat-toolbar h5,
    .mat-toolbar h6,
    .mat-tooltip,
    .mat-list-item,
    .mat-list-option,
    .mat-list-base .mat-subheader,
    .mat-list-base[dense] .mat-subheader,
    .mat-option,
    .mat-optgroup-label,
    .mat-simple-snackbar,
    .mat-tree {
        font-family: $fontFamily;
    }

    .mat-badge-content {
        background-color: orange;
    }

    .mat-paginator {
        -webkit-box-shadow: 0 5px 15px -2px rgba(10, 16, 34, 0.16);
        box-shadow: 0 5px 15px -2px rgba(10, 16, 34, 0.16);
    }

    .mat-progress-bar-fill::after {
        background-color: $taxillaDarkGreen !important;
    }

    mat-hint,
    mat-error {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .mat-option-text {
        font-weight: 400;
    }

    .mat-elevation-z8 {
        -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
    }

    .pAdjust td.mat-cell,
    td.mat-footer-cell,
    th.mat-header-cell {
        padding: 0 1em !important;
    }

    .tabelCellData {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: normal;
        white-space: nowrap;
    }

    .matTableContainer tr.mat-header-row th.mat-header-cell.mat-column-checkbox {
        min-width: 60px;
    }

    .mat-form-field-infix input[matInput] {
        font: inherit;
        background: 0 0;
        color: currentColor;
        border: none;
        outline: 0;
        padding: 0;
        margin: 0;
        width: 100%;
        max-width: 100%;
        vertical-align: bottom;
        text-align: inherit;
    }

    .mat-form-field-infix input[matInput],
    .mat-form-field-infix input[matInput]::-webkit-search-cancel-button,
    .mat-form-field-infix input[matInput]::-webkit-search-decoration,
    .mat-form-field-infix input[matInput]::-webkit-search-results-button,
    .mat-form-field-infix input[matInput]::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }

    textarea[matInput] {
        resize: vertical;
        padding: 2px 0;
        margin: -2px 0;
        overflow: auto;
    }

    .cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
        -webkit-animation-name: cdk-text-field-autofill-end;
        animation-name: cdk-text-field-autofill-end;
    }

    textarea.mat-input-element.cdk-textarea-autosize {
        resize: vertical !important;
        white-space: nowrap;
    }

    textarea.cdk-textarea-autosize {
        resize: none;
    }

    .mat-form-field-infix textarea {
        resize: vertical;
        width: 100%;
        overflow: hidden;
        -webkit-appearance: none !important;
        outline: none;
        border: none;
        max-height: 300px;
    }

    /* .mat-checkbox-inner-container {
    height: 1em !important;
    width: 1em !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background: var(--siteColor);
} */

    .mat-ripple-element {
        background: rgba(27, 142, 241, 0.4) !important;
    }

    /* .mat-checkbox-label {
    font-weight: normal;
} */

    .mat-menu-panel {
        width: 100%;
    }

    /* global matMenuContent change */

    .mat-tab-link:hover,
    .mat-tab-link:focus {
        text-decoration: none;
    }

    .mat-radio-group {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    th.matTableHeader.mat-header-cell.mat-column-log {
        width: 18px !important;
    }

    td.mat-cell.mat-column-log {
        padding: 0 !important;
    }

    .mat-option.mat-active {
        background: rgba(0, 0, 0, 0.1);
        color: rgba(0, 0, 0, 0.87);
    }

    mat-option.matOptionWithCrossIcon {
        background: #fff !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .mat-tooltip-panel {
        min-width: auto;
    }

    .mat-form-field .mat-form-field-infix input[matInput] {
        line-height: 20px;
    }

    .mat-form-field-infix input {
        border: 0;
        width: 100%;
    }

    .mat-form-field-appearance-legacy .mat-form-field-label {
        color: $placeholderHintColor;
    }

    mat-form-field {
        width: 100%;
    }

    // .mat-form-field-infix {
    //     padding: 0.4375em 0;
    //     border-top: 1.5em solid transparent;
    // }
    // if issue raised from qa related this, please have a container class and target this class

    .mat-form-field-infix input::-ms-input-placeholder {
        color: transparent;
    }

    .mat-form-field-infix input::-webkit-input-placeholder {
        color: transparent;
    }

    .mat-form-field-infix input:-ms-input-placeholder {
        color: transparent;
    }

    .mat-form-field-infix input::placeholder {
        color: transparent;
    }

    .mat-form-field-infix input::-moz-placeholder {
        color: transparent;
    }

    .securityAuthenticateMatContainer .mat-card {
        padding: 0px;
        max-height: 360px;
        min-height: 360px;
    }

    .loginButtonDiv,
    .forgotPasswordButtonDiv {
        margin: 35px 0px 20px 0px;
        cursor: pointer;
    }

    .marginTop10 {
        margin-top: 10px;
    }

    .pdbtm5 {
        padding-bottom: 5px;
    }

    .pdbtm15 {
        padding-bottom: 15px;
    }

    .mat-form-field-hint-wrapper strong {
        font-weight: 500;
        color: $fontFieldHindColor;
    }

    .mat-form-field-label {
        font-weight: 400 !important;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: black;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    .slider:before {
        position: absolute;
        content: '';
        height: 20px;
        width: 20px;
        left: 1px;
        bottom: 3px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    input:checked + .slider {
        background-color: #32ac7b;
    }

    input:focus + .slider {
        -webkit-box-shadow: 0 0 1px #32ac7b;
        box-shadow: 0 0 1px #32ac7b;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(38px);
        transform: translateX(38px);
    }

    input.require::-webkit-input-placeholder:after {
        content: '*';
        color: red;
        font-size: 1.5rem;
    }

    .mat-form-field-hide-placeholder .mat-select-placeholder {
        color: transparent;
        -webkit-text-fill-color: transparent;
        -webkit-transition: none;
        transition: none;
        display: block;
    }

    .mat-form-field input {
        &::-webkit-input-placeholder,
        &:-ms-input-placeholder,
        &::-ms-input-placeholder,
        &::placeholder {
            font-size: 14px;
            text-shadow: none;
            color: transparent;
        }
    }

    .allevents {
        margin-top: 25px;
    }

    .mat-form-field-label-wrapper {
        position: absolute;
        left: 0;
        -webkit-box-sizing: content-box;
        box-sizing: content-box;
        width: 100%;
        height: 100%;
        overflow: hidden;
        pointer-events: none;
    }

    .mat-form-field:not(.mat-focused) .mat-form-field-label {
        text-shadow: none !important;
        color: #9c9c9c !important;
    }

    label {
        margin-bottom: 0;
    }

    .mat-raised-button.mat-primary {
        background-color: $submitButtonColor;
        outline: none;
    }

    .mat-raised-button.mat-tx-grey:hover {
        background: $settingsPageBackgroundColor;
        outline: none;
    }

    .validateOptionsDiv {
        color: #a94442 !important;
        font-size: 12px !important;
        line-height: 0px;
        margin-left: 0;
        padding: 0;
    }

    .subscriptionItemContent {
        font-weight: 300;
        margin: 30px 0;
    }

    .btn-link {
        cursor: pointer;
    }

    .eventLogNotifications {
        min-width: 65%;
    }

    .notificationsContentContainer {
        .header-actions {
            display: flex;
            align-items: center;
            .refreshLogs {
                margin-right: 20px !important;
            }
        }
        .closeNoticationIcon {
            position: relative;
            top: -13px;
            left: -24px;
            font-size: 26px;
            margin: -12px;
        }

        .eventFilterTypes {
            margin: auto;
        }

        .notifyContentContainer {
            padding: 10px;

            .eventLogHedderDiv {
                padding: 0 15px 10px;
            }
        }
    }

    .eventTable {
        overflow-y: auto;
        padding: 10px;
    }

    .successPanelClass {
        background: $submitButtonColor;
        color: white;
        text-align: center !important;
    }

    .failurePanelClass {
        background: #e6140eb8;
        color: white;
        text-align: center !important;
    }

    .warningPanelClass {
        background: $taxillaOrangeColor;
        color: white;
        text-align: center !important;
    }

    .notifyPanelClass {
        background: #f9eacf;
        color: black;
        text-align: center !important;
    }

    .successPanelClass .mat-simple-snackbar,
    .notifyPanelClass .mat-simple-snackbar,
    .warningPanelClass .mat-simple-snackbar .failurePanelClass .mat-simple-snackbar {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    #postHeaderContainer {
        margin-bottom: 0;
        border-radius: 0;
        border: none;
        background-color: #3598dc;
    }

    .navbar-nav {
        -webkit-box-orient: unset !important;
        -webkit-box-direction: unset !important;
        -ms-flex-direction: unset !important;
        flex-direction: unset !important;
    }

    .listitems:last-child {
        border-right: none;
    }

    li.listitems span {
        cursor: pointer;
    }

    .navbar {
        position: relative;
        min-height: 50px;
    }

    .matTableActionIcon {
        font-size: 16px;
        padding-right: 10px;
        vertical-align: middle;
    }

    .fullWidthMatMenu {
        &.mat-menu-panel {
            max-width: none;
            width: 100vw;
            margin-top: 0px;
            border-radius: 0px;
            background: transparent;
            padding: 0 4vw 2vw;
            -webkit-box-shadow: unset;
            box-shadow: unset;
            overflow: hidden;

            .mat-menu-content {
                border-top: 2px solid $taxillaDarkGreen;
                background: $cancelButtonBgColor;
                -webkit-box-shadow: 12px 12px 15px $curtainBoxShadowColor;
                box-shadow: 12px 12px 15px $curtainBoxShadowColor;
            }

            .mat-card-title {
                padding: 0 31px;
                font-size: 20px;
            }

            .mat-card {
                border-radius: 0;
                border: none;
                -webkit-box-shadow: none;
                box-shadow: none;
                background: transparent;

                .tenantStructureCnt {
                    .tenantsList {
                        padding-left: 0px;
                        padding-right: 15px;

                        .orgId {
                            color: $lightGreyColor;
                        }
                    }

                    .levelOneTenants {
                        padding-right: 30px;
                        border-right: 1px solid $lineColor;
                        padding-left: 30px !important;

                        .searchTenants {
                            padding: 0 16px;
                            font-size: 14px;
                        }

                        .alphaFilterSection {
                            padding: 0 10px 0 0px;
                            font-size: 15px;
                            word-break: break-all;

                            .alphaFilter {
                                margin: 4px;
                                cursor: pointer;

                                &.active {
                                    color: $taxillaDarkGreen;
                                    border-bottom: 1px solid $taxillaDarkGreen;
                                }

                                &.clearFilter {
                                    color: white;
                                    border-radius: 50%;
                                    padding: 0px 6px 3px;
                                    background: #f99999;
                                    margin-left: 10px;
                                }
                            }
                        }

                        .tenantsList {
                            max-height: 242px;
                            overflow: auto;
                            padding-top: 5px;
                        }
                    }

                    .section-title {
                        padding: 0 16px;
                        font-size: 22px;
                        font-weight: 600;
                        margin-bottom: 5px;
                    }

                    .appName {
                        position: relative;

                        .headerIcon {
                            margin-right: 7px;
                        }
                    }

                    .actionBtns {
                        display: none;
                        position: absolute;
                        right: 0;
                        top: 0;
                        z-index: 51;

                        .appActionBtn {
                            margin: 3px 10px;

                            button {
                                line-height: 28px;
                                padding-left: 10px;
                                padding-right: 10px;
                            }
                        }
                    }

                    .appName:hover .actionBtns {
                        display: block;
                    }

                    .tenantFlatStructure {
                        float: left;

                        .section-title {
                            color: $taxillaDarkGreen;
                        }

                        .searchSubTenants {
                            padding: 0 16px;
                            font-size: 14px;
                            width: 75%;
                        }

                        .tenantsList {
                            max-height: 257px;
                            overflow: auto;
                        }

                        .selectedAppName {
                            padding-left: 30px;
                        }

                        .appsGraphContainer {
                            margin: 10px 30px;
                            -webkit-box-shadow: 0px 1px 5px 2px $lineColor;
                            box-shadow: 0px 1px 5px 2px $lineColor;
                            overflow: auto;
                        }
                    }

                    .packageDetails {
                        .section-title {
                            display: inline-block;
                        }

                        .switchPackageAppsView {
                            display: inline-block;
                        }

                        .activeType {
                            &.listViewBtn {
                                margin-bottom: 50px;
                            }

                            .mat-button-focus-overlay {
                                opacity: 0.04;
                            }
                        }
                    }

                    button.mat-menu-item {
                        height: 34px;
                        line-height: 34px;
                        font-size: 15px;

                        &:hover {
                            background: $newTaxillaLighterGreen;
                        }
                    }
                }
            }

            .mat-form-field-infix {
                border-top: 0.84375em solid transparent;
            }
        }

        & .mat-card {
            padding: 0px;
            overflow: hidden;
        }
    }

    .activeOrganization {
        background-color: $newTaxillaLighterGreen;
    }

    .userDetailsSection {
        background: #cccccc38;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }

    .settingsContainer {
        padding: 30px 40px;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 80%;
        flex: 0 0 80%;
        max-width: 80%;
    }

    #settingsContainer {
        width: 100%;
        position: relative;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        height: 100%;

        .drawerContainer {
            width: 100%;
            overflow: auto;
            overflow: unset;
            border-radius: 0px;
        }

        .sidenavContent > mat-card.mat-card {
            padding: 0;
            min-height: 100%;
        }

        .sidenavContent {
            height: auto;
            background: #efefef;
            padding: 15px;
        }

        .matDrawer {
            min-width: 50px;
            width: 235px;
            background-color: $settingsPageBackgroundColor;

            .mat-drawer-inner-container {
                background: transparent;
            }
        }

        .mat-drawer-content {
            overflow: auto;
            overflow: unset;
            font-size: 13px;
            background: #efefef;

            .mat-drawer-container {
                background: transparent;
            }

            .mat-drawer-content {
                background: transparent;
                width: 100%;
            }
        }

        .sliderButton {
            top: 0px;
            left: 0px;
            width: 30px;
            height: 30px;
        }

        .settingsMenu {
            background-color: #efefef;
            -webkit-transition: 0.5s;
            transition: 0.5s;
            padding-top: 1px;

            .settingsMenuTab {
                height: 57px;
                text-decoration: none;
                display: block;
            }

            .mat-list-item {
                border-left: 5px solid transparent;
                height: 40px;
                font-size: 13px;
                font-weight: bold;
            }

            .mat-list-item.active {
                color: $significantRegFontColor;
                background-color: $cancelButtonBgColor;
                border-left: 5px solid $taxillaDarkGreen;

                mat-icon {
                    color: $taxillaDarkGreen;
                }
            }

            .mat-list-text > a {
                font-weight: bold;
            }

            .mat-list-icon {
                font-size: 20px;
                padding: 0px 0px 0px 0px;
                width: 20px;
                height: 20px;
                color: $matIconGreyColor;
            }

            .mat-list-text {
                padding: 0px 0px 0px 10px;
            }

            .settingActorIcon {
                height: 78px;
                margin: 10px 0px 10px 0px;
                outline: none;
                cursor: pointer;

                .nameIcon {
                    height: 55px;
                    border-radius: 50%;
                    background-color: $cancelButtonBgColor;
                    width: 55px;
                    text-align: center;
                    padding-top: 5px;
                    margin: 0 auto;
                    font-size: 31px;
                    color: $taxillaDarkGreen;
                    text-transform: uppercase;
                    -webkit-box-shadow: 1px 1px 19px 1px rgba(0, 0, 0, 0.2);
                    box-shadow: 1px 1px 19px 1px rgba(0, 0, 0, 0.2);
                }

                .orgName {
                    text-align: center;
                    padding: 5px 20px 5px 20px;
                    font-size: 16px;
                    font-weight: bold;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        mat-drawer-container {
            background: #efefef;
            min-height: 500px !important;

            .mat-drawer-inner-container {
                overflow: hidden;
                background: $cancelButtonBgColor;
                height: 100%;
            }

            .mat-tab-body.mat-tab-body-active {
                overflow-y: hidden;
            }

            .mat-tab-body {
                .mat-tab-body-content {
                    overflow: auto;
                    overflow: unset;
                }
            }

            mat-drawer-container {
                background: transparent;
                min-height: 500px !important;

                mat-drawer {
                    overflow: auto;
                    overflow: unset;
                    margin-bottom: 0;
                    border-left: none;
                    width: 100%;
                    background: transparent;
                    height: stretch;
                    min-height: inherit;

                    .mat-drawer-inner-container {
                        overflow: auto;
                        overflow: unset;
                    }

                    &.integrationsMatDrawer {
                        .mat-drawer-inner-container {
                            background: $cancelButtonBgColor;
                        }
                    }
                }
            }

            .mat-drawer-content {
                background: transparent;
                height: -webkit-fill-available;
                min-height: inherit;
            }
        }
    }

    .manageRoleMatDrawer {
        transform: none !important;
    }

    .displayBlock {
        display: block;
    }

    .dateFont {
        font-weight: 300;
        font-size: 12px;
    }

    .settingsCurtain {
        padding: 60px 40px 0px 40px;
    }

    .orgNameCls {
        padding-left: 40px;
        font-size: 22px;
        font-weight: 600;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .settingsDiv {
        margin-bottom: 10px;
        cursor: pointer;
        font-weight: 400;
    }

    .pointer {
        cursor: pointer;
    }

    .cancel .mat-raised-button.mat-primary {
        background-color: #707070 !important;
        outline: none;
        margin-right: 10px;
    }

    #homepPageContainer {
        width: 100%;
        height: 100% !important;
        position: relative;
        background-color: #f2f2f2;

        .homeContainer {
            width: 100%;

            .homeCard {
                background-color: #f2f2f2;
                height: auto;
                -webkit-box-shadow: none;
                box-shadow: none;

                .textPosition {
                    margin: 0px 0px;
                    font-size: 14px;
                    color: $significantRegFontColor;
                }

                .menuContainer {
                    background-color: #f2f2f2;
                    margin-bottom: 0px;
                    padding-left: 0px;
                    padding-right: 0px;
                    margin-top: 20px;

                    &.myApps {
                        .circleTick {
                            visibility: hidden;
                        }
                    }

                    .subMenuWidth {
                        position: relative;
                        padding: 0px 0px 0px 0px !important;
                        height: 280px;
                        display: -webkit-inline-box;
                        display: -ms-inline-flexbox;
                        display: inline-flex;
                        text-align: center;

                        .menuItem {
                            background-color: $cancelButtonBgColor;
                            width: inherit;
                            margin: 0px 20px 0px 0px;

                            .menuName {
                                font-weight: bold;
                                font-size: 16px;
                                color: $significantRegFontColor;
                            }

                            .menuContant {
                                font-size: 12px;
                                color: #636363;
                                margin-top: 12px;
                                padding-left: 12px;
                                padding-right: 12px;
                            }

                            .circleTick {
                                margin: 20px 0px;
                            }
                        }

                        .menuItem:hover {
                            -webkit-box-shadow: 10px 10px 18px 0px rgba(0, 0, 0, 0.15);
                            box-shadow: 10px 10px 18px 0px rgba(0, 0, 0, 0.15);
                            cursor: pointer;
                        }
                    }
                }

                ul > li.subMenuWidth {
                    background: #f2f2f2;
                }
            }
        }

        .mat-expansion-panel {
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
        }

        .mat-card-title {
            font-size: 30px;
            color: #000;
        }

        .mat-expansion-panel-header-title {
            font-size: 16px;
            color: $significantRegFontColor;
        }

        .fontWeightBold {
            font-weight: bold !important;
        }

        .mat-expansion-panel-header {
            border-bottom: 1px solid #20c997;
            border-radius: 0px !important;
            padding: 0 15px 0 2px !important;
            background-color: #f2f2f2 !important;
        }

        .mat-card {
            height: 100% !important;
            padding-left: 190px;
            padding-right: 190px;
            overflow: auto;
        }

        .mat-expansion-panel-body {
            padding: 0 0 0 0 !important;
            margin-bottom: 15px;
        }

        .mat-expansion-panel-spacing {
            margin: 0 0 0 0 !important;
        }

        .mat-expansion-panel-content {
            background-color: #f2f2f2 !important;
        }

        mat-list {
            overflow: hidden;
        }
    }

    .mainAddress {
        float: left;
        padding: 0 30px;

        .headAddress,
        .billingAddress {
            float: left;

            .withSame {
                padding: 0px;
            }

            .manageCardTitle {
                font-size: 15px;
                font-weight: bold;
                margin: 15px 0px 0px 0px;
                float: left;
                padding: 0px;
            }

            .rowSpace {
                margin-bottom: 5px;
                padding-left: 0px;
            }
        }

        .headAddress > div {
            float: left;
        }

        .billingAddress > div > div {
            float: left;
        }

        .sameAs {
            float: right;
            padding: 0px;

            .sameAscheckbox {
                float: left;
                margin: 10px 0px 0px 0px;
                font-weight: 700;
            }
        }

        .manageCardTitle {
            font-size: 15px;
            font-weight: bold;
            margin: 20px 0px 0px 0px;
            float: left;
            padding: 0px;
        }

        .addressFooter {
            float: right !important;
            padding-bottom: 20px;
            margin-right: 20px;

            .cancelBtn {
                margin-right: 10px;
                float: right;
            }

            .submit {
                float: right;
            }
        }
    }

    .manageOrg {
        width: 100%;
        background: $cancelButtonBgColor;
        min-height: calc(100vh - (45px + 30px));

        .manageOrgContainer {
            position: relative;

            .manageOrgCard {
                .editCard {
                    .orgDetails {
                        padding: 0 30px;

                        .manageCardTitle {
                            font-size: 15px;
                            font-weight: bold;
                            margin: 20px 0px 5px 0px;
                            padding: 0px;
                        }

                        .emailwq {
                            padding-left: 5px;
                            vertical-align: middle;
                        }
                    }
                }

                .rightPaneTitle {
                    font-size: 16px;
                    font-weight: bold;

                    .editIcon {
                        margin-top: -10px;

                        button {
                            background-color: $submitButtonColor;
                        }
                    }
                }

                .addressContainer {
                    float: left;
                    padding: 30px;
                    height: 88%;
                    margin: 20px 25px 0 30px;
                    border: 1px solid #ebebeb;

                    .tabelCellData {
                        max-width: 360px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        word-wrap: normal;
                        white-space: nowrap;
                    }

                    .subHeading {
                        margin-bottom: 10px;
                        font-weight: 600;
                        border-bottom: 1px solid #ebebeb;
                        padding-bottom: 5px;
                        font-size: 14px;
                        height: 30px;
                    }

                    .addressMsg {
                        font-size: 13px;

                        .orgName {
                            font-size: 14px;
                            font-weight: bold;
                            overflow: hidden;
                            word-wrap: normal;
                            text-overflow: ellipsis;
                        }
                    }

                    .marginTop10 {
                        margin-top: 10px;
                    }
                }

                .addressContainer:hover {
                    -webkit-box-shadow: 10px 10px 18px 0px rgba(0, 0, 0, 0.15);
                    box-shadow: 10px 10px 18px 0px rgba(0, 0, 0, 0.15);
                }

                .mat-card-title {
                    font-weight: bolder;
                    border-bottom: 1px solid #ebebeb;
                    padding: 0px 10px 15px 0px;
                    margin-bottom: 0;
                }
            }
        }
    }

    .noRecordsFound {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
        color: #888282;
    }

    .noRecordsFound span.material-icons {
        color: #3598dc;
        font-size: 2.2em;
    }

    .disable:hover {
        cursor: not-allowed;
    }

    .disable:active {
        pointer-events: none;
    }

    input[disabled],
    .mat-select-disabled .mat-select-trigger {
        cursor: not-allowed !important;
        color: rgba(0, 0, 0, 0.38) !important;
    }

    .downloadTableIcon {
        cursor: pointer;
        font-size: 25px;
        position: absolute;
        margin: -3px 16px;
    }

    .checkSum {
        width: 80%;
    }

    .checkSumIcon {
        float: right;
        cursor: pointer;
        margin-top: 20px;
        font-size: 25px;
    }

    .browseBtn {
        background-color: #909090;
        border-color: #909090;
    }

    .browseBtn:hover {
        background-color: #909090;
        border-color: #909090;
    }

    .modal-title {
        line-height: normal;
        font-weight: bold;
        font-size: 16px;
    }

    .digitalSignDiv {
        margin-top: 15px;
        margin-left: -15px;
        padding: 15px 0;
        background: #f6f6f6;
        border-radius: 4px;

        .dscLabelRow {
            margin-left: 0;
            margin-right: 0;

            .labelDiv {
                align-self: center;
            }

            .userDscFileName {
                color: $taxillaDarkGreen;
                text-decoration: underline;
                cursor: pointer;
                padding: 0 0px 0 15px;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 140px;
                display: inline-block;
                white-space: nowrap;
                margin-bottom: -5px;
            }
        }

        .dscChangeRow {
            margin-left: 0;
            margin-right: 0;

            .chngBtn {
                text-align: right;
            }
        }

        .dscUploadBtnRow {
            margin: -5px 0 0 0;

            .dscBrowseBtn {
                align-self: center;

                .btn {
                    margin-right: 50px;
                    cursor: pointer;
                    padding: 0;
                    text-align: center;
                }

                .btn1 {
                    margin-right: 60px;
                    cursor: pointer;
                    padding: 0;
                    text-align: center;
                }
            }

            .dscUploadBtn {
                align-self: center;
            }

            .dscCancelBtn {
                align-self: center;
                text-align: right;
            }
        }

        .fileTypeSupport {
            color: $taxillaRedColor;
            padding: 5px 15px;
        }
    }

    .eula {
        ul.list_style {
            padding-left: 0;
        }

        li {
            margin-left: 10px;
        }

        .mat-dialog-title {
            text-align: center;
            width: 100%;
        }

        .EulaBody {
            padding: 1em 3em;
        }

        h3 {
            font-size: 1.2rem;
            font-weight: 600;
        }

        h4 {
            font-size: 1rem;
            font-weight: 600;
            color: #585555;
        }

        .modal-header {
            padding: 2rem 1rem 1rem;
        }
    }

    .manageUsers {
        .mat-horizontal-content-container {
            overflow: hidden;
            padding: 20px 30px;
        }

        .usersMainDiv {
            .userTableContainer {
                background: white;
                margin-bottom: 15px;

                .userTableHeader {
                    font-size: 18px;
                    color: $significantRegFontColor;
                    border-bottom: 1px solid #ebebeb;
                    margin-left: 10px;
                    height: 62px;

                    .manageUserHeader {
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        font-size: 16px;
                        font-weight: bold;
                        float: left;
                        -webkit-box-flex: 1;
                        -ms-flex: auto;
                        flex: auto;
                        line-height: 60px;
                    }

                    .pull-right {
                        margin-right: 20px;
                    }
                }
            }

            .userWidget {
                .addUserContainer {
                    padding: 20px 10px 20px 0px;

                    .addUserDetails {
                        .addUserDetailsHeader {
                            margin-bottom: 20px;

                            .headerNote {
                                font-size: 14px;
                                font-weight: 200;

                                .learnMoreCls {
                                    color: #469d3e;
                                    font-weight: 500;
                                }
                            }

                            .mainHeder {
                                margin-bottom: 5px;
                                font-size: 14px;
                                font-weight: bold;
                            }
                        }

                        .addUserNextButton {
                            margin-top: 40px;
                        }

                        .addUserFieldsDiv {
                            .row {
                                margin-bottom: 20px;
                            }
                        }
                    }
                }

                .addUserHeader {
                    font-size: 18px;
                    color: $significantRegFontColor;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: justify;
                    -ms-flex-pack: justify;
                    justify-content: space-between;
                    border-bottom: 1px solid #ebebeb;

                    span {
                        font-size: 16px;
                        font-weight: bold;
                    }
                }

                .assignRolesContainers {
                    .rolesCreationDiv {
                        .existingRolesContainerHeader {
                            .headerSpanCls {
                                font-size: 14px;
                                font-weight: 400;
                            }

                            .mainHeder {
                                margin-bottom: 5px;
                                font-size: 14px;
                                font-weight: bold;
                            }
                        }

                        .assignRolesHeaderSection {
                            margin-bottom: 20px;
                            margin-top: 20px;

                            .getExistingRolesBtn {
                                margin-right: 40px;
                            }
                        }

                        .creationOfNewRolesContainer {
                            .addRolesFieldsDiv {
                                .rolePermissionsDiv {
                                    margin: 40px 0px;

                                    .orgPermissionsDiv {
                                        padding: 20px 0px;

                                        li {
                                            list-style: none;
                                            margin-bottom: 5px;

                                            span {
                                                margin-left: 5px;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .existingRolesContainer {
                            .existingRoleTableContainer {
                                margin: 20px 0px;
                            }
                        }
                    }
                }

                .userReviewContainer {
                    .reviewUserDetailsContainer {
                        border-bottom: 1px solid #ccc;
                        margin: 0px 0px 30px;

                        .userDetailsHeader {
                            border-bottom: 1px solid #ccc;
                            padding: 15px 10px 15px 0px;

                            span {
                                font-weight: 600;
                            }
                        }

                        .reviewUserDetailsSection {
                            margin: 0px 0px 30px;
                            font-size: 14px;

                            .seectedRolesContainerROW {
                                margin-bottom: 0px !important;

                                .userRolesHeaderDiv {
                                    margin-bottom: 30px;
                                }
                            }

                            .selectedRolesDiv {
                                display: inline-block;
                                border: 1px solid #2e9c3d;
                                cursor: pointer;
                                width: 150px;
                                text-align: center;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }

                            .userRolesContainer {
                                background: #d3ffe8;
                                border: 2px solid #aef1ce;
                                border-radius: 30px;
                                cursor: default;
                                padding: 8px 15px;
                                margin-right: 8px;
                            }

                            .userRolesContainer:hover {
                                border: 2px solid rgb(0, 166, 81);
                                background: rgb(255, 255, 255) !important;
                            }

                            .userDetailUserName {
                                position: relative;
                                top: 4px;
                            }

                            .userDetailUserId {
                                position: relative;
                                top: 12px;
                            }

                            .userDetailEmailId {
                                position: relative;
                                top: 25px;
                            }

                            .row {
                                margin-bottom: 25px;
                            }
                        }
                    }

                    .usersReviewHeader {
                        margin: 0px 0px 30px;

                        .reviewCls {
                            font-weight: bold;
                            font-size: 14px;
                        }

                        .reviewNoteCls {
                            font-size: 14px;
                            font-weight: 200;
                        }
                    }
                }
            }
        }

        .userHeaderLine {
            padding: 20px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;

            .userHeader {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;

                .userHeaderText {
                    color: #2e9c3d;
                }
            }
        }

        .emailwq {
            padding-left: 5px;
            vertical-align: middle;
        }
    }

    .displyInlineBlock {
        display: inline-block;
    }

    .existingRolesContainerHeader .mat-raised-button.mat-primary {
        background-color: $taxillaDarkGreen;
    }

    .activeBtn .mat-raised-button.mat-primary {
        background-color: #47a651;
    }

    .floatRight {
        float: right;
    }

    .floatLeft {
        float: left;
    }

    tr.mat-header-row {
        height: 56px;
        background: #f1f1f1;
    }

    .mat-header-cell {
        color: rgba(0, 0, 0, 0.77);
        font-size: 14px;
        font-weight: 600;
    }

    .tableActions {
        position: relative;
        padding: 0 0 1em 0;
    }

    .logMenu {
        min-width: 260px;
        max-width: 300px;
        width: 260px;
        max-height: 350px;
        padding: 1em;
        overflow-x: hidden;
    }

    .actionCheckBox {
        margin: 1em 0 0 0;
    }

    .actionItem {
        margin: 1em 0 0 1em;
    }

    .tableActions button {
        margin: 0 1em 0 0;
    }

    .logListItems {
        -webkit-transition: all 0.3s ease-in;
        transition: all 0.3s ease-in;
    }

    .checkStyle {
        padding-left: 10px;
    }

    .checkStyle label {
        font-weight: unset;
    }

    .nextButton {
        width: 100%;
        background: $taxillaDarkGreen;
        color: white;
    }

    .cancelButton {
        width: 100%;
    }

    .cancelButtonDiv {
        margin-right: 10px;
    }

    .mat-step-header .mat-step-icon-selected,
    .mat-step-header .mat-step-icon-state-done {
        background-color: #00a84d;
        color: $cancelButtonBgColor;
    }

    .mat-step-header .mat-step-icon-state-edit {
        background-color: rgba(0, 0, 0, 0.54);
        color: $cancelButtonBgColor;
    }

    .multiHeirarchy {
        position: relative;
    }

    .multiHeirarchyDiv {
        position: relative;
    }

    .userModalDiv {
        padding: 20px 40px;
    }

    .mandatory {
        color: #d9534f;
    }

    .assignRoleHeader {
        font-weight: 600;
    }

    .userRoleSelectedRow {
        position: relative;
        top: 29px;
    }

    .assignRolesUserBlock {
        margin-bottom: -15px;
    }

    .listUserRoles {
        list-style-type: none;
    }

    .listOfRolesDiv {
        margin: 10px 0px;
    }

    .userRoleDiv {
        margin-bottom: 5px;
    }

    .noOutline,
    .noOutline:focus,
    .noOutline button {
        outline: none;
        border: 0;
        white-space: normal;
    }

    .changepassword {
        max-width: 675px;
    }

    .editProfileModal {
        max-width: 900px;

        .row.userModalRow {
            margin-top: 10px;
        }
    }

    .changepassword,
    .subscribeAssetModal,
    .editProfileModal {
        font-size: 14px;
    }

    .passwordInfo {
        max-width: 45% !important;
        margin-left: 20px;
    }

    .changepassword .matDialogBody,
    .subscribeAssetModal .matDialogBody,
    .editProfileModal .matDialogBody {
        padding: 0;
        width: 100%;
        max-width: 100%;
        margin: 0;
    }

    .changepassword .matDialogFooter,
    .subscribeAssetModal .matDialogFooter,
    .editProfileModal .matDialogFooter {
        padding: 15px 30px;
    }

    .subscribeAssetModal .matDialogFooter {
        padding: 6px;
    }

    .changepassword h4,
    .subscribeAssetModal h4,
    .editProfileModal h4 {
        font-size: 1rem;
        font-weight: 600;
        color: $significantRegFontColor;
    }

    .submitColor button {
        background: $taxillaDarkGreen;
    }

    .rejectColor button {
        background: $taxillaRedColor !important;
    }

    .zeroPaddingLeft {
        padding-left: 0px;
    }

    .zeroPaddingRight {
        padding-right: 0px;
    }

    .radioFieldName {
        margin-left: 15px;
        padding-right: 20px;
    }

    .disabledColor {
        background: rgb(235, 235, 228);
    }

    .disabledDivColor {
        color: rgba(0, 0, 0, 0.38) !important;
    }

    .disabledDivPointer {
        cursor: not-allowed !important;
    }

    #vdmContainer {
        max-width: none;
        margin-top: 0px;
        border-radius: 0px;
        background: transparent;
        -webkit-box-shadow: unset;
        box-shadow: unset;
        overflow: hidden;

        .mat-card-title {
            padding: 0 31px;
            font-size: 20px;
        }

        button.mat-raised-button.mat-default {
            background: #707070;
            color: $cancelButtonBgColor;
        }

        .vdmContainer > mat-card {
            padding: 20px 30px;
            border-radius: 0px;
        }

        .vdmContainer {
            width: 100%;
            height: 100%;
            position: relative;
            font-weight: 300;
            color: #141414;
            font-size: 14px;

            .mat-drawer-side.mat-drawer-end {
                border-left: 0;
            }

            .vdmHeaderContainer {
                border-bottom: 1px solid #ebebeb;
                padding-bottom: 15px;
                padding-top: 3px;
                margin-left: 10px;
                font-size: 16px;
                font-weight: bold;
            }

            .vdmContentContainer {
                .mat-card {
                    border-radius: 0;
                    border: none;
                    -webkit-box-shadow: none;
                    box-shadow: none;
                    background: transparent;
                    height: calc(100vh - 103px);

                    .tenantStructureCnt {
                        .tenantsList {
                            padding-left: 0px;
                            padding-right: 15px;

                            .orgId {
                                color: $lightGreyColor;
                            }
                        }

                        .levelOneTenants {
                            padding-right: 30px;
                            border-right: 1px solid $lineColor;
                            padding-left: 30px !important;

                            .searchTenants {
                                padding: 0 16px;
                                font-size: 14px;
                                padding-left: 0px;
                            }

                            .alphaFilterSection {
                                padding: 0 10px 0 0px;
                                font-size: 15px;
                                word-break: break-all;

                                .alphaFilter {
                                    margin: 4px;
                                    cursor: pointer;

                                    &.active {
                                        color: $taxillaDarkGreen;
                                        border-bottom: 1px solid $taxillaDarkGreen;
                                    }

                                    &.clearFilter {
                                        color: white;
                                        border-radius: 50%;
                                        padding: 0px 6px 3px;
                                        background: #f99999;
                                        margin-left: 10px;
                                    }
                                }
                            }

                            .tenantsList {
                                padding-top: 5px;
                                margin: 0px -14px;
                            }
                        }

                        .section-title {
                            padding: 10px 0px;
                            font-size: 16px;
                            font-weight: 600;
                            margin-bottom: 5px;
                        }

                        .appName {
                            position: relative;

                            .headerIcon {
                                margin-right: 7px;
                            }
                        }

                        .actionBtns {
                            display: none;
                            position: absolute;
                            right: 0;
                            top: 0;
                            z-index: 51;

                            .appActionBtn {
                                margin: 3px 10px;

                                button {
                                    line-height: 28px;
                                    padding-left: 10px;
                                    padding-right: 10px;
                                }
                            }
                        }

                        .appName:hover .actionBtns {
                            display: block;
                        }

                        .tenantFlatStructure {
                            float: left;

                            .section-title {
                                color: $taxillaDarkGreen;
                            }

                            .searchSubTenants {
                                padding: 0 16px;
                                font-size: 14px;
                                width: 75%;
                            }

                            .selectedAppName {
                                padding-left: 30px;
                            }

                            .appsGraphContainer {
                                margin: 10px 30px;
                                -webkit-box-shadow: 0px 1px 5px 2px $lineColor;
                                box-shadow: 0px 1px 5px 2px $lineColor;
                                overflow: auto;
                            }
                        }

                        .packageDetails {
                            .section-title {
                                display: inline-block;
                            }

                            .switchPackageAppsView {
                                display: inline-block;
                            }

                            .activeType {
                                &.listViewBtn {
                                    margin-bottom: 50px;
                                }

                                .mat-button-focus-overlay {
                                    opacity: 0.04;
                                }
                            }
                        }

                        .subOrg-item {
                            height: 40px;
                            line-height: 34px;
                            font-size: 15px;
                            display: -webkit-box;
                            display: -ms-flexbox;
                            display: flex;
                            align-items: center;

                            &:hover {
                                background: $newTaxillaLighterGreen;
                            }

                            .subOrgName {
                                margin: 0px;
                                display: inline-block;
                                width: inherit;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }

                            .toggleButton {
                                padding: 0px;
                                margin: 0px;
                                display: inline-block;

                                button {
                                    background-color: $cancelButtonBgColor;
                                    outline: none;
                                    color: #000;
                                    min-width: 50px;
                                    max-width: 60px;
                                    margin-left: 10px;
                                    line-height: normal;
                                    font-size: 11px;
                                    height: 24px;
                                    padding: 0px;

                                    &:hover {
                                        background: $taxillaDarkGreen;
                                        color: #ffffff;
                                    }
                                }
                            }
                        }

                        button.mat-menu-item {
                            height: 43px;
                            line-height: 34px;
                            font-size: 15px;

                            &:hover {
                                background: $newTaxillaLighterGreen;
                            }
                        }
                    }
                }

                .mat-form-field-infix {
                    border-top: 0.84375em solid transparent;
                }
            }
        }
    }

    #integrationsContainer {
        button.mat-raised-button.mat-default {
            background: #707070;
            color: $cancelButtonBgColor;
        }

        .integrationsContainer > mat-card {
            padding: 20px 30px;
            border-radius: 0px;
        }

        .integrationsContainer {
            width: 100%;
            height: 100%;
            position: relative;
            font-weight: 300;
            color: #141414;
            font-size: 14px;
            background: $cancelButtonBgColor;

            .mat-drawer-side.mat-drawer-end {
                border-left: 0;
            }

            .integrationsHeaderContainer {
                border-bottom: 1px solid #ebebeb;
                padding-bottom: 15px;
                padding-top: 3px;
                font-size: 16px;
                font-weight: bold;
                margin-left: 10px;
            }

            .integrationsContentContainer {
                margin: 10px 15px 0px;

                .integrationTabsContainer {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    border-bottom: 1px solid #cacaca;

                    .integrationTab {
                        -webkit-box-flex: 1;
                        -ms-flex: auto;
                        flex: auto;
                        border-top: 2px solid $cancelButtonBgColor;
                        text-align: center;
                        padding: 10px 0;
                        margin-bottom: -1px;
                        border-bottom: 1px solid #cacaca;
                        cursor: pointer;
                        border-left: 1px solid $cancelButtonBgColor;
                        border-right: 1px solid $cancelButtonBgColor;
                    }

                    .integrationTab:last-child {
                        border-right: 1px solid $cancelButtonBgColor;
                    }

                    .integrationTab:hover,
                    .integrationTab.activeIntegrationTab {
                        border-top: 2px solid #47a44f;
                        border-left: 1px solid #cacaca;
                        border-right: 1px solid #cacaca;
                        border-bottom-color: $cancelButtonBgColor;
                    }
                }

                .integrationTabContentContainer {
                    padding: 0;

                    .integrationRecordContainer {
                        padding-right: 15px;
                        padding-left: 15px;
                    }

                    .integrationsFeedTable {
                        padding: 15px;
                    }

                    .integrationFieldsContainer {
                        .integrationRecordFieldContainer {
                            display: -webkit-box;
                            display: -ms-flexbox;
                            display: flex;
                            margin: 10px 0;

                            .integrationRecordField.integrationArrayTextField {
                                .integrationRecordArrayTextFieldContainer {
                                    display: -webkit-box;
                                    display: -ms-flexbox;
                                    display: flex;

                                    .integrationRecordTextFieldContainer {
                                        width: 45%;
                                        -webkit-box-flex: 1;
                                        -ms-flex: auto;
                                        flex: auto;
                                    }
                                }
                            }

                            .integrationRecordTextFieldActions {
                                -webkit-box-flex: 1;
                                -ms-flex: auto;
                                flex: auto;
                                text-align: center;
                                display: -webkit-box;
                                display: -ms-flexbox;
                                display: flex;
                                line-height: 66px;

                                .integrationRecordFieldActionText {
                                    mat-icon {
                                        vertical-align: middle;
                                        cursor: pointer;
                                    }
                                }
                            }

                            .integrationRecordField {
                                -webkit-box-flex: 1;
                                -ms-flex: auto;
                                flex: auto;

                                mat-radio-group.inputRadioGroup {
                                    -webkit-box-orient: horizontal;
                                    -webkit-box-direction: normal;
                                    -ms-flex-direction: row;
                                    flex-direction: row;

                                    mat-radio-button {
                                        margin: 0 10px;
                                    }
                                }

                                label.fieldLabel {
                                    line-height: 36px;
                                }

                                .integrationFieldArrayTextLabel {
                                    line-height: 66px;
                                }

                                material-dropdown {
                                    button.mat-button {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }

                    .integrationRecordSubmitContainer {
                        text-align: right;
                        margin-bottom: 20px;
                        padding-right: 40px;

                        .mat-raised-button {
                            width: 150px;
                            margin: 0 5px;
                        }
                    }

                    .integrationsFeedActions {
                        margin: 0 10px 0;
                        text-align: right;
                    }

                    .mat-drawer-content {
                        overflow: auto;
                        overflow: unset;
                        height: auto;
                    }
                }
            }
        }
    }

    .integrationTabStructure {
        margin: 40px 0 20px 0;

        .integrationTabHeaders {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            border: 1px solid $lineColor;
        }

        .integrationTabHeaderText:last-child {
            margin-right: 0;
        }

        .integrationTabHeaderText:first-child {
            margin-left: 30px;
        }

        .integrationTabHeaderText {
            -webkit-box-flex: 1;
            -ms-flex: auto;
            flex: auto;
            text-align: center;
            padding: 10px 0;
            margin: 0 1px;
            cursor: pointer;
            background: $cancelButtonBgColor;
            color: rgba(0, 0, 0, 0.87);
            opacity: 0.6;
            font-weight: bold;
            font-family: $fontFamily;
            font-size: 14px;

            &:hover {
                color: #000 !important;
                opacity: 1;
            }
        }

        .integrationTabHeaderText.disabledTab {
            color: rgba(0, 0, 0, 0.38);
            cursor: not-allowed !important;
            pointer-events: none;
            font-weight: bold;
            opacity: 0.6;
        }

        .integrationTabHeaderText.activeIntegrationTabHeader {
            background: $cancelButtonBgColor;
            color: #000 !important;
            border-bottom: 2px solid $taxillaDarkGreen;
            font-weight: bold;
            font-size: 14px;
            opacity: 1;
        }

        .integrationSectionTabContent {
            padding: 2% 3%;
            background: white;
            border: 1px solid #e0e0e0;
            border-top: 0;

            .tabRecordsContainer {
                .tabRecordContainer {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;

                    .integrationTabHeaderText:first-child {
                        margin-left: 30px;
                    }

                    .integrationTabHeaderText {
                        -webkit-box-flex: 1;
                        -ms-flex: auto;
                        flex: auto;
                        text-align: center;
                        padding: 10px 0;
                        margin: 0 1px;
                        cursor: pointer;
                        background: $cancelButtonBgColor;
                        color: rgba(0, 0, 0, 0.87);
                        opacity: 0.6;
                        font-weight: bold;
                        font-family: $fontFamily;
                        font-size: 14px;

                        .fieldPositionSBS {
                            -webkit-box-flex: 1;
                            -ms-flex: auto;
                            flex: auto;
                            margin: 0 10px;
                        }
                    }

                    .tabRecordFieldsContainer {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;

                        .fieldPositionSBS {
                            -webkit-box-flex: 1;
                            -ms-flex: auto;
                            flex: auto;
                            margin: 0 10px;
                        }
                    }

                    .addRecords {
                        width: 50% !important;
                        display: flex;
                        flex: 2;
                        margin: 0 10px;

                        .fieldPositionSBS {
                            -webkit-box-flex: 1;
                            -ms-flex: auto;
                            flex: auto;
                            margin: 0 10px;
                        }
                    }

                    .placeHolderMapingClass {
                        width: 100%;
                        max-width: 95%;
                        display: flex;
                        flex-wrap: wrap;
                        margin: 0 0;
                        gap: 10px;
                        flex: 1;
                        .placeholderField {
                            flex: 1 1 auto;
                            margin: 0 10px;
                        }
                    }

                    .tabRecordFieldsContainer.hasTabRecordActions {
                        width: 65%;
                        -webkit-box-flex: 1;
                        -ms-flex: auto;
                        flex: auto;
                    }

                    .addRecordsActions {
                        -webkit-box-flex: 1;
                        display: flex;
                        position: relative;
                        top: 10px;

                        mat-icon {
                            vertical-align: middle;
                            cursor: pointer;
                            margin: 0px 60px;
                        }
                    }

                    .placeHolderMaping {
                        -webkit-box-flex: 1;
                        display: flex;
                        position: relative;
                        top: 10px;
                        // max-width: 20px !important;

                        mat-icon {
                            vertical-align: middle !important;
                            cursor: pointer !important;
                            margin: 0px 10px;
                        }
                    }

                    .tabRecordActionsContainer {
                        -webkit-box-flex: 1;
                        -ms-flex: auto;
                        flex: auto;
                        width: 35%;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;

                        .tabRecordActionContainer {
                            -webkit-box-flex: 1;
                            -ms-flex: auto;
                            flex: auto;
                            text-align: center;
                            line-height: 65px;

                            mat-icon {
                                vertical-align: middle;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }

    .logIcon {
        cursor: pointer;
    }

    .mat-menu-panel.tableLogMenu {
        max-width: 400px;
        min-height: unset !important;

        .logMenu {
            max-width: 400px;
            max-height: 350px;
            overflow-x: hidden;
            min-width: 300px;
            width: 100%;
            padding: 5px 10px;
            font-size: 13px;

            .logListItems {
                -webkit-transition: all 0.3s ease-in;
                transition: all 0.3s ease-in;
                padding-left: 10px;

                .actionCheckBox {
                    margin: 1em 0 0 0;
                }

                .mCheckbox > input:checked {
                    background-color: #2196f3;
                }
            }
        }
    }

    .locationManagementHeading {
        font-size: 16px;
        font-weight: bolder;
        color: $significantRegFontColor;
    }

    .locationMainRow {
        border-bottom: 1px solid #ebebeb;
        margin-left: 10px !important;
    }

    .locationTopRow {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 16px;
        font-weight: 700;
        float: left;
        -webkit-box-flex: 1;
        -ms-flex: auto;
        flex: auto;
        line-height: 60px;
        margin-bottom: 0px !important;
    }

    .locationTable {
        width: 100%;
    }

    .locationTable thead tr {
        height: 30px;
        width: 100%;
    }

    .locatinContent {
        max-height: 100%;
        height: 80%;
        overflow: auto;
        padding-top: 10px;
    }

    .locaitonSearchCol {
        margin-top: -25px;
        font-size: 13px;
        margin-right: 10px;
    }

    .subscriptionSearchCol {
        margin-top: -15px;
        font-size: 13px;
        margin-right: 10px;
    }

    .locationCard {
        padding: 30px 30px !important;
        height: 100% !important;
    }

    // Use this as common class for aligning center
    .textAlignCenter {
        text-align: center;
    }

    .colorGray {
        color: #868686;
    }

    .textAlignRight {
        text-align: right;
    }

    .locationMatIconPtag {
        font-size: 50px;
        margin-top: -50px;
        margin-bottom: 0px;
    }

    .locationMatIconPtag button {
        background-color: $taxillaDarkGreen !important;
    }

    .locationMatDrawer,
    .integrationsMatDrawer,
    .partnerMatDrawer,
    .loginMatDrawer {
        width: 100%;
        visibility: visible !important;
        // overriding the visibility property is counterfitting the IE drawer to not display totally
        // TAX-5514 IE Browser: User unable to add roles into the roles and permissions list ,it showing empty pop-up.
    }

    .locationMatDrawerContainer {
        width: 100%;
        height: 90%;
    }

    .taxHide {
        display: none;
    }

    mat-footer-row mat-footer-cell {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        font-style: italic;
    }

    .locationManagementContainer {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%;
    }

    .LocationMatDrawer {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .locationMatContent {
        height: 100%;
        min-height: auto !important;

        .mainAddress {
            padding: 0px 30px;
        }
    }

    .slideLeft {
        -webkit-transition: 1s;
        transition: 1s;
        right: 0;
    }

    .mat-cell:last-of-type,
    mat-header-cell:last-of-type {
        -webkit-box-flex: 0.2;
        -ms-flex-positive: 0.2;
        flex-grow: 0.2;
    }

    .locationMatDrawer {
        width: 100%;
        height: 100%;
        border-radius: 4px;
    }

    .locationManageContainer .manageFooter {
        text-align: right;
    }

    .locationManageContainer .manageFooter .cancel .mat-raised-button.mat-primary {
        margin: 0px 10px;
        outline: 0;
        background: #707070 !important;
    }

    .locationManageContainer .manageFooter .mat-raised-button.mat-primary {
        background: #47a651;
        outline: 0;
    }

    .mat-tooltip {
        font-size: 12px !important;
    }

    .mat-form-field-appearance-legacy {
        input:-webkit-autofill {
            background: none;
        }

        input:-webkit-autofill + span label {
            -webkit-transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px) !important;
            transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px) !important;
            width: 133.33333% !important;
        }
    }

    .mat-horizontal-stepper-header {
        pointer-events: none !important;
    }

    .stepperTooltipEnabled {
        .mat-horizontal-stepper-header {
            pointer-events: auto !important;
        }

        .mat-step-header:hover {
            background-color: transparent;
        }
    }

    .marginLeftClass {
        margin-left: 30px;
    }

    .marginRightBtn {
        margin-right: 10px;
    }

    .padding15 {
        padding: 15px !important;
    }

    .paddingleft15px {
        padding-left: 15px !important;
    }

    .searchEventClass {
        padding: 5px 10px !important;
    }

    .noEventSubscriptions {
        text-align: center;
        margin-bottom: 15px;
    }

    .manageroles {
        background: rgb(241, 241, 241);

        mat-drawer-container .mat-drawer-content {
            height: inherit !important;
        }

        .rolesMainDiv {
            background: white;

            mat-drawer-container {
                background: #efefef;
                min-height: 500px !important;

                .mat-drawer-inner-container {
                    overflow: hidden;
                }

                .mat-tab-body.mat-tab-body-active {
                    overflow-y: unset;
                }

                .mat-tab-body {
                    .mat-tab-body-content {
                        overflow: auto;
                        overflow: unset;
                    }
                }

                mat-drawer-container {
                    background: transparent;
                    min-height: 500px !important;

                    mat-drawer {
                        overflow: auto;
                        overflow: unset;
                        margin-bottom: 15px;
                        border-left: none;

                        .mat-drawer-inner-container {
                            overflow: auto;
                            overflow: unset;
                        }
                    }
                }

                .mat-drawer-content {
                    background: transparent;
                    height: -webkit-fill-available;
                }
            }

            .rolesTableContainer {
                height: inherit;
                background: $cancelButtonBgColor;
                margin-bottom: 15px;

                .rolesTableHeader {
                    font-size: 18px;
                    color: $significantRegFontColor;
                    border-bottom: 1px solid #ebebeb;
                    margin-left: 10px;
                    height: 62px;

                    .pull-right {
                        margin-right: 20px;
                    }

                    .manageRoleHeader {
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        font-size: 16px;
                        font-weight: bold;
                        float: left;
                        -webkit-box-flex: 1;
                        -ms-flex: auto;
                        flex: auto;
                        line-height: 60px;
                    }
                }
            }

            .copyRoleFieldsContainer {
                background: $cancelButtonBgColor;

                .copyRoleHeader {
                    font-size: 18px;
                    color: $significantRegFontColor;
                    border-bottom: 1px solid #ebebeb;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: justify;
                    -ms-flex-pack: justify;
                    justify-content: space-between;

                    .copyRoleHeaderSpan {
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 700;
                        float: left;
                        margin-left: 15px;
                        -webkit-box-flex: 1;
                        -ms-flex: auto;
                        flex: auto;
                        line-height: 60px;
                    }
                }

                .copyRoleInformation {
                    color: $significantRegFontColor;
                    font-size: 14px;
                }

                .copyRoleDiv {
                    padding: 20px 30px;
                    position: relative;
                }

                .noLocation {
                    min-height: 440px;
                    padding-top: 100px;
                    text-align: center;
                    color: $significantRegFontColor;
                    font-size: 14px;
                }

                .listOfOrgDiv {
                    padding: 10px 0px;
                    min-height: 500px;

                    .footerRole {
                        height: 40px;
                    }

                    .selectLocDiv {
                        padding: 10px 0px;
                        margin-bottom: 10px;
                    }

                    .copyRoleSelectDropdwn {
                        padding: 15px 0px;

                        li {
                            list-style-type: none;
                            padding-bottom: 5px;
                            color: $significantRegFontColor;
                            font-size: 13px;
                        }
                    }

                    .selectLocSpan {
                        font-size: 14px;
                        font-weight: bold;
                        color: $significantRegFontColor;
                    }

                    .searchLocation {
                        padding-left: 50px;
                    }

                    .selectAllDiv {
                        padding-left: 18px;
                        margin-bottom: 10px;

                        span {
                            padding-left: 5px;
                        }
                    }
                }
            }

            .assignUserFieldsContainer {
                .assignUsersHeader {
                    font-size: 18px;
                    color: $significantRegFontColor;
                    border-bottom: 1px solid #ebebeb;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: justify;
                    -ms-flex-pack: justify;
                    justify-content: space-between;

                    .assignUsersHeaderSpan {
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 700;
                        float: left;
                        margin-left: 15px;
                        -webkit-box-flex: 1;
                        -ms-flex: auto;
                        flex: auto;
                        line-height: 60px;
                    }
                }

                .assignUsersMainDiv {
                    padding: 20px 25px;
                    position: relative;

                    .partnersSelectionDiv {
                        width: 25%;
                    }

                    .partnerUsersDiv {
                        li {
                            list-style-type: none;
                        }

                        .partnerUsersHeader {
                            padding: 10px 0px;
                        }

                        .partnerUsersMainDiv {
                            padding: 10px 0px;

                            .partnerUsersMainDiv {
                                padding: 10px 0px;

                                .selectedPartnerUsers {
                                    padding-bottom: 5px;

                                    .userFullName {
                                        padding-left: 5px;
                                        font-size: 13px;
                                        color: $significantRegFontColor;
                                        max-width: 230px;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        word-wrap: normal;
                                        white-space: nowrap;
                                        display: inline-block;
                                        position: relative;
                                        top: 7px;
                                    }
                                }
                            }
                        }

                        .selectedPartnerUsers {
                            padding-bottom: 5px;

                            .userFullName {
                                padding-left: 5px;
                                font-size: 13px;
                                color: $significantRegFontColor;
                                max-width: 230px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                word-wrap: normal;
                                white-space: nowrap;
                                display: inline-block;
                                position: relative;
                                top: 3px;
                            }
                        }
                    }
                }

                .assignUsersActionButtons {
                    padding: 10px 25px;
                    position: relative;
                    top: 0;
                    overflow: auto;
                }

                .noUsersSelectedDiv {
                    position: relative;
                    width: 100%;
                    height: 345px;

                    .noUserMsg {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        -webkit-transform: translate(-50%, -50%);
                        transform: translate(-50%, -50%);
                        text-align: center;
                        color: $significantRegFontColor;
                    }
                }

                .selectLocSpan {
                    font-size: 14px;
                    font-weight: bold;
                    color: $significantRegFontColor;
                }

                .searchUser {
                    padding-left: 50px;
                }

                .partnerUsersHeader {
                    margin-bottom: 10px;
                }

                .selectAllDiv {
                    margin-bottom: 10px;

                    span {
                        padding-left: 5px;
                    }
                }
            }
        }

        .rolesWidget {
            .partnerUsersMainDiv {
                padding: 10px 0px;

                .selectedPartnerUsers {
                    padding-bottom: 5px;

                    .userFullName {
                        padding-left: 5px;
                        font-size: 13px;
                        color: $significantRegFontColor;
                        max-width: 230px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        word-wrap: normal;
                        white-space: nowrap;
                        display: inline-block;
                        position: relative;
                        top: 7px;
                    }
                }
            }

            .addRoleHeader {
                font-size: 18px;
                color: $significantRegFontColor;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                justify-content: space-between;
                border-bottom: 1px solid #ebebeb;
                margin-left: 20px;

                span {
                    padding-bottom: 10px;
                    font-size: 16px;
                    font-weight: bold;
                }
            }

            .materialStepperDiv {
                .mat-horizontal-content-container {
                    padding: 0 15px 20px !important;
                }

                .rolePermissionsDiv {
                    padding: 0px 0px 10px 0px;
                }

                .roleReviewContainer {
                    .reviewRolerDetailsContainer {
                        .roleDetailsHeader {
                            border-bottom: 1px solid #ccc;
                            padding: 15px 10px 15px 0px;

                            span {
                                font-weight: 600;
                            }
                        }

                        .reviewRoleDetailsSection {
                            font-size: 14px;
                            margin-bottom: 20px;

                            .selectedRolesDiv {
                                display: inline-block;
                                padding: 10px 20px;
                                border: 1px solid #2e9c3d;
                                margin-right: 20px;
                                cursor: pointer;
                                margin-bottom: 20px;
                                width: 150px;
                                text-align: center;
                            }

                            .row {
                                margin-bottom: 20px;
                            }
                        }

                        .rolePermission {
                            border-top: 1px solid #ccc;
                        }
                    }

                    .roleReviewHeader {
                        margin: 20px 0px;

                        .reviewCls {
                            font-weight: bold;
                            font-size: 14px;
                        }

                        .reviewNoteCls {
                            font-size: 14px;
                            font-weight: 200;
                        }
                    }
                }
            }
        }
    }

    .mat-tab-group.mat-primary .mat-ink-bar,
    .mat-tab-nav-bar.mat-primary .mat-ink-bar {
        background-color: #4ba94e;
    }

    .rolePermissionsContainer .mat-accordion {
        margin: 10px;
    }

    .assetCheckboxSpan {
        padding-left: 10px;
        font-weight: 700;
        font-size: 15px;
    }

    .locationManagementIcons {
        font-size: 16px !important;
    }

    .locationCardContent {
        border-radius: 0px !important;
        background: $cancelButtonBgColor;
        margin-bottom: 10px;

        mat-card-title {
            font-weight: bolder;
            border-bottom: 1px solid #ebebeb;
            padding: 0px 0px 15px 0px;
            font-size: 16px;
        }

        .manageCardDetails {
            padding: 0px 30px;
        }

        .manageFooter .cancelBtn {
            margin-right: 10px;
        }
    }

    .locationCardContent material-input {
        font-size: 13px;
    }

    .locationMatContent mat-card-title {
        border-bottom: 1px solid #ebebeb;
    }

    .locationMatContent .manageCardTitle {
        margin: 20px 0px 0px 0px;
        font-size: 15px;
        font-weight: bold;
    }

    .locationMatContent .sameAsMsg {
        padding-left: 10px;
        font-size: 14px;
        font-weight: bold;
    }

    .locationMatContent .mat-form-field-appearance-legacy .mat-form-field-infix {
        font-size: 13px;
    }

    .matTableMessages {
        text-align: center;
        font-style: italic;
    }

    // styles for setupattributes..
    .setupattributesContainer {
        width: 100%;
        height: 100%;
        -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
        background: #ffffff;

        .row {
            width: 100%;
            height: 100%;
            padding: 20px 30px;
        }

        .saveConfirmButtonDiv {
            right: 0px;
            text-align: right;
            padding: 15px;
        }

        .setUpAttributesCancelBtn {
            margin-right: 10px;
        }

        .saveConfirmButtonDiv .saveConfirmButton {
            text-align: right;
            padding-right: 0px;
        }

        .errorInfoMsg {
            position: absolute;
            transform: translateX(-50%) translateY(-50%);
            top: 50%;
            left: 50%;
        }
    }

    #matDrawerContent {
        background-color: $cancelButtonBgColor !important;
    }

    .setUpAttributeTitle {
        border-bottom: 1px solid #ebebeb;
        padding: 15px 15px 15px 15px;
        font-weight: 700;
        font-size: 16px;
        margin: 0px 15px;
    }

    .paddingLeftCls {
        padding-left: 10px;
    }

    .rolePermissionsContainer .mat-expansion-panel {
        margin: 20px;
    }

    .userMatIconPtag {
        margin-top: 0px;
        margin-bottom: 0px;
        cursor: pointer;
    }

    .packgePageRouteContainer {
        padding: 0 20px 20px;
        width: 100%;
        height: 100%;

        .sidenavContent {
            background: $cancelButtonBgColor;
        }
    }

    .packagePageBreadcrumbContainer {
        display: flex;
        width: 100%;
        padding: 0px 20px;
        min-height: 44px;
        line-height: 44px;

        .left-content {
            width: 90%;
            display: flex;
            justify-content: flex-start;
            min-height: inherit;
            line-height: inherit;
        }

        .right-content {
            width: 10%;
            display: flex;
            justify-content: flex-end;
            min-height: inherit;
            line-height: inherit;
        }

        .packageDetailBreadcrumbItem::first-letter {
            text-transform: uppercase;
        }

        .packageDetailBreadcrumbItem {
            display: inline-block;
            margin: 0 5px;
            min-height: inherit;
            line-height: inherit;

            span.packageDetailBreadcrumbNext {
                margin: 0 0 0 5px;
                display: inline-block;
                vertical-align: middle;
                min-height: inherit;
                line-height: inherit;
            }

            .fontSizeCls {
                font-size: 14px;
                position: relative;
                line-height: 36px;
                text-align: center;
            }

            .packageDetailBreadcrumbItem:first-child {
                margin-left: 0;
            }
        }
    }

    .packagePageBreadcrumbContainerForHome {
        width: 100%;
        display: flex;
        padding: 0px 20px 0px 32px;
        min-height: 44px;
        line-height: 44px;

        .left-content {
            width: 90%;
            display: flex;
            justify-content: flex-start;
        }

        .right-content {
            width: 10%;
            display: flex;
            justify-content: flex-end;
        }
    }

    .PackagePageContainer {
        mat-list-item.mat-list-item.active {
            background: $cancelButtonBgColor;
        }

        button.mat-raised-button.mat-default {
            background: #707070;
            color: $cancelButtonBgColor;
        }

        .matDrawer {
            overflow: hidden;
        }
    }

    .mat-search-query-builder {
        .mat-icon-button {
            outline: none;
        }

        .mat-arrow-icon {
            outline: none;
            line-height: 32px;
        }

        .mat-form-field {
            padding-left: 5px;
            padding-right: 5px;
        }

        .text-input {
            padding: 4px 8px;
            border-radius: 4px;
            border: 1px solid #ccc;
        }

        .text-area {
            width: 300px;
            height: 100px;
        }

        .output {
            width: 100%;
            height: 300px;
        }

        mat-form-field {
            width: auto;
        }

        .mat-radio-group {
            -webkit-box-orient: unset;
            -webkit-box-direction: unset;
            -ms-flex-direction: unset;
            flex-direction: unset;
        }

        .mat-form-field-appearance-legacy {
            .mat-form-field-infix {
                padding: 0.4375em 0;
            }

            .mat-form-field-wrapper {
                padding-bottom: 0.4375em;
                border: 0;
            }

            .mat-form-field-underline {
                bottom: 0.25em;
            }
        }

        .mat-form-field-appearance-legacy:not(.mat-form-field-has-label) .mat-form-field-infix {
            border: 0;
        }

        // [_nghost-c1] .q-rule[_ngcontent-c1],
        // [_nghost-c2] .q-rule[_ngcontent-c2],
        // [_nghost-c3] .q-rule[_ngcontent-c3],
        // [_nghost-c4] .q-rule[_ngcontent-c4],
        // [_nghost-c5] .q-rule[_ngcontent-c5],
        // [_nghost-c6] .q-rule[_ngcontent-c6],
        // [_nghost-c7] .q-rule[_ngcontent-c7],
        // [_nghost-c8] .q-rule[_ngcontent-c8],
        // [_nghost-c9] .q-rule[_ngcontent-c9],
        // [_nghost-c10] .q-rule[_ngcontent-c10],
        // [_nghost-c11] .q-rule[_ngcontent-c11],
        // [_nghost-c12] .q-rule[_ngcontent-c12],
        // [_nghost-c13] .q-rule[_ngcontent-c13],
        // [_nghost-c14] .q-rule[_ngcontent-c14],
        // [_nghost-c15] .q-rule[_ngcontent-c15],
        // [_nghost-c16] .q-rule[_ngcontent-c16],
        // [_nghost-c17] .q-rule[_ngcontent-c17],
        // [_nghost-c18] .q-rule[_ngcontent-c18],
        // [_nghost-c19] .q-rule[_ngcontent-c19],
        // [_nghost-c20] .q-rule[_ngcontent-c20],
        // [_nghost-c21] .q-rule[_ngcontent-c21],
        // [_nghost-c22] .q-rule[_ngcontent-c22],
        // [_nghost-c23] .q-rule[_ngcontent-c23],
        // [_nghost-c24] .q-rule[_ngcontent-c24],
        // [_nghost-c25] .q-rule[_ngcontent-c25] {
        //     border: 1px solid #f1f1f1;
        //     background: white;
        //     border-radius: 5px;
        // }

        // [_nghost-c1] .q-connector[_ngcontent-c1],
        // [_nghost-c2] .q-connector[_ngcontent-c2],
        // [_nghost-c3] .q-connector[_ngcontent-c3],
        // [_nghost-c4] .q-connector[_ngcontent-c4],
        // [_nghost-c5] .q-connector[_ngcontent-c5],
        // [_nghost-c6] .q-connector[_ngcontent-c6],
        // [_nghost-c7] .q-connector[_ngcontent-c7],
        // [_nghost-c8] .q-connector[_ngcontent-c8],
        // [_nghost-c9] .q-connector[_ngcontent-c9],
        // [_nghost-c10] .q-connector[_ngcontent-c10],
        // [_nghost-c11] .q-connector[_ngcontent-c11],
        // [_nghost-c12] .q-connector[_ngcontent-c12],
        // [_nghost-c13] .q-connector[_ngcontent-c13],
        // [_nghost-c14] .q-connector[_ngcontent-c14],
        // [_nghost-c15] .q-connector[_ngcontent-c15],
        // [_nghost-c16] .q-connector[_ngcontent-c16],
        // [_nghost-c17] .q-connector[_ngcontent-c17],
        // [_nghost-c18] .q-connector[_ngcontent-c18],
        // [_nghost-c19] .q-connector[_ngcontent-c19],
        // [_nghost-c20] .q-connector[_ngcontent-c20],
        // [_nghost-c21] .q-connector[_ngcontent-c21],
        // [_nghost-c22] .q-connector[_ngcontent-c22],
        // [_nghost-c23] .q-connector[_ngcontent-c23],
        // [_nghost-c24] .q-connector[_ngcontent-c24],
        // [_nghost-c25] .q-connector[_ngcontent-c25] {
        //     border-radius: 5px;
        // }
    }

    .widgetChartDialogModal {
        width: 98%;
        overflow: auto;
        pointer-events: auto;
        font-size: 14px;
        max-width: 100vw !important;

        .matDialogBorderBottom {
            margin-bottom: 0;

            .matDialogTitle {
                margin-bottom: 0 !important;
            }
        }
    }

    .parentWidgetModalClass {
        overflow-y: hidden !important;
    }

    .cdk-overlay-container {
        z-index: 1051;
    }

    // styles for workflow..
    .workflowContainer {
        width: 100%;
        height: 100%;
        padding: 0;
        background: #ffffff;
        -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;

        .row {
            width: 100%;
            height: 100%;
            overflow-y: hidden;
        }

        .innerContent {
            padding: 15px 30px;
        }

        .innerselectContent {
            padding: 15px 15px !important;
        }
    }

    .tableInputClass material-input mat-form-field {
        width: 80%;
    }

    .workflowSaveButton {
        text-align: right;
        padding: 10px 20px;
    }

    .addReportUl {
        list-style: none;
        padding: 0px;
    }

    .addReportUl li {
        padding-left: 10px;
    }

    .noReportMessage {
        text-align: center;
    }

    .padding25px {
        padding: 0px 25px;
    }

    .manageUsers .mat-drawer-inner-container,
    .manageroles .mat-drawer-inner-container {
        width: 100%;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        overflow: auto;
        overflow: unset;
    }

    .locationManageContainer {
        width: 100%;
        height: 100%;

        .mat-drawer-content {
            margin-right: unset !important;
        }

        .mat-drawer-container {
            height: 98%;
        }

        mat-drawer-container {
            mat-drawer {
                height: -webkit-fit-content;
                height: -moz-fit-content;
                height: fit-content;
            }
        }
    }

    .closeColor {
        background: $matIconGreyColor !important;
    }

    .integrationsFeedActions {
        text-align: right;
        padding: 10px 0 10px 0;

        button.mat-mini-fab.mat-primary {
            background: $submitButtonColor;
        }

        mat-icon {
            font-size: 22px;
        }
    }

    [slimscroll] {
        width: 100%;
    }

    #settingsContainer.PackagePageContainer {
        .packgePageRouteContainer {
            height: auto;

            .sidenavContent {
                height: auto;
                background: $cancelButtonBgColor;
                padding: 0px !important;
            }
        }
    }

    .rolesTableMainDiv,
    .userTableMainDiv {
        height: inherit;
    }

    .rolesSerachDiv {
        width: 300px;

        mat-icon {
            font-size: 24px !important;
        }

        .mat-form-field-suffix {
            margin-bottom: 10px !important;
        }
    }

    .workflowTitle {
        font-size: 16px;
        font-weight: bold;
        padding: 15px 15px;
        border-bottom: 1px solid #ebebeb;
        margin: 0px 15px;
    }

    .apiAccessWidget {
        font-weight: 300;
        color: #141414;
        font-size: 14px;

        .apiAccessHeading {
            font-size: 16px;
            padding: 0px 0px 15px 0px;
            font-weight: bold;
            border-bottom: 1px solid rgb(235, 235, 235);
        }

        .accessApiActionButtons {
            text-align: right;

            .cancelButton {
                margin-right: 10px;
            }
        }

        .userTableHeader {
            font-size: 18px;
            color: $significantRegFontColor;
            border-bottom: 1px solid #ebebeb;

            .manageUserHeader {
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                font-size: 16px;
                font-weight: bold;
                float: left;
                margin-left: 15px;
                -webkit-box-flex: 1;
                -ms-flex: auto;
                flex: auto;
                line-height: 60px;
            }
        }

        .materialStepperDiv {
            padding: 20px 30px;
        }
    }

    .searchInputField,
    .searchMasters {
        mat-icon {
            font-size: 24px !important;
        }

        .mat-form-field-suffix {
            margin-bottom: 10px !important;
        }

        .mat-button-focus-overlay {
            width: 24px;
            height: 24px;
        }
    }

    .mat-checkbox-checked.mat-accent .mat-checkbox-background,
    .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
    .mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element,
    .mat-radio-button.mat-accent .mat-radio-inner-circle,
    .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
    .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
    .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
        background-color: $taxillaDarkGreen;
    }

    .material-icons-outlined {
        color: grey;
    }

    .mat-accent .mat-pseudo-checkbox-checked,
    .mat-accent .mat-pseudo-checkbox-indeterminate,
    .mat-pseudo-checkbox-checked,
    .mat-pseudo-checkbox-indeterminate {
        background: $taxillaDarkGreen !important;
    }

    .noReportRecord {
        color: rgba(0, 0, 0, 0.87);

        .mat-pseudo-checkbox-disabled {
            display: none !important;
        }
    }

    .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
        border-color: $taxillaDarkGreen;
    }

    .mat-focused .mat-form-field-label {
        color: $taxillaDarkGreen !important;
    }

    .mat-form-field.mat-focused .mat-form-field-ripple {
        background-color: $taxillaDarkGreen;
    }

    .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
    .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
        background-color: #f44336;
    }

    .locationContainer {
        padding-left: 1px;
    }

    .childPermissionsDiv {
        background: #efefef;
        margin: 5px 0px;
    }

    .rolePermissionsContainer .mat-expansion-panel-body {
        padding: 20px 15px 16px;
    }

    %permCheckboxAndLabel {
        .assetCheckbox {
            display: inline-block;
            padding-left: 0px;
            padding-right: 0px;
            vertical-align: top;
        }

        .assetCheckboxSpan {
            display: inline-block;
            width: calc(100% - 30px);
        }
    }

    .checkboxLabel {
        white-space: initial;
    }

    .subChildPermissionDiv {
        margin: 10px 0px;

        @extend %permCheckboxAndLabel;
    }

    .permissionSectionHead {
        @extend %permCheckboxAndLabel;
    }

    .childPermissionsDivCheckBoxContainer {
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .subPermisns:first-child {
        margin-left: 10px;
    }

    .childPermission {
        padding: 0;
    }

    .parentPermissionsDiv {
        margin: 10px 0px;
    }

    .subSectionPermissionDiv {
        border-bottom: 1px solid #ccc;
        padding: 10px 0px;

        @extend %permCheckboxAndLabel;
    }

    .mainPermissionSection {
        border-bottom: 1px solid #ccc;
    }

    .subSectionPermissionDiv:last-child {
        border-bottom: none;
    }

    .entityReports {
        width: 35%;

        .matDialogBody {
            padding: 13px;
        }

        .matDialogFooter {
            padding: 18px;
        }

        .mat-checkbox {
            font-size: 14px;
        }
    }

    .addButtonBackground {
        background-color: $taxillaDarkGreen !important;
    }

    %appProcessComponentStyles {
        .appRequestStatus {
            .inprogress {
                color: $inProgressColor;
            }

            .terminated {
                color: $lightRedColor;
            }

            .completed {
                color: $submitButtonColor;
            }
        }

        .processInfoMainHeading {
            font-weight: bolder;
            font-size: 14px;
            margin-right: 30px;
            color: $placeholderHintColor;
        }

        .processInfoPara {
            color: $placeholderHintColor;
        }

        .appProcessReportingAttributes {
            display: flex;
        }

        .appProcessReportingAttributes.loaderBlock.isLoading {
            width: 155px;
            min-height: 16px;
        }

        .lastProcessedTime.loaderBlock.isLoading {
            min-width: 159px;
            min-height: 16px;
        }

        .processInfoTitle {
            font-weight: bold;
            margin-right: 5px;
            display: inline-block;
        }

        .moreHistoryPara {
            margin-top: 5px;
            margin-bottom: 5px;
        }

        .processInfoPara:last-child {
            margin-bottom: 5px;
            padding-top: 15px;
        }
    }

    .mat-tab-label {
        font-weight: bold;

        &.mat-tab-label-active {
            color: #000 !important;
            opacity: 1;

            .mat-tab-label-content {
                color: #000 !important;
                // font-weight: bold !important;
            }
        }

        &:hover {
            color: #000 !important;
            opacity: 1;
        }
    }

    .rolesTableContainer .integrationsFeedActions .material-icons,
    .userTableContainer .integrationsFeedActions .material-icons {
        font-size: 22px;
    }

    .orgPermissionsContainer {
        margin: 10px 10px;

        &.viewPermissionItem {
            li {
                &::before {
                    content: '\2713';
                }
            }
        }
    }

    .orgPermissionsContainer li {
        list-style-type: none;

        &::before {
            font-weight: bold;
            font-size: 17px;
            position: relative;
            top: 2px;
            color: $taxillaDarkGreen;
        }
    }

    .rolePermissionsCheckBoxContainer {
        padding: 0;

        li {
            list-style-type: none;

            &::before {
                font-weight: bold;
                font-size: 17px;
                position: relative;
                top: 2px;
                color: $taxillaDarkGreen;
            }

            &.permissionList::before {
                content: '\2713';
                font-weight: bold;
                font-size: 17px;
                position: relative;
                top: 2px;
                color: $taxillaDarkGreen;
            }
        }

        span {
            padding-left: 5px;
        }
    }

    .orgPermissionsContainer span {
        padding-left: 5px;
    }

    .orgSelectAllDiv {
        margin-top: 20px;
        padding-left: 25px;
        font-weight: 600;

        span {
            padding-left: 5px;
        }
    }

    .assetSelectAllDiv {
        padding-left: 35px;
        font-weight: 600;
    }

    .assetSelectionDiv {
        width: 35%;
    }

    .PermissionsDiv {
        margin-bottom: 10px;
        margin-left: 0px;
    }

    .usersSlimCls {
        background: white;
        min-height: auto !important;
    }

    .userheader {
        font-weight: bold;
        font-size: 14px;
    }

    .textEllipsis {
        width: inherit;
        overflow: hidden;
        white-space: nowrap;
        display: block;
        text-overflow: ellipsis;
    }

    .rolePermissionsContainer .mat-paginator {
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .userRoleFieldsContainer,
    .userRolePermissionsContainer {
        padding: 10px 0px;
    }

    .noPermissionsDiv {
        text-align: center;
        margin: 60px 0px;
    }

    .tableContainerWithoutPaginator {
        .ps__rail-y {
            z-index: 999;
        }
    }

    .masterTableContainer .tableContainerWithoutPaginator {
        height: calc(100vh - 246px);
    }

    .userUpdateBtnInFirstPage {
        padding-right: 10px;
    }

    button.mat-raised-button {
        -webkit-transform: none;
        transform: none;
    }

    .settingsCurtainMainDiv {
        min-height: 365px;
        margin: 0;
    }

    .subscribeAssetModal button.close.noOutline,
    .editProfileModal button.close.noOutline {
        line-height: 16px;
    }

    .mat-raised-button {
        border-radius: 4px;
    }

    tbody {
        tr.mat-row {
            td.matTableCell.mat-cell {
                max-width: 170px;
                overflow-x: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                &.file-browse {
                    max-width: 280px !important;
                }

                &.mat-column-displayDetail:hover {
                    text-overflow: unset;
                    overflow: hidden;
                    cursor: pointer;
                    white-space: pre-wrap;
                }

                &.mat-column-requestId:hover {
                    text-overflow: unset;
                    overflow: hidden;
                    cursor: pointer;
                    white-space: pre-wrap;
                    max-width: 300px;
                }

                &.mat-column-checkSum:hover {
                    text-overflow: unset;
                    overflow: hidden;
                    cursor: pointer;
                    white-space: pre-wrap;
                    max-width: 430px;
                }
            }
        }
    }

    app-confirmation {
        .modal-title {
            line-height: normal;
            font-weight: bold;
            font-size: 16px;
        }

        .modal-body {
            text-align: center;
        }

        .modal-footer > :not(:last-child) {
            margin-right: 5px;
        }

        .modal-footer > :not(:first-child) {
            margin-left: 5px;
        }
    }

    td.matTableCell.mat-column-table-actions i {
        width: 45px;
        text-align: center;
    }

    .downloadFileName {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 200px;
        display: inline-block;
    }

    .tableHeaderPadding th {
        padding: 14px 14px 0px 14px !important;
    }

    .paddingBottom30px {
        padding-bottom: 30px !important;
    }

    .visibilityVisible {
        visibility: visible !important;
    }

    ul.myAllApps {
        li.menuItem {
            text-align: center;
            background: #efefef;
            padding: 10px;
            position: relative;
            width: calc(20% - 20px);
            min-height: 200px;
            margin: 10px;
            display: inline-block;
            vertical-align: middle;

            .appActions {
                position: absolute;
                right: 10px;
                text-align: right;
                height: 20px;
                top: 10px;

                & > div {
                    margin-left: 10px;
                    display: inline-block;
                }
            }

            .menuName {
                padding: 15px 16px;
                font-size: 14px;

                .reportUnderApp {
                    color: grey;
                    font-size: 13px;
                }
            }

            .appImage {
                position: relative;
                margin-top: 40px;
                width: 100%;

                img {
                    max-width: 48px;
                }
            }

            .mat-raised-button.mat-primary {
                background-color: #b8b8b8;
                outline: none;
            }

            .mat-raised-button.mat-primary:hover {
                background-color: $taxillaDarkGreen;
                outline: none;
            }

            menuitem:hover {
                -webkit-box-shadow: 10px 10px 18px 0px rgba(0, 0, 0, 0.15);
                box-shadow: 10px 10px 18px 0px rgba(0, 0, 0, 0.15);
                cursor: pointer;
            }

            .myApps_appButtons {
                padding: 0;
                margin: 0;
                font-size: 12px;

                a {
                    text-decoration: none;
                    color: inherit;
                }
            }

            .myApps_appButton {
                padding: 0;
            }

            .myApps_appButton:first-child {
                margin-right: 3px;
            }
        }
    }

    ul.transformMyApps {
        position: relative;
        padding: 10px;
        width: 100%;
    }

    .tax-ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .homeAppsHeading {
        font-weight: bold;
        font-size: 16px;
        color: $significantRegFontColor;
    }

    .orgsList {
        min-height: 70%;
        margin: 0px -14px;
    }

    .verticalAlingTop {
        vertical-align: top;
    }

    .borderRightForSubscription {
        border-right: 1px solid $lineColor;
    }

    .borderRightForAuthentication {
        border-right: 1px solid #dcdcdc;
    }

    .subscribeAssetModal {
        width: 900px;
        max-width: 900px;
        overflow: hidden !important;

        .alphaFilterSection {
            padding: 0 10px 0 0;
            font-size: 15px;
            word-break: break-all;

            .alphaFilter {
                margin: 3px;
                cursor: pointer;
            }

            .active {
                color: $taxillaDarkGreen;
                border-bottom: 1px solid $taxillaDarkGreen;
            }

            .clearFilter {
                color: $cancelButtonBgColor;
                border-radius: 50%;
                padding: 0 6px 3px;
                background: #f99999;
                margin-left: 10px;
            }
        }

        .section-title {
            font-size: 16px;
            font-weight: bold;
        }

        .orgFlatStructure {
            .section-title {
                color: $taxillaDarkGreen;
            }

            .searchSubOrgs {
                padding: 0 16px;
                font-size: 12px;
                width: 75%;
            }

            .orgsList {
                .eachOrgList {
                    line-height: 1%;
                }

                .orgName {
                    font-size: 14px;
                }
            }
        }

        .orgId {
            color: $lightGreyColor;
        }

        .selectedAssetSection {
            .mat-form-field-infix {
                border-top: 0px !important;
            }
        }

        .selectedMatChip {
            background: #d3ffe8;
            border: 2px solid #aef1ce;

            &:hover {
                background: #fff !important;
                border: 2px solid #00a651;
            }
        }
    }

    .selectedAssetsBody {
        .mat-form-field-appearance-legacy .mat-form-field-underline {
            background: none;
            display: none;
        }
    }

    ul.myPendingApps li {
        display: inline-block;
        text-align: center;
        padding: 0px;

        .menuItem {
            padding: 20px 0px 10px 0px;
            background: $cancelButtonBgColor;
            margin: 0px 15px 15px 0px;

            .menuName {
                padding: 15px 20px;
            }
        }
    }

    ul.myPendingApps li:hover {
        -webkit-box-shadow: 10px 10px 18px 0px rgba(0, 0, 0, 0.15);
        box-shadow: 10px 10px 18px 0px rgba(0, 0, 0, 0.15);
        cursor: pointer;
    }

    .noAppsFound {
        padding: 10px 0px;
        text-align: center;
        font-style: italic;
        background: $cancelButtonBgColor;
    }

    .appsMessageText {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .paddingTop20px {
        padding-top: 20px;
    }

    .subscriptionApprovalContainer {
        .subcriptionSelectBox {
            margin-left: 30px;
            display: inline-block;
        }

        .approveBtn {
            button {
                line-height: 30px;
                margin-left: 5px;
                margin-right: 5px;
            }
        }

        .rejectBtn {
            button {
                line-height: 30px;
            }
        }
    }

    .columnsWithCheckbox mat-checkbox {
        padding-right: 5px;
    }

    .nopadding {
        padding: 0px !important;
    }

    .accessApiInputFieldContainer {
        padding-right: 20px;
    }

    .accessApiRowContainer {
        .addIconDiv {
            text-align: right;
            cursor: pointer;
        }

        .removeIconDiv {
            align-self: flex-end;
            cursor: pointer;
        }
    }

    .searchHiddenDiv {
        width: 50px;
        height: 66.25px;
    }

    #transformationsPageContainer {
        width: 100%;
        height: 100%;
        background: #ffffff;
        -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
        min-height: 540px;

        .transformationsHeaderContainer {
            padding: 3px 15px 15px 15px;
            border-bottom: 1px solid #ebebeb;
            position: relative;

            .mat-card-title {
                font-weight: bold;
                font-size: 16px;
                margin-bottom: 0;

                .transformationsAction {
                    position: absolute;
                    bottom: 10px;
                    left: 0;
                    width: 100%;
                    text-align: right;

                    button.mat-mini-fab.mat-primary {
                        background: $submitButtonColor;
                    }
                }
            }
        }

        .transformationsBodyContainer {
            padding: 20px 30px;

            .appVersionContainer {
                max-width: 300px;
                margin-bottom: 10px;
            }

            .appVersionTransformationsContainer {
                .transformationTabContentContainer {
                    .tranformationTableContainer {
                        padding: 20px 5px 5px;
                    }
                }
            }
        }
    }

    .tableColumnSelectSearch {
        width: 40%;
        display: inline-block;
        padding-top: 12px;

        .mat-focused .mat-form-field-label-wrapper {
            top: -21px;
        }
    }

    .optionWithCheckBox .mat-option-pseudo-checkbox {
        display: none !important;
    }

    .zeroPadding {
        padding: 0px !important;
    }

    .marginTop10px {
        margin-top: 10px !important;
    }

    table {
        border-collapse: initial;
    }

    .mat-simple-snackbar {
        display: block !important;
    }

    .subSideNavContainer {
        background: $cancelButtonBgColor;
        height: 100%;
    }

    .subSideNavAppContainer {
        background: $cancelButtonBgColor;
        height: 100%;
    }

    .apiContainer {
        mat-drawer-container {
            mat-drawer {
                overflow: auto;
                overflow: unset;
                height: -webkit-fit-content;
                height: -moz-fit-content;
                height: fit-content;
            }
        }
    }

    .onNewUI {
        #homePage {
            background: unset;
        }
    }

    #homePage {
        background: #efefef;

        .processAppsSearchContainer {
            text-align: right;
            margin: 16px 15px 0;
            padding: 0;
            height: 110px;

            .processAppsSearch {
                width: 100%;
                text-align: right;
                float: right;
                padding: 0 10px;
                background: $cancelButtonBgColor;
                border: 1px solid transparent;
                font-size: 14px;

                .mat-form-field-wrapper {
                    padding-bottom: 0;

                    .mat-form-field-underline {
                        background-color: #bfb5b5;
                        bottom: 0;
                    }
                }

                mat-icon {
                    font-size: 20px !important;
                    position: relative;
                    top: 3px;
                }

                .mat-form-field-suffix {
                    padding: 10px 0px;
                    display: inherit;
                }

                .mat-form-field .mat-form-field-infix input[matInput] {
                    line-height: 30px !important;
                }

                .filterSpanDiv:hover {
                    color: #09a651;
                    text-decoration: underline;
                    cursor: pointer;
                }

                .filterSpanDiv {
                    line-height: 35px;
                }

                .clearSpanDiv {
                    color: $taxillaRedColor;
                    cursor: pointer;
                }
            }
        }

        .homePageLinksContainer {
            padding: 0;
            padding-left: 15px;
        }

        .homePageEventsContainer {
            padding: 0;
            padding-right: 15px;
            margin-bottom: 15px;
        }

        .homePageAppsProcessesContainer {
            padding: 0;

            &.col-9 {
                padding-right: 15px;
            }
        }

        .homeAppProcessesTitleText {
            display: inline-block;
            font-size: 16px;
            font-family: $fontFamily;
            color: $taxillaDarkGreen;
            padding-left: 20px;
            font-weight: 600;

            .homeAppProcessesNewText {
                line-height: 20px;
                display: inline-block;
                font-size: 11px;
                vertical-align: text-top;
                height: 20px;
                padding: 0 10px;
                margin: 0px 0 0px 10px;
                background: #fa8b3b;
                color: $cancelButtonBgColor;
                border-radius: 2px;
            }
        }

        .homeProcessesAppContainer > mat-card.mat-card {
            padding: 0;
            border-radius: 0px !important;
        }

        .homeProcessesAppContainer {
            margin: 10px 15px;

            .homeAppProcessesTitle.isLoading {
                min-height: 60px;
            }

            .homeAppProcessesTitle {
                margin-bottom: 0;
                padding: 2px 0;

                .homeAppProcessesTitleText.loaderBlock.isLoading {
                    min-height: 20px;
                    min-width: 200px;
                    text-indent: 0;
                    float: left;
                    margin-left: 10px !important;
                    margin-top: 12px !important;
                }

                .homeAppProcessesTitleText {
                    display: inline-block;
                    font-size: 16px;
                    font-family: $fontFamily;
                    color: #000000;
                    padding-left: 20px;

                    .homeAppProcessesNewText {
                        line-height: 20px;
                        display: inline-block;
                        font-size: 11px;
                        vertical-align: text-top;
                        height: 20px;
                        padding: 0 10px;
                        margin: 0px 0 0px 10px;
                        background: #fa8b3b;
                        color: $cancelButtonBgColor;
                        border-radius: 2px;
                    }
                }

                .homeAppProcessesLastModified {
                    display: inline-block;
                    font-size: 12px;
                    position: absolute;
                    top: 15px;
                    right: 15px;
                    color: $significantRegFontColor;
                    font-weight: 500;
                }

                .homeAppProcessesLastModified.loaderBlock.isLoading {
                    width: 280px;
                    min-height: 16px;
                    margin-right: -5px;
                }
            }

            .homeAppProcessesContent {
                padding: 10px;
                padding-top: 0;

                .card {
                    border: none;
                }

                .homeAppProcessMainContainer {
                    .matAccordionContainerDiv {
                        margin-top: 0px;
                    }

                    .materialAccordionHeader {
                        .appActionsContainer {
                            div.bridge-parcticipanttitle {
                                display: flex;
                                align-items: center;
                                max-width: 100%;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }

                            span {
                                font-size: 15px;
                                color: $significantRegFontColor;
                            }

                            .participantAppTitle {
                                margin-right: 5px;
                                color: $lightGreyColor;
                                font-size: 13px;
                                display: inline-block;
                            }
                        }
                    }

                    .materialAccordionHeaderDescription {
                        span {
                            font-size: 13px;
                            font-weight: normal;
                            cursor: pointer;
                            color: $taxillaDarkGreen;

                            a {
                                text-decoration: none;
                                color: inherit;
                            }

                            a:hover {
                                text-decoration: none;
                                color: inherit;
                            }
                        }

                        span:hover {
                            border-bottom: 1px solid $taxillaDarkGreen;
                        }
                    }

                    .homeAppProcessBodyMainContainer {
                        margin-top: 0px;

                        &.packageApp {
                            margin-top: 0;
                        }

                        .mat-stepper-horizontal {
                            background: none;
                        }

                        .homeAppTitle {
                            font-size: 14px;
                            font-weight: 600;
                            color: $significantRegFontColor;
                        }

                        mat-step-header {
                            padding: 20px 0 10px 5px;
                        }

                        .mat-vertical-content-container {
                            margin-left: 17px;
                        }

                        .mat-stepper-vertical-line::before {
                            border-left-color: $submitButtonColor;
                            border-left-width: 2px;
                        }

                        .mat-step-icon {
                            background-color: $submitButtonColor;
                        }

                        .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::before,
                        .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::after,
                        [dir='rtl'] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::after,
                        [dir='rtl'] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::before {
                            top: 32px;
                        }

                        .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
                            top: 32px;
                        }

                        mat-horizontal-stepper {
                            .mat-step-icon {
                                background: none;
                                color: #d7d7dd;
                            }

                            mat-icon.completed {
                                color: $submitButtonColor;
                            }
                        }

                        .mat-vertical-stepper-content .mat-vertical-content::before {
                            content: '';
                            position: absolute;
                            top: -16px;
                            bottom: 0;
                            left: 0;
                            border-left-width: 2px;
                            border-left-style: solid;
                            color: $submitButtonColor;
                            border-left-color: $submitButtonColor;
                        }

                        .homeAppProcessWorkflowContainer {
                            font-size: 13px;
                            color: $significantRegFontColor;
                            padding: 0px;
                            margin-top: -25px !important;
                            position: relative;

                            @extend %appProcessComponentStyles;
                        }
                    }

                    .alternativeColorDiv .mat-expansion-panel {
                        background: #f7f7f9;
                        -webkit-box-shadow: none;
                        box-shadow: none;
                    }

                    .mat-expansion-panel {
                        background: $sectionSeperator;
                        -webkit-box-shadow: none;
                        box-shadow: none;
                    }

                    .mat-expansion-indicator {
                        display: none;
                    }

                    .mat-expansion-panel-header {
                        padding: 0px 10px 26px 10px;
                    }

                    .mat-expansion-panel-header-description {
                        margin-right: 0px;
                    }

                    .mat-expansion-panel-body {
                        padding: 0 10px 5px;
                    }

                    .homeAppProcessContainer {
                        mat-vertical-stepper {
                            .homeAppTitle {
                                font-size: 14px;
                                font-weight: 600;
                                color: $significantRegFontColor;
                            }

                            mat-step-header {
                                padding: 20px 0 10px 5px;
                            }

                            .mat-vertical-content-container {
                                margin-left: 17px;
                            }

                            .mat-stepper-vertical-line::before {
                                border-left-color: $submitButtonColor;
                                border-left-width: 2px;
                            }

                            .mat-step-icon {
                                background-color: $submitButtonColor;
                            }

                            .mat-vertical-content {
                                padding: 0 0 0 26px;

                                mat-horizontal-stepper {
                                    .mat-step-icon {
                                        background: $cancelButtonBgColor;
                                        color: #d7d7dd;
                                    }

                                    mat-icon.completed {
                                        color: $submitButtonColor;
                                    }
                                }
                            }

                            .mat-vertical-stepper-content .mat-vertical-content::before {
                                content: '';
                                position: absolute;
                                top: -16px;
                                bottom: 0;
                                left: 0;
                                border-left-width: 2px;
                                border-left-style: solid;
                                color: $submitButtonColor;
                                border-left-color: $submitButtonColor;
                            }
                        }

                        .appActionsContainer {
                            button.mat-raised-button {
                                margin: 0 10px;
                                height: 28px;
                                line-height: 25px;
                                font-size: 13px;
                                border-radius: 14px;
                                background: $cancelButtonBgColor;
                                -webkit-box-shadow: none;
                                box-shadow: none;
                                color: #000;
                                border: 1px solid $submitButtonColor;
                            }

                            button.mat-raised-button:hover {
                                background: $submitButtonColor;
                                color: $cancelButtonBgColor;
                            }

                            button.mat-raised-button:first-child {
                                margin-left: 0;
                            }
                        }

                        .homeAppHorizontalBarContainer {
                            position: absolute;
                            content: '';
                            border-top: 2px solid $submitButtonColor;
                            left: -45px;
                            top: 10px;
                            width: 35px;
                        }

                        .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::before,
                        .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::after,
                        [dir='rtl'] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::after,
                        [dir='rtl'] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::before {
                            top: 32px;
                        }

                        .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
                            top: 32px;
                        }

                        .appFilingAttributeContainer {
                            margin: 5px 0;

                            label {
                                font-weight: 600;
                            }

                            label::first-letter {
                                text-transform: uppercase;
                            }

                            span {
                                margin: 0 5px;
                            }
                        }

                        .homeAppProcessWorkflowContainer {
                            font-size: 13px;
                            font-weight: normal;
                            position: relative;
                            margin-top: -25px !important;
                            @extend %appProcessComponentStyles;
                        }

                        .workflowStagesContainer {
                            .mat-horizontal-stepper-header-container {
                                overflow-x: auto;

                                mat-step-header {
                                    min-width: min-content;
                                    padding-bottom: 10px;
                                    padding-left: 7.5px;
                                    padding-right: 7.5px;
                                }

                                .workFlowName {
                                    font-size: 15px !important;
                                    color: #636363;
                                }

                                .workFlowName.completed {
                                    color: $submitButtonColor !important;
                                }
                            }

                            ::-webkit-scrollbar {
                                height: 8px;
                            }
                            .mat-horizontal-content-container {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        .quickLinksContainer {
            margin: 15px 7px 20px 20px;
            width: calc(100% - 27px);
            background: $cancelButtonBgColor;

            .quickLinksHeading {
                padding: 10px 20px;
                border-bottom: 1px solid #ebebeb;
                font-size: 16px;
                font-weight: bold;
                color: $taxillaDarkGreen;
            }

            .quickLinksContent {
                padding: 10px 0px;

                .quickLinksName {
                    padding: 5px 20px;
                    font-size: 14px;
                    cursor: pointer;
                }

                .quickLinksName:hover {
                    background: $sectionSeperator;
                }
            }
        }

        .newsAndEventsContainer {
            background: $cancelButtonBgColor;

            .newsAndEventsContent {
                .newsContentDiv {
                    margin-top: 15px;
                    border-bottom: 1px solid #d7d7d7;
                    padding: 0px 20px;

                    .newsContentDate {
                        display: inline-block;
                        border: 2px solid $taxillaDarkGreen;
                        text-align: center;
                        margin: 5px 10px 15px 0px;
                        height: 46px;
                        width: 46px;
                        font-size: 12px;
                        color: $matIconGreyColor;

                        .dateText {
                            font-size: 18px;
                            line-height: 16px;
                            padding: 7px 0px 0px 0px;

                            .dataTextMonth {
                                font-size: 12px;
                            }
                        }
                    }

                    .newsContentHeading {
                        font-size: 16px;
                        font-weight: bold;
                        width: 70%;
                        display: inline-block;
                        vertical-align: top;
                    }

                    .newsContent {
                        font-size: 12px;
                        color: #636363;
                        margin-bottom: 5px;
                    }

                    .viewMore {
                        font-size: 13px;
                        margin-bottom: 15px;
                        display: inline-block;
                        cursor: pointer;
                        text-decoration: none;
                        color: $taxillaDarkGreen;
                    }
                }
            }

            .newsAndEventsHeader {
                padding: 0px;
                border-bottom: 1px solid $taxillaDarkGreen;

                .newsHeader,
                .eventsHeader {
                    text-align: center;
                    display: inline-block !important;
                    padding: 10px 10px;
                    font-size: 13px;
                    color: $taxillaDarkGreen;
                }
            }
        }

        .faqsContainer {
            background: $cancelButtonBgColor;

            .faqsContentDiv {
                margin-top: 15px;
                border-bottom: 1px solid #d7d7d7;
                padding: 0px 20px;

                .faqsContentHeading {
                    font-size: 16px;
                    font-weight: bold;
                    display: inline-block;
                    vertical-align: top;
                }

                .faqsContent {
                    font-size: 12px;
                    color: #636363;
                    margin-bottom: 5px;
                    margin-top: 15px;
                }

                .viewMore {
                    font-size: 13px;
                    margin-bottom: 15px;
                    display: inline-block;
                    cursor: pointer;
                    text-decoration: none;
                    color: $taxillaDarkGreen;
                }
            }

            .faqsHeader {
                font-size: 16px;
                font-weight: bold;
                padding: 10px 20px;
                border-bottom: 1px solid #d7d7d7;
                color: $taxillaDarkGreen;
            }
        }
    }

    .height100 {
        height: 100% !important;
    }

    .height110 {
        height: 110% !important;
    }

    .indexDataContainer {
        height: 100%;
        background: $cancelButtonBgColor;

        .indexHeader {
            font-size: 16px;
            font-weight: bold;
            padding: 18px 0;
        }

        .indexDataBody {
            padding: 0 30px;
        }

        .orgStructureCnt {
            padding: 20px 0px 0px 0px;

            .orgId {
                color: $lightGreyColor;
            }

            .levelOneOrgs {
                padding: 0px 10px 0px 0px;

                .section-title {
                    margin-bottom: 0px !important;
                }

                .searchOrganizations {
                    padding-right: 50px;
                    font-size: 14px;
                }
            }

            .orgFlatStructure {
                .section-title {
                    margin-bottom: 0px !important;
                    color: $taxillaDarkGreen;
                }
            }

            button.mat-menu-item {
                font-size: 15px;
            }
        }

        .section-title {
            font-size: 16px;
            font-weight: 700;
        }

        .alphaFilterSection {
            padding: 0 10px 0 0px;
            font-size: 15px;
            word-break: break-all;

            .alphaFilter {
                margin: 3.7px;
                cursor: pointer;

                &.active {
                    color: $taxillaDarkGreen;
                    border-bottom: 1px solid $taxillaDarkGreen;
                }

                &.clearFilter {
                    color: white;
                    border-radius: 50%;
                    padding: 0px 6px 3px;
                    background: #f99999;
                    margin-left: 10px;
                }
            }
        }

        .indexActionButtons {
            text-align: right;
            padding: 10px 0px 20px 0px;

            .cancelBtn {
                margin-right: 10px;
            }
        }

        .assetList {
            padding: 20px 0px 0px 0px;

            .assetHeader {
                display: inline-block;
                font-size: 16px;
                font-weight: 700;
                padding: 0px;
            }
        }

        .bridgeNodesList {
            padding: 20px 0px 0px 30px;

            .assetHeader {
                display: inline-block;
                font-size: 16px;
                font-weight: 700;
                padding: 0px;
            }
        }

        .matTabGroup {
            min-height: 400px;
        }

        .searchSubOrgs {
            padding-right: 50px;
        }
    }

    .paddingleftzero {
        padding-left: 0px !important;
    }

    .noMarginCls {
        margin: 0px !important;
    }

    .addRemoveUserCls {
        padding: 50px 0px;
    }

    .removeBtnInUsers {
        padding: 0px 55px;
    }

    .userListReviewContainer {
        border: 1px solid #efefef;
        padding: 10px 10px;
        margin-left: 10px;
        margin-bottom: 10px;
    }

    .userListReviewSpanConatiner {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow-x: hidden;
    }

    .width100 {
        width: 100% !important;
    }

    .width60 {
        width: 60%;
    }

    .newsHeaderActive {
        background: $taxillaDarkGreen;
        color: $cancelButtonBgColor !important;
    }

    .workqMainDiv {
        background: #efefef;

        .workqSlimCls {
            padding: 30px;

            .workqContainer {
                background: $cancelButtonBgColor;
            }

            .workqRow {
                margin-right: 0px;
            }

            .workqMainHeader {
                padding: 5px 20px;
                border-bottom: 1px solid #d7d7d7;

                .workqInfoIcon {
                    vertical-align: middle;
                    padding-left: 10px;
                    height: 18px;

                    .material-icons {
                        font-size: 20px;
                        color: $matIconGreyColor;
                    }
                }

                .workqNameCls {
                    font-size: 16px;
                    color: $significantRegFontColor;
                    font-weight: bold;
                    margin-left: 20px;
                    line-height: 36px;
                    vertical-align: middle;
                }

                .WorkqBack {
                    padding-right: 50px !important;
                }
            }

            .feedMainDiv {
                padding-right: 0px;
            }

            .workdetailMainDiv {
                padding: 0px 15px 0px 0px;

                .noRecordMainContainer {
                    width: 100%;
                    height: 100%;
                }
            }

            .workqDetailsContainer {
                position: relative;
                height: 100%;
                width: 100%;

                .noRecordClass {
                    position: absolute;
                    -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                    left: 50%;
                    top: 50%;
                    width: 100%;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    font-size: 14px;
                    color: $significantRegFontColor;
                }

                .workDetailHeader {
                    padding: 20px 30px;
                    border-bottom: 1px solid #ccc;
                    background: #e3f8ee;
                    font-weight: bold;
                    font-size: 16px;
                    color: $significantRegFontColor;

                    .workqDetailSpan {
                        cursor: pointer;
                    }
                }

                .workqTableContainer {
                    padding: 30px 30px 15px 30px;

                    .workqMaterialTable {
                        padding: 15px 0px;

                        .mat-sort-header-button {
                            font-size: 12px;
                        }

                        .mat-cell {
                            font-size: 13px;
                        }
                    }

                    .workqDateDiv {
                        font-size: 13px;
                        color: $matIconGreyColor;
                    }
                }

                .workqMessagesSection {
                    padding: 0px 30px;
                    font-size: 14px;
                    color: $significantRegFontColor;
                }

                .workqRouterLink {
                    padding: 10px 30px;
                    font-size: 14px;
                    color: $significantRegFontColor;

                    .workqClickHereSpan {
                        padding-right: 5px;
                        color: $taxillaDarkGreen;
                        cursor: pointer;
                    }

                    a {
                        color: $submitButtonColor;
                    }
                }

                .workqdetailsRegardsDiv {
                    padding: 10px 30px;
                    font-size: 14px;
                    color: $significantRegFontColor;

                    .regardsCls {
                        font-size: 13px;
                        color: $significantRegFontColor;
                    }
                }
            }
        }
    }

    .workFeedButtonContainer .mat-raised-button.mat-primary {
        background-color: white;
        color: black;
        border: 1px solid $taxillaDarkGreen;
        border-radius: 20px;
        font-size: 12px;
        width: 110px;
        line-height: 30px;
    }

    .activeWorkQueBtn .mat-raised-button.mat-primary {
        background-color: $taxillaDarkGreen;
        color: white;
        border-radius: 20px;
        font-size: 12px;
        width: 110px;
        border: 1px solid $taxillaDarkGreen;
    }

    .settingsCurtainMenu {
        .userNameContainer {
            padding: 0 10px;
        }
    }

    .homePageWorkqDiv {
        margin: 15px 25px 0 0px;
        background: white;

        .workqViewMore {
            font-size: 13px;
            cursor: pointer;
            float: right;
            color: $taxillaDarkGreen;
            margin-top: 3px;
        }

        .workqViewMore:hover {
            text-decoration: underline;
        }

        .workqFeedContainer {
            border: none;
        }
    }

    .workqFeedContainer {
        border-right: 1px solid #ccc;

        .workFeedButtonContainer {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            padding: 18px 10px 18px 23px;

            .workqUpdateBtn {
                padding-left: 19px;
            }
        }

        .workqFeedSerchContainer {
            padding: 0 0 10px 10px;
            margin: 0;
            background: #e7e7e7;

            .mat-form-field-infix {
                border: none;
                padding-left: 10px;
            }

            mat-form-field {
                margin-bottom: -19px;
            }

            .mat-form-field-label {
                left: 10px;
            }

            .material-icons {
                font-size: 18px;
            }
        }

        .workqFeedDiv {
            .workqFeedItem {
                padding: 10px 20px;
                border-bottom: 1px solid #d7d7d7;
                cursor: pointer;
                font-size: 13px;

                .workqfeesSubjectField {
                    font-size: 14px;
                    font-weight: 600;
                }
            }

            .feedActiveWorkqItem {
                background: #e3f8ee !important;
                border-left: 3px solid $taxillaDarkGreen;

                &:hover {
                    background: #e3f8ee !important;
                }
            }

            .workqFeedItem:hover:not(.feedActiveWorkqItem) {
                background: #f9f9f9;
            }

            .markAsReadSpan {
                float: right;
                color: $taxillaDarkGreen;
            }
        }
    }

    .homePageWorkqHeader {
        padding: 10px 20px;
        border-bottom: 1px solid #d7d7d7;

        .workqHeaderCls {
            font-size: 16px;
            font-weight: bold;
            color: $taxillaDarkGreen;
        }
    }

    .workqDetailIcon {
        border-right: 1px solid #e1e1e1;
    }

    .workdetailDisabledSpan {
        pointer-events: none;
        color: #909090;
    }

    newsContentDate dateText {
        font-size: 18px;
    }

    .tableWrap {
        max-width: 300px;
        padding: 5px 0px;
    }

    .hideRangeLable {
        .mat-paginator-range-label {
            display: none;
        }
    }

    #notifyContainer {
        max-width: none;
        margin-top: 0px;
        border-radius: 0px;
        background: transparent;
        -webkit-box-shadow: unset;
        box-shadow: unset;
        overflow: hidden;
        height: 100%;

        .mat-card-title {
            padding: 0 31px;
            font-size: 20px;
        }

        .actionButton {
            margin: 20px 2px 0px 2px;
            line-height: 20px;
            width: 30px;
            height: 30px;
        }

        .notifyContainer > mat-card {
            padding: 20px 30px;
            border-radius: 0px;
        }

        .notifyContainer {
            width: 100%;
            height: 100%;
            position: relative;
            font-weight: 300;
            color: #141414;
            font-size: 14px;

            .notifyHeaderContainer {
                border-bottom: 1px solid #ebebeb;
                padding-right: 15px;
                font-size: 16px;
                font-weight: bold;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                margin-left: 10px;

                .notifyHeaderTitle {
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    font-size: 16px;
                    font-weight: 700;
                    float: left;
                    -webkit-box-flex: 1;
                    -ms-flex: auto;
                    flex: auto;
                    line-height: 60px;
                }
            }

            .notifyContentContainer {
                padding: 15px;

                .mat-card {
                    border-radius: 0;
                    border: none;
                    -webkit-box-shadow: none;
                    box-shadow: none;
                    background: transparent;
                }

                .eventFilter {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                }

                .eventTable {
                    padding: 10px 15px;
                }

                .matTableContainer {
                    overflow: hidden;
                }

                .eventLogHedderDiv {
                    padding: 0px 15px;
                }
            }
        }
    }

    .integrationsPostManStructure {
        .subjectInput {
            margin-top: 0;
        }
    }

    .positionRelative {
        position: relative !important;
    }

    .positionAbsolute {
        position: absolute !important;
    }

    .tableSearchIcon {
        font-size: 16px;
        top: 40%;
        right: 0%;
    }

    .initiateProcessContainer {
        background: $cancelButtonBgColor;

        .header {
            border-bottom: 1px solid #d7d7d7;
            padding: 10px 10px;
            font-size: 16px;
            font-weight: 700;
        }

        .bodyContainer {
            padding: 10px;

            p {
                margin: 0px;
            }

            .bodyContainerDiv {
                .processButton {
                    float: right;
                    margin-top: -10px;
                }

                .processButton button {
                    line-height: 25px;
                    padding: 0px 10px;
                    border-radius: 0px;
                    font-size: 11px;
                }
            }
        }
    }

    .appHomePageColumnsContainer {
        .homeProcessesAppContainer.appGraphContainer {
            min-height: 351px;
        }

        .homeProcessesAppContainer {
            margin: 10px 20px 20px 14px;

            .mat-card {
                padding: 0px;
                border-radius: 0px;

                .homeAppProcessesTitle {
                    margin-bottom: 0;
                    padding: 2px 0;

                    .leftSideText {
                        display: block;
                        padding-right: 10px;
                        font-size: 13px;
                        padding-top: 5px;
                        float: right;

                        .appView {
                            color: #909090;
                            cursor: pointer;

                            .homeAppProcessesTitleText {
                                display: inline-block;
                                font-size: 16px;
                                font-family: $fontFamily;
                                padding-left: 10px;
                                font-weight: 600;

                                .homeAppProcessesNewText {
                                    display: inline-block;
                                    vertical-align: top;
                                    padding: 0 10px 0 0;

                                    .mat-icon {
                                        font-size: 18px;
                                        vertical-align: sub;
                                    }
                                }
                            }

                            .separatorPipe {
                                padding: 0px 5px;
                                color: #ebebeb;
                            }
                        }

                        .bridgeView {
                            margin-right: 20px;
                            color: #909090;
                            cursor: pointer;
                        }

                        .zoomOut {
                            .mat-icon {
                                vertical-align: bottom;
                                cursor: pointer;
                            }
                        }
                    }

                    .homeAppProcessesTitleText {
                        display: inline-block;
                        font-size: 16px;
                        font-family: $fontFamily;
                        padding-left: 10px;
                        font-weight: 600;

                        .homeAppProcessesNewText {
                            display: inline-block;
                            vertical-align: top;
                            padding: 0 10px 0 0;

                            .mat-icon {
                                font-size: 18px;
                                vertical-align: sub;
                            }
                        }
                    }
                }

                .homeAppProcessesContent {
                    padding: 10px;
                    height: calc(100% - 41px);
                    position: inherit;

                    .card {
                        border: none;
                        height: 100%;
                        min-height: 310px;

                        app-graph {
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            margin: 0;
                        }
                    }
                }
            }
        }

        .homeAppProcessContainer {
            .appPageProcess {
                margin-bottom: 10px;
            }

            .homeAppProcessMainContainer {
                padding: 10px 0px;

                .appContainer {
                    padding: 0px 10px;
                }

                .workflowContainer {
                    padding: 5px 10px;
                }
            }

            .mat-stepper-horizontal {
                background: none !important;
            }

            .mat-horizontal-stepper-header-container {
                overflow-x: auto;

                .mat-step-header {
                    min-width: 100px;
                    padding-bottom: 10px;
                    padding-left: 5px;
                    padding-right: 5px;

                    .mat-step-icon {
                        background: none;
                        color: #d7d7dd;

                        .material-icons.completed {
                            color: $taxillaDarkGreen;
                        }
                    }

                    .mat-step-label {
                        padding: 5px 0 0 0;
                        text-align: center;
                        width: 100%;

                        .workFlowName {
                            font-size: 11px !important;
                        }

                        .workFlowName.completed {
                            color: $taxillaDarkGreen !important;
                        }
                    }
                }
            }

            .mat-horizontal-content-container {
                display: none;
            }

            .fetchMoreBtn {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;

                .inProgressFilingAttrBtn {
                    margin: 0 5px;
                }
            }
        }
    }

    .inProgressFilingAttributesContainer.fromAppHome .inProgressFilingContent {
        font-size: 13px;
    }

    .inProgressFilingAttrSeparator {
        padding: 0px 2px;
    }

    .inProgressFilingAttrBtn button {
        line-height: 25px;
        border-radius: 0px;
        font-size: 13px;
    }

    .homeAppProcessContainer {
        .rencentyCompletedProcessContainer {
            .processContent {
                margin: 0px 0px 10px 0px;

                .processContentDirective {
                    padding: 10px 10px;
                    background: $evenItemBgColor;
                }
            }

            .reportingAttributes {
                padding-bottom: 10px;

                .reportingAttributesLabel {
                    font-size: 13px;
                    font-weight: bold;
                    color: $significantRegFontColor;
                }

                .attributes {
                    display: inline-block;
                    font-size: 13px;
                    margin: 0px 5px 10px 0px;
                }
            }

            .processOutcomes {
                -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                justify-content: space-between;

                .reportingAttributesLabel {
                    font-size: 14px;
                    font-weight: bold;
                    color: $taxillaDarkGreen;
                    margin-bottom: 5px;
                }

                .reportSection {
                    border: 1px solid #d7d7d7;
                    border-radius: 2px;
                    margin-left: 15px;
                    margin-right: 20px;
                    min-height: 150px;
                    max-height: 150px;
                    padding-left: 20px;
                    padding-top: 15px;
                    padding-bottom: 10px;
                    overflow: auto;

                    .reportsList {
                        list-style: none;
                        padding: 0px;

                        li {
                            cursor: pointer;
                            padding: 0 10px 0 0;
                            display: inline;

                            .reportsCls {
                                display: inline-block;
                            }

                            mat-icon {
                                margin-right: 5px;
                            }

                            .reportName {
                                font-size: 13px;
                                color: green;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                width: 30px;
                                vertical-align: bottom;
                                display: inline-block;
                            }
                        }
                    }

                    .sectionLabel {
                        font-size: 13px;
                        font-weight: bold;
                        width: 80px;
                        border-bottom: 2px solid green;
                        margin-bottom: 10px;
                    }
                }

                .eventLogs {
                    border: 1px solid #d7d7d7;
                    border-radius: 2px;
                    margin-right: 15px;
                    margin-left: 20px;
                    padding-left: 20px;
                    padding-top: 15px;
                    padding-bottom: 10px;

                    .reportsList {
                        padding-left: 20px;

                        li {
                            word-break: break-all;
                            padding: 0 10px 0 0;

                            .eventLogName {
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                    }

                    min-height: 150px;
                    max-height: 150px;

                    .sectionLabel {
                        font-size: 13px;
                        font-weight: bold;
                        width: 80px;
                        border-bottom: 2px solid green;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }

    .verticalAlingBottom {
        vertical-align: bottom;
    }

    .fontWeightBold {
        font-weight: bold !important;
    }

    .demo-chart {
        height: 300px;
        background-color: transparent;
        background-image: -webkit-gradient(
                linear,
                left bottom,
                left top,
                color-stop(24%, transparent),
                color-stop(25%, $canvasGridColor),
                color-stop(26%, $canvasGridColor),
                color-stop(27%, transparent),
                color-stop(74%, transparent),
                color-stop(75%, $canvasGridColor),
                color-stop(76%, rgba(255, 255, 255, 0.05)),
                color-stop(77%, transparent),
                to(transparent)
            ),
            -webkit-gradient(linear, left top, right top, color-stop(24%, transparent), color-stop(25%, $canvasGridColor), color-stop(26%, $canvasGridColor), color-stop(27%, transparent), color-stop(74%, transparent), color-stop(75%, $canvasGridColor), color-stop(76%, rgba(255, 255, 255, 0.05)), color-stop(77%, transparent), to(transparent));
        background-image: linear-gradient(
                0deg,
                transparent 24%,
                $canvasGridColor 25%,
                $canvasGridColor 26%,
                transparent 27%,
                transparent 74%,
                $canvasGridColor 75%,
                rgba(255, 255, 255, 0.05) 76%,
                transparent 77%,
                transparent
            ),
            linear-gradient(
                90deg,
                transparent 24%,
                $canvasGridColor 25%,
                $canvasGridColor 26%,
                transparent 27%,
                transparent 74%,
                $canvasGridColor 75%,
                rgba(255, 255, 255, 0.05) 76%,
                transparent 77%,
                transparent
            );
        background-size: 50px 50px;
    }

    .backgroundLightGray {
        background: $oddItemBgColor !important;
    }

    .backgroundLightBlue {
        background: $evenItemBgColor;
    }

    .appHomePagefilingAttributeSearchDiv {
        background: white;
        margin: 10px 0px;

        .mat-card {
            padding: 0px;
        }

        .mat-card-title {
            font-size: 16px;
            font-weight: bold;
            color: $significantRegFontColor;
        }

        .headerIcon {
            padding-right: 10px;
        }

        .searchheaderSection {
            padding: 10px;
            border-bottom: 1px solid #ccc;
        }

        .filingAttributesSearchFieldsDiv {
            padding: 0px 20px 20px 20px;
        }

        .FilingAttributesearchButton {
            text-align: right;
            margin-top: 10px;
        }

        .noFieldsDiv {
            height: 50px;
            position: relative;

            .processNewBtn {
                position: absolute;
                -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                left: 50%;
                top: 50%;
                width: 100%;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                font-size: 14px;
                color: $significantRegFontColor;
            }

            .processNewBtn material-button button {
                line-height: 28px;
            }
        }

        .mat-raised-button.mat-primary {
            border-radius: 0px;
        }
    }

    .appHomePageWidgetsDiv {
        background: white;
        margin: 15px 0px;

        .mat-card {
            padding: 0px;
        }

        .mat-card-title {
            font-size: 16px;
            font-weight: bold;
            color: #c1bcbc;
        }

        .searchheaderSection {
            padding: 10px;
            border-bottom: 1px solid #ccc;
        }

        .headerIcon {
            padding-right: 10px;
        }

        .widgetsDiv {
            padding: 0px 10px 10px 10px;
            font-size: 13px;
            color: #c1bcbc;
        }

        .mat-raised-button.mat-primary {
            border-radius: 0px;
        }
    }

    .appHomePageUsageSinceDiv {
        background: white;
        margin: 15px 0px;

        .mat-card {
            padding: 0px;
        }

        .headerIcon {
            padding-right: 10px;
        }

        .mat-card-title {
            font-size: 16px;
            font-weight: bold;
            color: $significantRegFontColor;
        }

        .searchheaderSection {
            padding: 10px;
            border-bottom: 1px solid #ccc;
        }

        .ContentDiv {
            padding: 0px 10px 10px 10px;
            font-size: 13px;
            color: $significantRegFontColor;
        }

        .mat-raised-button.mat-primary {
            border-radius: 0px;
        }
    }

    .appHomePageReleaseNotesDiv {
        background: white;
        margin: 15px 0px;

        .mat-card {
            padding: 0px;
        }

        .headerIcon {
            padding-right: 10px;
        }

        .mat-card-title {
            font-size: 16px;
            font-weight: bold;
            color: #c1bcbc;
        }

        .mat-raised-button.mat-primary {
            border-radius: 0px;
        }

        .searchheaderSection {
            padding: 10px;
            border-bottom: 1px solid #ccc;
        }

        .ContentDiv {
            padding: 0px 10px 10px 10px;
            font-size: 13px;
            color: #c1bcbc;
        }
    }

    .homeProcessesAppContainerForGraph {
        position: fixed;
        z-index: 9999;
        height: 100%;
        width: 100%;
        top: 0%;
        left: 0%;
        margin: 0px !important;
        background: rgba(0, 0, 0, 0.2);

        .mat-card {
            height: 100%;
            top: 0%;
            left: 0%;
            width: 100%;

            .demo-chart {
                height: 100% !important;
            }
        }
    }

    .appsPage .mat-form-field-flex {
        background-color: $cancelButtonBgColor !important;
    }

    .appsPage .mat-form-field-label-wrapper .mat-form-field-label {
        padding-left: 5px;
    }

    .materialAccordionHeaderDescription {
        width: 100%;
    }

    .materialAccordionHeaderDescription messagepopup {
        /* margin: .75em 0 0 0; */
        position: absolute;
    }

    .noRecordMessage {
        font-size: 14px;
        text-align: center;
    }

    .noWorkflowMessg {
        text-align: center;
        margin: 36px;
        font-size: 14px;
    }

    .processAppsMainContainer .noRecordMessage {
        margin: 25px;

        .navigateToLink {
            cursor: pointer;
            color: $taxillaDarkGreen;

            &:hover {
                color: $taxillaDarkGreen;
            }
        }
    }

    .processAppsMainContainer {
        .importantMsgBlock {
            margin: 16px 15px 0px 15px;
            background: #ffffff;
            padding: 15px 20px 2px 15px;
            font-size: 14px;
            color: $significantRegFontColor;
            border: 1px solid #ffaaaa;
            position: relative;
        }

        .impHeading {
            font-size: 15px;
            font-weight: 500;
            color: $cancelButtonBgColor;
            background: #ff6b6b;
            padding: 3px 6px;
            width: 98%;
        }

        .impMsg {
            margin-bottom: 8px;
            padding-left: 6px;
        }

        .closeImpMsg {
            position: absolute;
            top: 2px;
            right: 2px;
            cursor: pointer;
            font-size: 20px;

            &:hover {
                background: #f1f1f1;
            }
        }
    }

    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
    .mat-vertical-stepper-header {
        padding: 17px 24px 10px 24px;
    }
    .fixedHomeLink {
        position: absolute;
        left: -15px;
    }

    .fixedHomeEvent {
        position: absolute;
        right: -15px;
    }

    .homeProcessAfterScroll {
        position: rleative;
        left: 25%;
    }

    .displayflex {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .appPermissionsList {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        padding-bottom: 5px;
    }

    .roleFieldsContainer {
        .roleFieldsContainerRow {
            margin-bottom: 20px;
        }
    }

    .permissionsTopRow {
        overflow: hidden;

        .search-form-field {
            float: right;
        }

        .searchAssetDiv {
            float: right;
        }
    }

    .masterSelectAllDiv {
        padding-top: 15px;
        text-align: left;
        margin-right: 26px;
        font-size: 15px;
        padding-left: 4px;
        color: $significantRegFontColor;

        span {
            padding-left: 5px;
        }
    }

    .matDrawerSlideCss {
        -webkit-transform: none !important;
        transform: none !important;
        visibility: visible !important;
        width: 75px !important;
        background-color: $settingsPageBackgroundColor;
    }

    .matDrawerContentCss {
        margin-left: 75px !important;
    }

    .userIdIcon {
        color: $taxillaDarkGreen;
    }

    .noRecordFound {
        margin-top: 15px;
    }

    .integrationFieldsContainer {
        .integrationRecordFieldContainer {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;

            .integrationRecordField.integrationArrayTextField {
                .integrationRecordArrayTextFieldContainer {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;

                    .integrationRecordTextFieldContainer {
                        width: 45%;
                        -webkit-box-flex: 1;
                        -ms-flex: auto;
                        flex: auto;
                    }
                }
            }

            .integrationRecordTextFieldActions {
                -webkit-box-flex: 1;
                -ms-flex: auto;
                flex: auto;
                text-align: center;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                line-height: 66px;

                .integrationRecordFieldActionText {
                    mat-icon {
                        vertical-align: middle;
                        cursor: pointer;
                    }
                }
            }

            .integrationRecordField {
                -webkit-box-flex: 1;
                -ms-flex: auto;
                flex: auto;

                mat-radio-group.inputRadioGroup {
                    -webkit-box-orient: horizontal;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: row;
                    flex-direction: row;

                    mat-radio-button {
                        margin: 0 10px;
                    }
                }

                label.fieldLabel {
                    line-height: 36px;
                }

                .integrationFieldArrayTextLabel {
                    line-height: 66px;
                }

                material-dropdown {
                    button.mat-button {
                        width: 100%;
                    }
                }
            }
        }
    }

    .integrationRecordSubmitContainer {
        .mat-raised-button {
            width: 150px;
            margin: 0 5px;
        }
    }

    .addConfiguration {
        width: 65%;
    }

    .bridgeViewActive {
        color: $taxillaDarkGreen !important;
        cursor: pointer !important;
    }

    .slideSideNavArrow {
        background: $taxillaDarkGreen;
        color: white;
        cursor: pointer;
        float: right;
    }

    .manageAppstoggleMenuIcon {
        cursor: pointer;
        color: #fff;
    }

    .listPostion {
        padding-top: 30px !important;
    }

    .manageAppsListPostion {
        padding-top: 15px;
    }

    .drawerSideNavToggleCss {
        width: 75px !important;
        -webkit-transition: width 0.2s;
        transition: width 0.2s;
    }

    .sideNavCollapsed {
        .sideNavListItems {
            outline: none;
            padding: 5px 5px;

            mat-icon {
                font-size: 24px !important;
            }

            .mat-line {
                font-size: 11px;
                word-break: break-word;
            }
        }

        .sideNavListItems.active {
            color: $significantRegFontColor;
            background-color: $cancelButtonBgColor !important;
        }

        .sideNavListItems:hover {
            background: rgba(0, 0, 0, 0.04);
        }

        .manageAppsSideNavList {
            &:hover {
                background: none !important;
            }

            .clickedActiveApp:hover {
                background: $cancelButtonBgColor;
            }
        }

        .manageAppsListItems {
            padding: 15px 5px;
            cursor: pointer;

            &:hover {
                background: rgba(0, 0, 0, 0.04);
            }
        }
    }

    .appHomeDrawerContainer {
        .packageMatDrawer {
            width: 6% !important;

            .sideNavListItems {
                outline: none;
                padding: 15px 5px;

                mat-icon {
                    font-size: 24px !important;
                }

                .mat-line {
                    font-size: 10px;
                    font-weight: bold;
                    color: $matIconGreyColor;
                    word-break: break-word;
                }
            }

            .sideNavListItems.active {
                color: $significantRegFontColor;
                background-color: $cancelButtonBgColor !important;
            }

            .sideNavListItems:hover {
                background: rgba(0, 0, 0, 0.04);
            }
        }
    }

    .inboundIntegrationsContainer {
        mat-drawer-content {
            margin-left: 0% !important;
            width: 100% !important;
            background: #ffffff !important;
        }

        .inboundBox {
            background: $cancelButtonBgColor !important;
            -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
            box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
            margin-bottom: 15px;
        }

        .inboundBoxTable {
            background: $cancelButtonBgColor !important;
            -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
            box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
            height: inherit;
        }
    }

    .uniqueSearch {
        mat-icon {
            font-size: 20px !important;
        }

        .integAddIcon {
            mat-icon {
                font-size: 22px !important;
            }
        }

        .mat-form-field-suffix {
            margin-bottom: 10px !important;
        }

        .workqSearchIcon {
            border: none;
            background: none;
        }
    }

    .searchForApps {
        text-align: left !important;
        width: 95% !important;
        margin-right: 10px;
    }

    .searchAlign {
        vertical-align: super;
    }

    .appsSearch {
        background: $cancelButtonBgColor;
        padding: 0px;
        position: relative;
        float: right;
        top: -34px;
    }

    .appHomePageAppsProcessesContainer {
        padding: 0 20px;
    }

    .appHomePageLinksContainer {
        padding: 0px;
        overflow-y: auto;
        height: calc(100vh - 95px);
    }

    .FilingAttributesearchButton material-button button {
        line-height: 28px;
    }

    .homeAppProcessesGraphContent {
        padding: 0px !important;
    }

    .packageDetailBreadcrumbNewItem {
        font-size: 18px;
        font-weight: bold;
        color: $significantRegFontColor;

        .appsInfoIcon {
            color: #626262;
            margin: 5px;
            font-size: 19px;
            cursor: pointer;
        }

        .appsInfoIcon:hover {
            color: $taxillaDarkGreen;
        }

        .appsHelpIcon {
            margin-left: 0px;
        }
    }

    .permissionsTabContentContainer {
        overflow: hidden;
    }

    .rolePermissionsContainer .mat-expansion-panel-header {
        background: $settingsPageBackgroundColor !important;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0 15px;
        border-radius: inherit;
    }

    .graphCardBody {
        padding: 5px;
        height: 100%;
    }

    .appsLinks {
        display: inline-block;
        margin: 0 3px;
    }

    .appLinksHover {
        display: inline-block;
        color: $taxillaDarkGreen;
        cursor: pointer;

        a:hover {
            color: inherit;
            text-decoration: none;
        }

        a {
            color: inherit;
            text-decoration: none;
        }
    }

    .appLinksHover.loaderBlock.isLoading {
        min-width: 20%;
        min-height: 16px;
    }

    .appLinkPipe {
        color: #d7d7d7 !important;
    }

    .ParticipantAppView {
        margin: 0x;
    }

    .appsSubscribeLink {
        display: inline-block;
        color: $taxillaDarkGreen;
        text-align: center;
        cursor: pointer;
        font-size: 14px;
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
    }

    .appsSubscribeBlueLink {
        display: inline-block;
        color: $taxillaDarkGreen;
        text-align: center;
        cursor: pointer;
        font-size: 12px;
        padding: 5px;
    }

    .brideAssetLink {
        margin: 0 5px;
    }

    .removePaddingBottom .mat-expansion-panel-header {
        padding-bottom: 17px !important;
    }

    .reportAttributeValueColor {
        color: $taxillaDarkGreen;
    }

    .reportsAttributePipe {
        color: #d7d7d7;
    }

    .processDetails {
        float: right;
    }

    .fetchMoreBtn button {
        background-color: #707070 !important;
    }

    .DisabledColor button {
        color: #c7c7c7;
    }

    .homePageAppSearch .mat-form-field-underline {
        display: none !important;
    }

    .homePageAppSearch .mat-form-field-infix {
        border: 0px;
        padding: 10px 0px;
        display: inherit;
        line-height: 35px;
    }

    .homePageAppSearch .mat-icon-button {
        vertical-align: inherit;
    }

    #appHomePage {
        .homeAppProcessesTitle {
            border-bottom: 1px solid #d7d7d7;
        }
    }

    .appRequestStatus.loaderBlock.isLoading {
        min-width: 240px;
        min-height: 16px;
    }

    .workflowStagesContainer.loaderBlock.isLoading {
        width: 100%;
        height: 16px;
        margin: 5px 0;
    }

    @-webkit-keyframes GradientDualToneAnimation {
        0% {
            background-position: 0% 50%;
        }

        50% {
            background-position: 100% 50%;
        }

        100% {
            background-position: 0% 50%;
        }
    }

    @keyframes GradientDualToneAnimation {
        0% {
            background-position: 0% 50%;
        }

        50% {
            background-position: 100% 50%;
        }

        100% {
            background-position: 0% 50%;
        }
    }

    span.reportingAttributesLabel.ng-star-inserted {
        font-size: 13px;
        font-weight: bold;
        color: $significantRegFontColor;
    }

    .homeAppProcessMainContainer {
        .mat-expansion-panel {
            margin-bottom: 10px;
        }
    }

    .topCancelButton {
        padding: 10px 0 5px 0 !important;
    }

    .masterDataForRow {
        .marginRight20px {
            margin-right: 20px !important;

            .integrationsFeedActions {
                padding-top: 0px;
            }
        }
    }

    .flexAuto {
        -webkit-box-flex: 1;
        -ms-flex: auto;
        flex: auto;
    }

    .workflowContainerAppPage {
        padding: 5px 10px;
    }

    .marginRight20px {
        margin-right: 20px !important;
    }

    .inProgresslastProcessedData {
        padding-top: 10px;

        .reportingAttributesLabel {
            font-size: 13px;
            font-weight: bold;
            color: $significantRegFontColor;
        }
    }

    .fontWeightNormal {
        font-weight: normal !important;
    }

    .permissionList {
        list-style-type: none !important;
        display: list-item !important;
    }

    .padding0px {
        padding: 0px !important;
    }

    .postLoginHeaderToobar {
        position: relative;
        z-index: 0;
    }

    .subscriptionButtons button {
        color: #000;
    }

    .headingEllipsis {
        display: inline-block;
        width: 40%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .resetPaswdField {
        font-weight: 600;
    }

    .resetPaswdRow {
        font-size: 13px;
    }

    .partnerRejectBtn {
        background: $taxillaRedColor !important;
        color: white;
    }

    .homeAppNewText {
        line-height: 20px;
        display: inline-block;
        font-size: 11px;
        vertical-align: text-top;
        height: 20px;
        padding: 0 10px;
        margin: 0px 0 0px 10px;
        background: #fa8b3b;
        color: $cancelButtonBgColor;
        border-radius: 2px;
    }

    .homeAppTitleText {
        max-width: 230px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin: 10px 0 0px 0px !important;
    }

    .fetchAssetModalParent {
        width: 90%;
        max-width: 90% !important;

        .fetchAssetModal {
            padding: 16px;

            .myAllApps {
                padding: 0px;
                margin: 0px;

                .menuItem:hover {
                    background: #f7f5f5;
                }
            }
        }
    }

    .processDetails a.btn-link {
        font-size: 13px;
        color: $submitButtonColor;
    }

    .processSearch {
        border: 1px solid $taxillaDarkGreen !important;
        -webkit-transition: border 0.5s ease;
        transition: border 0.5s ease;
    }

    .homePageColumnsContainer {
        -ms-flex-wrap: unset !important;
        flex-wrap: unset !important;
    }

    bs-datepicker-container {
        z-index: 9999;
    }

    .homePageAppSearch {
        .mat-form-field-label-wrapper {
            top: -10px;
        }
    }

    .heightAtBottomForDatePicker {
        height: 325px;
    }

    a.noLink {
        color: inherit;
        text-decoration: none;
    }

    a.noLink:hover {
        color: inherit;
        text-decoration: none;
    }

    .homePageStepperWorkFlowName {
        font-size: 11px !important;
    }

    .homePageStepperWorkFlowName.completed {
        color: $taxillaDarkGreen !important;
    }

    .workqBtnDiv {
        border-bottom: 1px solid #d7d7d7;
    }

    .matOptionWithCrossIcon {
        width: 100%;
        padding-right: 5px;
    }

    .inputInsideSelectInput {
        width: calc(100% - 23px) !important;
        color: black !important;
    }

    .inputWithClearBtn {
        width: calc(100% - 83px) !important;
        margin-left: 5px !important;
    }

    .inputInsideSelectInputIcon {
        width: 8% !important;
        color: black !important;
    }

    .fillingDate {
        .form-control:focus {
            -webkit-box-shadow: unset !important;
            box-shadow: unset !important;
        }
    }

    .appLinksHover.integrationTestConnections {
        text-decoration: none;
        display: inline-block;
        vertical-align: top;
        margin-right: 10px;
    }

    .emailNotification {
        color: #696969;
        vertical-align: middle;
    }

    .chekBoxInsideAssetBox {
        position: absolute;
        left: 10px;
        top: 10px;
    }

    .allAppsAccordionSubscribeDiv {
        line-height: 40px;

        .allAppsAccordionSubscribeSpan {
            display: inline-block;
            color: $taxillaDarkGreen;
            text-align: center;
            cursor: pointer;
            bottom: 5px;

            .clearAll {
                margin-left: 20px;
            }
        }
    }

    .selectedAssetName {
        display: inline-block;
        border: 1px solid #e6d9d9;
        padding: 5px;

        .mat-icon {
            font-size: 12px;
            margin-left: 5px;
            height: 15px;
            vertical-align: bottom;
            text-align: right;
        }
    }

    .selectedAssetsTitle {
        font-weight: bold;
        margin-top: 15px;
        white-space: nowrap;
    }

    .noAppSelectedClass {
        text-align: center;
        font-size: 16px;
        padding: 10px;
        font-style: italic;
    }

    .selectedAppsSeparation {
        border-top: 2px solid #48a752;
        padding-top: 10px;
    }

    .transform {
        -webkit-transform: none !important;
        transform: none !important;
    }

    .margin5px {
        margin: 5px !important;
    }

    .loaderBlock.messagesCount.isLoading {
        max-height: 36px;
        border-radius: 18px;
    }

    .worqFeedLoading.loaderBlock.isLoading {
        min-width: 120px;
        min-height: 32px;
        border-radius: 16px;
    }

    .locationDetailsHeader {
        border-bottom: 1px solid #ccc;
        padding: 15px 10px 15px 0px;
        margin: 10px 25px 0;
    }

    .locationDetailsHeader span {
        font-weight: 600;
        font-size: 14px;
    }

    .displayFlexAlighCenter {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .privateAppClas {
        position: absolute;
        right: 56px;
        padding-top: 5px;
        padding-right: 5px;
        top: 6px;

        .material-icons {
            font-size: 20px;
        }
    }

    .resetPasswordApprovalDiv {
        background: white;
        margin-bottom: 15px;

        .resetPasswordApprovalHeader {
            font-size: 18px;
            color: $significantRegFontColor;
            border-bottom: 1px solid #ebebeb;
            margin-left: 10px;

            .resetPasHeader {
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                font-size: 16px;
                font-weight: bold;
                float: left;
                -webkit-box-flex: 1;
                -ms-flex: auto;
                flex: auto;
                line-height: 60px;
            }
        }

        .resetPasswordApprovalContainer {
            padding: 20px 30px;

            .selectUserDiv {
                width: 25%;
            }

            .noUserSelectedDiv {
                position: relative;
                width: 100%;
                height: 345px;

                &.passwordLinkMsg {
                    height: 100px;
                }

                .noUserSelectedSpan {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                    text-align: center;
                    color: $significantRegFontColor;
                }
            }

            .userDetailsDiv {
                margin: 15px 0px;

                .userDetailsHeader {
                    font-size: 14px;
                    font-weight: bold;
                }

                .resetPasswordBtnDiv {
                    float: right;
                    margin: 30px 15px;
                }
            }
        }
    }

    th.mat-column-log {
        position: -webkit-sticky;
        position: sticky;
        right: -1px;
        background: #f1f1f1;
    }

    user-table th.mat-column-log {
        padding-right: 0 !important;
        text-align: center !important;
    }

    .clearSelectedListBtn {
        button {
            line-height: 20px !important;
            padding: 0px 0px !important;
            width: 50px !important;
            min-width: 20px !important;
        }
    }

    .menuName.tax-ellipsis.loaderBlock.isLoading {
        padding: 0;
        min-height: 16px;
        min-width: 100px;
    }

    .mat-expansion-panel-header {
        cursor: pointer;
    }

    .quickLinksName {
        .material-icons {
            font-size: 20px;
            color: $matIconGreyColor;
        }
    }

    .mat-expansion-panel-header.mat-expanded:focus,
    .mat-expansion-panel-header.mat-expanded:hover {
        background: #e3fcf0 !important;
    }

    .filingAttributeFieldRow {
        margin-right: 0px;
    }

    .graphModalPopUp {
        padding: 10px 15px;
    }

    .graphModalWidth {
        max-width: 600px;
    }

    #scheduleJobContainer {
        min-height: 590px;
        padding-top: 0;
        background: $cancelButtonBgColor;

        .inboundIntegrationsContainer {
            .inboundBox {
                min-height: 200px;
            }
        }

        .schedulerContainerButtons {
            text-align: right;
        }
    }

    .fillingDate .mat-form-field-infix {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .infoIcon {
        font-size: 20px;
        color: darkgray;
        cursor: pointer;
    }

    .infoIcon:hover {
        color: #696969 !important;
        cursor: pointer;
    }

    .descriptionInfo {
        position: absolute;
        right: -190px;
        z-index: 1;
        background: #696969;
        padding: 12px;
        border-radius: 23px;
        top: -29px;
        width: 187px;
        color: $cancelButtonBgColor;
    }

    .moveDescToLeft .descriptionInfo {
        position: absolute;
        right: 22px;
        z-index: 1;
        background: #696969;
        padding: 12px;
        border-radius: 23px;
        top: -28px;
        width: 187px;
        color: $cancelButtonBgColor;
    }

    .subAssetInfo {
        position: relative;
    }

    .descriptionInfo:after {
        content: '';
        position: absolute;
        right: 183px;
        top: 10px;
        border-top: 10px solid transparent;
        border-left: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 12px solid #696969;
        pointer-events: none;
    }

    .moveDescToLeft .descriptionInfo:after {
        content: '';
        position: absolute;
        right: -19px;
        top: 10px;
        border-top: 10px solid transparent;
        border-left: 11px solid #696969;
        border-right: 12px solid transparent;
        border-bottom: 10px solid transparent;
        pointer-events: none;
    }

    #appDashboard {
        position: relative;
        background: transparent;

        .subscriptionsWidgetContainer {
            .matTableContainer {
                border: 1px solid #f1f1f1;
            }
        }

        .accordianWidgetPart {
            padding: 9px 0px;
        }

        .mat-expansion-panel-header {
            background-color: transparent !important;
            height: 62.5px;
        }

        .headerTitle {
            font-weight: 600;
            margin-top: 0;
            line-height: 62px;
            vertical-align: middle;
            display: inline-block;
        }

        .subcriptionSelectBox {
            margin-left: 20px;
            margin-top: 0;
            vertical-align: middle;
            display: inline-block;
        }

        .appDashboardContainer {
            padding: 20px;

            &.appDashboardWrapper {
                padding: 0;
            }

            .appDashboardContainerRow {
                height: 100%;
                border: 1px solid #f1f1f2;

                .dashboardIframe {
                    width: 100%;
                    height: 100%;

                    #newPrimary_nav {
                        display: none !important;
                    }
                }
            }

            .dashBoardViewContainer {
                .dashboardGrids {
                    background: #e6e6e6;
                    min-height: 400px;
                    height: 86.5vh !important;

                    .gridHeading {
                        border-bottom: 1px solid #d7d7d7;
                        padding: 5px 0;
                        height: 43px;

                        .gridTitleText {
                            display: inline-block;
                            font-size: 16px;
                            font-family: $fontFamily;
                            padding-left: 10px;
                            font-weight: 600;
                            vertical-align: top;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow-x: hidden;

                            .gridTitleChartType {
                                font-size: 12px;
                            }
                        }

                        .gridIcons {
                            display: inline-block;
                            padding-right: 10px;
                            font-size: 13px;
                            padding-top: 5px;
                            text-align: right;
                            padding-left: 0;
                        }
                    }

                    .dashboardGridsItem {
                        .dashboardGridsContainer {
                            height: 100%;

                            .gridBody {
                                height: calc(100% - 43px);
                                overflow-y: auto;
                                overflow-x: auto;

                                .loader {
                                    position: absolute;
                                    left: 50%;
                                    top: 50%;
                                    opacity: 1;
                                    -webkit-transform: translateX(-50%) translateY(-50%);
                                    transform: translateX(-50%) translateY(-50%);
                                }

                                .matTableContainer {
                                    height: 100%;
                                }

                                .tableChart {
                                    padding: 10px 10px 50px 10px;
                                }

                                .tableChartBlock .tableContainerWithoutPaginator {
                                    overflow: unset;
                                }

                                .tableChartBlock .matTableContainer {
                                    overflow: unset;
                                }

                                app-chart-data {
                                    width: 100%;
                                    height: 100%;
                                    display: block;
                                }
                            }
                        }
                    }
                }

                .widgetGrids.dashboardGrids {
                    min-height: 40vh !important;
                    height: calc(100vh - 195px) !important;
                }

                .noDataFound {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    font-size: 14px;
                    transform: translateX(-50%) translateY(-50%);
                    margin: 0;
                    text-align: center;
                }
            }

            .bottomDiv {
                padding: 20px;
            }

            .actionDiv {
                padding-bottom: 12px;
                padding-top: 0;
                text-align: right;
            }

            .drawerContainer {
                .dashboardMatDrawer {
                    background: rgb(239, 239, 239);
                    border: none;
                    width: 100%;

                    .mat-drawer-inner-container {
                        height: 100%;
                        background-color: white;
                        overflow: hidden;

                        .appDashboardContainer {
                            padding-top: 10px;

                            .actionDiv {
                                padding-bottom: 0px;
                                border-bottom: 1px solid green;

                                .dashBoardWidgetSearch {
                                    display: inline-block;
                                    margin-right: 20px;
                                }
                            }

                            .noWidgetFound {
                                font-style: italic;
                                margin-top: 20%;
                            }

                            &.addWidgetsContainer {
                                .actionDiv {
                                    text-align: left;

                                    .newWidgetActions {
                                        text-align: right;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .material-icons,
        i {
            color: $matIconGreyColor;
            font-size: 20px;
            margin-right: 2px;
            padding: 1px;
        }

        .header-icon {
            &:hover {
                background: $settingsPageBackgroundColor;
                border-radius: 50%;
            }
        }

        .closeColor {
            .material-icons,
            i {
                color: $cancelButtonBgColor;
                margin-right: 0;
            }
        }

        gridster-item {
            box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
            border-radius: 5px;
        }

        //TAF-2436
        .gridster-item-resizable-handler.handle-e,
        .gridster-item-resizable-handler.handle-s,
        .gridster-item-resizable-handler.handle-n,
        .gridster-item-resizable-handler.handle-w {
            width: 5px;
        }

        //TAF-2436
        .pie-label {
            font-size: 13px;
        }

        .widgetsRenderContainer {
            padding-top: 10px;
            height: 100%;

            .analyticsContainer {
                height: calc(100% - 50px);

                app-analytics {
                    display: block;
                    height: 100%;
                }
            }
        }

        .addWidgetsContainer {
            font-size: 14px;

            .headerTitle {
                margin-top: 0;
                font-size: 15px;
            }

            .mat-list-base .mat-list-option {
                font-size: 14px;
            }
        }

        .dashBoardWidgetsOptions {
            width: 50%;
            float: left;
        }

        .ngx-charts .grid-panel.odd rect {
            fill: rgba(193, 191, 191, 0.05);
            border-radius: 6px;
        }

        .chart-legend {
            .legend-label {
                color: #9197a2;

                &.filterAdded {
                    opacity: 0.5;

                    .legend-label-color {
                        opacity: 0.5;
                    }
                }
            }
        }
    }

    .backgroundGray {
        background-color: rgb(239, 239, 239) !important;
    }

    .privateMaster span.material-icons {
        font-size: 16px;
    }

    .noMargin {
        margin: 0 !important;
    }

    .headerTitle {
        display: block;
        margin-top: 25px;
    }

    .userHeaderTitle {
        display: block;
        margin-top: 20px;
    }

    .integrationHeaderTitle {
        margin-left: 15px;
    }

    .rolecls {
        min-height: unset !important;
    }

    .locationTitle {
        padding: 0 15px 10px !important;
    }

    .schedulerContainer {
        margin: 20px 30px;
        line-height: 0px;
    }

    .marginRight10px {
        margin-right: 10px !important;
    }

    .noBorderTop {
        border-top: 0px;
    }

    .headerLogo {
        height: 31px;
    }

    .dashboardSettingsModal {
        .q-button-group {
            display: none !important;
        }

        .masterSearchDialogBody {
            padding-bottom: 0px;
            background: white;

            mat-tab-group {
                width: 100%;
            }

            .loginDetailSection {
                padding: 20px 0px;
            }

            .auth2Section {
                padding: 20px 0px;
            }
        }
    }

    .paddingRightLeft15 {
        padding: 0 15px;
    }

    .displayInlineFlex {
        display: inline-flex;
    }

    .centerTheChildDiv {
        display: flex;
        align-items: center;
        justify-content: left;
    }

    .wordWrap {
        word-break: break-all;
    }

    .custom_tooltip {
        background-color: ghostwhite;
        position: relative;
        font-size: 16px !important;
        color: #000 !important;
        border-radius: 9px;
        min-width: 550px;
        min-height: 220px;
        box-shadow: 1px 1px 1px 1px lightgrey;
        font-weight: 100;
        margin-left: 180px !important;
    }

    .noWidgets {
        height: 30em;
        font-size: 14px;
    }

    .widgetStatusApprove {
        color: $taxillaDarkGreen;
        font-weight: 600;
    }

    .widgetStatusPending {
        color: #ec971f;
        font-weight: 600;
    }

    .widgetStatusDeactivate {
        color: #ff4500;
        font-weight: 600;
    }

    .indexDataMainContainer {
        margin-left: 10px !important;
    }

    .userMainTableConatiner {
        .mat-card-title {
            font-weight: bolder;
            border-bottom: 1px solid #ebebeb;
            padding: 0px 15px 15px !important;
            margin-bottom: 0;

            .backBtn {
                cursor: pointer;
                margin-top: -5px;
                margin-right: 5px;

                .actionButton.mat-icon-button {
                    width: 25px;
                }

                &:hover {
                    color: $taxillaDarkGreen;

                    mat-icon {
                        color: $taxillaDarkGreen !important;
                    }
                }

                mat-icon {
                    font-size: 1.5em;
                }
            }
        }

        .manageContentTitle {
            margin-top: 15px;
        }

        .rightPaneTitle {
            font-size: 16px !important;
            font-weight: bold;

            .editIcon {
                margin-top: -8px;

                button {
                    background-color: $submitButtonColor;
                }
            }

            .integrationEditIcon {
                margin-top: 0px;
            }
        }

        .integrationsFeedActions {
            padding: unset;
        }

        .masterDataSettings {
            visibility: unset !important;
        }
    }

    .subscriptionButtons {
        margin-top: -6px !important;
    }

    .selectSearchFilter {
        margin: 0px 17px;
    }

    .invoiceUploadButton {
        background: gray;
        padding: 6px 17px;
        color: $cancelButtonBgColor;
        cursor: pointer;
        border-radius: 5px;
    }

    material-group-checkbox {
        .materialGroupCheckboxContainer {
            line-height: 60px;

            button {
                width: 100%;
                border-bottom: 1px solid $lineColor;
                text-align: left;
                padding-left: 0;
                border-radius: 0;

                .groupCheckboxSelectedText {
                    text-overflow: ellipsis;
                    overflow-x: hidden;
                    white-space: nowrap;
                    max-width: calc(100% - 20px);
                    display: inline-block;
                }

                .dropdowns {
                    top: 50%;
                    position: absolute;
                    right: 17px;
                    -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
                }

                &.descriptionButton {
                    width: 24px;
                    height: 24px;
                    line-height: 20px;
                    top: 50%;
                    position: absolute;
                    right: -5px;
                    -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
                }

                .fulWidthMatMenuShowArrowIcon {
                    display: none;
                }

                &[aria-expanded='true'] {
                    .defaultDropdownIcon {
                        display: none;
                    }

                    .fulWidthMatMenuShowArrowIcon {
                        display: inline-block;
                    }
                }
            }

            .hintsContainer {
                line-height: 20px;
                font-size: 75%;
            }

            &.mat-error {
                padding-top: 10px;

                .hintsContainer {
                    border-top: 1px solid $taxillaRedColor;
                }
            }

            &.mat-warning {
                .hintsContainer {
                    border-top: 1px solid $taxillaOrangeColor;
                }
            }
        }
    }

    .reportattributefieldValues {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .modalBodyPopupBlock {
        margin: 0px -45px;
    }

    .graphContentRender {
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 340px;
        overflow: hidden;
    }

    .orgTimeZoneField .mat-select-value {
        padding: 3px 0;
    }

    .approvalRequestsDiv {
        border: $matIconGreyColor solid 1px;
        padding: 20px;
        display: flex;
    }

    .approvalCheckbox {
        margin-right: 10px;
    }

    .chart-legend {
        float: right;
        max-width: 17%;
    }

    .ngx-charts {
        float: left;
        overflow: visible;
        max-width: 83%;
    }

    .referredEntityDiv {
        padding: 0px 0px 10px 10px;
    }

    .referredEntityHeader {
        font-weight: bold;
    }

    .referredEntitySpan {
        padding-left: 5px;
    }

    .resetpwdfullName {
        text-transform: capitalize;
        max-width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
        white-space: nowrap;
    }

    .resetpwdHeader {
        margin-bottom: 15px;
        margin-top: 25px;
    }

    .passwordRow {
        padding: 5px 0px;
        font-size: 13px;
    }

    .resetpwdUserName {
        margin-right: 8px;
        max-width: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
        white-space: nowrap;
    }

    .resetpwdEmail {
        max-width: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: inline-block;
        white-space: nowrap;
    }

    .vdmActionButtons {
        display: inline-block;
        vertical-align: middle;
        line-height: normal;
    }

    .required {
        color: $taxillaRedColor !important;

        span {
            color: rgba(0, 0, 0, 0.87) !important;
        }
    }

    .mat-slide-toggle.mat-primary.mat-checked {
        .mat-slide-toggle-thumb {
            background-color: $taxillaDarkGreen !important;
        }

        .mat-slide-toggle-bar {
            background-color: #00a65154 !important;
        }
    }

    .togglebtn {
        text-align: end !important;
    }

    .masterSettingHeader {
        padding: 0px 10.5px !important;
        height: 50px;
    }

    .masterSettingDataActionCls {
        top: 3px !important;
    }

    .colonText {
        font-size: 15px;
        font-weight: bold;
        padding: 0px 4px 0px 0px;
    }

    .settingsHeader {
        padding: 0;
        font-weight: bold;
        text-transform: capitalize;
        border-bottom: 1px solid #ccc;
        position: relative;

        .settingsNameText {
            display: inline-block;
            line-height: 41px;
            padding-left: 15px;
            font-size: 15px;
            height: 41px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow-x: hidden;
            max-width: calc(100% - 40px);
        }

        .colonText {
            margin-right: 3px;
        }

        .masterDateSearchDiv {
            margin-top: 0;
        }

        .settingsDataActionsContainer {
            float: right;
            text-align: right;
            position: relative;
            top: 0;
            right: 5px;
            height: 41px;

            .addIcon {
                position: relative;
                top: 0;
                cursor: pointer;
            }

            .actionButtons {
                span.material-icons.hasError {
                    font-size: 24px;
                    padding-right: 0;
                }
            }
        }

        .closeIcon {
            position: relative;
            top: 10px;
            cursor: pointer;
        }
    }

    .integrationSettingsHeader {
        height: 48px !important;
    }

    .mainSearchFilters {
        font-size: 14px;
        width: 100%;
        width: 0;
        height: 0;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        top: 0px;
        z-index: 2;
        right: 0px;
        -webkit-transition: 0.5s;
        transition: 0.5s;
    }

    .mainSearchFilters.filterVisible {
        width: 82%;
        height: 200px;
        opacity: 2;
        background: $cancelButtonBgColor;

        .srchHeading {
            position: relative;
            height: 42px;
            padding: 10px 10px 10px 20px;
            border-bottom: 1px solid #a6a6a691;

            .srchHeadingLabel {
                font-size: 15px;
            }
        }

        .mat-radio-checked .mat-radio-label-content {
            font-weight: 600;
        }

        .filterSelection {
            padding-left: 20px;
        }

        .searchFilterContainer label,
        .requestFilterContainer label,
        .defaultFilterContainer label {
            font-weight: 600;
        }

        .mat-tab-label {
            opacity: 1;
            font-weight: bold;
            color: $matIconGreyColor;
        }

        .mat-tab-label.mat-tab-label-active {
            color: #333;
        }

        .mat-tab-label:hover {
            color: #333;
        }

        .actionBtnsSection {
            padding: 10px 20px;
            border-top: 1px solid $headerItemBorderColor;
        }

        .tagSearchMainDiv {
            padding: 10px 20px;

            .radioButtonGroup {
                .mat-radio-group {
                    display: inline-block;

                    .mat-radio-button {
                        padding-right: 50px;
                    }
                }
            }
        }

        .searchFooterDiv {
            padding-top: 20px;

            .cancelBtn {
                padding-left: 20px;
            }
        }
    }

    .closeOutlookSearch {
        position: absolute;
        right: 10px;
        top: 5px;
    }

    .closeOutlookSearch span.material-icons {
        font-size: 16px;
        background: $matIconGreyColor !important;
        color: $cancelButtonBgColor;
        border-radius: 50%;
        padding: 2px;
        margin: 5px;
    }

    .addIconColor {
        color: $taxillaDarkGreen !important;
    }

    .removeIconColor {
        color: $taxillaRedColor !important;
    }

    .locationsViewactionDiv {
        padding: 30px;
        overflow: auto;
    }

    .OrgEditIcon {
        .editButton {
            background-color: $taxillaDarkGreen;
            box-shadow: none;

            .material-icons {
                font-size: 14px !important;
                position: relative;
                left: -1px;
                bottom: 6px;
            }
        }

        .mat-mini-fab {
            height: 21px !important;
            width: 21px !important;
        }
    }

    .orgSettingsHeader {
        .settingsDataActionsContainer {
            position: relative;
            overflow: hidden;

            .OrgEditIcon {
                height: 21px;
                padding-top: 12px;
            }
        }
    }

    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header {
        background: none;
    }

    .serviceTypesSelectDiv {
        padding: 0px;
        padding-right: 30px;
    }

    .manageSubscriptionContainer {
        background: $cancelButtonBgColor;

        .mat-form-field-infix {
            display: inherit;
        }

        .filterSpanDiv:hover {
            color: #09a651;
            text-decoration: underline;
            cursor: pointer;
        }

        .filterSpanDiv {
            line-height: 20px;
        }
    }

    .searchInputField {
        mat-icon {
            font-size: 20px !important;
        }
    }

    .clearSpanDiv {
        color: $taxillaRedColor;
        cursor: pointer;

        mat-icon {
            font-size: 20px;
            line-height: 30px;
        }
    }

    .mat-form-field-appearance-legacy .mat-form-field-underline {
        background-color: rgba(0, 0, 0, 0.24);
    }

    @media only screen and (min-width: 1056px) and (max-width: 1366px) {
        .workFeedButtonContainer {
            padding: 10px 10px 10px 5px !important;
        }
    }

    .dateClearIcon {
        font-size: 18px;
    }

    .dashBoardWidgetSearch {
        .search-form-field {
            width: unset;
        }
    }

    .messagesMenuMainContainer {
        max-width: 500px;
    }

    span.material-icons {
        &.hasError {
            color: $taxillaRedColor;
        }

        &.hasWarning {
            color: $taxillaOrangeColor;
        }
    }

    .entityFieldContainer {
        flex: 0 0 100%;
        max-width: 100%;
        width: 100%;
        padding: 0;

        .fieldInReadOnly {
            height: 30px;

            .readOnlyFieldValues {
                display: inline-flex;
                margin: 5px 0;

                .readOnlyLabel {
                    font-size: inherit;
                    margin-left: 25px;
                    line-height: 24px;
                    padding-right: 10px;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    color: #9c9c9c;
                }

                .readOnlyValue {
                    padding-left: 10px;
                }

                .userViewLabel {
                    width: 105px;
                }

                .locationViewLabel {
                    width: 180px;
                }

                .orgViewLabel {
                    width: 205px;
                }

                .userRolesViewLabel {
                    padding: 8px 10px 0 0;
                }
            }
        }

        .userRoleFieldInReadonly {
            min-height: 40px !important;
            height: auto;
        }
    }

    .scheduleJobContainer {
        .inboundIntegrationsBodyContainer {
            min-height: unset !important;
        }
    }

    .outlookSearchContainer {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        font-weight: 400;
        text-align: left;

        .mainSearch {
            height: 41px;
            padding: 5px;

            .mainSearchSearcher {
                position: relative;
                padding: 0 0.5em;
                height: 32px;
                min-width: 250px;
                -webkit-transition: all 300ms ease;
                transition: all 300ms ease;

                .searchIcon {
                    font-size: 19px;
                    color: $matIconGreyColor;
                }
            }

            .searchBorder {
                border: 1px solid lightgray;

                input {
                    width: calc(100% - 20px);
                    outline: none;
                    border: 0;
                }
            }
        }

        .masterMainSearch {
            padding: 0 !important;
        }
    }

    .groupCheckboxContainer {
        width: 100%;
        min-width: 40vh !important;
        max-width: unset !important;

        .checkBoxContainer {
            padding-left: 10px;
            margin: 15px 0;
        }

        .mat-menu-content {
            .ps__rail-y {
                right: 0;
                left: unset !important;
                display: none !important;
            }
        }
        .groupCheckboxListContainer {
            min-height: 200px;
            max-height: 400px;
            max-width: 500px;
            overflow-y: auto !important;
        }
    }

    .paddingTop15px {
        padding-top: 15px;
    }

    .nav-shadow {
        box-shadow: 0 3px 5px -1px #0003, 0 6px 10px #00000024, 0 1px 18px #0000001f;
    }

    .is-loading > span.mat-option-text {
        display: flex;
        justify-content: center;
    }

    .mat-autocomplete-panel .mat-option {
        &.is-loading {
            height: 15rem !important;
        }

        &.no-results {
            height: 15rem !important;
            text-align: center;
            white-space: pre-wrap;
        }
    }

    .analyticsFilterMenu {
        .activeMenuItem {
            background: $submitButtonColor;
            color: #fff;

            &.mat-menu-item:hover:not([disabled]) {
                background: $submitButtonColor;
                color: #fff;
            }
        }
    }

    .parentSubscribeAssetModal {
        .mat-dialog-container {
            overflow-x: hidden;
        }
    }

    &.onNewUI {
        #settingsContainer {
            mat-drawer-container {
                background: transparent;

                .matDrawer {
                    background-color: transparent;

                    .mat-drawer-inner-container {
                        background: transparent;
                    }
                }
                .settingsMenu {
                    background-color: transparent;
                }
            }
            .sidenavContent {
                background: transparent;
            }
        }

        .manageAppsLeftNav {
            background: transparent;
        }

        .workqMainDiv {
            background: none;
        }

        #signinmaindiv {
            .signInDetailsDiv {
                background: transparent;

                mat-card {
                    background: transparent;

                    .loginSummaryContainer,
                    .forgotPasswordSummaryContainer {
                        background: transparent;
                    }

                    .loginDetails {
                        background: #fff;
                    }
                }
            }
        }
    }
}

.lastRefresh {
    display: inline-block;
}

.h-100 {
    height: 100%;
}

.manageSubscriptionHeader {
    margin: 10px 20px 0;
    padding-right: 30px;
    height: 40px;
}

.manageAppsLeftNav {
    background: #efefef;
    height: 100%;

    .manageAppsSlideSideNavArrow {
        background: $taxillaDarkGreen;
        color: white;
        cursor: pointer;
        width: 15%;
        height: 37px;
        padding: 6px;
    }

    .appsLabelBlock {
        font-weight: 700;
        font-size: 13px;
    }

    .clickedActiveApp {
        color: $significantRegFontColor;
        background-color: $cancelButtonBgColor;
        border-left: 5px solid $taxillaDarkGreen;

        &:hover {
            background: $cancelButtonBgColor;
        }

        .mat-list-item-content {
            margin-left: -5px;
        }

        .material-icons {
            color: $taxillaDarkGreen;
        }
    }

    .matListIcon {
        font-size: 20px;
        padding: 0;
        width: 20px;
        height: 20px;
        color: $matIconGreyColor;
    }

    .manageAppsSideNavList {
        padding-top: 20px !important;

        .clickedActiveApp {
            border-left: none !important;
        }

        .material-icons {
            color: $matIconGreyColor !important;
        }
    }

    .backArrowIcon {
        margin: 0px 4px;
        background: #616161;
        cursor: pointer;
        padding: 5px;
        width: 50%;
        color: #fff;
        height: 35px;

        &:hover {
            background: $taxillaDarkGreen;
            color: #fff;
        }
    }

    .backArrowButton {
        cursor: pointer;
        background: #616161;
        color: #fff;
        font-weight: 600;
        width: 84%;
        text-align: center;
        height: 37px;

        &:hover {
            background: $taxillaDarkGreen;
            color: #fff;
        }

        .mat-icon {
            position: relative;
            top: 5px;
            left: -13px;
        }
    }

    .expandManageApps {
        width: 50%;
        padding: 4px 9px;
        height: 35px;
    }
}

.appWidth20 {
    width: 20%;
}

.userNameContainer {
    .col-12 {
        padding: 0px 15px;
    }
}

/* IE10+ specific styles go here */
@media all and (-ms-high-contrast: active), all and (-ms-high-contrast: none) {
    .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .fulWidthMatMenu {
        max-width: none !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }

    .fulWidthMatMenu :not(:empty).mat-menu-content {
        max-height: none !important;
    }

    .slimScrollBar {
        display: block !important;
    }

    .settingsCurtainMenu {
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }

    .settingsHeader {
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important;
        -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
        visibility: visible !important;
    }

    .settingsHeader .settingsNameText {
        -webkit-box-flex: 0.5 !important;
        -ms-flex: 0.5 !important;
        flex: 0.5 !important;
    }

    .settingsHeader .settingsDataActionsContainer {
        -webkit-box-flex: 1 !important;
        -ms-flex: 1 !important;
        flex: 1 !important;
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
        right: 0 !important;
        top: 0 !important;
    }

    .subscriptionApprovalContainer .settingsHeader .settingsDataActionsContainer .outlookSearchContainer {
        -ms-flex-preferred-size: 44% !important;
        flex-basis: 44% !important;
    }

    #processView .processViewContainer .entityMainDataContainer .entityDataContainer .recordDataHeader .recordDataEntityName {
        min-width: 35% !important;
    }

    #processView
        .processViewContainer
        .entityMainDataContainer
        .entityDataContainer
        .recordDataMainContainer
        .dataDisplayMainContainer
        .recordDataDisplayMainContainer.isInReadOnlyMode {
        min-width: 100% !important;
    }

    .rolecls .rolesWidget .materialStepperDiv {
        visibility: visible !important;
    }

    .roleMatDrawer {
        min-width: 100%;
    }

    #settingsContainer .drawerContainer {
        min-height: (100vh - 10vh) !important;

        mat-drawer-content {
            height: calc(100vh - 6vh);
        }
    }

    .matOptionWithCrossIcon {
        .inputInsideSelectInput {
            position: relative;
            top: -12px;
            line-height: 0 !important;
        }

        .inputInsideSelectInputIcon {
            position: relative;
            left: -5px;

            .mat-icon {
                height: 21px;
                width: 21px;
                line-height: 21px;
            }

            .material-icons {
                font-size: 21px;
            }
        }
    }

    .restApiExplorerContainer {
        .feedContainer,
        .detailsContainer {
            height: 100vh;
        }
    }

    #settingsContainer .mat-drawer-container .mat-drawer-inner-container {
        overflow: auto;
    }

    #settingsContainer mat-drawer-container mat-drawer-container mat-drawer {
        margin-bottom: auto !important;
    }

    .drawerContainer {
        overflow-x: hidden !important;
    }

    .integrationTabContentContainer .drawerContainer {
        min-height: calc(100vh - 18vh) !important;
    }

    .levelOneOrgs .slimScrollBar {
        display: none !important;
    }

    .mat-form-field .mat-form-field-infix input[matInput] {
        line-height: 0 !important;
    }

    .ieTabContainer ~ .slimScrollBar {
        display: none !important;
    }

    .noAppsFound .loaderBlock.isLoading {
        text-indent: 0px !important;
        width: 100% !important;
    }

    .menuItem .privateAppClas {
        padding: 7px 7px 0px 26px !important;
    }

    .reportsText {
        max-width: 125px;
        min-width: 116px;
    }

    .materialStepperDiv {
        padding-top: 0 !important;
    }

    .tableContainerWithoutPaginator {
        overflow: auto;
    }

    app-confirmation {
        .mat-dialog-content {
            padding-top: 10px;
            padding-bottom: 10px;
            text-align: center;
        }
    }

    @media only screen and (min-width: 1056px) and (max-width: 1366px) {
        .locationCardContent .row .ps .materialStepperDiv {
            overflow: auto;
            -ms-overflow-style: auto;
        }

        source-table material-table .matTableContainer,
        .inboundTransmisionTable app-virtual-table app-table-virtual-scroll-viewport .matTableContainer {
            height: auto;
        }
    }

    .masterFilterSelection .mat-radio-group {
        flex-direction: row;
    }

    .accessApiInputFieldContainer:not(.ignore) {
        width: calc(100vw - 50vw);
    }

    .mat-search-query-builder .mat-radio-group {
        flex-direction: row;
    }

    .setupAttributesListContainer .paddingBottom30px div .displyInlineBlock material-textarea textarea {
        height: 36px;
        overflow: auto;
    }

    .appName {
        .mat-ripple {
            display: none;
        }
    }

    .shortcutMenu .mat-menu-content {
        max-height: 200px !important;
    }
}

.restApiExplorerContainer {
    width: 100%;
    height: 100%;
    min-height: 540px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
    background: #ffffff;

    .restApiMainContainer {
        margin: 0px;
        min-height: 563px;

        .feedContainer {
            border-right: 1px solid #ccc;

            .listOfApisDiv {
                margin-top: 10px;

                .apisDiv {
                    padding: 5px 0px;
                    cursor: pointer;
                }

                .apisDiv span:hover {
                    color: #09a651;
                    text-decoration: underline;
                }

                .selectedAppDiv {
                    color: #09a651;
                }
            }
        }

        .detailsContainer {
            padding: 0px;

            .selectApiDetailHeader {
                padding: 10px 10px;
                font-weight: bold;
                border-bottom: 1px solid #ccc;
                font-size: 14px;
            }

            .restApiUrlClass {
                padding: 10px;
                background: #efefef;
                margin: 14px;
            }

            .restApiDescDiv {
                padding: 0px 20px;
            }
        }

        .prepareRequestDiv {
            margin: 0px 10px;

            .mainHeaderDiv {
                padding: 10px 10px;
                border-bottom: 1px solid #ccc;
                font-size: 14px;
                font-weight: bold;
            }

            .contentDiv {
                padding: 10px 10px 20px 10px;

                .pathParametersDiv {
                    margin-bottom: 10px;

                    .detailsSectionCls {
                        padding: 10px;
                        background: #545050;
                        color: white;
                    }

                    .pathParameterContentDiv {
                        display: inline-block;
                        width: 100%;
                        padding-bottom: 5px;

                        .pathDiv {
                            display: inline-block;
                            width: 70%;

                            .spanCls {
                                float: right;
                                padding-right: 10px;
                            }
                        }

                        .pathHeadDiv {
                            display: inline-block;
                            width: 30%;

                            .spanCls {
                                float: right;
                                padding-right: 10px;
                            }
                        }
                    }
                }

                .queryParametersDiv {
                    margin-bottom: 10px;

                    .detailsSectionCls {
                        padding: 10px;
                        background: #545050;
                        color: white;
                    }

                    .QueryParameterContentDiv {
                        display: inline-block;
                        width: 100%;
                        padding-bottom: 5px;

                        .queryDiv {
                            display: inline-block;
                            width: 50%;

                            .spanCls {
                                float: right;
                                padding-right: 10px;
                            }
                        }

                        .queryHeadDiv {
                            display: inline-block;
                            width: 30%;

                            .spanCls {
                                float: right;
                                padding-right: 10px;
                            }
                        }
                    }
                }

                .payloadContentDiv {
                    margin-bottom: 10px;

                    .detailsSectionCls {
                        padding: 0px 0px 0px 0px;
                        background: #545050;
                        color: white;
                    }
                }

                .responseDiv {
                    .detailsSectionCls {
                        padding: 0px 0px 0px 0px;
                        background: #efefef;

                        pre {
                            position: relative;
                            top: -20px;
                        }

                        .mat-select-value {
                            color: rgba(93, 93, 93, 0.79);
                        }

                        .responseBodyHeader {
                            padding-left: 10px;
                        }
                    }
                }

                .headerDiv {
                    font-size: 14px;
                    font-weight: bold;
                    background: #efefef;
                    padding: 10px 10px;

                    .payloadType {
                        font-weight: 400;
                        padding-left: 5px;
                    }
                }

                pre {
                    background: #545050;
                    margin: 0px;
                    width: 100%;
                    color: $cancelButtonBgColor;
                    padding: 20px;
                }

                .statusCodeDiv {
                    padding: 0px 0px 10px 10px;
                    font-size: 12px;

                    span {
                        font-weight: 600;
                        font-size: 11px;
                    }
                }

                .selectedResponseDiv {
                    width: 25%;
                    padding-bottom: 10px;
                    padding-right: 10px;
                    float: right;
                    position: relative;
                    top: 5px;
                }
            }
        }
    }
}

.noApiSelected {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.navigationConfirmation {
    width: 30%;

    .updateBtnDiv {
        material-button {
            margin: 0 5px;
        }
    }
}

.mat-standard-chip {
    height: auto !important;
}

.materialStepperDiv {
    visibility: visible;
    height: inherit;
    background: white;
}

.ps__thumb-y {
    background-color: #827f7f !important;
    width: 8px !important;
    min-height: 60px;
}

.ps__rail-y {
    width: 13px !important;
}

.reportsText {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.documentMenu {
    background-color: ghostwhite !important;
}

.documentsHeader {
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 10px;
}

.documentCloseIcon {
    font-size: 18px !important;
}

.documentDownloadIcon {
    color: #626262 !important;

    &:hover {
        color: $taxillaDarkGreen !important;
    }
}

.noDocument {
    width: 85%;
    margin: auto;
    padding: 10px;
    font-size: 14px;
}

app-dashboard {
    height: 100%;
    width: 100%;
    display: block;

    #appDashboard {
        height: 100%;
        width: 100%;

        .appDashboardWrapper {
            height: 100%;

            .mat-drawer-container {
                height: 100%;
                background: transparent;

                .mat-drawer-content {
                    max-height: 100%;
                }
            }
        }
    }
}

.tokenTable {
    padding: 25px;
}

.tokenActionContainer {
    position: absolute;
    top: 5px;
    right: 23px;

    .addIconColor {
        float: right;
        cursor: pointer;
    }

    .tokenDeleteBtn button {
        line-height: 30px;
    }
}

.requiredCls {
    top: calc(100% - 0.291667em) !important;
}

app-setupattributes {
    material-input,
    material-input-password,
    app-assetlookup {
        width: calc(100% - 24px);

        material-select {
            width: 100%;
        }
    }
}

.userDeatilsContainer {
    font-size: 14px;
    padding: 0px 30px;

    .userDetailsUserName {
        padding: 15px;
    }

    .fieldTitle {
        font-weight: 600;
    }

    .rolesDiv {
        display: inline-block;
        padding: 10px 20px;
        border: 1px solid #2e9c3d;
        width: 150px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: 20px;
    }
}

.detailsCloseIcon {
    opacity: unset !important;
}

.changeButton {
    position: relative;
    top: 3px;
    font-size: 20px !important;
    cursor: pointer;
}

.foureyeCheckModal {
    width: 70%;

    .userModalActions {
        position: absolute;
        top: 10px;
        right: -38px;
        text-align: right;

        .material-icons {
            cursor: pointer;
        }
    }

    .changeLogRightTable {
        overflow: hidden;
    }
}

.updateUserContainer {
    padding: 20px 30px;

    .updateUserDetails {
        .updateUserNextButton {
            margin-top: 40px;
        }

        .updateUserFieldsDiv {
            .row {
                margin-bottom: 20px;
            }
        }
    }
}

#rolesModal .modal-dialog {
    max-width: 70%;
}

.parentSubscribeAssetModal {
    overflow-y: hidden !important;
}

.full-screen-modal {
    width: 100%;
    height: 100%;

    .mat-dialog-container {
        .mat-dialog-content {
            height: 80vh;
            max-height: unset !important;
            overflow: hidden;
        }
    }
}

.doc-preview {
    .mat-dialog-container {
        padding: 0px 1px 7px !important;
    }
}

.bridgeHelpIcon {
    vertical-align: bottom;
    margin-right: 0 !important;
}

.bridgeText {
    margin-right: 5px !important;
}

.dropZone {
    text-align: center;
    border: 2px dashed #c9c9c9;
    padding: 10px;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.fileDragDetected {
    * {
        color: rgba(0, 0, 0, 0.4);
        border-color: rgba(0, 0, 0, 0.4);

        .mat-form-field .mat-form-field-infix {
            border-color: transparent;
        }

        img {
            opacity: 0.4;
        }

        button {
            &.mat-button,
            &.mat-icon-button,
            &.mat-button-base {
                opacity: 0.5;
            }
        }

        mat-icon.mat-icon {
            opacity: 0.4;
        }

        .latestStatusMessage {
            opacity: 0.4;
        }

        .instanceBreadcrumb .packageDetailBreadcrumbItem .packageDetailBreadcrumbText {
            opacity: 0.4;
        }

        .mat-card {
            box-shadow: none;
            border-bottom: 1px solid rgba(0, 0, 0, 0.3);
        }

        #processView .processViewContainer .entitiesListColumn {
            box-shadow: none;
            border-bottom: 1px solid rgba(0, 0, 0, 0.3);
        }

        .fileDropZoneContainer {
            display: block;
            position: relative;
            top: 72%;
            left: 0%;
            padding-right: 14px;
            transform: scale(1.5);
            opacity: 0.5;
            margin: 10px 30px 10px 0px;

            .fileDropZoneText {
                color: #212529;
                padding: 0px 28px;
                border: 1px dashed green;
                border-radius: 4px;
                text-align: center;
                line-height: 35px;

                &:hover,
                &.fileHovered {
                    -webkit-animation: border-dance 4s infinite linear;
                    animation: border-dance 4s infinite linear;
                    height: 36px;
                    width: 138px;
                    background: linear-gradient(90deg, $taxillaDarkGreen 50%, transparent 50%),
                        linear-gradient(90deg, $taxillaDarkGreen 50%, transparent 50%),
                        linear-gradient(0deg, $taxillaDarkGreen 50%, transparent 50%),
                        linear-gradient(0deg, $taxillaDarkGreen 50%, transparent 50%);
                    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
                    background-size: 12px 2px, 12px 2px, 2px 12px, 2px 12px;
                    background-position: 0px 0px, 140px 38px, 0px 38px, 140px 0px;
                    line-height: 36px;
                    border: 0 !important;
                }
            }
        }

        .fileBrowseContainer {
            display: none;
            opacity: 1;
            color: #212529;
        }

        .modal-dialog,
        .modal-content {
            border: none;
        }
    }
}

.fileDropZoneContainer {
    display: none;
}

.partnerApproveBtn {
    button {
        line-height: 30px;
        margin-left: 5px;
        margin-right: 5px;
    }
}

.allMastersDropdownContainer {
    float: right;
    position: relative;
    top: 6px;
    right: 5px;
}

.integrationsContentContainer {
    .integrationSettingsHeader {
        border-bottom: none !important;

        .settingsDataActionsContainer {
            top: 0px !important;
        }
    }
}

.selectedMasterNameCls {
    display: inline-block;
    position: relative;
    line-height: 50px;
}

.allMastersList {
    max-height: 41vh;
    min-width: 268px;
    max-width: 270px;
    overflow-x: hidden;

    .activeMasterItem {
        background: #e2e2e2;
    }
}

.selectedMasterSpan {
    font-size: 15px;
}

.addEventSubscriptionBtn {
    top: -2px !important;
}

.eventSubscription {
    .criteriaContainer {
        padding-top: 7px;
        padding-bottom: 10px;
        margin-top: 5px;
        margin-bottom: 20px;
        position: relative;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

        .labelText {
            margin-top: 11px;
        }

        mat-error {
            margin-top: 15px;
            margin-left: 15px;
        }

        .velocityTemplatePlaceholders {
            position: absolute;
            right: 0;
            top: 0px;
            font-size: 12px;
            background-color: #f5f5f5;
            border: 1px solid #e3e3e3;
            padding: 0;
            margin-right: 8px;
            overflow-y: auto;
            max-height: 95px;
            overflow-x: hidden;
            .copyPlaceholder {
                position: relative;
                width: 95%;
                margin: 5px 0px 5px 15px;

                .copyIcon {
                    cursor: pointer;
                    position: relative;
                    top: 5px;
                    font-size: 12px;
                }
            }

            .accessApiInputFieldContainer {
                background: #f1f1f1;
                padding: 5px;
            }
        }

        .mat-form-field-subscript-wrapper {
            left: 0;
        }
    }

    .emailTemplate {
        padding: 10px 15px;
        margin: 5px -15px 10px;
        position: relative;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

        .velocityTemplatePlaceholders {
            position: absolute;
            top: 5px;
            right: 0;
            bottom: 10px;
            display: flex;
            flex-flow: column;

            .placeholder-wrapper {
                height: 50%;

                .placeholders-container {
                    height: calc(100% - 53px);
                    display: flex;
                    flex-flow: column;
                    align-items: baseline;
                    overflow: auto;

                    .copyPlaceholder {
                        display: flex;
                        position: relative;
                        width: 85%;
                        margin: 5px 0px 5px 15px;

                        .copyIcon {
                            cursor: pointer;
                            position: relative;
                            top: 5px;
                            font-size: 22px;
                        }
                    }
                }
            }

            .accessApiInputFieldContainer {
                background: #f1f1f1;
                padding: 5px;
            }
        }
    }

    .includeHierarchyCntr {
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 0px;

        .labelText {
            margin-top: 11px;
        }

        .mat-radio-group {
            flex-direction: row;
        }

        .mat-radio-button {
            padding: 10px;
        }
    }

    .accessApiActionButtons {
        margin: 5px -15px;
    }

    .mat-form-field-subscript-wrapper.error {
        font-size: 90%;
    }

    .eventHeaderActions {
        width: 30%;
    }

    .addIcon {
        cursor: pointer;
    }
}

.hide {
    display: none;
}

.mainSearchFilters.homePageSearchFilter {
    top: 15px !important;
    right: 15px !important;
}

.masterRequestIconButton {
    background-color: #616161;
    border-radius: 5px !important;
    width: 25px !important;
    height: 25px !important;
    margin: 0px 2px 0px 5px !important;

    img {
        position: relative;
        top: -1px;
    }
}

.masterRequestIconButton:hover {
    background: #00a651 !important;
}

.accordian_body_text {
    color: $taxillaDarkGreen;
    text-decoration: underline;
    cursor: pointer;
    max-width: 150px;
    overflow-x: hidden;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
}

#changeLogTemplateBodyContainer {
    margin-top: 15px;

    .mat-expanded {
        .mat-expansion-panel-header {
            padding: 0 10px;
            height: 50px !important;
            box-shadow: 0 1px 4px #b1b1b1;
            background: #707070 !important;
        }

        .mat-expansion-indicator::after {
            color: #fff !important;
        }

        .mat-expansion-panel-header-title {
            color: #fff !important;
        }
    }

    .mat-expansion-panel-header {
        padding: 0 10px;
        height: 50px !important;
        background: rgba(0, 0, 0, 0.02) !important;
        box-shadow: 0 1px 4px #b1b1b1;
    }

    .mat-expansion-panel-header:hover {
        background: #707070 !important;
        color: #fff;

        .mat-expansion-indicator::after {
            color: #fff;
        }

        .mat-expansion-panel-header-title {
            color: #fff;
        }
    }

    .mat-expansion-panel-body {
        padding: 15px;
    }
}

.masterChangeLogModal,
.setupattributeChangeLogModal {
    width: 65%;

    .changeLogsMatBody {
        overflow: hidden;

        .changeLogDataContainer {
            padding: 10px;
        }
    }
}

.refreshIconClass {
    font-size: 27px !important;
}

.noSearchResults {
    position: absolute;
    left: 22.5%;
    top: 20%;
}

.vdmNoSearchResults {
    position: absolute;
    left: 22.5%;
    top: 30%;
}

.mat-chip.mat-standard-chip::after {
    background: none !important;
}

.setUpAttributeChangeLog {
    font-size: 20px;
    cursor: pointer;
    padding-right: 15px;
}

.saveCustomFilterDialog {
    width: 30%;
}

.identityProviders {
    .addIcon {
        cursor: pointer;
    }

    .fieldContainer {
        display: inline-block;
    }

    textarea.mat-input-element.cdk-textarea-autosize {
        white-space: unset !important;
    }
}

.booleanFieldContainer {
    .booleanLabel {
        position: relative;
        top: 8px;
        padding-left: 15px;
        text-overflow: ellipsis;
        overflow-x: hidden;
        white-space: nowrap;
    }

    .booleanValues {
        position: relative;
        padding-left: 20px;
        display: flex;
        margin-top: 6px;

        .mat-radio-group {
            display: flex;
        }

        .mat-radio-button {
            flex: auto;
            margin: 0 5px 0 0;
        }
    }
}

.acessApiradioGroup {
    margin-top: 10px;
    margin-bottom: 10px;

    .labelText {
        margin-top: 11px;
    }

    .mat-radio-group {
        flex-direction: row;
    }

    .mat-radio-button {
        padding: 10px;
    }
}

.collabConfigContainer {
    .labelText {
        margin-top: 15px;
        margin-left: 15px;
    }

    .mainLabel {
        font-weight: 600;
        font-size: 15px;
        padding-bottom: 10px;
    }

    .heading {
        font-weight: 600;
        font-size: 16px;
    }

    .partnerAppSetting {
        margin: 0 15px 30px;
        position: relative;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 10px 1px;
        background: #fdfdfd;
    }

    .addIconDiv {
        cursor: default !important;

        .addIcon {
            cursor: pointer;
        }
    }

    .removeIconDiv {
        text-align: end;
        margin-left: 3px;
        cursor: pointer;
    }

    .sectionDiv {
        background: $sectionSeperator;
        padding: 10px 20px;
        margin: 10px -20px 15px;
    }

    .collabActionsDiv {
        .addIcon {
            position: relative;
            top: 7px;
            cursor: pointer;
        }

        .collabActions {
            position: relative;

            .removeIcon {
                position: absolute;
                right: -10px;
                top: 15px;
                cursor: pointer;
            }
        }
    }

    .workflowSaveButton {
        material-button {
            margin-left: 10px;
        }
    }
}

.identyProvidersMatChipContainer {
    .mat-standard-chip.mat-chip-with-trailing-icon {
        word-break: break-word;
    }
}

.mat-table {
    tr.example-element-row {
        &.matTableError {
            background: #f6c1ac;

            &.badScore {
                background: #ffffe0;

                .actionsMenu {
                    .actionButtons {
                        .matTableActionIcon {
                            color: #000000de !important;
                        }
                    }
                }
            }

            &:not(.example-expanded-row):hover,
            &.matTableWarning:hover {
                background: #e54304 !important;
                color: #fff;

                &.badScore {
                    background: #fffacd !important;

                    .mat-cell {
                        color: #000000de !important;
                    }

                    .actionsMenu {
                        .actionButtons {
                            .matTableActionIcon {
                                color: #b3b3b3 !important;
                            }
                        }

                        &:hover {
                            .actionButtons {
                                .matTableActionIcon {
                                    color: #00000042 !important;
                                }
                            }
                        }
                    }
                }

                .actionsMenu {
                    .actionButtons {
                        .matTableActionIcon {
                            color: #fff !important;
                        }
                    }

                    &:hover {
                        .actionButtons {
                            .matTableActionIcon {
                                color: #b3b3b3 !important;
                            }
                        }
                    }
                }
            }

            &.matTableWarning {
                background: #e5430454;
            }

            &:hover .mat-cell,
            &.matTableWarning:hover .mat-cell,
            &:hover .mat-footer-cell,
            &.matTableWarning:hover .mat-footer-cell {
                color: #ffffff;
            }
        }

        &.matTableWarning {
            background: #ffeac4;

            &:not(.example-expanded-row):hover {
                color: #fff;
                background: #f5bc54;
            }
        }
    }
}

.eventSelectBox {
    max-width: 65% !important;
}

.subscriptionActions {
    justify-content: flex-end !important;
    margin-right: 5px !important;
}

.packagePageDetailContainer {
    .dowmtimeMessage {
        margin: 5px 20px;
    }
}

.fieldDescInfoIcon {
    font-size: 20px !important;
    color: #b8b8b8 !important;
}

.masterFilterSelection {
    padding: 10px 0px 20px 0px;

    .mat-radio-group {
        flex-direction: unset !important;
    }
}

.partnerOrgId {
    color: $lightGreyColor;
}

.partnersInformation {
    color: $significantRegFontColor;
    font-size: 14px;
    margin-left: 8px;
    margin-bottom: 10px;
}

.partnerSelectOrg {
    margin-left: 10px !important;
}

.providerOrgnizationMainDiv {
    .manageCardDetails {
        padding: 0px 30px;
    }

    .manageCardTitle {
        font-size: 15px;
        font-weight: bold;
        margin: 15px 0px 0px 0px;
        padding: 0px;
    }
}

.noUsersFound {
    font-size: 14px;
    color: #363636;
}

.downloadLink {
    color: $submitButtonColor;
    cursor: pointer;

    a {
        color: $submitButtonColor;
        cursor: pointer;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
}

.invoiceCollapseHeader,
.settingsCollapseHeader {
    padding: 7px 1em 7px 21px;
    text-align: left;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
}

.displayNone {
    display: none;
}

[aria-expanded='false'] .collapseRightArrow,
[aria-expanded='true'] .collapseDownArrow {
    display: block;
}

[aria-expanded='true'] .collapseRightArrow,
[aria-expanded='false'] .collapseDownArrow {
    display: none;
}

.mat-sort-header-container {
    border-bottom: none !important;
    .mat-sort-header-content {
        display: inline-block;
        max-width: 140px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.synchronizeMasterCard {
    #inboundIntegrationsPageContainer {
        .inboundIntegrationsContainer {
            app-integrations-template {
                .userMainTableConatiner {
                    .inboundIntegrationsBodyContainer {
                        .inboundIntegrationsFormContainer {
                            height: auto !important;
                        }
                    }
                }
            }
        }
    }
}

.well {
    background-color: white !important;
}

.matTableContainer th.mat-header-cell:first-of-type {
    min-width: 64px;
}

.appsPaginator {
    margin-right: 20px;
}

.appsWrapper {
    position: relative;
    background: $whiteColor;
    .header {
        align-items: center;
        border-bottom: 1px solid $borderColorGrey;
        display: flex;
        justify-content: space-between;
        font-weight: 700;
        padding: 5px 10px 5px 15px;
        text-transform: capitalize;
        .headerTitleText {
            font-size: 15px;
            max-width: calc(100% - 2.5rem);
            overflow-x: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    .searchFiltersContainter {
        font-size: 14px;
        height: 0;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        right: 0px;
        top: 0px;
        transition: 0.5s;
        -webkit-transition: 0.5s;
        width: 0;
        z-index: 2;

        .filterHeader {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 46px;
            border-bottom: 1px solid #a6a6a691;
            font-weight: 700;
            padding: 0 15px 0 20px;
            .filterHeaderLabel {
                font-size: 15px;
            }
            .closeFilter span.material-icons {
                font-size: 16px;
                background: $matIconGreyColor !important;
                color: $whiteColor;
                border-radius: 50%;
                padding: 2px;
                margin: 5px;
            }
        }

        .tagSearchContainer {
            padding: 10px 20px;
            .textInputContainer {
                display: flex;
                justify-content: space-between;
                align-items: stretch;
                .textInputWrapper {
                    flex: 1;
                    padding-right: 2rem;
                }
            }
        }

        .filterFooter {
            margin-top: 20px;

            .cancelBtn {
                margin-left: 20px;
            }
        }

        &.filterContainer {
            width: 82%;
            height: 200px;
            opacity: 2;
            background: $whiteColor;
        }
    }
}

.searchContainer {
    .searchInnerContainer {
        align-items: center;
        border: 1px solid lightgray;
        cursor: pointer;
        display: flex;
        font-weight: 400;
        height: 35px;
        justify-content: space-between;
        padding: 5px;
        text-align: left;
        width: 250px;
        .searchIcon {
            font-size: 19px;
            color: $matIconGreyColor;
        }
        .searchAlign {
            vertical-align: super;
        }
    }
}

.appCardsContainer {
    height: calc(100vh - 20rem);
    .cardsHeaderContainer {
        align-items: center;
        display: flex;
        height: 2.8rem;
        justify-content: space-between;
        .selectServiceTypeContainer {
            margin: 0.8rem 1.1rem 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .serviceTypesSelect {
                width: 16rem;
            }
            .allAppsSubscribeContainer {
                display: inline-block;
                color: $taxillaDarkGreen;
                text-align: center;
                cursor: pointer;
                bottom: 5px;

                .clearAll {
                    margin-left: 20px;
                }
            }
        }
    }

    .cardsContainerLoader {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 0.6rem 1.1rem 0 1.1rem;
    }

    .approvedAppsScroll {
        height: calc(100vh - 230px);
        margin-top: 0.8rem;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .bundleScroll {
        height: calc(100vh - 390px);
        margin-top: 0.8rem;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .bundlePackageScroll {
        height: calc(100vh - 140px);
        margin-top: 0.8rem;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .cardsContainer {
        overflow-x: hidden;
        overflow-y: auto;
        display: block;
        width: 100%;
        padding-right: 1rem;
        padding-bottom: 1.2rem;
        .cardsWrapper {
            width: calc(100% / 5 - 1rem);
            display: inline-block;
            margin-left: 1rem;
            margin-top: 1.2rem;
            vertical-align: top;
        }
    }
    .appsPaginator {
        margin: 0;
    }
    .noAppsFound {
        height: calc(100vh - 12rem);
        line-height: calc(100vh - 12rem);
    }
}
