@import './base';

#processView {
    position: relative;
    max-height: 100%;
    padding: 0 15px 0 15px;

    &.auditMode {
        .packagePageHeaderContainer {
            background: $backgroundGreen;
            color: #fff;

            .packagePageActionsContainer {
                .groupActionsContainer {
                    .categoryActionItemContainer {
                        .material-icons {
                            color: #c1c0c0;
                        }
                    }

                    .categoryActionItemContainer.activeIcon span.material-icons {
                        color: #fff;
                    }
                }
            }
        }

        .processViewContainer {
            background: #c9e6cb;

            .entitiesListColumn {
                .entitiesListContainer {
                    background: #f7f7f9;
                }
            }

            .entityRecordsColumn {
                .entityRecordsMainContainer {
                    background: #f7f7f9;

                    .entityRowsBodyContainer {
                        .entityRowsDisplayContainer {
                            &.deletedRecord {
                                .entityDataRowContainer {
                                    .entityDataRowDetailsContainer {
                                        .entityDataDetail {
                                            text-decoration: line-through;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .entityDataColumn {
                .entityMainDataContainer {
                    background: #f7f7f9;
                }
            }

            .viewsColumn {
                .viewsMainContainer {
                    background: #f7f7f9;
                }
            }
        }

        .processStatusViewContainer {
            top: calc(100% + 16px);
        }
    }

    .headerContainer {
        font-size: 13px;
        height: 48px;
        padding: 0 !important;

        .pxUp {
            position: relative;
            top: -1px;
            margin-right: 0 !important;
        }

        .packagePageBreadcrumbContainer {
            .packageDetailBreadcrumbItem {
                .packageDetailBreadcrumbNext {
                    margin: 0 3px;
                }

                span.font-size {
                    font-size: 12px;
                    position: relative;
                    top: 2px;
                }
            }
        }

        .groupActionsContainer {
            margin: 0;
        }

        button.mat-primary {
            line-height: 30px;
        }
    }

    .processViewContainer > .col-2 {
        padding-right: 0px;
        // padding-left: 10px;
        padding-left: 0px;
        // height: 96%;
        height: 98%;
    }

    .processViewContainer > .col-9 {
        padding-left: 10px;
        padding-right: 0px;
    }

    .processViewContainer > .col-1 {
        height: 100%;
    }

    .processViewContainer {
        height: 100%;
        margin: 0;
        width: calc(100% - -10px);
        // padding-top: 5px;

        .singleRecordSelected {
            .entityRecordsMainContainer {
                .entityRowHeaderContainer {
                    padding: 3px 0 3px 13px;

                    .entityDataRowSelectContainer {
                        .mat-badge-medium.mat-badge-above .mat-badge-content {
                            top: -3px;
                        }
                    }
                }
            }
        }

        .mat-card {
            padding: 0px;
            border-radius: 0;
        }

        .processLeftNav {
            padding-bottom: 50px;
            position: relative;

            .fixToBottom {
                position: absolute;
                bottom: 8px;
                width: 100%;
                border-top: 1px solid $leftNavBorderColor;

                .lastRowItem {
                    cursor: pointer;
                    text-align: center;
                    padding: 5px 0 10px;

                    button.mat-icon-button {
                        icon {
                            position: relative;
                            bottom: 3px;
                        }

                        &[aria-expanded='true'] {
                            .material-icons {
                                color: $taxillaDarkGreen;
                            }
                        }

                        &:hover {
                            background: $bodyBgColor;

                            .material-icons {
                                color: $taxillaDarkGreen;
                            }
                        }
                    }

                    &.masterSelectedDiv {
                        background: $bodyBgColor;

                        .material-icons {
                            color: $taxillaDarkGreen;
                        }
                    }
                }

                .material-icons {
                    font-size: 24px;
                    width: 24px;
                    height: 24px;
                    color: #616161;
                }
            }
        }

        .filteredRequestsContainer {
            max-width: 0;
            flex: 0 0 0;
            overflow-x: hidden;
            padding: 0;
            transition: 0.2s;

            .fullParentHeight {
                height: 100%;
                position: relative;

                .dowmtimeMessage {
                    margin: 0 0 0.7em;
                }
            }

            &.showingFilteredProcesses {
                flex: 0 0 calc(100% - 54px);
                max-width: calc(100% - 54px);
                flex-grow: 0;
                flex-shrink: 0;
                flex-basis: calc(100% - 54px);
                padding-right: 0px;
                padding-left: 10px;
                height: 98%;
                margin-top: 0px;
                overflow-y: hidden;

                &.navigatorExpanded {
                    flex: 0 0 83.333333%;
                    max-width: calc(83.333333% - 10px);

                    &.outOfContext {
                        // position: relative;
                        // margin-left: -14%;
                        // width: 97%;
                        // max-width: 97%;
                        // -webkit-box-flex: 0;
                        // flex: 0 0 97%;
                        z-index: 2;
                        height: 92.6%;

                        @extend .non-context-class !optional;
                    }
                }

                app-processes-view {
                    position: relative;
                    height: 100%;
                    display: block;

                    .processViewDirectiveContainer {
                        margin: 0;
                        width: 100%;
                        padding-right: 0;
                        padding-top: 0;
                        height: 100%;

                        .processesViewMatCard {
                            margin: 0;
                            width: 100%;
                            max-width: 100%;
                            flex: 0 0 100%;
                            height: 100%;

                            .processesViewContainer {
                                height: 100%;
                            }
                        }
                    }
                }

                &.outOfContext {
                    // position: relative;
                    // margin-left: -14%;
                    // width: 97%;
                    // max-width: 97%;
                    // -webkit-box-flex: 0;
                    // flex: 0 0 97%;
                    z-index: 2;
                    height: 99%;

                    @extend .non-context-class !optional;
                }
            }
        }

        .entitiesListColumn > .mat-card {
            padding: 0;
            height: 100%;
        }

        .entitiesListColumn {
            box-shadow: none;
            border-bottom: 1px solid rgba(0, 0, 0, 0.3);
            transition: 0.2s;
            font-size: 13px;
            // width: 54px;
            // flex: 0 0 54px;
            width: 44px;
            flex: 0 0 44px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: clip;
            position: relative;

            &.navigatorExpanded {
                flex: 0 0 16.666667%;
                max-width: 16.666667%;
                box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);

                &.hideColumn {
                    width: 0%;
                    max-width: 0%;
                    padding: 0;
                    overflow-y: hidden;
                }
            }

            &.hideColumn {
                width: 0%;
                max-width: 0%;
                padding: 0;
                overflow-y: hidden;
            }

            .statusGroupsContainer.isRelatedAppsVisible {
                // height: 120px;

                .statusGroupContainer {
                    // max-height: 40px;
                    // border-bottom: 1px solid $leftNavBorderColor;

                    .statusGroupHeaderText {
                        // padding: 10px;
                    }

                    .entitiesListContainer {
                        max-height: 0;
                        padding: 0;

                        .entitiesListMainContainer {
                            max-height: 0;
                            overflow-y: hidden;
                        }
                    }
                }
            }

            .statusGroupsContainer {
                // height: calc(100% - 115px);
                transition: 0.2s;
                min-width: 200px;
                text-overflow: clip;
                overflow-x: hidden;
                white-space: nowrap;

                .statusGroupContainer {
                    max-height: calc(100% - 80px);
                    transition: 0.2s;
                    overflow-y: hidden;
                    border-bottom: 1px solid $leftNavBorderColor;

                    icon {
                        margin-right: 8px;
                        display: inline-block;
                        text-align: center;
                        padding-top: 1px;
                        width: 26px;
                        color: $matIconGreyColor;

                        .mat-icon {
                            font-size: 21px;
                            width: 21px;
                            height: 21px;
                        }
                    }

                    .statusGroupHeaderText {
                        padding: 6px 10px;
                        // background: #ececec;
                        cursor: pointer;
                        // border-bottom: 1px solid $leftNavBorderColor;
                        font-weight: 600;

                        .statusText {
                            float: none;
                            display: inline-block;
                            vertical-align: middle;
                            color: #565656;
                        }

                        span {
                            float: right;

                            icon {
                                margin-right: 0;
                                padding-top: 0;

                                .mat-icon {
                                    font-size: 24px;
                                    width: 24px;
                                    height: 24px;
                                }
                            }
                        }
                    }
                }

                .activeProcesses {
                    .statusGroupHeaderText {
                        .statusText {
                            color: #00a651;
                        }

                        icon {
                            color: #00a651;
                        }

                        .mat-icon {
                            color: #00a651;
                        }
                    }
                }

                .activeProcess {
                    .statusGroupHeaderText {
                        // background: #efefef;
                        // border-bottom: 1px solid $leftNavBorderColor;

                        .statusText {
                            color: #00a651;
                        }

                        icon {
                            color: #00a651;
                        }

                        .mat-icon {
                            color: #00a651;
                        }
                    }
                }

                &.instanceSelected {
                    position: absolute;
                    bottom: 64px;
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    border-top: 1px solid $leftNavBorderColor;
                    background: #fff;

                    .statusGroupContainer {
                        border-bottom: none;
                        margin: 0 5px;

                        icon {
                            margin: 0;
                            width: 24px;
                            padding-top: 0;

                            .mat-icon {
                                font-size: 24px;
                                width: 24px;
                                height: 24px;
                            }
                        }

                        .statusGroupHeaderText {
                            padding: 5px;
                            margin: 5px;

                            &:hover {
                                background: $bodyBgColor;
                                border-radius: 50%;
                            }
                        }
                    }
                }
            }

            .entitiesListContainer {
                height: calc(100% - 36px);
                max-height: calc(100% - 36px);
                // padding: 5px;
                padding: 10px 5px 10px 5px;
                overflow: hidden;
                transition: 0.2s;
                // border-bottom: 1px solid $leftNavBorderColor;

                &.entitiesOnlyContainer {
                    height: 100%;
                    max-height: 100%;
                }

                .entitiesListHeaderName {
                    // margin-bottom: 10px;
                    padding: 5px 10px 0;
                    font-weight: 600;
                    transition: 0.2s;
                }

                .entitiesListMainContainer {
                    max-height: 98%;
                    overflow-y: auto;
                    padding: 0 0 0 5px;
                    transition: 0.2s;
                    overflow-x: hidden;
                    text-overflow: ellipsis;

                    .entityListItemContainer {
                        cursor: pointer;
                        line-height: 20px;
                        padding-top: 4px;
                        padding-left: 5px;

                        .entityItemIcon {
                            display: inline-block;
                            line-height: 24px;
                            vertical-align: top;
                            margin-right: 3px;
                            font-size: 16px;
                        }

                        .entityItemName {
                            display: inline-block;
                            line-height: 24px;
                            text-transform: capitalize;
                            word-break: break-word;
                            white-space: nowrap;
                            overflow-x: hidden;
                            // max-width: calc(100% - 34px);
                            width: calc(100% - 55px);
                            text-overflow: ellipsis;
                        }

                        &.activeEntity,
                        &:hover {
                            color: $submitButtonColor;
                            background: $bodyBgColor;

                            .entityItemIcon {
                                color: $submitButtonColor;
                            }
                        }

                        &.disabled {
                            cursor: default;
                            pointer-events: none;

                            .entityItemIcon {
                                color: #b1acac;
                            }

                            .entityItemName {
                                color: #b1acac;
                            }
                        }

                        &.activeEntity {
                            &.errorEntity,
                            &.warningEntity {
                                color: $submitButtonColor;

                                .entityInfoIcon {
                                    .entityItemIcon {
                                        color: $submitButtonColor;
                                    }
                                }
                            }
                        }

                        &.errorEntity,
                        &.warningEntity {
                            .normalIcon {
                                display: none;
                            }
                        }

                        &.errorEntity {
                            .errorIcon {
                                color: $taxillaRedColor;
                                display: inline-block;
                            }
                        }

                        &.warningEntity {
                            .warningIcon {
                                color: $taxillaOrangeColor;
                                display: inline-block;
                            }
                        }

                        .errorIcon,
                        .warningIcon {
                            display: none;
                        }
                    }
                }

                &.mat-card {
                    border-radius: 0;
                }

                .businessKeysRow {
                    cursor: pointer;
                    font-size: 10px;
                    white-space: normal;
                    word-break: break-all;
                    padding: 5px 10px 0;

                    .businessKeyValue {
                        color: $submitButtonColor;
                    }
                }
            }

            .navigatorSliderSelection {
                display: inline-block;
                width: 44px;
                height: 41px;
                // padding: 10px 0;
                padding: 8px 0;
                text-align: center;
                vertical-align: middle;
                cursor: pointer;

                mat-icon {
                    color: $matIconGreyColor;
                }
            }

            .newProcessButton {
                .createNewbtn {
                    display: inline-block;
                    width: calc(100% - 44px);
                    // width: 80%;
                    cursor: pointer;

                    icon {
                        display: inline-block;
                        text-align: center;
                        width: 24px;
                        height: 16px;

                        .mat-icon {
                            font-size: 16px;
                            width: 16px;
                            height: 16px;
                        }
                    }
                }

                button {
                    width: 100%;
                    border-radius: 0;
                }

                &.iconOnly {
                    width: 44px;
                    // height: 44px;
                    transition: 0.2s;

                    &.navigatorExpanded {
                        width: 0;
                        height: 0;
                        overflow: hidden;
                    }

                    .createNewbtn {
                        width: 44px;
                    }
                }
            }

            .searchEntities {
                // padding: 0 16px;
                font-size: 12px;
                padding-left: 5px;

                &.mat-form-field-appearance-legacy .mat-form-field-label {
                    color: $lightGreyColor;
                }

                .mat-form-field-infix {
                    border-top: 10px solid transparent;
                }

                .mat-form-field-wrapper {
                    padding-bottom: 8px;
                }
            }

            .searchInputField {
                mat-icon {
                    font-size: 18px !important;
                }

                .mat-form-field-suffix {
                    margin-bottom: 10px !important;
                }

                .mat-button-focus-overlay {
                    width: 24px;
                    height: 24px;
                }
            }

            .searchEntitiesIcons {
                font-size: 16px !important;
            }

            .greyMask {
                display: none;
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: gray;
                opacity: 0.5;
                z-index: 1;
            }
        }

        .entityRecordsColumn.singleRecordSelected {
            flex: 0 0 23.666667%;
            max-width: 23.666667%;
            padding-right: 0px;
            padding-left: 10px;
            height: 98%;
            width: 23.666667%;
        }

        .entityRecordsColumn {
            width: 0;
            flex: 0 0 0;
            max-width: 0;
            padding: 0;
            transition: 0.2s;
            height: 98%;

            .entityRecordsMainContainer {
                height: 100%;
                overflow-x: hidden;
                text-overflow: clip;
                max-width: 100%;
                position: relative;
                padding-bottom: 32px;
                background: #fff;
                overflow-y: hidden;

                .entityRowHeaderContainer {
                    // padding: 5px 0 5px 10px;
                    padding: 4px 0 0px 13px;
                    text-transform: capitalize;
                    border-bottom: 1px solid $headerItemBorderColor;
                    text-overflow: ellipsis;
                    max-width: 100%;
                    overflow: hidden;
                    white-space: nowrap;
                    margin: 0;

                    .entityRowHeaderText {
                        flex: auto;
                        line-height: 42px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        // width: 45%;
                        width: calc(100% - 101px);
                        font-weight: 600;
                        max-width: 70px;
                    }

                    .inboxStyleFeedActionsContainer {
                        // -webkit-box-flex: 1;
                        // flex: auto;
                        // display: -webkit-box;
                        // display: flex;
                        // width: 1%;
                        //min-width: 20px;

                        .inboxIconsContainer {
                            line-height: 38px;
                        }

                        button {
                            width: 30px;
                            height: 30px;
                        }

                        .actionsListContainer {
                            button {
                                margin-left: -10px;
                                line-height: 30px;
                                margin-top: 4px;
                            }
                        }

                        .searchIcon {
                            display: none;
                        }
                    }
                }

                .entityRowsBodyContainer {
                    width: 100%;
                    height: calc(100% - 45px);
                    // overflow-y: auto;

                    .entityRowsDisplayContainer {
                        border-bottom: 1px solid $lineColor;
                        padding: 6px 10px 4px 7px;

                        .processesRowActionsContainer {
                            .recordActionContainer {
                                .mat-icon-button {
                                    i {
                                        font-size: 16px;
                                        line-height: 16px;
                                    }
                                }
                            }
                        }

                        &:hover {
                            background: $oddItemBgColor;
                        }

                        &.deletedRecord {
                            background-color: #f7bfa3;
                        }
                    }

                    .entityRowsDisplayContainer.entitySelectedRecord {
                        border-left: 4px solid $submitButtonColor;
                        background: $oddItemBgColor;
                        padding-left: 3px;

                        .processesRowActionsContainer {
                            .recordActionContainer.selectedAction {
                                .mat-icon-button {
                                    i {
                                        color: $submitButtonColor;
                                    }
                                }
                            }
                        }
                    }

                    .entityContentCntr {
                        position: relative;
                        overflow: hidden;
                        min-height: 16px;

                        .entityDataRowSelectContainer {
                            position: absolute;
                            width: 26px;
                            top: calc(50% - 19px);
                            padding: 7px 5px;
                            float: left;

                            label.mat-checkbox-layout {
                                height: 24px;
                            }
                        }

                        .entityDataColCntr {
                            width: calc(100% - 26px);
                            float: right;
                            padding-left: 5px;
                        }
                    }

                    .entityDataRowContainer {
                        display: flex;

                        .entityDataRowDetailsContainer {
                            flex: auto;
                            // display: inline-block;
                            // max-width: calc(100% - 15px);
                            font-size: 12px;
                            // padding-left: 5px;
                            // padding-top: 5px;
                            line-height: 1.2;
                            width: 100%;

                            .entityDataDetail {
                                width: 50%;
                                display: inline-block;
                                max-width: 50%;
                                overflow-x: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                padding: 0 5px 0 0;
                                line-height: 1.2;
                            }
                        }
                    }
                }

                .recordsNavigationContainer {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    left: 15px;
                    background: #fff;

                    .recordNavigationAction {
                        padding: 1px;
                        flex: 0 0 50%;
                        max-width: 50%;
                        // margin: 0 0.5%;

                        button {
                            width: 100%;
                            border-radius: 0;
                            line-height: 28px;
                            background: $taxillaDarkGreen;
                            color: #fff;
                            box-shadow: none;
                            height: 40px;

                            &[disabled] {
                                background: $matIconGreyColor;
                                height: 40px;
                            }

                            span.material-icons {
                                position: relative;
                                top: -1px;

                                &.backIcon {
                                    transform: rotate(180deg);
                                    -webkit-transform: rotate(180deg);
                                    top: -2px;
                                }
                            }
                        }

                        .mat-raised-button[disabled][disabled] {
                            // background: #f1f1f1;
                            background: $matIconGreyColor;
                        }
                    }
                }
            }
        }

        .entityMainDataContainer {
            height: 100%;
            display: flex;
            padding: 0;

            .entityDataContainer {
                flex: auto;
                width: 100%;
                transition: 1s;
                overflow-y: hidden;

                .recordDataHeader {
                    margin: 0;
                    padding: 6.5px 15px;
                    // padding: 5px;
                    font-weight: bold;
                    // height: 44px;
                    text-transform: capitalize;
                    border-bottom: 1px solid $headerItemBorderColor;
                    // overflow: auto;
                    height: 50px;

                    &.auditLogView {
                        overflow: auto;
                        padding: 4px 15px !important;

                        .recordDataEntityName {
                            line-height: 28px;
                        }

                        .recordDataActionsContainer {
                            float: right;
                            margin: 0px 0 5px;

                            .refreshAuditTrailLst {
                                position: relative;
                                top: 6px;
                                right: 1px;
                                margin: 0 5px;
                            }
                        }
                    }

                    .recordDataEntityName {
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        max-width: 35%;
                        flex: auto;
                        display: inline-block;
                        line-height: 28px;
                        position: relative;
                        // top: 2px;
                        padding-left: 5px;
                        font-size: 15px;
                    }

                    .displayNameEntityRecord {
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        max-width: 48%;
                        line-height: 24px;
                        vertical-align: middle !important;
                        display: inline-block !important;
                        font-size: 17px;
                    }

                    .recordDataActionsContainer {
                        // float: right;
                        // text-align: right;
                        // position: relative;
                        // top: -9px;
                        // right: 5px;

                        .entityRecordButtons {
                            position: relative;
                            // top: 4px;
                            // top: 1px;
                            top: 0px;
                            left: -11px;
                        }

                        .addRecordBtn,
                        .deleteRecordBtn {
                            // position: relative;
                            // top: 4px;

                            button {
                                line-height: 30px;
                                margin-left: 20px;
                                margin-right: 0px;
                            }
                        }

                        messagepopup {
                            .messagePopup_error,
                            .messagePopup_warning {
                                height: 40px;
                                width: 40px;
                                text-align: center;
                                padding-top: 8px;
                            }

                            .tooltip {
                                top: 1px;
                                right: 40px;
                            }
                        }

                        button {
                            margin-right: 10px;

                            &.closeColor {
                                margin: 8px 5px;
                            }

                            &.mat-icon-button {
                                margin-right: 0;
                                width: 24px;
                                height: 24px;
                                line-height: 1 !important;

                                .mat-button-wrapper {
                                    width: 24px;
                                    height: 24px;
                                    display: block;
                                    text-align: center;
                                }
                            }
                        }

                        mat-icon {
                            cursor: pointer;
                        }

                        .actionButtons {
                            span.material-icons {
                                font-size: 18px;
                                line-height: 24px;
                                position: relative;
                                display: contents;
                            }
                        }
                    }

                    .recordViewTypeIconsContainer {
                        display: inline-block;
                        height: 24px;
                        vertical-align: middle;
                        margin-left: 10px;

                        mat-icon {
                            margin-right: 5px;
                            cursor: pointer;
                        }

                        mat-icon.activeIcon {
                            color: $submitButtonColor;
                        }
                    }

                    .recordActionButtonForStyle {
                        position: relative;
                        // top: 3px;
                    }
                }

                .recordDataMainContainer {
                    margin: 0;
                    width: 100%;
                    max-height: calc(100% - 44px);
                    overflow-y: hidden;
                    height: 100%;
                    // padding: 20px 5px;

                    .dataDisplayMainContainer {
                        width: 100%;
                        transition: 0.2s;
                        max-width: 100%;
                        flex: 0 0 100%;
                        // overflow-x: hidden;
                        height: 100%;
                        font-size: 13px;

                        .recordsDataDisplayMainContainer {
                            // padding: 10px;
                            padding: 15px;

                            .recordsDataDisplayContainer {
                                width: 100%;

                                .mat-paginator-range-label {
                                    // display: none;
                                }
                            }
                        }

                        .processAttributeFieldValue {
                            max-width: 156px;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow-x: hidden;
                        }

                        .recordDataDisplayMainContainer {
                            padding: 15px;

                            .entityFieldContainer {
                                line-height: 75px;
                                color: #565656;
                                padding-top: 5px;
                                padding-bottom: 5px;

                                .fieldInReadOnly {
                                    height: 75px;

                                    .readOnlyFieldValues {
                                        .readOnlyLabel {
                                            font-size: 12px;
                                            line-height: 16px;
                                            color: $placeholderHintColor;
                                        }

                                        .readOnlyValue {
                                            font-size: 13px;
                                            line-height: 24px;
                                            white-space: nowrap;
                                            overflow-x: hidden;
                                            text-overflow: ellipsis;

                                            .fieldFileName {
                                                a {
                                                    color: $submitButtonColor;
                                                    text-decoration: none;
                                                    cursor: pointer;
                                                }
                                            }

                                            .readOnlyPasswordIcon {
                                                vertical-align: middle;
                                                display: inline-block;
                                            }
                                        }
                                    }

                                    .readOnlyFieldHighlights {
                                        line-height: 15px;
                                        font-size: 11px;
                                        border-top: 1px dashed $headerItemBorderColor;
                                        padding-top: 5px;
                                    }
                                }

                                &.highlightField {
                                    .mat-form-field-underline {
                                        background-image: linear-gradient(
                                            to right,
                                            $submitButtonColor 0,
                                            $submitButtonColor 33%,
                                            transparent 0
                                        ) !important;
                                        background-size: 5px 100% !important;
                                    }

                                    .mat-form-field-label {
                                        color: $submitButtonColor;
                                    }
                                }
                            }

                            .childEntitiesContainer {
                                margin: 30px 0;
                                line-height: 24px;
                                padding-bottom: 40px;
                                font-size: 15px !important;

                                .childEntitiesHeaderContainer {
                                    margin-bottom: 20px;
                                    font-weight: bold;

                                    span.material-icons {
                                        vertical-align: middle;
                                    }
                                }

                                .childEntityNamesContainer {
                                    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.12),
                                        0 1px 3px 0 rgba(0, 0, 0, 0.2) !important;

                                    .childEntityNameContainer {
                                        //margin-bottom: 10px;
                                        cursor: pointer;

                                        .childEntityNameIcon {
                                            vertical-align: middle;
                                            margin-right: 10px;
                                        }

                                        .childEntityNameText {
                                            display: inline-block;
                                            vertical-align: middle;
                                        }
                                    }

                                    &:hover {
                                        background-color: $bodyBgColor !important;
                                    }

                                    .childEntityNameContainer.disabled {
                                        cursor: default;
                                        pointer-events: none;

                                        .childEntityNameIcon {
                                            color: #b1acac;
                                        }

                                        .childEntityNameText {
                                            color: #b1acac;
                                        }
                                    }
                                }
                            }

                            .recordDataActionsContainer {
                                margin: 15px 0;
                                text-align: right;
                                padding-bottom: 40px;

                                button {
                                    margin: 0 7px;
                                }

                                .actionButtons {
                                    span.material-icons {
                                        font-size: 21px;
                                    }
                                }
                            }

                            &.isInReadOnlyMode {
                                line-height: 22px;
                                padding: 15px;
                                width: 100%;
                                flex: 0 0 100%;
                                max-width: 100%;
                                height: 100%;

                                .recordNotCreated {
                                    position: relative;
                                    width: 100%;
                                    height: calc(100% - 40px);

                                    .recordNotCreatedText {
                                        position: absolute;
                                        left: 50%;
                                        top: 50%;
                                        transform: translateX(-50%) translateY(-50%);
                                        display: grid;

                                        .addDataButton {
                                            color: $submitButtonColor;
                                            margin-left: 20px;
                                        }
                                    }
                                }

                                .addButton {
                                    margin-top: 10px;
                                }

                                .recordGroupContainer {
                                    margin: 0;

                                    .groupedFields {
                                        .groupedFieldsHeader {
                                            padding: 5px 5px 5px 10px;
                                            margin-bottom: 0px;
                                        }

                                        .groupedFieldsBody {
                                            margin-left: 0;
                                            margin-right: 0;
                                            margin-top: 0 !important;
                                        }
                                    }
                                }

                                .entityFieldContainer {
                                    flex: 0 0 100%;
                                    max-width: 100%;
                                    width: 100%;
                                    padding: 0;

                                    .fieldInReadOnly {
                                        height: 24px;
                                        line-height: 24px;

                                        .readOnlyFieldValues {
                                            display: flex;
                                            margin: 5px 0;
                                            height: inherit;
                                            line-height: inherit;

                                            .readOnlyLabel {
                                                flex: 0 0 30%;
                                                max-width: 30%;
                                                width: 30%;
                                                font-size: inherit;
                                                line-height: 24px;
                                                padding-right: 20px;
                                                text-overflow: ellipsis;
                                                white-space: nowrap;
                                                overflow: hidden;
                                                padding-left: 10px;

                                                .mat-icon {
                                                    font-size: 16px;
                                                    height: 20px;
                                                    width: 20px;
                                                    display: inline-block;
                                                    vertical-align: middle;
                                                    transform: rotate(45deg);
                                                }
                                            }

                                            span.sep-colon {
                                                flex: 0 0 1%;
                                                max-width: 1%;
                                                width: 1%;
                                                height: inherit;
                                                line-height: inherit;
                                            }

                                            .readOnlyValue {
                                                flex: 0 0 69%;
                                                max-width: 69%;
                                                width: 69%;
                                                padding-right: 15px;

                                                .readOnlyAttachmentIcon {
                                                    mat-icon {
                                                        height: 18px;
                                                        font-size: 18px;
                                                        vertical-align: middle;
                                                        width: 18px;
                                                        cursor: pointer;
                                                    }
                                                }
                                            }

                                            .auditValueContainer {
                                                flex: 0 0 60%;
                                                max-width: 51%;
                                                width: 60%;
                                                font-size: inherit;
                                                text-align: left;
                                                line-height: 24px;
                                                padding-right: 20px;
                                                text-overflow: ellipsis;
                                                white-space: nowrap;
                                                overflow: hidden;
                                                padding-left: 10px;

                                                .oldValueContainer,
                                                .newValueContainer {
                                                    display: inline-block;
                                                    width: auto;
                                                    padding: 0 10px;
                                                }

                                                .oldValueContainer {
                                                    text-decoration: line-through;
                                                }

                                                .readOnlyValue {
                                                    flex: none;
                                                    max-width: none;
                                                    width: auto;
                                                    padding-right: 0;
                                                }

                                                &.recordDeleted {
                                                    .newValueContainer {
                                                        background: #f7bfa3;
                                                        text-decoration: line-through;
                                                    }

                                                    .oldValueContainer {
                                                        display: none;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .dataDisplayMainContainer.hide {
                        width: 0%;
                        max-width: 0;
                        flex: 0 0 0;
                        max-height: 0;
                        overflow: hidden;
                    }

                    .auditChangeLogsContainer {
                        width: 0%;
                        max-width: 0;
                        flex: 0 0 0;
                        transition: 0.2s;
                        overflow-x: hidden;
                        max-height: 0;

                        .auditChangeLogContainer {
                            padding: 0 15px;
                            margin: 15px 0;

                            .changeLogModifiedByContainer {
                                margin-bottom: 10px;
                            }
                        }
                    }

                    .auditChangeLogsContainer.show {
                        width: 100%;
                        max-width: 100%;
                        flex: 0 0 100%;
                        max-height: 100%;
                    }

                    &.downloadFilesTable {
                        padding: 15px;
                    }
                }

                .recordDataMainContainer.auditListTable {
                    // margin-top: 20px;
                    // height: calc(100% - 64px);
                    padding: 15px;

                    .disabled {
                        pointer-events: auto;
                    }
                }

                .recordDataMainContainer.showingChangeLogs {
                    // margin-top: 20px;
                    // max-height: calc(100% - 64px);
                    padding: 15px;
                }

                .recordDataMainContainer.processAttributesContainer {
                    .dataDisplayMainContainer {
                        .processAttributesDataBlock {
                            margin: 20px 0 20px;
                            padding: 0 40px;

                            .processAttributesHeader {
                                padding: 10px;
                                background: #ececec;
                            }

                            .processAttributesDetails {
                                margin: 0;
                                padding: 0 30px;

                                .processAttributesDetailsFieldContainer {
                                    flex: 1;
                                    min-width: 33.33%;
                                    margin-top: 10px;
                                    max-width: 33.33%;

                                    .processAttributeFieldHeader {
                                        strong {
                                            display: inline-block;
                                            vertical-align: middle;
                                            line-height: 40px;
                                        }

                                        .material-icons-outlined {
                                            line-height: 24px;
                                            vertical-align: middle;
                                            display: inline-block;
                                            transform: rotateZ(45deg);
                                            margin-left: 10px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .auditChangeLogContainer {
                    margin: 0px 10px 10px 10px;

                    mat-expansion-panel {
                        // border: 1px solid #847c7c63;
                        // box-shadow: none;
                        margin: 11px;
                    }
                }

                .changeLogDataHeader {
                    // padding: 12px 10px;
                    padding: 10.5px 15px;
                    font-weight: bold;
                    // height: 60px;
                    text-transform: capitalize;
                    border-bottom: 1px solid #c1c1c1;
                    // overflow: auto;
                    // height: 50px;
                    font-size: 15px;

                    .recordDataActionsContainer {
                        float: right;
                        text-align: right;
                        margin-top: -9px;

                        button {
                            margin-right: 10px;
                        }

                        mat-icon {
                            cursor: pointer;
                        }
                    }
                }

                .changeLogDataContainer {
                    margin: 0;
                    width: 100%;
                    max-height: calc(100% - 44px);
                    overflow-y: auto;
                    padding: 15px 0;
                }

                @include collaboratorsListCSS;

                .collaboratorsListDiv {
                    margin: auto;
                }

                .addCollboratorsBtn button {
                    line-height: 31px;
                }
            }
        }

        #changeLogTemplateBodyContainer {
            .mat-expanded {
                .mat-expansion-panel-header {
                    padding: 0 10px;
                    height: 50px !important;
                    box-shadow: 0 1px 4px #b1b1b1;
                    background: #707070;
                }

                .mat-expansion-indicator::after {
                    color: #fff;
                }

                .mat-expansion-panel-header-title {
                    color: #fff;
                }
            }

            .mat-expansion-panel-header {
                padding: 0 10px;
                height: 50px !important;
                background: rgba(0, 0, 0, 0.02);
                box-shadow: 0 1px 4px #b1b1b1;
            }

            .mat-expansion-panel-header:hover {
                background: #707070;
                color: #fff;

                .mat-expansion-indicator::after {
                    color: #fff;
                }

                .mat-expansion-panel-header-title {
                    color: #fff;
                }
            }

            .mat-expansion-panel-header .mat-expansion-indicator::after {
                // color: #FFF;
            }

            .mat-expansion-panel-header-title {
                // color: #FFF;
            }

            .mat-expansion-panel-body {
                padding: 15px;
            }
        }

        .viewsMainContainer {
            flex: auto;
            width: 100%;
            height: 98%;
            flex-direction: column;
            display: flex;

            .viewContainer {
                flex-grow: 1;
                cursor: pointer;
                padding: 5px;
            }

            .isCurrentView.viewContainer {
                background: #cdcdcf;
            }
        }

        .viewsColumn {
            width: 0;
            flex: 0 0 0;
            max-width: 0;
            height: 100%;
            overflow-x: hidden;
            visibility: hidden;
            transition: 0.2s;
            padding: 0;
        }

        .viewsColumn.hasMultipleViews {
            width: 5%;
            flex: 0 0 5%;
            max-width: 5%;
            visibility: visible;
            padding-right: 5px;
            padding-left: 10px;
            height: 100%;
        }

        .entityDataColumn {
            width: calc(100% - 54px);
            max-width: calc(100% - 54px);
            flex: 0 0 calc(100% - 54px);
            -ms-flex: 0 0 calc(100% - 54px);
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: calc(100% - 54px);
            -webkit-box-flex: 0;
            transition: 0.2s;
            padding-right: 10px;
            // height: 96%;
            height: 98%;

            &.singleRecordSelected {
                width: calc(77.333333% - 54px);
                max-width: calc(77.333333% - 54px);
                flex: 0 0 calc(77.333333% - 54px);
                -ms-flex: 0 0 calc(77.333333% - 54px);
                flex-grow: 0;
                flex-shrink: 0;
                flex-basis: calc(77.333333% - 54px);

                .addRecordBtn,
                .deleteRecordBtn {
                    position: relative;
                    // top: 1px !important;
                }

                .mainSearchSearcher {
                    width: 175px;
                }
            }

            &.navigatorExpanded {
                width: 83.333333%;
                max-width: 83.333333%;
                flex: 0 0 83.333333%;

                &.hasMultipleViews {
                    width: 82.66667%;
                    max-width: 82.66667%;
                    flex: 0 0 82.66667%;
                    -ms-flex: 0 0 82.66667%;

                    &.singleRecordSelected {
                        width: 60%;
                        flex: 0 0 60%;
                        max-width: 60%;
                        -webkit-box-flex: 0;
                        -ms-flex: 0 0 60%;
                    }

                    .recordDataHeader {
                        border-top: 1px solid #f1f1f1;
                    }
                }

                &.singleRecordSelected {
                    flex: 0 0 59.666666%;
                    width: 59.66667%;
                    max-width: 59.66667%;
                }

                &.maxView {
                    width: 100%;
                    max-width: 100%;
                    flex: 0 0 100%;
                }

                &.outOfContext {
                    // position: relative;
                    // margin-left: -14%;
                    // width: 97%;
                    // max-width: 97%;
                    // -webkit-box-flex: 0;
                    // flex: 0 0 97%;
                    z-index: 2;
                    height: 99%;

                    @extend .non-context-class !optional;
                }
            }

            &.hasMultipleViews {
                width: 96%;
                flex: 0 0 96%;
                max-width: 96%;
                padding-right: 0;
                height: 98%;

                .entityMainDataContainer {
                    height: calc(100% - 35px);
                }

                &.singleRecordSelected {
                    -webkit-box-flex: 0;
                    -ms-flex: 0 0 73.33334%;
                    width: 73.33334%;
                    flex: 0 0 73.33334%;
                    max-width: 73.33334%;
                }

                &.maxView {
                    width: 100%;
                    max-width: 100%;
                    flex: 0 0 100%;
                }

                .tabsMainContainer {
                    display: block;
                }
            }

            &.maxView {
                width: 100%;
                max-width: 100%;
                flex: 0 0 100%;

                .entityMainDataContainer {
                    .entityDataContainer {
                        .recordDataMainContainer {
                            max-height: calc(100% - 64px);
                        }
                    }
                }
            }

            .tabsMainContainer {
                background: $bodyBgColor;
                margin: 0;
                padding: 0;
                max-height: 35px;
                display: none;

                .tabsContainer {
                    margin: 0;
                    list-style-type: none;
                    line-height: 35px;
                    max-height: 35px;
                    overflow: hidden;
                    display: inline-block;
                    padding-right: 20px;
                    zoom: 1;

                    .tabContainer {
                        float: left;
                        // margin: 0px -10px 0;
                        margin: 0px 1px 0;
                        // border-top-right-radius: 25px 170px;
                        border-top-right-radius: 6px;
                        // border-top-left-radius: 20px 90px;
                        border-top-left-radius: 6px;
                        padding: 0 30px 0 25px;
                        height: 170px;
                        background: #fff;
                        position: relative;
                        // box-shadow: 0 13px 15px rgba(0, 0, 0, 1);
                        box-shadow: none;
                        max-width: 200px;
                        cursor: pointer;
                        border: 1px solid $leftNavBorderColor;

                        &.active {
                            z-index: 2;
                            // background: $bodyBgColor;
                            background: $taxillaDarkGreen;

                            &:before {
                                border-color: transparent;
                            }

                            &:after {
                                border-color: transparent;
                            }

                            .tabNameContainer {
                                color: #fff;
                            }

                            .close {
                                color: #fff;
                                opacity: 1;
                            }
                        }

                        .tabNameContainer {
                            display: inline-block;
                            max-width: 100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            text-decoration: none;
                            color: #222;
                            white-space: nowrap;
                        }

                        .closeTab {
                            cursor: pointer;
                            position: absolute;
                            margin-left: 10px;
                            font-size: 24px;
                        }

                        &:before {
                            border-color: transparent;
                            transform: rotate(48deg);
                            left: -23px;
                        }

                        &:after {
                            border-color: transparent;
                            transform: rotate(-48deg);
                            right: -17px;
                        }

                        &:before,
                        &:after {
                            content: '';
                            background: transparent;
                            height: 20px;
                            width: 20px;
                            border-radius: 100%;
                            border-width: 10px;
                            top: 0px;
                            border-style: solid;
                            position: absolute;
                        }

                        &:first-child {
                            margin-left: 0px;
                            // box-shadow: 0px 25px 25px black
                        }
                    }

                    &:before,
                    &:after {
                        content: '';
                        display: table;
                    }

                    &:after {
                        clear: both;
                    }
                }
            }

            &.outOfContext {
                // position: relative;
                // margin-left: 0%;
                // width: 96%;
                // max-width: calc(100% - 44px);
                // -webkit-box-flex: 0;
                // flex: 0 0 98%;
                z-index: 2;
                height: 99%;

                @extend .non-context-class !optional;
            }
        }
    }

    .processStatusViewContainer.statusBarOpened {
        .processViewMainContainer {
            .latestStatusMessage:before {
                content: '\21D3';
            }
        }
    }

    .processStatusViewContainer {
        position: fixed;
        top: calc(97% - 5px);
        width: 66.33%;
        left: 25.6%;
        padding: 10px;
        padding-top: 0;
        transition: 0.3s;
        z-index: 2;

        .processViewMainContainer {
            position: relative;
            background: #fff;
            font-size: 14px;
            transition: 0.3s;
            padding: 0;
            box-shadow: 0 5px 16px 3px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);

            .latestStatusMessage {
                position: relative;
                text-align: center;
                padding-top: 2px;
                border-top: 1px solid #47a652;
            }

            .latestStatusMessage:before {
                content: '⇑';
                position: absolute;
                top: -20px;
                width: 20px;
                left: 50%;
                transform: translateX(-50%);
                background: #47a652;
                color: #fff;
                border-top-left-radius: 3px;
                border-top-right-radius: 3px;
                height: 20px;
                cursor: pointer;
            }

            .allProcessStatusMessagesContainer {
                padding: 10px 10px 10px;
                max-height: calc(100% - 30px);
                overflow-y: auto;
                color: $hoverRedColor;
                text-align: center;

                .processStatusMessageText {
                    margin: 5px 0;
                }

                .processStatusMessageText:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .processStatusViewContainer.singleRecordSelected {
        left: 40.3%;
        width: 60%;
        padding: 0;

        .processViewMainContainer {
            margin: 0 auto;
            width: 90%;
        }
    }

    // .processStatusViewContainer.hasMultipleViews {
    //     width: 78.33334%;
    // }

    .processStatusViewContainer.hasMultipleViews.singleRecordSelected {
        // width: 61.66667%;
        padding-left: 10px;

        .processViewMainContainer {
            margin: 0 5%;
            width: 90%;
        }
    }

    .processStatusViewContainer.hideStatusBar {
        top: calc(100% + 20px) !important;
    }
}
