body.enReconMainContainer {
    background: #f3f5ff;

    &.onNewUI {
        .matHeader {
            padding-left: 75px;
            bottom: 20px;
            position: relative;
        }
        .enReconDetailsFile {
            padding-left: 60px !important;
            position: relative;
            bottom: 20px !important;
        }
    }

    .appsPageContainer {
        .searchQbSection .qbCondition {
            display: unset;
        }
    }

    .reconciliationBody th.mat-header-cell {
        padding: 0 0.5em !important;
    }
}

::-webkit-scrollbar {
    width: 8px;
}

.app-table-virtual-scroll-viewport::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
