@import '~perfect-scrollbar/css/perfect-scrollbar.css';

$lineColor: #eaeaea;
$taxillaDarkGreen: #00a651;
$backgroundGreen: #45a253;
$taxillaRedColor: #d81844;
$taxillaOrangeColor: #af7813;
$taxillaLightGreen: #a4d5a7;
$significantRegFontColor: #363636;
$submitButtonColor: $taxillaDarkGreen;
$headerItemBorderColor: #ccc;
$curtainBoxShadowColor: #c1c2c1;
$headerHeight: 50px;
// $footerHeight: 32px;
$footerHeight: 32px;
$settingsPageBackgroundColor: #f0f0f0;
$placeholderHintColor: #9c9c9c;
$fontFieldHindColor: rgba(0, 0, 0, 0.4);
$cancelButtonBgColor: #fff;
$newTaxillaLighterGreen: #ddfede;
$lightGreyColor: #a2a2a2;
$lightRedColor: rgba(255, 0, 0, 0.5);
$hoverRedColor: rgba(255, 0, 0, 0.7);
$matIconGreyColor: #616161;
$oddItemBgColor: #f7f7f7;
$evenItemBgColor: #f7f9ff; //Little Bluish
$inProgressColor: #6e6eff; //Medium Bluish
$canvasGridColor: #e3e3e3;
$fontFamily: Lato, San Francisco, Proxima Nova, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Ubuntu, Droid Sans, Helvetica Neue,
    Open Sans, sans-serif;
$matTableRowBorderGrey: #d8d8d8;
$bodyBgColor: #efefef;
$leftNavBorderColor: #e0e0e0;
$fileDragOpacity: 0.4;
$fileDragColor: rgba(0, 0, 0, 0.4);
$headerColor: #f1f1f1;

@-webkit-keyframes GradientDualToneAnimation {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

@keyframes GradientDualToneAnimation {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

@mixin collaboratorsListCSS() {
    .collaboratorsListDiv {
        margin: -15px;

        .collaborator {
            padding: 15px;
            margin: 15px;
            border: 1px solid $matTableRowBorderGrey;

            &:nth-child(even) {
                background: $oddItemBgColor;
            }

            .readOnlyLabel {
                color: $placeholderHintColor;
                font-size: inherit;
                text-align: right;
                line-height: 24px;
                padding-right: 20px;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .readOnlyValue {
                font-size: 13px;
                line-height: 24px;
                white-space: nowrap;
                overflow-x: hidden;
                text-overflow: ellipsis;
                padding-right: 15px;
            }
        }
    }
}
