/* You can add global styles to this file, and also import other style files */
@import '/node_modules/material-symbols';

* {
    outline: none;
}

/* CSS custom properties (or) CSS variables */

:root {
    /* --detailColumn-bg: #f8fcff; */
    /* --siteColor: #3598dc; */
    /* --mat-primary-color: #3f51b5; */
    /* --site-icon-color: #1976d2; */
    /* --newSiteColor: #00a651; */
    /* --newSiteGrayColor: #efefef; */
    --newSiteFont: Lato, San Francisco, Proxima Nova, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Ubuntu, Droid Sans,
        Helvetica Neue, Open Sans, sans-serif;
    /* --selectedItemBorderColor: #4fa753; */
    /* --selectedItemBackgroundColor: #f6f6f6; */
    /* --defaultFontColor: #333; */
    /* --searchBarClearColor: #da534e; */
}

$taxillaDarkGreen: #00a651;
$significantRegFontColor: #363636;
$taxillaRedColor: #d81844;
$taxillaOrangeColor: #af7813;
$settingsPageBackgroundColor: #f0f0f0;
$cancelButtonBgColor: #fff;
$lightGreyColor: #a2a2a2;
$matIconGreyColor: #616161;
$headerColor: #f1f1f1;
$lightGreenColor: #32ae7c;
$blackColor: #000;
$grayColor: #808080;
$darkGrayColor: #666;
$brightBlueColor: #3598dc;
$redShadeColor: #d9534e;
$submitButtonColor: $taxillaDarkGreen;
$bodyBgColor: #efefef;
$matTableRowBorderGrey: #d8d8d8;
$fontFamily: Lato, San Francisco, Proxima Nova, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Ubuntu, Droid Sans, Helvetica Neue,
    Open Sans, sans-serif;

@keyframes blinkAnimation {
    0% {
        background-color: unset;
        color: $matIconGreyColor;
    }

    50% {
        background-color: #4fa753;
        color: #fff;
    }

    100% {
        background-color: unset;
        color: $matIconGreyColor;
    }
}

/* global matMenuContent change */
.mat-menu-content:not(:empty) {
    padding: 0 !important;
}

.mat-form-field-subscript-wrapper {
    overflow: inherit !important;
    padding-left: 0px !important;
}

.enInvoiceMainContainer,
.enCollabMainContainer,
.enReconMainContainer,
.gstFilingMainContainer {
    &.onNewUI {
        .enInvoiceLeftNav {
            display: none !important;
        }

        .enInvoiceBodyContainer {
            max-width: 100%;
            width: 100% !important;
        }
    }

    [hidden],
    template {
        display: none !important;
    }

    .menuButton .mat-button-ripple {
        top: -1px;
        left: -5px;
        right: 5px;
    }

    .row {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0px;
    }

    .listItemContainer ul {
        list-style-type: none;
        display: inline;
    }

    .listItemContainer li {
        float: left;
        border-right: 1px solid $headerColor;
    }

    .listItemContainer li a {
        display: block;
        color: #000000b8;
        text-align: center;
        padding: 14px 16px;
        text-decoration: none;
    }

    .imageContainer {
        display: inline;
        /* float: left; */
    }

    .listItemContainer {
        display: inline;
        /* float: left; */
        padding: 0px 85px;
    }

    .userDetails {
        width: 16%;
        float: right;
        height: 31%;
        padding: 8px 0px;
    }

    .userDetails ul {
        list-style-type: none;
    }

    .userDetails li {
        float: left;
    }

    .envoiceHeaderLogo {
        margin: 5px 0;
        display: inline-block;
        vertical-align: middle;
        min-width: 250px;
        /* border-right: 1px solid #f1f1f1; */
        min-height: 41px;
    }

    .form-control {
        border-radius: 0;
    }

    .btn-link,
    .btn-link:focus,
    .btn-link:active {
        text-decoration: none !important;
    }

    .mat-raised-button.mat-primary {
        background-color: $taxillaDarkGreen !important;
        color: $cancelButtonBgColor !important;
    }

    span.material-icons:not(.noDefaultColor):not(.hasError):not(.hasWarning) {
        color: #616161;
    }

    button.btn-link,
    .instanceAction button span {
        color: $significantRegFontColor;
    }

    .btn-link:focus,
    .btn-link:hover {
        color: $taxillaDarkGreen;
    }

    .mat-menu-panel a mat-icon,
    .mat-menu-panel span mat-icon {
        color: $grayColor;
    }

    .mat-menu-panel a:hover {
        color: $taxillaDarkGreen;
    }

    .marginBottom0 {
        margin-bottom: 0px;
    }

    label {
        margin-bottom: 0;
    }

    .registrationSuccessDiv {
        margin: 30% auto;
        padding: 0px 80px;
    }

    .errorMessage {
        min-width: 150px;
        color: #ff0000 !important;
        font-size: 12px !important;
        /* font-weight: 600 !important; */
        line-height: 20px;
        position: absolute;
        left: 2px;
        top: 33px;
    }

    .loginErrorMessage {
        color: #a94442 !important;
        font-size: 12px !important;
        /* font-weight: 600 !important; */
        line-height: 20px;
        position: absolute;
        left: 14px;
    }

    .has-error {
        position: relative;
    }

    .container {
        width: 100%;
    }

    .container.componentContainer {
        width: calc(100% - 20px);
        padding: 0 5%;
        margin: 10px;
        border: 1px solid #e2e2e2;
        margin-bottom: 0;
    }

    .addConfig {
        cursor: pointer;
        margin: 0 10px;
    }

    .sectionHeader {
        margin: 20px 0;
        font-size: 18px;
        text-transform: uppercase;
        color: #333;
        font-weight: 500;
        border-bottom: 1px solid #e2e2e2;
        padding-bottom: 15px;
    }

    .sectionInfoContainer {
        margin: 0 0 20px;

        label {
            color: #606060;
            display: block;
            line-height: 34px;
        }
    }

    .navbar-default .navbar-nav > li > a {
        /* padding: 0px 25px;
        cursor: pointer;
        margin: 6px 0px;
        text-align: center;
        color: #fff;
        line-height: 45px;
        display: inline-block; */
        padding: 0px 25px;
        cursor: pointer;
        text-align: center;
        color: $cancelButtonBgColor;
        line-height: 38px;
        display: inline-block;
    }

    .navbar-right .listitems {
        line-height: 57px;
    }

    .navbar-right .orgPopUpDiv .dropdown-menu {
        right: -52px;
    }

    .apps {
        font-size: 2em !important;
        color: $cancelButtonBgColor;
    }

    .navbar-default .navbar-nav > li > a:hover,
    .navbar-default .navbar-nav > li > a:focus {
        color: $cancelButtonBgColor;
    }

    #postHeaderContainer {
        margin-bottom: 0;
        border-radius: 0px;
        border: none;
        /* min-width: 1360px; */
    }

    .fa-dedent,
    .fa-indent {
        font-size: 12px;
        color: #8c8888;
    }

    .active-tab {
        color: black;
        background-color: $cancelButtonBgColor;
        margin: 0px;
        padding: 10px;
        border-left: 3px solid #32ac7b;
    }

    #preLoginContainer .container.componentContainer {
        border: 0;
    }

    .navbar-header {
        padding: 10px 13px;
        background-color: $brightBlueColor;
        border-bottom: 1px solid $cancelButtonBgColor;
        cursor: pointer;
    }

    #postHeaderContainer .container-fluid {
        padding: 0px;
        background-color: $brightBlueColor;
        border-color: #2f5597;
        height: 57px;
    }

    .navbar-right {
        margin-right: 0px;
    }

    .navbar-default .navbar-nav > .open > a,
    .navbar-default .navbar-nav > .open > a:focus,
    .navbar-default .navbar-nav > .open > a:hover {
        color: $cancelButtonBgColor;
        background-color: $brightBlueColor;
    }

    .navbar-default .navbar-nav > li > a.orgMenu {
        padding: 0px;
    }

    .lastRowItem {
        border-bottom: none;
    }

    .nav .open > a,
    .nav .open > a:focus,
    .nav .open > a:hover {
        border-color: $cancelButtonBgColor;
    }

    .listitems:last-child {
        border-right: none;
    }

    @media screen and (min-width: 768px) {
        .navbar > .container-fluid .navbar-brand {
            margin-left: 0px !important;
        }
    }

    .helpDocModal {
        width: 80% !important;
    }

    #helpDocModal {
        padding: 0px 15px;
    }

    #helpDocModal .panel-collapse {
        width: 100%;
        -webkit-box-shadow: 0px 0px;
        box-shadow: 0px 0px;
    }

    #masterModal .slimScrollDiv {
        height: 450px !important;
    }

    .address {
        width: 257px;
        height: 166px;
    }

    #addAddress .modal-footer,
    #addNewLocation .modal-footer,
    #addlocationAddress .modal-footer,
    #masterModal .modal-footer {
        text-align: center;
        border-top: none;
    }

    .btn-blue {
        background-color: #288dc5;
        color: $cancelButtonBgColor;
        font-weight: 600;
        border: 1px solid #288dc5;
        border-radius: 20px !important;
    }

    .btn-blue:hover {
        background-color: #288dc5;
        font-weight: 600;
        border: 1px solid #288dc5;
    }

    .btn-danger {
        color: $cancelButtonBgColor;
        background-color: #828992;
        border-color: #828992;
        font-weight: 600;
        border-radius: 20px !important;
    }

    .ewb-modal-btn-default {
        font-weight: 500;
        border-radius: 20px !important;
    }

    .ewb-modal-btn-default:hover {
        font-weight: 600;
    }

    .btn-danger:hover,
    .btn-danger:active,
    .btn-danger:focus,
    .btn-danger:active:focus {
        font-weight: 600;
        background-color: #828992;
        border-color: #828992;
        outline: none;
    }

    .btn-blue:hover,
    .btn-blue:focus,
    .btn-blue:active,
    .btn-blue:active:focus {
        color: $cancelButtonBgColor;
        background-color: #288dc5;
        border-color: #288dc5;
        outline: none;
    }

    .btn {
        border-radius: 0;
        min-width: 100px;
        text-transform: uppercase;
        font-weight: 500;
    }

    .navbar-right .dropdown-menu {
        right: 10px;
    }

    .has-feedback[class^='col-'] .form-control-feedback {
        right: 15px;
    }

    .form-control-feedback {
        pointer-events: auto;
    }

    [class^='col-'] > .errorMessage {
        margin-left: 15px;
    }

    countries,
    states,
    cities {
        display: block;
    }

    .locationName {
        text-transform: lowercase;
        display: inline-block;
    }

    .locationName::first-letter {
        text-transform: uppercase;
    }

    .btnTrans {
        text-transform: initial;
    }

    .container.componentContainer.connectionsContaienr,
    #profileComponentContainer .container.componentContainer {
        padding: 0px;
    }

    .btn-link:active,
    .btn-link:focus {
        outline: none;
    }

    .active {
        color: #32ae7b;
        font-weight: 500;
    }

    .dropdown-menu {
        border-radius: 0;
    }

    .alert {
        border-radius: 0;
    }

    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    .fa-search,
    .fa-envelope-o {
        cursor: pointer;
    }

    .input-group-addon {
        border-radius: 0;
    }

    .error {
        color: #a94442 !important;
        font-size: 12px !important;
        font-weight: 600 !important;
    }

    .panel-collapse {
        -webkit-box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5);
        box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.5);
        width: 80%;
    }

    .badgeContainer .badge {
        border-radius: 0;
        font-size: 14px;
        padding-right: 5px;
    }

    .badgeContainer {
        padding: 0 0 0 2px;
        border: 1px solid #e2e2e2;
        background: #777;
        color: $cancelButtonBgColor;
        cursor: default;
    }

    .active .badgeContainer .badge {
        background: #32ae7b;
    }

    .active .badgeContainer {
        background: #32ae7b;
    }

    .settingsRouting {
        padding: 0px;
        -webkit-transition: all 0.5s ease-out;
        -o-transition: all 0.5s ease-out;
        transition: all 0.5s ease-out;
    }

    .billingAdd p,
    .sendAdd p,
    .shippingAdd p {
        margin: 0px;
        font-weight: 500;
        color: #545755;
        font-size: 14px;
    }

    .sendAdd p {
        text-align: left;
    }

    .billingAdd,
    .sendAdd,
    .shippingAdd {
        margin: 0 0 20px 0px;
    }

    .sendAddDiv span {
        float: left;
    }

    .userName {
        color: #878787 !important;
        font-weight: 500 !important;
    }

    #mainContent:after {
        position: absolute;
        top: 32px;
        content: ' ';
        width: 2px;
        height: 80%;
        background-color: #d7d7d7;
        margin-left: 10px;
        left: 93px;
        z-index: 1;
    }

    div#myNavbar.navbar-collapse.collapse.in {
        width: 200px;
        position: absolute;
        right: 0;
        border: 2px solid #e2e2e2;
        top: 100%;
    }

    .searchInput:focus {
        -webkit-box-shadow: 0px 0px;
        box-shadow: 0px 0px;
    }

    .searchInput,
    .locationsListSearchContainer .input-group-addon .userButton {
        border: none;
        background-color: #fbf7f7;
        -webkit-box-shadow: 0px 0px;
        box-shadow: 0px 0px;
    }

    .searchInput {
        padding: 0px;
        height: 35px;
        border: none;
        background: #fbf7f7;
        -webkit-box-shadow: 0px 0px;
        box-shadow: 0px 0px;
    }

    .mastersearchInput {
        background: $cancelButtonBgColor;
        padding-left: 10px;
        padding-right: 25px;
    }

    .navbar-nav .listitems .locationsList {
        width: 20%;
    }

    .mastersSearchContainer .input-group-addon,
    .invoiceNewSearchContainer .input-group-addon,
    .invoiceFilter .input-group-addon {
        border: 0;
        padding: 0;
        background: #fbf7f7;
    }

    .mastersSearchContainer .input-group-addon .userButton,
    .invoiceNewSearchContainer .input-group-addon .userButton,
    .invoiceFilter .input-group-addon .userButton {
        border: none;
        background: #fbf7f7;
        -webkit-box-shadow: 0px 0px;
        box-shadow: 0px 0px;
    }

    .mastersSearchContainer .input-group,
    .invoiceNewSearchContainer .input-group,
    .invoiceFilter .input-group {
        background: #fbf7f7;
    }

    .invoiceFilter select {
        border: none;
    }

    .iconContainer {
        background: #c7c7c7;
        text-align: center;
        padding: 10px;
        padding: 7px;
    }

    .invoicesContainer .container.componentContainer {
        width: 100%;
        padding: 0;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
    }

    .no-padding {
        padding: 0;
    }

    ngb-datepicker .ngb-dp-header.bg-light {
        height: 3.4rem;
        background: $cancelButtonBgColor;
        min-width: 235px;
        border: 1px solid #8c8c8c;
        border-bottom: 0;
    }

    ngb-datepicker .ngb-dp-arrow {
        height: 3.4rem;
    }

    ngb-datepicker-navigation select {
        padding: 0.5rem 0.5rem;
        font-size: 1.2rem;
        height: 3.4rem;
        background: $cancelButtonBgColor;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    ngb-datepicker .ngb-dp-day,
    ngb-datepicker .ngb-dp-weekday,
    ngb-datepicker .ngb-dp-week-number {
        width: 3.2rem !important;
        height: 3.7rem !important;
    }

    ngb-datepicker .ngb-dp-weekday,
    ngb-datepicker .ngb-dp-week-number {
        line-height: 3.2rem !important;
    }

    ngb-datepicker .ngb-dp-arrow-btn {
        padding: 0rem 0rem !important;
        min-width: 0px !important;
        width: 100% !important;
    }

    ngb-datepicker .custom-day.range,
    ngb-datepicker .custom-day:hover {
        background-color: $taxillaDarkGreen !important;
        color: $cancelButtonBgColor !important;
    }

    ngb-datepicker.dropdown-menu {
        min-width: 100% !important;
        text-align: center !important;
        width: 3.2rem !important;
        height: 3.2rem !important;
        line-height: 3.2rem !important;
        border-radius: 0.25rem !important;
        background: $cancelButtonBgColor !important;
        left: -7% !important;
        margin: 2px 2px !important;
    }

    ngb-datepicker .ngb-dp-months {
        background: $cancelButtonBgColor !important;
        min-width: 235px;
        border: 1px solid #8c8c8c;
        /* border-top: 0; */
    }

    .text-white {
        color: $cancelButtonBgColor !important;
    }

    .bg-primary {
        background-color: #007bff !important;
    }

    .btn-light {
        color: #111;
        background-color: #f8f9fa;
        border-color: #f8f9fa;
    }

    .table_header_split > div:first-child {
        border-bottom: 1px solid $lightGreyColor;
    }

    .table_header_split > div:last-child > div:first-child {
        border-right: 1px solid $lightGreyColor;
    }

    .table-cell-split > div > div:first-child {
        border-right: 1px solid $lightGreyColor;
    }

    td.matTableMessages {
        text-align: center;
    }

    tr.mat-header-row {
        background: $headerColor !important;
    }

    .mat-header-cell {
        color: rgba(0, 0, 0, 0.77);
        font-size: 14px;
        font-weight: 600;
    }

    table.mat-table tbody tr.mat-row td.matTableCell.mat-cell.mat-column-Details {
        max-width: 440px;
        overflow-x: hidden;
        white-space: nowrap;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        cursor: pointer;
    }

    table.mat-table tbody tr.mat-row td.matTableCell.mat-cell.mat-column-Details:hover {
        -o-text-overflow: unset;
        text-overflow: unset;
        overflow: visible;
        white-space: pre-wrap;
    }

    table {
        &.mat-table {
            tbody {
                tr.mat-row {
                    td.matTableCell.mat-cell {
                        max-width: 205px;
                        overflow-x: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        padding-left: 10px;
                        padding-right: 10px;
                        text-align: left;
                    }

                    .mat-column-Files mat-expansion-panel.mat-expansion-panel {
                        margin: 0 !important;
                        border: 0 !important;
                        box-shadow: none;
                        background: none !important;

                        .mat-expansion-panel-header {
                            box-shadow: none !important;
                            border-bottom: 0;
                            padding: 0;
                            background: none;
                        }

                        .mat-expansion-panel-content {
                            .mat-expansion-panel-body {
                                padding-left: 0;
                                padding-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .taxHide {
        display: none;
    }

    .pl-10px {
        padding-left: 10px;
    }

    .display-inlineBlock {
        display: inline-block;
        vertical-align: middle;
        position: relative;
    }

    .textOverFlowContent {
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
        display: block;
    }

    .width60p {
        width: 60%;
    }

    .padding5px {
        padding: 5px;
    }

    .text-black {
        color: #333;
        display: inline-block;
        border-bottom: 1px solid #2384cd;
    }

    .text-blue {
        color: #2384cd;
        display: inline-block;
    }

    .modal-header {
        // font-size: 16px;
        color: $significantRegFontColor;
        /* margin-bottom: .5em; */
    }

    .hideBorder {
        border-right: transparent;
    }

    .dropdown {
        cursor: pointer;
    }

    .invoiceWizardHeaderItem {
        padding: 10px 0;
        background: transparent;
        z-index: 1;
        position: relative;
    }

    .invoiceWizardHeaderItem span.fnt16 {
        margin-left: 10px;
    }

    .uploadSection .downloadWizard .form-control {
        width: 72%;
        height: 2.64em;
        display: inline-block;
    }

    .uploadSection .downloadWizard {
        margin: 10px 0px;
    }

    .mat-badge-content {
        background-color: orange;
    }

    .mat-badge-medium.mat-badge-after:not(.ignore) .mat-badge-content {
        right: -20px;
    }

    .outlookSearchContainer {
        background: #fdfdfd;
        -webkit-transition: all 0.7s ease 0s;
        -o-transition: all 0.7s ease 0s;
        transition: all 0.7s ease 0s;

        .mat-badge-medium.mat-badge-above:not(.ignore) .mat-badge-content {
            top: -3px;
            height: 21px;
            width: 21px;
            left: 18px;
            font-size: 9px;
        }
    }

    .downloadWizard a {
        color: $taxillaDarkGreen;
    }

    .uploadSection .downloadWizard .btn-primary {
        margin-top: 0px;
    }

    .invoiceWizardHeaderItem:first-child {
        padding: 0 0 10px 0;
    }

    .invoiceWizardHeaderItem:last-child {
        padding: 0 10px 0 0;
    }

    .invoiceWizardHeaderItem.active {
        font-size: 16px;
        font-weight: 700;
    }

    .floatRight {
        float: right;
    }

    .borderClass {
        padding: 15px;
        border: 1px solid #e2e2e2;
        margin-bottom: 10px;
        -webkit-box-shadow: 1px 3px 4px 0px #e2e2e2;
        box-shadow: 1px 3px 4px 0px #e2e2e2;
    }

    .uploadButton {
        cursor: pointer;
    }

    #masterModal .modal-dialog {
        width: 65%;
    }

    #masterModal .modal-title {
        color: #333;
        text-transform: uppercase;
    }

    .invoiceUploadButton {
        background: $grayColor;
        padding: 8px 18px;
        color: $cancelButtonBgColor;
        cursor: pointer;
        border-radius: 5px;
        min-height: 17px;
        display: block;
        position: relative;
        left: -12px;

        img {
            width: 15px;
            height: 15px;
        }
    }

    .invoiceFilterColumn {
        padding: 0;
        width: 16.67%;
        white-space: nowrap;
        color: $cancelButtonBgColor !important;
    }

    .invoiceCollapseHeader,
    .settingsCollapseHeader {
        padding: 7px 1em 7px 21px;
        text-align: left;
        font-size: 12px;
        font-weight: 500;
        cursor: pointer;
    }

    .settingsCollapseHeader {
        padding: 7px 0;
        text-align: left;
        font-size: 13px;
        font-weight: 500;
        cursor: pointer;
        margin: 0 20px;
    }

    .displayNone {
        display: none;
    }

    [aria-expanded='false'] .collapseRightArrow,
    [aria-expanded='true'] .collapseDownArrow {
        display: block;
    }

    [aria-expanded='true'] .collapseRightArrow,
    [aria-expanded='false'] .collapseDownArrow {
        display: none;
    }

    .arrowImage {
        min-width: 12px;
        margin-left: 10px;
    }

    .filterItemContainer {
        padding: 5px 15px 5px 10px;
        cursor: pointer;
        font-size: 14px;
        margin-right: 15px;
    }

    .inboundFilterItem:not(.activeItem) .filterItemContainer,
    .outboundFilterItem:not(.activeItem) .filterItemContainer,
    .mastersFilterItem:not(.activeItem) .filterItemContainer {
        border-bottom: 1px solid #69b6ea;
    }

    .inboundFilterItem,
    .outboundFilterItem,
    .mastersFilterItem {
        padding: 0 0 0 50px;
        border-left: 3px solid #3698dc;
    }

    .stateFilterItem {
        border-left: 3px solid #3698dc;
        padding: 0 0 0 30px;
    }

    .invoiceCollapseHeader .arrowImage,
    .settingsCollapseHeader .arrowImage {
        float: right;
        margin: 5px 5px;
    }

    .inboundFilterItem.activeItem,
    .outboundFilterItem.activeItem,
    .mastersFilterItem.activeItem {
        border-color: #20c747;
        background: #e0f2ff;
        color: #585859;
    }

    .pointerEventsNone {
        pointer-events: none;
    }

    .mandatory {
        color: red;
    }

    .feedColumn {
        margin: 0 0.7em;
        width: calc(30% - 1.4em);
        /* border-right: 1px solid #e5e5e5; */
        z-index: auto;
        -webkit-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
        box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    }

    .invoiceFeedContainer {
        height: 100%;
    }

    .invoiceFeedItem_details {
        display: inline-block;
        width: 100%;
        vertical-align: middle;
        position: relative;
    }

    .invoiceColumnFeedItem {
        /* border-bottom: 1px solid #dcdcdc; */
        cursor: pointer;
        background: $cancelButtonBgColor;
        height: auto;
        overflow: hidden;
        /*V2.0*/
        border-bottom: 1px solid #efefef;
    }

    .expandMore {
        height: auto;
    }

    .invoiceColumnFeedItem:not(.consolidatedEwbFeedItem) {
        /* padding: 10px 5px;
margin: 10px; */
        /* padding: 5px 0px; */
        padding: 11.5px 5px;
        /* margin: 5px; */
    }

    .feedSelectboxDiv {
        float: left;
        margin-top: -1px;
    }

    .feedCheck {
        padding: 5px;
        width: 34px;
    }

    .default-feedLabel {
        font-size: 12px;
        margin-top: 6px;
    }

    .default-feedValue {
        margin-top: 6px;
        font-size: 12px;
    }

    .feedMainDiv {
        width: calc(100% - 34px);
        /* float: left; */
        float: right;
    }

    .expandFeed {
        width: 5%;
        float: left;
        /* font-size: 24px;
line-height: 76px; */
        position: absolute;
        bottom: 0;
    }

    .consolidatedItem .feedMainDiv {
        width: 100%;
    }

    .invoiceColumnFeedItem:not(.feedActiveItem):hover {
        background: #f6f6f6;
        color: $darkGrayColor;
    }

    .invoiceColumnFeedItem:hover .feedItemOrgName,
    .invoiceColumnFeedItem:hover .feedItemTimeBlock,
    .invoiceColumnFeedItem:hover .invoiceFeedItemValue {
        color: $darkGrayColor;
    }

    .invoiceDetailHeaderActionItem img {
        max-width: 14px;
        max-height: 14px;
    }

    .detailColumn {
        position: absolute;
        top: 0;
        right: 0;
        width: 73%;
        margin-right: 0;
        -webkit-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
        box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
        -webkit-box-flex: 0;
        -ms-flex: 0 0 73%;
        flex: 0 0 73%;
        max-width: 73%;
    }

    .invoiceDetailHeaderActionItem {
        padding: 8px 4px;
        // border-right: 1px solid #d9d0d0;
        cursor: pointer;
        font-size: 13px;
    }

    .invoiceDetailHeaderActionItem:hover {
        background: $cancelButtonBgColor;
    }

    .invoiceDetailHeaderActionItem:last-child {
        border-right: 0;
    }

    .feedActiveItem {
        /* background-color: #e3f8ee;
border-radius: 4px; */
        background-color: #f6f6f6;
        border-left: 5px solid #4fa753;
        padding-left: 0px !important;
    }

    .ics_body table {
        margin-top: 15px;
    }

    .ics_body td {
        padding: 2px 4px !important;
        font-size: 13px;
        color: $darkGrayColor;
        font-weight: 500;
        white-space: nowrap;
    }

    .headerSpan {
        border-bottom: 1px solid #c5c8ca;
        padding-bottom: 20px;
        font-family: 'Raleway', sans-serif;
        font-weight: 700;
    }

    .importOptionsSection {
        padding: 10px 0px 30px 0px;
    }

    .downloadSection {
        position: relative;
    }

    .downloadSection .fa-arrow-circle-down {
        position: absolute;
    }

    .fnt20 {
        font-size: 20px;
    }

    .fnt24 {
        font-size: 24px;
    }

    .fnt16 {
        font-size: 16px;
    }

    .downloadSection h3,
    .uploadSection h3,
    .finalSection h3 {
        text-transform: uppercase;
        font-size: 18px;
        margin: 20px 0px;
        color: #01b0f0;
        text-align: left;
    }

    .downloadWizard .btn-primary {
        background-color: $cancelButtonBgColor;
        border-color: unset;
        margin-top: 25px;
    }

    .downloadWizard .btn-primary.browseBtn {
        /* background-color: #909090;
border-color: #909090; */
        padding: 0;
    }

    .downloadWizard .btn-primary:active:focus {
        background-color: #01b0f0;
        border-color: #01b0f0;
        color: $cancelButtonBgColor;
    }

    .btn-green {
        background-color: #008051;
        color: $cancelButtonBgColor;
        border: 1px solid #008051;
        float: right;
        margin-top: 20px;
        text-transform: none;
    }

    .panelClass {
        margin: 0px;
        border: 1px solid #c5c8ca;
        border-radius: 0px;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .checkbox {
        margin-right: 10px !important;
    }

    .inputField {
        margin-bottom: 10px;
    }

    .copyright {
        padding-top: 125px;
        font-size: 14px;
        color: #5f8a94;
        font-family: 'Raleway', sans-serif;
    }

    .ics_body .createInvoice_lineItemsTable thead {
        color: #333333;
        font-size: 12px;
        background: $cancelButtonBgColor;
    }

    .ics_billDetailsBlock label {
        font-weight: 500;
    }

    .ics_billDetailsBlock > div > div:first-child,
    .ics_billDetailsBlock > div > div:first-child label {
        font-weight: 600;
    }

    select.stateSlection {
        font-size: 14px;
        color: #191717f2;
        font-weight: 700;
        font-family: 'Raleway', sans-serif;
    }

    .cursorPointer {
        cursor: pointer;
    }

    .ics_body input[type='text'] {
        font-size: 13px;
        margin: 1px;
    }

    .invoiceColumnFeedItem.activeFeed {
        color: $darkGrayColor;
        background: #f8fcff;
    }

    .invoiceColumnFeedItem.activeFeed .feedItemOrgName,
    .invoiceColumnFeedItem.activeFeed .feedItemTimeBlock,
    .invoiceColumnFeedItem.activeFeed .invoiceFeedItemValue {
        color: $darkGrayColor;
    }

    .ics_body .table-bordered.createInvoice_lineItemsTable {
        border: 1px solid $lightGreyColor;
    }

    .ics_body .table-bordered > tbody > tr > td,
    .ics_body .table-bordered > tbody > tr > th,
    .ics_body .table-bordered > tfoot > tr > td,
    .ics_body .table-bordered > tfoot > tr > th,
    .ics_body .table-bordered > thead > tr > td,
    .ics_body .table-bordered > thead > tr > th {
        border: 1px solid $lightGreyColor;
    }

    .ics_body .createInvoice_lineItemsTable td:first-child {
        color: #696f76;
        background: $cancelButtonBgColor;
    }

    .loginErrorField {
        top: 35px;
    }

    .point {
        background: #ddd;
        display: inline-block;
        height: 5px;
        margin-right: 1px;
        width: 5%;
    }

    .point:last-child {
        margin: 0 !important;
    }

    .ics_body span {
        line-height: 24px;
        margin: 2px 0;
    }

    .fa-times {
        cursor: pointer;
    }

    .userButtons,
    .roleButtons {
        text-align: center;
        margin-top: 20px;
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 25px;
        /* float: right; */
    }

    .switch input {
        display: none;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: black;
        -webkit-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
    }

    .slider:before {
        position: absolute;
        content: '';
        height: 20px;
        width: 20px;
        left: 1px;
        bottom: 3px;
        background-color: $cancelButtonBgColor;
        -webkit-transition: 0.4s;
        -o-transition: 0.4s;
        transition: 0.4s;
    }

    input:checked + .slider {
        background-color: #32ac7b;
    }

    input:focus + .slider {
        -webkit-box-shadow: 0 0 1px #32ac7b;
        box-shadow: 0 0 1px #32ac7b;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(38px);
        -ms-transform: translateX(38px);
        transform: translateX(38px);
    }

    input.require::-webkit-input-placeholder:after {
        content: '*';
        color: red;
        font-size: 1.5rem;
    }

    .activeText {
        position: absolute;
        font-size: 12px;
        color: $cancelButtonBgColor;
        top: 4px;
        left: 2px;
    }

    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }

    .invoiceDetailHeaderActionItem a {
        color: #253844;
        text-decoration: none !important;
    }

    .addressTypeFont {
        font-weight: 700;
    }

    .marginBottom10 {
        margin-bottom: 10px;
    }

    .btn-darkblue {
        background: #303b4b;
        color: $cancelButtonBgColor !important;
        font-weight: 600;
        border: 1px solid #303b4b;
        border-radius: 20px;
    }

    .btn-darkblue:hover {
        background-color: #303b4b;
        font-weight: 600;
        border: 1px solid #303b4b;
    }

    .scrollable-menu {
        height: auto;
        max-height: 200px;
        overflow-x: hidden;
    }

    .highlighted {
        background-color: #ddedf5;
    }

    .ics_body select {
        font-size: 13px;
        margin: 1px;
    }

    .ics_body datepicker select {
        margin: 0;
    }

    .table_header_split > div:last-child > div {
        padding: 0 0px;
    }

    .createInvoice_lineItemsTable th.table_header_split {
        min-width: 100px;
    }

    td.table-cell-split div {
        padding: 0;
        text-align: center;
    }

    .ics_body td.table-cell-split {
        min-width: 110px;
        padding: 0 0 0 0px !important;
    }

    .btn.btn-red {
        color: $cancelButtonBgColor;
        background-color: #d9534f;
        border-color: #d43f3a;
    }

    .btn-red.profileActionButton {
        border-radius: 0px !important;
    }

    .btn-red.active,
    .btn-red:active,
    .open > .dropdown-toggle.btn-red {
        color: $cancelButtonBgColor;
        background-color: #c9302c;
        border-color: #ac2925;
    }

    .btn-link.btn-error-link {
        color: $redShadeColor;
    }

    .ics_header .btn-error-link {
        margin: 10px 0;
        text-transform: capitalize;
        font-weight: 600;
    }

    .item-has-error {
        background: #ea7874;
        color: $cancelButtonBgColor;
    }

    .lineItem-has-error {
        border-color: #a94442;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    }

    .pointerClass .itemMasterActions a,
    .pointerClass .itemMasterActions a:hover {
        text-decoration: none;
        color: $brightBlueColor;
    }

    .ics_body .item-has-error td {
        color: $cancelButtonBgColor;
    }

    .ics_body .createInvoice_lineItemsTable tr.item-has-error:hover,
    .ics_body .createInvoice_lineItemsTable .item-has-error td:hover {
        background: #e85c57;
    }

    .color-red {
        color: $redShadeColor;
    }

    .errorsList {
        border-bottom: 1px solid #e2e2e2;
        padding: 0 0 15px;
        margin: 0 30px 15px;
    }

    .errorsList .color-red {
        font-weight: 600;
        margin-bottom: 5px;
    }

    .required {
        color: red;
    }

    .homePageContainer {
        position: relative;
    }

    .invoiceColumn {
        -webkit-transition-duration: 0.5s;
        -o-transition-duration: 0.5s;
        transition-duration: 0.5s;
        /* border-right: 1px solid #d9d9d9; */
        /*V1.0*/
        /* border-right: 1px solid #a7a7a7; */
        /*V2.0*/
        -o-text-overflow: clip;
        text-overflow: clip;
        /* overflow-x: hidden; */
        /* white-space: nowrap; */
        color: black !important;
        padding: 0;
        background: $cancelButtonBgColor;
        position: relative;
        float: left;
    }

    .invoiceFilterColumn.invoiceColumn.hideManageVerticalTabs,
    .settingsFilterColumn.settingsColumn.hideManageVerticalTabs {
        -webkit-transition-duration: 0.5s;
        -o-transition-duration: 0.5s;
        transition-duration: 0.5s;
        width: 48px !important;
        background: $cancelButtonBgColor;
        max-width: 48px;
    }

    /* for masters */

    .hideManageVerticalTabs {
        width: 63px;
    }

    .createNew {
        width: 100%;
        height: 48px;
        background: $matIconGreyColor;
        color: $cancelButtonBgColor;
    }

    .createNew:hover {
        background: $taxillaDarkGreen;
    }

    .manageFilterSections,
    .manageAppLabel {
        -webkit-transition: opacity 0.5s ease-in;
        -o-transition: opacity 0.5s ease-in;
        transition: opacity 0.5s ease-in;
    }

    .visibileAnimate {
        /* opacity: 1; */
        display: block;
    }

    .hiddenAnimate {
        opacity: 0;
        pointer-events: none;
        display: none;
    }

    .manageFilterSections,
    .settingsFilterSections {
        background: $cancelButtonBgColor;
        color: #333;
    }

    .manageRouteLinkContainer {
        padding: 0px;
        -webkit-transition-duration: 0.5s;
        -o-transition-duration: 0.5s;
        transition-duration: 0.5s;
        -webkit-transition-property: width;
        -o-transition-property: width;
        transition-property: width;
        width: 83.33%;
        max-width: calc(100% - 48px);
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    }

    .backgroundColorDiv {
        background-color: $cancelButtonBgColor;
    }

    /* Consolidated EWay Bill styles*/

    .consolidateewbContainer {
        padding: 0px;
        position: absolute;
        z-index: 99;
        height: 100%;
        background: $cancelButtonBgColor;
    }

    .consolidateewbContainer .searchParams {
        /* background-color: #2E75B6; */
        /* background-color: #3f51b5; */
        background-color: #efefef;
        color: $cancelButtonBgColor;
        padding: 10px;
    }

    .consolidateewbContainer .searchParams .form-control {
        color: #736161;
        font-size: 13px;
    }

    .col-xs-half {
        width: 4.1666%;
    }

    .consolidateewbContainer .searchParams .form-control,
    .consolidateewbContainer .dummyElem {
        height: 30px;
        background-color: $cancelButtonBgColor;
    }

    .consolidateewbContainer .searchParams input:-moz-placeholder {
        /* color: #a9a8a8 !important; */
        // color:  !important;
        font-size: 12px;
    }

    .consolidateewbContainer .searchParams input::-moz-placeholder {
        /* color: #a9a8a8 !important; */
        color: $grayColor !important;
        font-size: 12px;
    }

    .consolidateewbContainer .searchParams input:-ms-input-placeholder {
        /* color: #a9a8a8 !important; */
        color: $grayColor !important;
        font-size: 12px;
    }

    .consolidateewbContainer .searchParams input::-webkit-input-placeholder {
        /* color: #a9a8a8 !important; */
        color: $grayColor !important;
        font-size: 12px;
    }

    .consolidateewbContainer .searchParams .dateRange .input-group-addon {
        background-color: transparent;
    }

    .consolidateewbContainer .noSearchPerformedYet {
        text-align: center;
        margin-top: 100px;
    }

    .mb-2 {
        margin-bottom: 10px;
    }

    .m5 {
        margin-top: 5px;
    }

    .m10 {
        margin-top: 10px;
    }

    .m20 {
        margin-top: 20px;
    }

    .consolidateewbContainer .consolidateLabel {
        width: 34.8333%;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 34.8333%;
        flex: 0 0 34.8333%;
        max-width: 34.8333%;
    }

    @media only screen and (min-width: 1056px) and (max-width: 1366px) {
        .consolidateewbContainer .consolidateLabel {
            width: 28.8333%;
            -webkit-box-flex: 0;
            -ms-flex: 0 0 28.8333%;
            flex: 0 0 28.8333%;
            max-width: 28.8333%;
        }
    }

    .consolidateewbContainer .consolidateLabel i {
        font-size: 1.5em;
        margin-left: 25px;
    }

    .consolidateewbContainer .consolidateLabel .labelText {
        vertical-align: top;
        font-size: 16px;
        color: $significantRegFontColor;
    }

    .ent-d-inline {
        display: inline-block;
    }

    .ent-d-inline span.fa {
        color: $taxillaDarkGreen;
    }

    .consolidateewbContainer .searchParams .searchButton {
        min-width: auto;
        background-color: transparent;
        color: $taxillaDarkGreen;
        height: 34px;
        width: 34px;
        margin-left: -15px;
        border: 0;
        font-size: 24px;
        padding: 0;
    }

    .userModalRow {
        margin-top: 10px;
    }

    .consolidateewbContainer .searchParams .dateRange {
        color: #333;
        width: 50%;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 35%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .noPadding {
        padding: 0px;
    }

    .noPaddingLeft {
        padding-left: 0px;
    }

    .noPaddingRight {
        padding-right: 0px;
    }

    .padddingBottom {
        padding: 26px 17px;
    }

    .noMargin {
        margin: 0px;
    }

    .noMarginLeft {
        margin-left: 0px;
    }

    .noMarginRight {
        margin-right: 0px;
    }

    .cancelConsolidation,
    .uploadAndConsolidated {
        cursor: pointer;
    }

    .uploadAndConsolidated i {
        margin-right: 10px;
        padding: 4px 7px;
        width: 30px;
        height: 30px;
    }

    .consolidateewbContainer .searchParams .cancelConsolidation i {
        font-size: 1.8em;
        margin-right: 10px;
        background: transparent;
        border-radius: 50%;
        padding: 4px 4px;
    }

    .cancelConsolidation:hover,
    .uploadAndConsolidated:hover {
        color: #c9e7fd;
    }

    .consolidateewbContainer .searchLegendsInfoAndAction {
        font-size: 12px;
        background-color: $headerColor;
        padding: 10px 15px;
    }

    .consolidateewbContainer .searchLegendsInfoAndAction .row1 {
        margin-bottom: 15px;
    }

    .consolidateewbContainer .searchLegendsInfoAndAction label {
        padding-right: 5px;
    }

    .consolidateewbContainer .searchLegendsInfoAndAction .toggleSelectionBtn,
    .consolidateewbContainer .searchLegendsInfoAndAction .consolidationBtn {
        min-width: auto;
        text-transform: none;
        font-size: 12px;
        padding-left: 30px;
        padding-right: 30px;
    }

    .consolidateewbContainer .ewbCountText {
        padding: 10px 0;
        border-bottom: 1px solid #d1d1d1;
        margin-bottom: 10px;
    }

    .f13 {
        font-size: 13px;
    }

    .consolidatedEwbModal .consEwbText {
        font-size: 18px;
        text-align: center;
        line-height: 50px;
    }

    .consolidatedEwbModal .consEwbNum {
        font-size: 20px;
        color: #2e75b6;
        text-align: left;
    }

    .consolidatedEwbModal .successIcon i {
        font-size: 100px;
        color: green;
    }

    .consolidatedEwbModal .warningIcon i {
        font-size: 100px;
        color: rgb(230, 116, 16);
    }

    .consEwbModalHeader {
        border-bottom: 0px;
    }

    .consEwbModalFooter {
        border-top: 0px;
    }

    /*EWB Feeds*/

    .consolidateEwbRouteBtn .btn {
        width: 100%;
        text-transform: none;
    }

    .consolidateEwbRouteBtn .fa-chevron-right {
        margin-left: 10px;
    }

    .consolidatedEwbBlock {
        font-size: 11px;
        width: 100%;
    }

    .consolidatedEwbFeedItem {
        padding: 10px 5px;
    }

    /* .consolidatedEwbFeedItem .invoiceFeedItem_details {
padding: 20px 10px 20px 20px;
} */

    .consolidatedEwbNumCol .labelText {
        color: #2e75b6;
        font-size: 12px;
    }

    .consolidatedEwbBlock .valueText {
        font-weight: 600;
        /* color: #3f3f40; */
        color: #2b2b2b;
    }

    .consolidatedEwbDetails .ni_headerDetailContainer {
        margin: 12px 0;
    }

    .consolidatedEwbs {
        padding: 15px 30px;
        font-size: 13px;
    }

    .lh30 {
        line-height: 30px;
    }

    .advSrchLabel {
        padding-left: 10px;
    }

    .clearAdvSrch {
        padding-right: 10px;
        cursor: pointer;
    }

    .consolidatedEwbItem {
        padding: 10px;
        border-top: 4px solid #f3f3f3;
    }

    .consolidatedEwbItem .labelText {
        width: 125px;
        display: inline-block;
    }

    .consolidatedEwbItem .valueText {
        color: #2b2b2b;
        margin-left: 20px;
        font-weight: 600;
    }

    .createInvoice_section {
        margin: 10px 0;
        border-bottom: 1px dotted #76c7fc;
    }

    .promptText {
        text-align: center;
        margin: 10px 0;
        font-size: 13px;
        color: #a01919;
    }

    .actionBtn {
        text-transform: uppercase;
    }

    .lineItemsActionsContainer {
        text-align: center;
        margin-top: 10px;
    }

    .selectAddress.selectShipFrom {
        float: right;
        width: 300px;
        margin-right: 30px;
    }

    .ci_sectionBlockBody.row.invoiceTableBlock {
        max-width: 100%;
        overflow-x: auto;
    }

    .validateInvoiceActions,
    .generateInvoiceActions {
        text-align: right;
        margin: 15px 0;
    }

    .invoiceValidated {
        font-weight: bold;
        font-size: 16px;
    }

    .ci_detail .form-control {
        height: 30px;
        padding: 6px 10px;
        font-size: 13px;
    }

    .btn-light-blue {
        background: #5b9bd5;
        color: $cancelButtonBgColor;
        border: 1px solid #5b9bd5;
    }

    .btn-light-blue:hover,
    .btn-light-blue:active {
        background: #3d96eb;
        color: $cancelButtonBgColor;
        border: 1px solid #3d96eb;
    }

    .clearable__clear {
        position: absolute;
        right: 0px;
        top: 7px;
        /* z-index: -1; */
        /* padding: 0 8px; */
        font-style: normal;
        font-size: 1.5em;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        cursor: pointer;
        /* z-index: 999; */
        color: #f16666 !important;
    }

    .clearable__clear:hover {
        background: $headerColor;
    }

    .searchCustomerPartner .input-group-addon {
        padding: 0px;
        border: none;
    }

    .searchCustomerPartner .form-control {
        -webkit-box-shadow: 0px 0px;
        box-shadow: 0px 0px;
    }

    .searchCustomerPartner {
        /* width: 35%;
-webkit-box-shadow: 0px 1px 7px -2px #3598dc;
box-shadow: 0px 1px 7px -2px #3598dc; */
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }

    .searchCustomerPartner material-input {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }

    .customerOrg {
        padding: 10px;
    }

    /* .customPartnersContainer {
border-bottom: 1px solid #3598DC;
} */

    .rolesName {
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 5px;
    }

    .rolesCheckbox {
        float: left;
        margin-right: 5px;
    }

    .customerPartnersTable {
        width: 90%;
    }

    .customerPartnersTable tbody tr td {
        max-width: 150px;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .customerPartnersTable .switch {
        float: none;
        width: 35px;
        height: 15px;
    }

    .customerPartnersTable .slider:before {
        height: 14px;
        width: 14px;
        left: 1px;
        bottom: 1px;
    }

    .customerPartnersTable input:checked + .slider:before {
        -webkit-transform: translateX(19px);
        -ms-transform: translateX(19px);
        transform: translateX(19px);
    }

    .fa-pencil {
        padding: 0px 10px;
        cursor: pointer;
    }

    .ni_headerStatusLabel img {
        max-width: 24px;
    }

    .ni_headerDetailLabel {
        color: #5f5f5f;
        font-size: 13px;
        font-family: var(--newSiteFont);
        padding: 0 10px;
        height: inherit;
        line-height: inherit;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 45%;

        .entityFieldErrors {
            padding: 2px 4px;
            overflow: hidden;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: block;
        }

        .separator {
            padding-right: 10px;
            margin-top: 2px;
        }
    }

    .ni_headerDetailValue {
        color: $blackColor;
        font-weight: 600;
        font-size: 13px;
        margin-left: -4px;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap;
        /* overflow: hidden; EI-1343 */
        overflow: hidden;
        -webkit-transition: all 1s ease-in;
        -o-transition: all 1s ease-in;
        transition: all 1s ease-in;
        font-family: var(--newSiteFont);
        height: inherit;
        line-height: inherit;
        max-width: 45%;

        span.value {
            position: relative;
            top: 2px;
        }

        &.auditFieldContainer {
            text-overflow: initial;
            white-space: normal;
            overflow: visible;

            .auditValueContainer {
                font-size: inherit;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                width: 100%;
                display: inline-flex;
                max-width: 100%;

                .oldValueContainer,
                .newValueContainer {
                    display: inline-block;
                    width: auto;
                }

                .oldValueContainer {
                    text-decoration: line-through;
                    padding-right: 10px;
                }

                &.recordDeleted {
                    .newValueContainer {
                        background: #f7bfa3;
                        text-decoration: line-through;
                    }

                    .oldValueContainer {
                        display: none;
                    }
                }
            }
        }

        .readOnlyValue {
            .readOnlyAttachmentIcon {
                display: inline-block !important;

                mat-icon {
                    height: 18px;
                    font-size: 18px;
                    vertical-align: middle;
                    width: 18px;
                    cursor: pointer;
                }
            }

            .fieldFileName {
                display: inline-block !important;

                a {
                    color: $submitButtonColor;
                    text-decoration: none;
                }
            }
        }
    }

    td.mat-column-Old-Value,
    td.mat-column-New-Value {
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        -webkit-transition: all 1s ease-in;
        -o-transition: all 1s ease-in;
        transition: all 1s ease-in;
    }

    td.mat-column-Old-Value:hover,
    td.mat-column-New-Value:hover {
        max-width: -webkit-max-content;
        max-width: -moz-max-content;
        max-width: max-content;
        -o-text-overflow: clip;
        text-overflow: clip;
        white-space: normal;
        -webkit-transition: all 1s ease-in;
        -o-transition: all 1s ease-in;
        transition: all 1s ease-in;
    }

    .ni_headerDetailValue:hover {
        white-space: normal;
        word-wrap: break-word;
        -webkit-transition: all 1s ease-in;
        -o-transition: all 1s ease-in;
        transition: all 1s ease-in;

        &.fileValuesContainer {
            white-space: nowrap;
        }
    }

    .ni_headerDetailContainer {
        margin: 5px 0;
    }

    .ni_headerSection:first-child {
        padding-left: 16px;
    }

    .ni_headerStatusContainer {
        text-align: center;
        padding: 5px 0;
    }

    /* .ni_headerStatusContainer:first-child {
border-bottom: 1px dashed #c3b8b8;
} */

    .ni_headerStatusLabel {
        color: #5f5f5f;
        font-size: 13px;
    }

    .ni_headerStatusValue {
        color: $blackColor;
        font-size: 13px;
        font-weight: 600;
        padding-bottom: 3px;
    }

    .ni_globalActionText {
        display: inline-block;
        color: #2e75b6;
        font-size: 13px;
        font-weight: 600;
        margin: 0 10px;
        cursor: pointer;
    }

    .ni_globalActions {
        margin: 5px 0;
    }

    .ni_body {
        padding: 15px 20px;
    }

    .accordionContainer.ni_section:first-child {
        margin-top: 0;
    }

    .accordionContainer.ni_section {
        margin: 15px 0;
    }

    .ni_sectionHeader {
        cursor: pointer;
        font-size: 14px;
        color: $darkGrayColor;
        /* border-bottom: 2px solid #2872a5; */
        /* border-bottom: 2px solid #b7b7b7; */
        /* border-bottom: 1px solid #3598dc; */
        /*V1.0*/
        border-bottom: 1px solid #76c7ff;
        /*V2.0*/
        padding: 5px 2px;
    }

    .ni_sectionHeader.collapsed .fa-angle-down,
    .ni_sectionHeader .fa-angle-up {
        display: inline-block;
    }

    .ni_sectionHeader .fa-angle-down,
    .ni_sectionHeader.collapsed .fa-angle-up {
        display: none;
    }

    .ni_detailsSection {
        margin: 10px 0;
        font-size: 13px;
        overflow-x: auto;
    }

    .ni_detailsSection.borderMandate {
        /* border-bottom: 1px solid #868484; */
        /* border-bottom: 1px dotted #868484; */
        /*V1.0*/
        border-bottom: 1px dotted #76c7fc;
        /*V2.0*/
    }

    .ni_detailsSection.borderMandate .underlineMandate:not(.collapsed) .ni_sectionHeaderText {
        padding-bottom: 10px;
        border-bottom: 1px dotted #76c7fc;
        /*V2.0*/
    }

    .ni_sectionHeaderText .statusMsgNewUI {
        margin-left: 50px;
        color: #888686;
        display: none;
    }

    .headerStatusMsgNewUI {
        margin-left: 50px;
        color: #888686;
        display: inline-block;
    }

    .selectedActionType {
        font-weight: 600;
    }

    .ewbFinalActionBtn {
        padding-right: 0px !important;
    }

    .ni_detailsSection.borderMandate .underlineMandate.collapsed .ni_sectionHeaderText .statusMsgNewUI {
        display: inline-block;
    }

    .ni_sectionBody {
        /* margin-left: 20px; */
        margin-left: 30px;
    }

    .ni_detailHeader {
        padding-bottom: 10px;
        color: $blackColor;
        font-weight: 500;
        cursor: pointer;
    }

    .ni_detailValue {
        margin-left: 10px;
        font-weight: 600;
    }

    .ni_detailHeaderGSTIN {
        display: inline-block;
        margin-left: 20px;
        color: #585859;
    }

    .ni_detailColumnHeader {
        color: $blackColor;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .ni_detailsColumn:last-child {
        border-left: 1px dashed #cccbcb;
    }

    .ni_detailsBody {
        padding-bottom: 15px;
        margin-left: 25px;
    }

    .ni_totalLabel {
        /* color: #1f1fa9; */
        color: $brightBlueColor;
        font-weight: 600;
    }

    .ni_detailLabel {
        color: grey;
        min-width: 100px;
        display: inline-block;
    }

    .ewayDetailBlock .fa-check-circle {
        color: green;
    }

    .ewayDetailBlock {
        font-size: 13px;
        margin: 5px 0;
    }

    .ewayDetailBlock .fa {
        font-size: 14px;
        margin: 0 10px;
    }

    #generateEway {
        padding: 0 0 0 30px;
    }

    .ewayDetailBlock .fa-exclamation-triangle {
        color: orange;
    }

    .ewayDetailBlock .btn-danger {
        border-radius: 0 !important;
        background-color: #d9534f;
        border-color: #d43f3a;
    }

    .errorsFoundText {
        color: red;
        font-weight: 500;
        font-size: 12px;
    }

    .ni_headerSection:last-child {
        /* border-left: 1px dashed #777070; */
        /*V1.0*/
        border-left: 1px solid #d9d0d0;
        /*V2.0*/
    }

    .ni_detailsSection:last-child .ni_detailsBody {
        border-bottom: 0;
    }

    .ci_sectionHeader.collapsed .fa-minus,
    .ci_sectionHeader .fa-plus {
        display: none;
    }

    .ci_sectionHeader .fa-minus,
    .ci_sectionHeader.collapsed .fa-plus {
        display: block;
    }

    .btn-purple {
        background: #484860;
        border: 1px solid #484860;
        color: $cancelButtonBgColor;
    }

    .manageSectionHeader > span {
        margin: 0 5px;
        font-weight: 400;

        .bridgeIcon {
            max-width: 16px;
        }
    }

    .ni_sectionHeaderText {
        display: inline-block;
        margin-left: 15px;
        line-height: 25px;
    }

    .collapsed .fa-angle-down,
    .fa-angle-up {
        display: inline-block;
    }

    .ni_detailsBody .ni_detailsSection {
        /* border-bottom: 1px solid #ccbebe; */
        /*V1.0*/
        border-bottom: 1px solid #d3edff;
        /*V2.0*/
    }

    .collapseSubSection > .ni_detailsBody {
        padding-bottom: 0;
    }

    #invoiceLineItemsSection .ni_sectionBody .ni_detailsSection {
        padding-bottom: 15px;
    }

    #invoiceTotalsSection .ni_sectionBody .ni_detailsSection {
        padding-bottom: 15px;
        border-bottom: 0;
    }

    #assignTransporter .ni_detailsSection {
        border: 0;
        margin: 0;
        overflow-x: hidden;
    }

    #transportationDetails .ni_detailsSection {
        border: 0;
        overflow-x: hidden;
    }

    #transporterAcceptance .ni_detailsSection {
        padding: 10px 0 0;
        margin: 0;
        border-bottom: 1px solid #868484;
    }

    #transporterAcceptance .ni_detailsSection:last-child {
        padding: 10px 0 0;
        margin: 0;
        border-bottom: 0;
    }

    #consigneeAcceptance .ni_detailsSection {
        border: 0;
        margin-top: 0;
    }

    .ni_detailsSectionHeader {
        font-size: 13px;
        text-decoration: underline;
    }

    #closeEway .ni_detailsSection {
        border-bottom: 0;
    }

    #closeEway .invoiceDetail_actions {
        padding: 0;
    }

    #updateEway .ni_detailsSection {
        border-bottom: 0;
        margin: 0;
    }

    .ni_body .btn-danger {
        border-radius: 0 !important;
        color: $cancelButtonBgColor;
        background-color: #d9534f;
        border-color: #d43f3a;
    }

    #cancelEway .ni_detailsSection {
        border-bottom: 0;
        margin: 0;
    }

    .ni_headerActionText {
        line-height: 25px;
        font-size: 13px;
        cursor: pointer;
        margin-left: 10px;
        color: blue;
        padding: 0 10px;
    }

    .ni_headerActionText .fa {
        color: $blackColor;
    }

    .invDetailHeader {
        padding: 10px 15px;
        font-size: 14px;
        font-weight: 600;
        border-bottom: 1px solid #d9d0d0;
    }

    .ni_detailBlock {
        padding-top: 3px;
        padding-bottom: 3px;
    }

    .ni_remarks {
        /* width: 250px; */
        width: 100%;
        margin-left: -10px;
    }

    /*new UI changes - 27-Mar-2018*/

    .invoiceNewSearchContainer,
    .invoiceFilter {
        /* border-bottom: 1px solid #dad0d1; */
        background: #eee;
    }

    .advSearchMode,
    .invoiceFilter {
        overflow: hidden;
    }

    .advSearchDialogEllipsis,
    .refreshFeeds {
        border-left: 1px solid #dad0d2 !important;
    }

    .refreshFeeds i {
        width: 11px;
    }

    .advancedSearch {
        background: $cancelButtonBgColor;
    }

    .advancedSearch label {
        font-size: 12px;
        font-weight: 500;
        margin-left: 5px;
    }

    .advancedSearch .advSearchInput {
        padding-left: 10px;
        height: 30px;
    }

    .advanced-search-modal {
        width: 80%;
    }

    .statusMsg {
        padding-left: 15px;
    }

    .statusError {
        color: #d00e0e;
    }

    .statusSuccess {
        color: #3e8106;
    }

    .footerImg {
        padding: 0px 5px 8px 0px;
        height: 25px;
    }

    .accordionContent {
        padding-top: 30px;
    }

    .ewbActionInput {
        margin-right: 10px !important;
    }

    .btn-purple:hover,
    .btn-purple:active {
        color: $cancelButtonBgColor !important;
    }

    .feedMainName {
        color: $significantRegFontColor;
        margin-bottom: 5px;
        font-size: 12px;

        &:last-child {
            margin-bottom: 0;
        }

        .feedMainName {
            margin-bottom: 0;
        }
    }

    .feedItemCounterBlock {
        width: 47%;
        float: left;
        font-size: 12px;
        color: #424a5c;
        margin-right: 5px;
    }

    .feedItemTransporterBlock {
        float: left;
        width: 51%;
        font-size: 12px;
        color: #8d85a7;
    }

    .feedSubName {
        color: $blackColor;
        font-size: 13px;
        margin-bottom: 5px;
    }

    .feedValue {
        color: $blackColor;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow-x: hidden;
        display: inline-block;

        a {
            color: #00a651;
            text-decoration: none;
        }
    }

    .feedKey {
        color: #8d85a7;
    }

    .ci_sectionHeader {
        padding: 0 0 10px;
        color: $blackColor;
        font-weight: 500;
        cursor: pointer;
    }

    .statusError.warn,
    .warn {
        color: orange;
    }

    .pointerClass {
        cursor: pointer;
    }

    .noRecordClass:hover {
        text-decoration: underline;
        color: black;
    }

    .cursorNotAllowed {
        cursor: not-allowed;
        pointer-events: none;
    }

    .ni_sectionHeader.disabled,
    .chartExportActionContainer.disabled,
    .chartExportActionContainer.btn-link.disabled {
        pointer-events: none;
        cursor: not-allowed;
    }

    .acceptClass {
        color: #20c747;
    }

    .rejectClass {
        color: red;
    }

    .pointClass {
        cursor: pointer;
    }

    .settingsfilterItemContainer {
        /* border-bottom: 1px solid #555f72; */
        padding: 5px 15px 5px 3px;
        cursor: pointer;
        font-size: 14px;
    }

    .stateFilterItem:not(.activeSettings) .settingsfilterItemContainer {
        border-bottom: 1px solid #69b6ea;
    }

    .connectionDiv .table tbody tr td {
        vertical-align: baseline;
    }

    .connectionImg {
        width: 40%;
    }

    input[type='checkbox'] {
        margin: 0;
        vertical-align: middle;
    }

    .OrganizationContainer {
        /* padding: 20px; */
        margin: 0px 60px;
    }

    .RightArrow {
        display: block;
    }

    .DownArrow {
        display: none;
    }

    .fontCls {
        font-weight: 500;
        font-size: 20px;
    }

    .inputCont {
        padding-left: 10px;
    }

    .RightLoArrow {
        display: block;
    }

    input[type='radio'].form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
        height: auto;
        margin: 0;
    }

    .sharedWithText {
        margin: 20px 0;
    }

    .disabledDiv {
        opacity: 0.4;
        pointer-events: none;
    }

    .spanCls {
        cursor: pointer;
    }

    .activeOrganization {
        background-color: #ddedf5;
    }

    .custom-day {
        text-align: center;
        padding: 0.185rem 0.25rem;
        border-radius: 0.25rem;
        display: inline-block;
        width: 3rem;
    }

    .custom-day:hover,
    .custom-day.focused {
        background-color: #e6e6e6;
    }

    .hidden {
        display: none;
    }

    .toDay {
        background-color: #9abfd8;
        color: $cancelButtonBgColor;
    }

    .toDay:hover {
        color: black;
    }

    .text-muted {
        color: #b7b7b7;
    }

    .text-right {
        text-align: right;
    }

    .setBtn {
        border: 1px solid transparent;
        padding: 0.375rem 0.75rem;
        color: #fff;
        background-color: #00a651;
        border-color: #00a651;
    }

    span.dashboardHeaderLine {
        width: auto;
        line-height: 1px;
        font-size: 21px;
        display: block;
        /* border-bottom: 2px solid #8bc1e8; */
        border-bottom: 1px solid #ebebeb;
        z-index: -1;
        padding: 7px 0;
    }

    .dashboardCollapseHeader {
        float: left;
        background: $cancelButtonBgColor;
        padding: 3px 10px;
        z-index: 1;
    }

    .dashboardChartBodyContainer {
        background: $cancelButtonBgColor;

        .dashboardChartHeaderContainer {
            margin: 10px 0 0;
            padding: 10px 7px 0px 10px;
        }

        .dashboardHeaderTextContainer {
            color: $significantRegFontColor;
            font-weight: 600;
            font-size: 14px;
            margin-right: 10px;
        }

        .dashboardDiv {
            border-top: 1px solid #e9f1f6;
            padding: 10px;
            position: relative;
        }

        .noChartDataFound {
            border-top: 1px solid #e9f1f6;
            min-height: 30px;
            line-height: 30px;
            text-align: center;
            background: $cancelButtonBgColor;
            padding: 10px;
        }
    }

    .fontExportIcon {
        font-size: 19px;
        margin: 2px 4px;
    }

    .chartExportActionContainer {
        float: right;
    }

    .ngx-charts-outer {
        margin: 0 auto;
    }

    svg.ngx-charts {
        display: block;
        /* float: none; */
    }

    .displaySelectedRange span {
        border-bottom: 1px solid #b5b2b2;
    }

    .timePeriodContainer {
        margin: 10px;
        min-height: 34px;
    }

    .dashboardFeedSection {
        margin: 20px 0;
        padding: 0 15px;
    }

    #dashboardFeedContainer {
        background: #d8eefd;
        padding-top: 1px;
    }

    .dashboardfeed_imageContainer {
        float: left;
        width: 100px;
        height: 100px;
        white-space: nowrap;
        position: relative;
    }

    .dashboardfeed_text {
        float: left;
        width: calc(100% - 110px);
        margin-left: 10px;
        cursor: pointer !important;
    }

    .dashboardfeed_headerText {
        color: #637593;
        font-size: 13px;
        font-weight: 600;
    }

    .dashboardfeed_bodyText {
        color: #817cb5;
        font-size: 13px;
    }

    #dashboardMainContainer {
        padding: 1px 15px;
        margin-bottom: 10px;
    }

    .notifiedParties {
        padding: 5px 0;
        margin-bottom: 5px;
        border-bottom: 1px solid #d2c9c9;
    }

    .widthCls {
        width: auto;
    }

    .noRecordsFound {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
        color: #888282;
    }

    .modal-footer {
        border-top: 1px solid #c8c8c8;
        padding: 5px;
        padding: 0.75rem;
    }

    .processContainer {
        padding: 20px 20px;
    }

    .processTable .table tbody tr td,
    .processTable .table thead tr th {
        white-space: nowrap;
        overflow-x: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        max-width: 140px;
    }

    .requestsProcessDialog {
        width: 90% !important;
        max-width: 90vw !important;
    }

    .processNoRecord {
        text-align: center;
        margin: 20px 0px;
    }

    .processTable .table thead {
        background: #177396;
        color: $cancelButtonBgColor;
        font-weight: 600;
    }

    .rangeDatePickerContainer {
        border-bottom: 0 !important;
        margin-right: 10px;
    }

    .rangeDatePickerContainer span.startDate {
        margin-right: 10px;
    }

    .rangeDatePickerContainer span.display-inlineBlock {
        max-width: 200px;
        border-bottom: 0 !important;
        max-height: 50px;
    }

    .ngb-dp-week {
        background: $cancelButtonBgColor;
    }

    .chartExportActionContainer.btn-link {
        cursor: pointer;
        color: $grayColor;
    }

    .rangechartExportActionContainer {
        margin-top: 13px;
    }

    .timePeriodSelectionToggle {
        margin: 0 10px;
    }

    div#feedActionsDropdown {
        width: 160px;
        left: -130px;
        padding: 5px;
    }

    #bulkUploadSummary .modal-dialog {
        width: 80%;
    }

    .summaryReportContainer {
        padding: 10px;
        margin: 10px 0;
        background: #ebe9e9;
        border: 1px solid #d4d1d1;
    }

    .reportHeaderContainer {
        padding: 10px 0;
        margin: 0 10px;
        border-bottom: 1px solid #b9b6b6;
    }

    .reportHeaderDetailsContainer {
        display: inline-block;
        text-align: center;
    }

    .reportHeaderInvoiceNumberContainer {
        display: inline-block;
        min-width: 150px;
    }

    .reportHeaderInvoiceDateContainer {
        display: inline-block;
        min-width: 150px;
    }

    .reportBodyContainer {
        margin-top: 10px;
        padding: 0 10px 0 30px;
    }

    .reportErrorsHeaderText.reportStatusMessage {
        color: red;
    }

    .reportStatusMessage {
        font-weight: 600;
        margin-bottom: 10px;
        font-size: 13px;
    }

    .reportSuccessHeaderText.reportStatusMessage {
        color: green;
    }

    .reportDetailsBody {
        line-height: 34px;
    }

    .summaryReportsContainer {
        padding-right: 15px;
    }

    .commingSoon {
        position: fixed;
        top: 57px;
        height: 100%;
        width: 100%;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 9999;
        background: rgba(0, 0, 0, 0.5);
    }

    .commingSoonText {
        position: fixed;
        left: 50%;
        top: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        font-size: 18px;
        font-weight: 600;
        color: $cancelButtonBgColor;
    }

    .feedActions.invoiceDetailHeaderActionItem .fa.fa-bars {
        padding: 11px;
    }

    .mainSearch .invoiceDetailHeaderActionItem {
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        overflow-x: hidden;
        -webkit-box-flex: 1;
        -ms-flex: auto;
        flex: auto;
    }

    .feedActionsDropdown {
        overflow: visible;
        -webkit-box-flex: 1;
        -ms-flex: auto;
        flex: auto;
    }

    .dashboardReporsDialog {
        width: 80%;
    }

    .reportsTableContainer .btn-link {
        padding: 0;
        text-transform: inherit;
        font-size: 13px;
    }

    .dashboardNewReportActions {
        font-size: 14px;
        width: 100%;
    }

    .reportsTableContainer th {
        padding: 8px;
        background: #e2e2e2;
    }

    .expiryDateheader {
        font-size: 12px;
        color: #8d85a7;
    }

    .expiryDateFeedValue {
        font-size: 12px;
        padding-left: 15px;
        color: $blackColor;
    }

    #deleteInvoiceModal .modal-dialog {
        width: 30%;
    }

    .deleteText {
        text-align: center;
        font-size: 16px;
        padding: 10px;
    }

    .deleteEwayBillButton {
        margin: 5px;
        border-radius: 0;
    }

    #ConsolidatedEwayBillEditModal .modal-dialog {
        width: 80%;
    }

    .consolidatedDetailLabel {
        line-height: 34px;
    }

    .expiredCls {
        color: #df5f5f;
    }

    .cancellationDiv {
        text-align: center;
    }

    .extendedDate {
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .ngb-dp-day.disabled {
        cursor: not-allowed !important;
    }

    ngb-datepicker.ngb-dp-day.disabled .custom-day.range,
    ngb-datepicker .ngb-dp-day.disabled .custom-day:hover {
        background: none !important;
        color: #b1b0b0 !important;
    }

    .expiryDiv {
        float: left;
    }

    .noTransformationDiv {
        text-align: center;
        margin: 30% auto;
    }

    .noTransformationDiv span {
        font-size: 16px;
        font-weight: 500;
    }

    .assignMasterDataBtn {
        background-color: #288dc5;
        border-color: #288dc5;
        color: $cancelButtonBgColor;
    }

    .assignMasterDataBtn:hover {
        color: $cancelButtonBgColor;
    }

    .selectTransCls {
        padding: 0;
        width: auto;
        max-width: 100%;
    }

    .customersSelectStateSection {
        padding: 10px;
    }

    .digitalSignDiv {
        margin-top: 15px;
        padding: 15px 0;
        background: #f6f6f6;
        border-radius: 4px;
    }

    .digitalSignDiv .dscLabelRow {
        margin-left: 15px;
    }

    .digitalSignDiv .dscLabelRow .labelDiv {
        -ms-flex-item-align: center;
        align-self: center;
    }

    .digitalSignDiv .dscLabelRow .passphraseDiv {
        -ms-flex-item-align: center;
        align-self: center;
    }

    .digitalSignDiv .dscLabelRow .userDscFileName {
        color: $taxillaDarkGreen;
        text-decoration: underline;
        cursor: pointer;
        padding: 0 15px;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        max-width: 140px;
        display: inline-block;
        white-space: nowrap;
        margin-bottom: -5px;
    }

    .digitalSignDiv .dscChangeRow {
        margin-left: 0;
        margin-right: 0;
    }

    .digitalSignDiv .dscChangeRow .chngBtn {
        text-align: right;
    }

    .digitalSignDiv .uploadFileName {
        width: 50%;
    }

    .digitalSignDiv .dscUploadBtnRow {
        margin-top: 10px;
    }

    .digitalSignDiv .dscUploadBtnRow .dscBrowseBtn {
        -ms-flex-item-align: center;
        align-self: center;
    }

    .digitalSignDiv .dscUploadBtnRow .btn {
        margin-top: -10px;
        cursor: pointer;
        padding: 5px 12px;
        background: transparent;
        border-color: none;
    }

    .digitalSignDiv .dscUploadBtnRow .mat-form-field-appearance-legacy .mat-form-field-infix {
        padding-top: 0;
        margin-top: -5px;
    }

    .digitalSignDiv .dscUploadBtn {
        -ms-flex-item-align: center;
        // align-self: center;
    }

    .digitalSignDiv .dscCancelBtn {
        -ms-flex-item-align: center;
        // align-self: center;
        text-align: right;
    }

    .digitalSignDiv .dscFileTypeSupport {
        color: #da534e;
        padding: 5px 15px;
        overflow: hidden;
    }

    .customerOptions {
        padding: 0px 20px;
    }

    .ni_globalActionText span.fa {
        display: inline;
    }

    .dropdown-menu .ni_globalActionText {
        margin: 5px 10px;
    }

    .processTable span.btn.btn-link {
        text-transform: inherit;
        padding: 0;
    }

    .exchangeWithEmailDiv {
        /* width: 300px;
margin: 0 auto; */
        padding: 0px 20px;
    }

    .spacer {
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    }

    .app-responsive .ForgotpasswordPage {
        background: url(/assets/images/backgrond.jpg) 28% 0% no-repeat;
        border-top: 2px solid #288aca;
        background-size: cover;
    }

    .device-handset .nav.navbar-nav.navbar-right {
        float: right !important;
    }

    .device-handset .nav.navbar-nav {
        float: left;
        margin: 0;
    }

    .device-handset .nav > li {
        float: left;
    }

    .device-handset .navbar-nav .open .dropdown-menu {
        position: relative;
        float: none;
        width: auto;
        margin-top: 0;
        left: auto;
        border: 1px solid rgba(0, 0, 0, 0.15);
        background: $cancelButtonBgColor;
        -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    }

    #reportDownload .selectExtensionDiv {
        margin: 0px 10px;
    }

    #preLoginContainer,
    #profileHeaderContainer,
    #mainRouterContainer.device-handset.app-responsive,
    .app-responsive .ForgotpasswordPage {
        height: 100%;
    }

    .manageSectionHeader:hover {
        background: #f6f6f6;
    }

    .manageSectionHeader.selectedAsset {
        /* background: #FFF;
color: #3698dc; */
        color: $significantRegFontColor;
        /* background-color: #fff; */
        background: #f6f6f6;
        border-left: 5px solid #4fa753;
        padding-left: 16px;
    }

    .form-control:focus {
        border-color: rgba(40, 167, 69, 0.3215686274509804);
        outline: 0;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #28a74552;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #28a74552;
    }

    .manageSectionHeader.selectedAsset span.fa {
        color: #4fa753;
    }

    #advancedSearchModal .modal-dialog {
        width: 80%;
    }

    .mat-progress-spinner circle,
    .mat-spinner circle {
        stroke: $taxillaDarkGreen;
    }

    .mat-progress-bar-fill::after {
        background-color: $taxillaDarkGreen !important;
    }

    .mat-form-field-infix input {
        border: 0;
        width: 100%;
        position: relative;
    }

    .mat-form-field-appearance-legacy .mat-form-field-label {
        color: #817f7f;
    }

    .mat-focused .mat-form-field-label {
        color: $taxillaDarkGreen !important;
        padding-left: 3px;
    }

    .mat-form-field.mat-focused .mat-form-field-ripple {
        background-color: $taxillaDarkGreen;
    }

    mat-form-field {
        width: 100%;
    }

    // .mat-form-field-infix {
    //     padding: .4375em 0;
    //     border-top: 1.5em solid transparent;
    // }
    // if issue raised from qa related this, please have a container class and target this class

    #reportDownload .modal-body,
    #errorReport .modal-body {
        padding: 40px;
    }

    #reportDownload ngb-datepicker .ngb-dp-header,
    #reportDownload ngb-datepicker .ngb-dp-months,
    #errorReport ngb-datepicker .ngb-dp-header,
    #errorReport ngb-datepicker .ngb-dp-months {
        border: 1px solid #c8c8c8;
    }

    #reportDownload ngb-datepicker.dropdown-menu,
    #errorReport ngb-datepicker.dropdown-menu {
        border-top: none;
    }

    #reportDownload ngb-datepicker .ngb-dp-header,
    #errorReport ngb-datepicker .ngb-dp-header {
        border-bottom: none;
    }

    #reportDownload .dateText {
        text-align: left;
    }

    #reportDownload .downloadReportSubmit {
        padding-top: 20px;
    }

    #errorReport .downloadReportSubmit {
        padding-top: 40px;
    }

    #errorReport .section,
    #reportDownload .section {
        margin-bottom: 10px;
    }

    #reportDownload ngb-datepicker.dropdown-menu {
        top: 30px !important;
        left: 0px !important;
    }

    #reportDownload .firstDateSection {
        padding-right: 40px;
    }

    #reportDownload .secondDateSection {
        padding-left: 40px;
    }

    .entityContainer {
        position: relative;
        z-index: 1;
    }

    .entityPartB {
        width: 98%;
        display: table;
        margin: 10px auto 20px;
    }

    .entityHeader {
        position: relative;
        background: #f6f6f6;
        font-size: 14px;
    }

    .primaryDataShowMore {
        position: absolute;
        right: 0.5em;
        bottom: 1em;
    }

    .entityField {
        height: 18px;
    }

    /* .entityEditField {} */

    #pDSMoreIconDown,
    #pDSMoreIconUp {
        cursor: pointer;
        margin: 5px 0px;
        font-size: 10px;
        color: #00a651;
        width: 92px;

        button {
            font-size: 12px;
            padding: 2px 5px;
            line-height: 20px;
        }
    }

    .nonBusinessKeys {
        position: absolute;
        width: 100%;
        max-height: 350px;
        overflow: auto;
        z-index: 999;
        background-color: $cancelButtonBgColor;
        background-clip: padding-box;
        border-radius: 4px;
        -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    }

    /* clearfix for latest browsers */

    .mat-expansion-panel-content .mat-expansion-panel-body {
        display: flow-root;
        padding: 0 0 0 0;
    }

    /* clearfix fallback for older browsers */

    .mat-expansion-panel-body::after {
        content: '';
        clear: both;
        display: table;
    }

    #newRequestComponent {
        position: absolute;
        height: 100%;
        background: $cancelButtonBgColor;
        z-index: 1000;
        width: calc(100% - 19px);
        margin: 0 10px;
        // height: calc(100% - 19px);
    }

    .formHeader {
        margin: 10px 0;
        font-size: 20px;
    }

    .newRequestFormBody.formFieldsContainer {
        margin: 10px 0;
    }

    .formFieldContainer {
        margin: 5px 0 20px;
        font-size: 14px;
    }

    .mat-raised-button {
        text-transform: capitalize;
    }

    #editRequestContainer {
        height: 100%;
        width: 100%;
    }

    .flex-space-between .material-icons,
    .justify-content-around .material-icons {
        font-size: 1.5em;
        color: orange;
        cursor: pointer;
    }

    .justify-content-around .messagePopup_error .material-icons {
        color: #dd2c00;
    }

    .defaultEditTemplateContainer {
        padding: 2% 5% 3% 3%;
        height: 100%;
        background: $cancelButtonBgColor;
    }

    .mat-expansion-panel {
        margin: 1em 1.3em !important;
    }

    .mat-select-disabled .mat-select-value {
        background-color: rgb(235, 235, 228);
        color: rgba(141, 130, 130, 0.87);
    }

    input:disabled,
    textarea:disabled {
        color: rgba(141, 130, 130, 0.87);
    }

    .detailsEntityHeader {
        font-size: 14px;
        /* font-weight: bold; */
        font-family: var(--newSiteFont);
    }

    mat-expansion-panel-header {
        max-height: 48px;
    }

    .mat-form-field-infix input::-ms-input-placeholder {
        color: transparent;
    }

    .mat-form-field-infix input::-webkit-input-placeholder {
        color: transparent;
    }

    .mat-form-field-infix input:-ms-input-placeholder {
        color: transparent;
    }

    .mat-form-field-infix input::-moz-placeholder {
        color: transparent;
    }

    .mat-form-field-infix input::placeholder {
        color: transparent;
    }

    .mat-accordion > .mat-expansion-panel-spacing:first-child,
    .mat-accordion > :first-child:not(.mat-expansion-panel) .mat-expansion-panel-spacing {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .mat-select {
        padding: 1px 0;
    }

    .multiViewContainers {
        position: relative;
        padding: 0;
        height: 100%;
    }

    .multiViewContainer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    #defaultEditTemplate mat-card.mat-card {
        padding: 0;
        -webkit-box-shadow: 0 -3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.79), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
        box-shadow: 0 -3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.79), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    }

    .viewActions {
        top: 5px;
        width: 24px;
        margin: 0 5px;
        position: absolute;
        text-align: left;
        z-index: 99;
        display: inline-block;
    }

    .viewActions.viewActionsToRight {
        right: 0;
    }

    .viewAction:hover {
        color: #3d3c3c;
    }

    .viewAction {
        cursor: pointer;
        color: #969595;
        min-width: 24px;
        height: 24px;
    }

    .defaultEditEntityContainer mat-card-title.mat-card-title {
        margin: 10px;
        text-decoration: underline;
        position: relative;
        font-family: var(--newSiteFont);
        width: 100%;
    }

    mat-expansion-panel-header.mat-expanded {
        border-bottom: 1px solid #dad0d1;
    }

    .defaultEditAddNewRecord {
        margin: 10px 0 40px;
        text-align: right;
    }

    .entityName {
        padding: 0.5em 3em;
        font-size: 1.8em;
    }

    span#entityHierarchyClose {
        position: absolute;
        left: 0.39em;
        top: 1em;
        color: $brightBlueColor;
        font-size: 1.5em;
    }

    asset-entitytable .mat-cell {
        cursor: pointer;
    }

    .detailColumn.invoiceColumn.hierarchyDetailColumn {
        width: 95%;
        position: absolute;
        z-index: 100;
        right: 0px;
        top: 0;
        margin: auto;
        -webkit-box-shadow: 0 0px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.79), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
        box-shadow: 0 0px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.79), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
        -webkit-box-flex: 0;
        -ms-flex: 0 0 95%;
        flex: 0 0 95%;
        max-width: 95%;
    }

    .defaultDetailEntityContainer {
        /* background: #f8fcff; */
        background: $cancelButtonBgColor;
        /* box-shadow: rgba(0, 0, 0, 0.176) 2px 2px 12px 1px; */
        height: 100%;
    }

    .recordViewPerfectScroll .ps-content {
        height: 100%;
    }

    .recordView {
        /*
overflow: auto;
overflow: overlay; */
        /* commented in favor of perfect scroll */
        height: auto;
        min-height: 100%;
        margin: 0 0 0 2px;
        padding: 0 2em;
        -webkit-box-shadow: 0 0px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.79), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
        box-shadow: 0 0px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.79), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
        padding-bottom: 3rem;
    }

    .entityHeirarchyClose,
    .entityHeirarchyShow {
        position: absolute;
        top: 1.5em;
        right: 1em;
        z-index: 2;
        cursor: pointer;
    }

    .multiHeirarchys {
        position: relative;
        height: calc(100vh - 45px);
    }

    .multiHeirarchy {
        position: absolute;
        top: 0;
        right: 0;
    }

    .w-50 {
        width: 50%;
        float: left;
    }

    .d-50 {
        width: 50%;
    }

    .w-50::before {
        content: '';
        display: table;
    }

    .w-50::after {
        content: '';
        display: table;
    }

    material-button {
        margin: 0 10px;
    }

    .recordGroupContainer {
        margin: 10px 0;
    }

    .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
        font-weight: 700;
        padding-left: 3px;
    }

    .mat-expansion-panel-body .defaultEditAddNewRecord {
        margin: 20px 0 0;
    }

    .mat-expansion-panel-body .defaultEditAddNewRecord material-button:last-child {
        margin-right: 0;
    }

    .matTableError,
    .matTableError.matTableWarning {
        background: #e5430454;
    }

    .mat-table tr.example-element-row.matTableError:not(.example-expanded-row):hover,
    .mat-table .matTableError.matTableWarning:hover {
        background: #e54304;
        color: $cancelButtonBgColor;
    }

    .matTableError:hover .mat-cell,
    .matTableError.matTableWarning:hover .mat-cell,
    .matTableError:hover .mat-footer-cell,
    .matTableError.matTableWarning:hover .mat-footer-cell {
        color: $cancelButtonBgColor;
    }

    .matTableWarning {
        background: #ffeac4;
    }

    .messagePopup_error {
        position: relative;
        color: #dd2c00;
        cursor: pointer;
        display: inline-block;
        height: 24px;
        vertical-align: middle;
    }

    .tooltip {
        pointer-events: none;
        position: absolute;
        top: -0.6em;
        display: none;
        visibility: hidden;
        opacity: 0;
        right: 36px;
        min-width: 500px;
        max-width: 550px;
        padding: 15px 20px;
        color: #2e2d2d;
        border-radius: 5px;
        white-space: normal;
        word-break: break-all;
        background: $cancelButtonBgColor;
        border: 2px solid $cancelButtonBgColor;
    }

    .tooltip:after {
        content: '';
        position: absolute;
        right: -28px;
        top: 3px;
        width: 2em;
        height: 1em;
        border-top: 10px solid transparent;
        border-left: 10px solid #f44336;
        border-bottom: 10px solid transparent;
        border-right: 12px solid transparent;
        pointer-events: none;
    }

    .tooltipOnRight:after {
        left: -26px;
        border-right: 10px solid #f44336;
        border-left: 12px solid transparent;
    }

    .revealTooltip {
        display: block;
        visibility: visible;
        opacity: 1;
    }

    mat-icon.editInfoIcon:hover + .tooltip {
        display: block;
        visibility: visible;
        opacity: 1;
        white-space: break-spaces;
        word-break: break-all;
    }

    .tooltip.tooltip_active_up {
        opacity: 1;
        margin-bottom: 5px;
        -webkit-transition: all 0.2s ease;
        -o-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }

    .tooltip.tooltip_active_down {
        opacity: 1;
        margin-top: 5px;
        -webkit-transition: all 0.2s ease;
        -o-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }

    .tooltip.out {
        opacity: 0;
        margin-top: -20px;
    }

    .messagePopup_error .tooltip {
        -webkit-box-shadow: 0px 3px 1px -2px #e57373, -2px 1px 2px 0 #ef9a9a, 0 1px 5px 0 #ef9a9a;
        box-shadow: 0px 3px 1px -2px #e57373, -2px 1px 2px 0 #ef9a9a, 0 1px 5px 0 #ef9a9a;
        -webkit-transition: visibility 0s linear 0.3s, opacity 0.3s linear;
        -o-transition: visibility 0s linear 0.3s, opacity 0.3s linear;
        transition: visibility 0s linear 0.3s, opacity 0.3s linear;
    }

    .messagePopup_warning {
        position: relative;
        color: #ffffff;
        cursor: pointer;
        display: inline-block;
        height: 24px;
        vertical-align: middle;
    }

    .messagePopup_warning :hover {
        color: #ffc107;
    }

    .messagePopup_warning .tooltip::after {
        border-left: 10px solid #ffc20d;
    }

    .messagePopup_warning .tooltip {
        -webkit-box-shadow: 0px 3px 1px -2px #ffc107, -2px 1px 2px 0 #ffc107, 0 1px 5px 0 #ffc107;
        box-shadow: 0px 3px 1px -2px #ffc107, -2px 1px 2px 0 #ffc107, 0 1px 5px 0 #ffc107;
        -webkit-transition: visibility 0s linear 0.3s, opacity 0.3s linear;
        -o-transition: visibility 0s linear 0.3s, opacity 0.3s linear;
        transition: visibility 0s linear 0.3s, opacity 0.3s linear;
    }

    .checkboxCls {
        padding: 6px 0px;
    }

    .materialAccordionHeaderDescription {
        width: 100%;
    }

    .messagePopup {
        line-height: 11px;
        height: 30px;
        min-width: 30px;
        position: relative;
    }

    .mat-row {
        cursor: pointer;

        &.isDeleted {
            background: #f7bfa3;
            text-decoration: line-through;

            .oldAuditValue {
                display: none;
            }
        }

        td.matTableCell.mat-cell {
            .oldAuditValue {
                text-decoration: line-through;
                margin-right: 5px;
            }
        }
    }

    .disableLastPageButton button.mat-paginator-navigation-last {
        pointer-events: none;
        color: #c4c4c3;
    }

    /* this class is removed as we don't need it when we have full table records count EI-1282 */

    .mat-paginator {
        -webkit-box-shadow: 0 5px 15px -2px rgba(10, 16, 34, 0.16);
        box-shadow: 0 5px 15px -2px rgba(10, 16, 34, 0.16);
    }

    .textCenterDiv {
        text-align: center;
    }

    mat-hint,
    mat-error {
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .currentView {
        z-index: 999 !important;
    }

    .hierarchyHead {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        background: grey;
        color: white;
        padding: 10px;
    }

    .entityHeirarchyTitle {
        font-size: 1em;
        font-weight: bold;
        font-family: var(--newSiteFont);
    }

    .hierarchyHead span.material-icons {
        cursor: pointer;
        color: white;
    }

    .hierarchyHead span.material-icons:hover {
        color: $brightBlueColor;
    }

    .hierarchyHead i:hover + .matInfoText {
        display: block;
        border: 1px solid #dcdcdc;
        opacity: 1;
    }

    .overlay {
        position: absolute;
        width: inherit;
        height: 100%;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.228);
        -webkit-transition: all 0.5s cubic-bezier(0.55, 0.09, 0.68, 0.53);
        -o-transition: all 0.5s cubic-bezier(0.55, 0.09, 0.68, 0.53);
        transition: all 0.5s cubic-bezier(0.55, 0.09, 0.68, 0.53);
    }

    .entityEdit span.material-icons,
    .entityDelete span.material-icons,
    .deleteRecord span.material-icons,
    .goToRecordView span.material-icons,
    .refreshComponent span.material-icons,
    .auditTrails span.material-icons,
    .changeLogs span.material-icons,
    .downloadUploadedFile span.material-icons,
    .auditModeCancelCls span.material-icons,
    .shareCollaborators span.material-icons,
    .eventNotifications span.material-icons {
        font-size: 1.5em;
        cursor: pointer;
        border-radius: 4px;
        padding: 4px;
        margin: 0;
        /* margin-right: 1.5em; */
    }

    .entityEdit span.material-icons:hover,
    .goToRecordView span.material-icons:hover,
    .refreshComponent span.material-icons:hover,
    .deleteRecord span.material-icons:hover,
    .entityDelete span.material-icons:hover,
    .auditTrails span.material-icons:hover,
    .changeLogs span.material-icons:hover,
    .downloadUploadedFile span.material-icons:hover,
    .shareCollaborators span.material-icons:hover,
    .eventNotifications span.material-icons:hover {
        color: $taxillaDarkGreen !important;
        background: $matTableRowBorderGrey;
        border-radius: 50%;
    }

    .goToRecordView span.material-icons,
    .deleteRecord span.material-icons {
        cursor: pointer;
        color: #1976d2;
    }

    .goToRecordView,
    .deleteRecord,
    .entityMessages,
    .refreshComponent,
    .downloadUploadedFile,
    .entityEdit,
    .entityDelete,
    .auditTrails,
    .changeLogs,
    .auditModeCancelCls,
    .shareCollaborators,
    .eventNotifications {
        margin: 0 2px !important;
        display: inline-block;
        /* height: 26px; */
        vertical-align: middle;
    }

    .mat-elevation-z8 {
        -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
    }

    .workflowAction button.mat-raised-button {
        color: $cancelButtonBgColor;
        text-transform: uppercase;
        font-weight: 600;
        min-height: 50px;
        width: 100%;
    }

    span.entityError {
        color: red;
        margin-left: 1em;
        font-size: 1.4em;
        cursor: pointer;
        -webkit-font-smoothing: subpixel-antialiased;
    }

    .defaultEditEntityContainer mat-expansion-panel .mat-content {
        overflow: inherit;
        overflow-x: inherit;
        overflow-y: inherit;
    }

    tr.matTableWarning.example-element-row[_ngcontent-c27]:not(.example-expanded-row):hover {
        background: #f5bc54;
    }

    .pointerClass span.material-icons {
        margin-right: 0.5em;
    }

    .editViewsContainer {
        position: relative;
        height: 100%;
        width: 100%;
    }

    td.mat-cell {
        padding: 0 0.5em;
        font-family: var(--newSiteFont);
    }

    .instanceAction {
        display: inline-block;
        cursor: pointer;
    }

    .dropdown-menu.instanceReportsContainer {
        min-width: 400px;
        padding: 0;
    }

    .dropdown-menu.instanceReportsContainer:before {
        content: '';
        position: absolute;
        right: 1em;
        top: -10px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid $brightBlueColor;
        clear: both;
    }

    .instanceAction .dropdown-menu {
        right: 0;
        left: auto;
    }

    .reportContainer:last-child {
        border: 0;
        margin: 0;
    }

    .reportContainer {
        padding: 1px 7px;
        line-height: 30px;
        /* background: #e0e0e0; */
        background: #f8fcff;
        border-bottom: 1px solid #e2e2e2;
        margin: 1px 0;
    }

    .reportContainer a,
    .reportContainer .reportIcon {
        height: 24px;
        display: inline-block;
        vertical-align: middle;
        margin: 0 5px;
    }

    .reportsList {
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-left: 5px;
    }

    .entityActions {
        padding: 7px 15px;
        line-height: 28px;
    }

    .changeLogs {
        width: 30px;
        height: 30px;
        /* margin-left: auto !important; */
    }

    .entityEdit,
    .entityDelete,
    .auditTrails,
    .changeLogs {
        color: #1976d2;
        margin: 0;
    }

    .deleteRecordModalContainer {
        width: 30%;
    }

    .bussinessKeys.primaryDataContainer {
        padding: 10px 15px;
        min-height: 6em;
    }

    .editHeaderBreadcrumb span.breadcrumbSeparator {
        padding: 3px 0;
        color: #d4d4d4;
    }

    .editHeaderBreadcrumb span .mat-icon.material-icons {
        height: 18px;
        width: 18px;
        font-size: 18px;
    }

    .defaultEditEntityContainer mat-expansion-panel.mat-expansion-panel {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .defaultEditEntityContainer {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-left: 0;
        margin-right: 0;
    }

    .masterModal {
        width: 48%;
        float: left;
        margin: 0 0.5em 0 0;
    }

    .noRecordsFound span.material-icons {
        color: $brightBlueColor;
        font-size: 2.2em;
    }

    a.invoiceCollapseHeader {
        width: 100%;
        display: block;
        color: inherit;
        text-decoration: none;
        padding: 0 5 px;
    }

    .collapseContainer .invoiceCollapseBody {
        padding-left: 25px;
    }

    .instanceReportsContainer {
        max-height: 350px;
        overflow-y: auto;
    }

    /* conditionally hides mat-option when there is table as mat-option */

    .matOptionHide {
        display: none !important;
    }

    .alert.alert-note {
        background: #d2d2d2;
        border: 1px solid #bfb9b9;
    }

    /* this is the fix for mat-tooltip. the tweak used for material table in editrequestdetail component affected mat-tooltip. This would fix that */

    .mat-tooltip-panel {
        min-width: auto;
    }

    /* this is the fix for mat-tooltip. the tweak used for material table in editrequestdetail component affected mat-tooltip. This would fix that */

    #masterFilterColumn.settingsCollapseBody {
        overflow-y: auto;
    }

    app-bridgeflowchart main .chart-col {
        z-index: 9;
        position: relative !important;
        height: auto !important;
        left: 0 !important;
        width: 100% !important;
    }

    app-bridgeflowchart main .chart-col > div {
        position: relative !important;
        top: 0 !important;
        left: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
    }

    ngx-graph.chart-container {
        position: relative !important;
        left: 0 !important;
        right: 0 !important;
        top: 0 !important;
        -webkit-transform: none !important;
        -ms-transform: none !important;
        transform: none !important;
    }

    #instanceComplete .modal-dialog {
        width: 70%;
    }

    /* .ngx-charts-outer {
width: 100% !important;
}

.ngx-charts-outer>svg.ngx-charts {
width: 100% !important;
} */

    .setupAttributesContainer {
        padding: 40px;
        margin: 0px auto;
    }

    .cdk-overlay-container {
        z-index: 1051;
    }

    .searchSavedOptions {
        width: 25%;
        font-size: 14px;
        position: absolute;
        right: 10%;
        top: 9px;
    }

    .mat-autocomplete-panel table thead > tr > th.mat-header-cell {
        padding-right: 0.8em;
    }

    .tableActions {
        position: relative;
        padding: 0 0 1em 0;
    }

    .logMenu {
        min-width: 260px;
        max-width: 300px;
        width: 260px;
        max-height: 350px;
        padding: 1em;
        overflow-x: hidden;
        /* overflow: auto; */
        /* top: 0; */
        /* left: 31em; */
    }

    .actionCheckBox {
        margin: 1em 0 0 0;
    }

    .actionItem {
        margin: 1em 0 0 1em;
    }

    .tableActions button {
        margin: 0 1em 0 0;
    }

    .logListItems {
        -webkit-transition: all 0.3s ease-in;
        -o-transition: all 0.3s ease-in;
        transition: all 0.3s ease-in;
    }

    .checkStyle {
        background: #dddddd;
        padding: 0.3em 0.5em;
    }

    .checkStyle label {
        font-weight: unset;
    }

    .mCheckbox > input:checked {
        background-color: #2196f3;
    }

    .noColumn {
        padding: 1em;
        margin-top: 1em;
    }

    .noColumn span.material-icons {
        color: mediumvioletred;
    }

    .actionItem label {
        font-weight: inherit;
        cursor: pointer;
    }

    .pAdjust td.mat-cell,
    td.mat-footer-cell,
    th.mat-header-cell {
        padding: 0 1em !important;
    }

    th.mat-header-cell {
        min-width: 140px;
    }

    .mat-paginator-page-size-label,
    .mat-select-value,
    th.mat-header-cell,
    button.mat-menu-item {
        font-family: var(--newSiteFont);
        // don't use direct css, have some class as a container and target with that specific CSS
    }

    .matTableContainer {
        overflow-x: auto;

        .mat-paginator {
            box-shadow: none;
        }
    }

    .selectAllCol label {
        cursor: pointer;
        text-transform: capitalize;
    }

    .logIcon {
        cursor: pointer;
        color: white;
    }

    .customBtn {
        display: inline-block;
        padding: 6px 12px;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        -ms-touch-action: manipulation;
        touch-action: manipulation;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-image: none;
        border: 1px solid transparent;
        border-radius: 4px;
    }

    button.customBtn.btn-link[disabled='true'] {
        color: grey;
    }

    #showPartners .modal-header {
        border-bottom: none;
    }

    #showPartners .modal-title {
        text-align: center;
        font-weight: 500;
    }

    #showPartners .modal-body {
        padding: 35px 25px;
    }

    #showPartners .modal-dialog {
        width: 45%;
    }

    .searchPartnersBtn {
        margin: 20px;
    }

    .searchPartnersBtn .btn {
        text-transform: none;
    }

    .emailIdSpan,
    .clickSpan {
        color: $brightBlueColor;
    }

    .clickSpan {
        cursor: pointer;
    }

    .exchangeContainer {
        color: black;
    }

    .exchangeRadioSpan {
        text-align: left;
    }

    .exchangeAtStateSec {
        background-color: #c7e4f9;
        color: black;
        margin-left: 20px;
        height: 40px;
        line-height: 40px;
        position: relative;
        z-index: 999;
        margin-bottom: 10px;

        .exchangeRadioSpan {
            padding-left: 36px;
        }
    }

    .partnerExchnageMainDiv {
        .exchangeAtOrgSec {
            .exchangeRadioSpan {
                padding-left: 35px;
            }
        }

        .exchangeAtStateSec {
            .exchangeRadioSpan {
                padding-left: 26px;
            }
        }
    }

    .exchangeAtOrgSec {
        background-color: $brightBlueColor;
        color: $cancelButtonBgColor;
        margin-bottom: 10px;
        height: 40px;
        line-height: 40px;

        .exchangeRadioSpan {
            padding-left: 52px;
        }
    }

    .exchangeAtLocSec {
        background-color: #e0f2ff94;
        position: relative;
        margin-left: 25px;
        height: 40px;
        line-height: 40px;
        margin-bottom: 10px;
        z-index: 99;
    }

    .mainDiv {
        position: relative;
        border-left: 1px dashed $brightBlueColor;
        margin-left: 10px;
    }

    .mainDiv:last-child {
        border: none;
    }

    .locationDiv {
        position: relative;
        margin-left: 40px;
    }

    .locationSubDiv {
        border-left: 1px dashed $brightBlueColor;

        &:last-child {
            border: none;
        }
    }

    .exchangeAtStateSec:before {
        position: absolute;
        bottom: 50%;
        width: 20px;
        height: 70%;
        border-bottom: 1px dashed $brightBlueColor;
        border-left: 1px dashed $brightBlueColor;
        left: -21px;
    }

    .exchangeAtLocSec:before {
        position: absolute;
        bottom: 60%;
        width: 25px;
        height: 100%;
        left: -25px;
        border-bottom: 1px dashed $brightBlueColor;
        border-left: 1px dashed $brightBlueColor;
    }

    // .locationSubDiv:last-child {
    //     border: none;
    // }

    .mvmLabel {
        color: #8d85a7;
        font-weight: 500;
        padding: 0px;
    }

    .Table {
        display: table;
    }

    .Title {
        display: table-caption;
        text-align: center;
        font-weight: bold;
        font-size: larger;
    }

    .Heading {
        display: table-row;
        text-align: center;
        background: #177396;
        color: $cancelButtonBgColor;
        font-weight: 600;
    }

    .Row {
        display: table-row;
    }

    .Cell {
        display: table-cell;
        border: 1px solid #ddd;
        border-width: thin;
        padding-left: 5px;
        padding-right: 5px;
    }

    .mvmRow {
        /* margin: 5px 0px; */
        margin: 25px 0px;
    }

    #addInitiateMVMRecord .modal-dialog {
        width: 65%;
    }

    #addVehicleModal .modal-dialog {
        width: 65%;
    }

    .initiateMvmCls {
        text-align: right;
        margin-bottom: 5px;
        margin-top: 10px;
    }

    .initiateMvmBtn {
        min-width: 89px;
        color: $cancelButtonBgColor;
        background-color: $brightBlueColor;
        padding: 2px 5px;
    }

    #initiateMultivehicleMoment {
        margin-left: 0px;
    }

    #initiateMultivehicleMoment .ni_detailsSection {
        overflow-x: none;
        margin: 0px 0px;
        margin-bottom: 20px;
    }

    #initiateMultivehicleMoment .processTable {
        overflow-x: auto;
        margin-bottom: 10px;
    }

    .changeVehiclesLabel {
        background-color: #efeaea;
        padding: 10px;
    }

    .fitstInitiateMvmBtnCls {
        text-align: center;
        margin-bottom: 5px;
    }

    .addVehicleDiv {
        margin-left: 35px;
    }

    .hiddentablerow {
        padding: 0px 0px !important;
    }

    .addVehicleAccordionbtn {
        margin: 15px 0px;
    }

    .addvehiclebtn {
        padding: 2px 0px;
    }

    .mvmAccordion {
        cursor: pointer;
    }

    .mvmaddVehicleHeader {
        font-weight: 700;
        border-bottom: 1px dotted #76c7fc;
        text-transform: uppercase;
        margin-bottom: 10px;
        margin-top: 10px;
        margin-left: 0px;
    }

    .VehicleRow {
        margin-bottom: 20px;
    }

    .vehicleDetailsSpan {
        width: 100%;
    }

    .vehicleDetailsSpan span {
        float: right;
    }

    .vehicleDetailsClass {
        margin-bottom: 20px;
    }

    .updateVehicleBtn {
        margin-right: 20px;
    }

    .initiateCancelBtn {
        border-radius: 0 !important;
        color: $cancelButtonBgColor;
        background-color: #d9534f;
        border-color: #d43f3a;
    }

    .updateVehicleDetailsBtnDiv {
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .addUpdateCancelBtn {
        margin-left: 10px;
    }

    .groupNumberNtGenDiv {
        margin: 50px auto;
        text-align: center;
    }

    .downloadReportsList {
        min-width: 170px;
    }

    .downloadReportsList li {
        line-height: 25px;
        width: 100%;
        color: #464343d4;
    }

    .downloadReportsList li:hover {
        background-color: #ddedf5;
    }

    .pdfSection {
        width: 100%;
        position: absolute;
        bottom: 32px;
        top: 57px;
    }

    #document-footerContainer {
        width: 100%;
        position: fixed;
        bottom: 0;
        height: 32px;
        line-height: 32px;
        background: #084f80;
        color: $cancelButtonBgColor;
        z-index: 1;
    }

    .footerCopyRightText {
        text-align: left;
    }

    .x-menu {
        border-radius: 0px;
        border: 0px;
        margin-bottom: 0px;
    }

    .pdfRenderSection .navbar-inverse .navbar-collapse,
    .pdfRenderSection .navbar-inverse .navbar-form {
        background-color: $brightBlueColor;
        color: $cancelButtonBgColor;
    }

    .pdfRenderSection .envoiceHeaderLogoImage {
        max-height: 37px;
    }

    .pdfRenderSection .envoiceHeaderLogo {
        margin: 8px 0px;
    }

    .envoiceHeaderLogo img {
        width: 288px;
        height: auto;
    }

    iframe {
        border: none;
    }

    .exchangeText {
        font-size: 16px;
        margin: 20px 0px;
        text-align: center;
    }

    .selectTransforCls {
        margin: 30px 0px;
    }

    .displayInLine {
        display: inline-block;
    }

    .marginRight {
        margin-right: 20px;
    }

    .docTypeHeader {
        font-size: 14px;
    }

    .textAlignCenter {
        text-align: center;
    }

    .skipBtn {
        margin-left: 20px;
    }

    .bulkActionModal {
        width: 40%;
    }

    .bulkActionSummaryModal {
        width: 65%;
        // max-width: 65%;
    }

    .reportDailogError {
        height: 210px !important;
        width: 720px !important;
    }

    .mat-checkbox-inner-container {
        height: 1em !important;
        width: 1em !important;
    }

    .mat-checkbox-checked.mat-accent .mat-checkbox-background,
    .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
        background: $taxillaDarkGreen;
    }

    .mat-ripple-element {
        background: rgba(40, 167, 69, 0.38) !important;
    }

    .mat-checkbox-label {
        font-weight: normal;
    }

    .extensionSelectionRow {
        max-width: 66%;
        padding: 0 15px;
    }

    .feedValue .btn-link.btn-error-link {
        color: #fd0900;
    }

    // .ni_headerSection .ni_headerStatusContainer:last-child {
    //     padding-bottom: 0;
    //     background: #efefef;
    //     border-radius: 4px;
    //     padding: 0;
    // }

    // .ni_headerSection .ni_headerStatusContainer:first-child {
    //     padding-top: 0;
    // }

    .ni_headerSection {
        padding-left: 15px;

        .ni_headerStatusContainer {
            &:last-child {
                background: #efefef;
                border-radius: 4px;
                padding: 0;
            }

            &:first-child {
                padding-top: 0;
            }
        }
    }

    .mat-menu-panel {
        width: 100%;
        min-height: 48px !important;
    }

    .modal button.close {
        opacity: 1;
        margin: 0;
        position: absolute;
        top: 5px;
        right: 0;
        float: none;
        left: unset;
    }

    .modal-body .recordGroupContainer {
        margin: 0;
    }

    // .groupedFieldsHeader {
    //     margin: 25px 0 10px 0;
    //     display: inline-block;
    //     border-bottom: 1px solid #969696;
    //     border-bottom-style: solid;
    //     width: 100%;
    // }

    // .groupedFieldsHeader span {
    //     /* background: #eaebe4; */
    //     margin: 0 0 3px 0;
    //     display: inline-block;
    //     padding: 0 5px;
    //     font-weight: bold;
    //     font-family: var(--newSiteFont);
    // }

    .groupedFieldsHeader {
        margin: 3px 0 5px 0;
        display: inline-block;
        border-bottom: 1px solid #969696;
        border-bottom-style: solid;
        width: 100%;

        span {
            margin: 0 0 -5px 0;
            display: inline-block;
            padding: 0 2px;
            font-weight: bold;
            font-family: var(--newSiteFont);
            padding-bottom: 10px;
            margin-top: 25px;
        }
    }

    .selectAssetPrompt {
        position: relative;
        height: 100%;
        width: 100%;
    }

    .selectAssetPromptText {
        position: absolute;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        width: 100%;
        text-align: center;
    }

    .mandate {
        color: red;
    }

    #matConsolidateTable {
        width: 100%;
    }

    #matConsolidateTable td.mat-cell {
        text-align: center;
    }

    .sectionInfoValue mat-radio-button:first-child label {
        margin: 0 0.6em 0 0;
    }

    .itsRadioBtn mat-radio-group mat-radio-button:first-child label {
        margin-right: 0.7em;
    }

    .addEwbMail material-input,
    .configEmail material-input {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }

    .addEwbMail span.material-icons,
    .configEmail span.material-icons {
        cursor: pointer;
        color: $brightBlueColor;
    }

    .rolesTable,
    .usersTable {
        width: 100%;
        height: 100%;
    }

    .rolesTable td.mat-cell {
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 0px;
    }

    .invoiceWizardHeaderItem span.fnt20 {
        width: calc(100% - 50px);
        display: inline-block;
        /* text-align: center; */
        margin-left: 10px;
    }

    .downloadWizard {
        left: 2em;
        position: relative;
    }

    .matIconEdit span.material-icons {
        cursor: pointer;
        font-size: 1.3em;
    }

    .userProfile span.material-icons {
        font-size: 1.3em;
    }

    li.listitems span {
        cursor: pointer;
    }

    .selectedItemPromptText {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    /* global matMenuContent change */

    .mat-tab-link:hover,
    .mat-tab-link:focus {
        text-decoration: none;
    }

    .mat-radio-group.integrationSelection {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .integrationSelection .mat-radio-label-content {
        vertical-align: super;
    }

    .copyRoleSpan {
        padding-left: 25px;
    }

    .roleHeaderSection {
        color: $brightBlueColor;
    }

    .noPartnerDiv {
        height: inherit;
    }

    .noPartnerSpan {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .noRolesDiv {
        text-align: center;
        margin-top: 15%;
    }

    .requestsProcessDialog .processModalBody,
    // app-organizationdetails .modal-body,
    .changeLogsModalDialog .changeLogRightTable,
    .changeLogsModalDialog .changeLogLeftTable {
        overflow: auto;
    }

    // app-organizationdetails .modal-body mat-expansion-panel {
    //     margin: 0 1.5em !important;
    // }

    /* Dynamically Generated Classes in matTable */

    /* td.mat-column-File-Name {
font-weight: 400;
color: #337ab7;
border-radius: 0;
min-width: 174px;
}

td.mat-column-File-Name:hover {
color: #23527c;
background-color: transparent;
} */

    /* Dynamically Generated Classes in matTable */

    .mat-column-Created-Date {
        min-width: 110px;
    }

    .mat-column-Request-Status {
        max-width: 380px;
    }

    /* Dynamically Generated Classes in matTable */

    .perfectMother {
        position: relative;
        height: 100%;
    }

    .perfectChild {
        position: relative;
        padding-top: 15px;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
    }

    .locationMenu,
    .stateMenu,
    .orgMenu {
        position: relative;
        top: 10px;
    }

    span.material-icons {
        font-size: 21px;
    }

    td.matTableCell.mat-column-checkbox {
        padding-left: 0px !important;
        padding-right: 0px !important;
        width: 18px !important;
    }

    th.matTableHeader.mat-header-cell.mat-column-log {
        padding-left: 0px !important;
        // padding-right: 0px !important;
        width: 18px !important;
    }

    td.mat-cell.mat-column-log {
        padding: 0 !important;
    }

    td.mat-cell.mat-column-checkbox {
        padding: 0 !important;
    }

    .forAdvancedSearchHeader {
        padding: 0 15px 0 15px;
    }

    .assignRoleHeader {
        padding-bottom: 10px;
    }

    .editHeaderTemplate .mat-icon.material-icons {
        font-size: 21px;
    }

    .entityHeader mat-icon.editInfoIcon {
        font-size: 21px;
    }

    #editOrganization mat-form-field .errorMessage,
    #addStatesModal mat-form-field .errorMessage {
        top: 45px;
        white-space: nowrap;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
    }

    /**
* Use below class when you want to center an element horizontally
*/

    .makeMeCenter {
        display: table;
        margin: 0 auto;
    }

    .centerMe {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    td.mat-column-Request-Status,
    td.mat-column-Stage {
        max-width: 350px;
        max-height: 70px;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        cursor: pointer;
        -webkit-transition: all 0.5s ease-in;
        -o-transition: all 0.5s ease-in;
        transition: all 0.5s ease-in;
    }

    td.mat-cell td.mat-column-Stage {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 10px 0px !important;
    }

    td.mat-column-Request-Status:hover,
    td.mat-column-Stage:hover {
        -o-text-overflow: unset;
        text-overflow: unset;
        overflow: visible;
        white-space: pre-wrap;
    }

    .mat-option.mat-active {
        background: rgba(0, 0, 0, 0.1);
        color: rgba(0, 0, 0, 0.87);
    }

    eninvoice-table {
        text-align: left;
    }

    // .ewbSnackbar {
    //     position: absolute;
    //     bottom: 28px;
    //     width: 100%;
    //     color: #363636;
    //     background: #a3d4a4;
    //     text-align: center;
    //     border: 1px solid #dcdcdc;
    //     font-size: 12px;
    //     border-right: none;
    //     border-left: none;
    //     z-index: 9999;
    // }

    // .ewbSnackbar p {
    //     margin: 0px;
    // }

    .clickHightlight {
        background-color: #ddedf5;
        text-decoration: underline;
        cursor: pointer;
        padding: 0 0.2em;
    }

    .appsClass {
        position: absolute;
        right: 0px;
    }

    .organizationUserName,
    .orgName {
        display: inline-block;
        max-width: 140px;
        /* white-space: nowrap;
text-overflow: ellipsis;
overflow-x: hidden; */
    }

    span.organizationLocation {
        display: inline-block;
        max-width: 150px;
        white-space: nowrap;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        overflow-x: hidden;
    }

    span.organizationState {
        display: inline-block;
        max-width: 150px;
        white-space: nowrap;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        overflow-x: hidden;
    }

    .print-menu-item {
        color: #337ab7;
        cursor: pointer;
        margin: 0 2px;
    }

    .exportCls {
        padding-left: 20px;
    }

    .refreshdashboard {
        float: right;
        cursor: pointer;
    }

    .dashboardContainer {
        position: relative;
    }

    .copyRoleSelectDropdwn {
        padding-top: 10px;
    }

    /* IE10+ specific styles go here */
    /* @media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
.fulWidthMatMenu {
max-width: none !important;
box-shadow: none !important;
}

.fulWidthMatMenu :not(:empty).mat-menu-content {
max-height: none !important;
}
} */

    /* IE10+ specific styles go here */
    @media all and (-ms-high-contrast: active), all and (-ms-high-contrast: none) {
        .navbar-nav {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
        }

        .fulWidthMatMenu {
            max-width: none !important;
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
        }

        .fulWidthMatMenu :not(:empty).mat-menu-content {
            max-height: none !important;
        }

        .slimScrollBar {
            display: block !important;
        }

        .settingsCurtainMenu {
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
        }

        #changePswdModal .modal button.close,
        #editProfileModal .modal button.close {
            position: relative !important;
        }

        // .invoiceNewSearchContainer .mainSearchSearcher {
        //     width: 35px !important;

        // }

        // .invoiceNewSearchContainer .mainSearchSuffix {
        //     width: 60px !important;
        //     margin: 0 0 0 6px !important;
        // }

        // .invoiceNewSearchContainer .mainSearch span.material-icons.refreshIcon {
        //     width: auto !important;
        // }

        // .invoiceNewSearchContainer .newFilterActiveCancel {
        //     top: 12px !important;
        // }

        .invoiceNewSearchContainer {
            .mainSearchSearcher {
                width: 35px !important;
            }

            .mainSearchSuffix {
                width: 60px !important;
                margin: 0 0 0 6px !important;
            }

            .mainSearch {
                span.material-icons {
                    &.refreshIcon {
                        width: auto !important;
                    }
                }
            }

            .newFilterActiveCancel {
                top: 12px !important;
            }
        }

        .consolidateewbContainer {
            .dateRange {
                .dates {
                    width: 100% !important;
                }
            }
        }

        .consoliDatepicker {
            .mat-form-field {
                min-height: auto !important;

                input {
                    &.mat-input-element {
                        position: relative !important;
                        top: 3px !important;
                    }
                }

                .mat-form-field-wrapper {
                    .mat-form-field-flex {
                        -webkit-box-align: center !important;
                        -ms-flex-align: center !important;
                        align-items: center !important;
                    }
                }
            }
        }

        .groupsMenuContainer .mat-optgroup {
            width: 100%;
        }

        .workflowEditRequestModal,
        .workflowUserInterventionModal {
            width: 70%;
        }

        .successPanelClass .mat-button-wrapper {
            color: $cancelButtonBgColor !important;
        }

        .messagePopup {
            display: block !important;
            height: 23px !important;
            float: right !important;
            /* text-alignt: right can be considered but it fails when user hovers over it */
        }

        .requestReviewDataContainer .noTrasformationSelectedCls .renderContent,
        .reviewPreCaptureAttrContainer .noTrasformationSelectedCls .renderContent {
            -webkit-transform: translate(0px, 0px) !important;
            transform: translate(0px, 0px) !important;
        }

        span.material-icons.refreshIcon {
            margin-left: 5px;
        }

        mat-expansion-panel-header .mat-content,
        mat-expansion-panel.mat-expansion-panel {
            overflow: visible !important;
        }

        .editInfoIcon {
            position: relative !important;
            top: 3px !important;
        }

        .ieStyleSearchModeSuffixCntr {
            width: 58% !important;
            padding: 0px !important;
        }

        // .mainSearch .processDataAction .mat-raised-button {
        //     padding: 0 2px 0 4px !important;
        //     line-height: 28px !important;
        //     font-size: 13px !important;
        // }

        // .mainSearch .processDataAction {
        //     width: 42% !important;
        // }

        .mainSearch {
            .processDataAction {
                width: 42% !important;

                .mat-raised-button {
                    padding: 0 2px 0 4px !important;
                    line-height: 28px !important;
                    font-size: 13px !important;
                }
            }
        }

        .mainSearchInput {
            flex: 1;
        }

        .searchModeSuffixCntr {
            flex-basis: 55%;
        }

        @media only screen and (min-width: 1056px) and (max-width: 1366px) {
            .bulkActionButton {
                margin: 0px !important;
                width: 39%;
            }

            .appBreadCrumb {
                min-width: 376px;
            }
        }

        .workflowStages {
            width: auto !important;
        }

        .timeFilterDialogModal {
            min-width: 40vw;
        }
    }

    .dashboardSpinnerCls {
        position: relative;
        top: 50%;
        left: 50%;
        bottom: 30%;
    }

    .chartLoader {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(88, 255, 169, 0.19);
    }

    .mat-menu-panel.reportsMenuContainer {
        min-width: 400px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .mat-menu-panel.messagesMenuMainContainer {
        max-width: 100vh;
        min-width: 20vh;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        height: 40vh;
        width: 800px;
    }

    .mat-menu-panel.messagesMenuMainContainer .mat-menu-content .messagesMainContainer .mat-menu-head {
        font-size: 16px;
        padding-bottom: 10px;
        border-bottom: 1px solid #d6d6d6;
    }

    .mat-menu-panel.messagesMenuMainContainer .mat-menu-content .messagesMainContainer .mat-menu-body.messagesContainer {
        height: calc(100% - 38px);
        overflow-y: auto;
        padding: 10px 0 0;
        font-size: 13px;
    }

    .mat-menu-panel.messagesMenuMainContainer .instanceErrorMessages {
        border-top: 3px solid #d81844;
    }

    .mat-menu-panel.messagesMenuMainContainer .instanceWarningMessages {
        border-top: 3px solid orange;
    }

    .mat-icon-button:not(.mat-calendar-previous-button):not(.mat-calendar-next-button) {
        width: 27px;
        height: 28px;
        line-height: 9px;
        margin-left: 4px;
    }

    .material-icons.hasError {
        color: #d81844 !important;
    }

    .material-icons.hasWarning {
        color: orange !important;
    }

    .reportsMenuMainContainer span.btn-link {
        cursor: pointer;
    }

    .dashboardNewReportText {
        padding: 0px 20px 0px 0px;
    }

    .reportDownload {
        cursor: pointer;
        color: #1976d2;
        vertical-align: middle;
        margin-right: 10px;
    }

    .closeNewReport {
        position: absolute;
        right: 1em;
        cursor: pointer;
    }

    /* Targetting mozilla only browser start */
    @-moz-document url-prefix() {
        .recordView {
            overflow: auto;
        }
    }

    /* Targetting mozilla only browser end*/

    .mat-expansion-panel-header-title {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .workflowUserInterventionModal .workflowModalUserDialog {
        position: relative;
        display: table;
        overflow-y: auto;
        overflow-x: auto;
        width: auto;
        min-width: 550px;
        max-width: 900px;
    }

    .workflowEditRequestModal .workflowEditRequestDiaog {
        position: relative;
        display: table;
        overflow-y: auto;
        overflow-x: auto;
        width: auto;
        min-width: 550px;
        max-width: 900px;
    }

    .elasticSearchModal {
        width: 75%;
    }

    .elasticSearchModal .modal-title {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        font-size: 16px;
        font-weight: bold;
        color: $significantRegFontColor;
    }

    .elasticSearchModal .modal-body {
        overflow: auto;
        max-height: 450px;
    }

    .messagesMenuMainContainer {
        max-width: 500px;
    }

    /* .modal-backdrop {
background-color: #1b4d6f;
} */

    @media (min-width: 768px) {
        .modal-content {
            -webkit-box-shadow: 0 5px 15px rgba(40, 115, 166, 0);
            box-shadow: 0 5px 15px rgba(40, 115, 166, 0);
        }
    }

    .sortQbSection {
        padding-bottom: 25px;
    }

    .sortQbSection .qbCondition,
    .sortQbSection .qbOperator {
        display: none;
    }

    // .mat-search-query-builder mat-form-field {
    //     width: auto;
    //     margin-left: 1%;
    // }

    /* Css fix for material input autofill chrome bug */
    .mat-form-field-appearance-legacy input:-webkit-autofill {
        background: none;
    }

    .mat-form-field-appearance-legacy input:-webkit-autofill + span label {
        -webkit-transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px) !important;
        transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px) !important;
        width: 133.33333% !important;
    }

    /* Css fix for material input autofill chrome bug */

    .duplicateRow span.material-icons,
    .deleteRow span.material-icons {
        color: #1976d2;
        font-size: 1.5em;
        padding: 4px;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
        vertical-align: middle;
        height: 32px;
    }

    .duplicateRow span.material-icons:hover,
    .deleteRow span.material-icons:hover {
        background-color: #eceff1;
        border-radius: 4px;
        cursor: pointer;
    }

    .downloadTemplate a {
        text-decoration: none;
    }

    .mask::after {
        content: '';
        position: absolute;
        background: #f8fcff;
        opacity: 0.7;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        cursor: not-allowed;
    }

    .isMasked {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .postLoginHeaderToobar .material-icons {
        font-size: 24px !important;
    }

    .postLoginHeaderToobar .goToParentOrganization .material-icons {
        font-size: 18px !important;
    }

    .postLoginHeaderToobar .postLoginHeader .navbar-nav .fulwidthmenu {
        font-family: var(--newSiteFont) !important;
        height: 44px !important;
    }

    .postLoginHeaderToobar .headerLogo {
        height: 31px;
    }

    .settingsCurtainMenu .mat-card {
        font-family: var(--newSiteFont) !important;
    }

    .fulWidthMatMenu .mat-menu-content:not(:empty) {
        max-height: unset !important;
    }

    .settingsCurtainMatMenuButton[aria-expanded='true'] span.fulWidthMatMenuShowArrowIcon {
        font-size: 2em !important;
    }

    .fulwidthmenu[aria-expanded='true'] span.fulWidthMatMenuShowArrowIcon {
        font-size: 2em !important;
    }

    .logSpanCls {
        position: relative;
        top: 2px;
    }

    .taxillaLink {
        color: $cancelButtonBgColor;
    }

    .taxillaLink:hover {
        color: $cancelButtonBgColor;
    }

    .footerText {
        font-size: 13px;
    }

    /* .postLoginFooterContainer a:visited {
color: white;
} */

    .noMarginImp {
        margin: 0px !important;
    }

    .assetLevelSearch {
        margin: 1em 6px;
    }

    .assetLevelSearch input {
        outline: none;
        border-radius: 15px;
    }

    .pinnedAssets {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        border-bottom: 1px solid #7cafd2;
        padding: 0 0 1em 0;
    }

    .pinnedAssetsHeader {
        width: 100%;
        padding: 5px 0;
    }

    .mat-button.mat-primary,
    .mat-icon-button.mat-primary,
    .mat-stroked-button.mat-primary,
    query-builder a {
        color: $taxillaDarkGreen;
    }

    .wideModal {
        width: 1200px !important;
        max-width: 1200px !important;
    }

    .masterItems {
        min-height: inherit;
        border-right: 1px solid #ccc;
    }

    .mastersModalClass {
        width: 90% !important;
        overflow: hidden;
        padding: 0;

        .mat-dialog-container {
            padding: 0;
        }

        .mat-dialog-content {
            min-height: 85vh;
            margin: 0;
            padding: 0px;
            overflow: hidden;

            .mastersHeader {
                padding: 10px 0px;
            }

            .masterSettingHeader {
                .selectedMasterNameCls {
                    line-height: 20px;
                }
            }
        }

        .tableContainerWithoutPaginator {
            overflow-x: auto;
        }

        .masterRequestsTable {
            .matTableContainer {
                overflow: hidden !important;
            }
        }

        span.material-icons {
            &.hasError {
                color: $taxillaRedColor !important;
            }

            &.hasWarning {
                color: $taxillaOrangeColor !important;
            }
        }

        tbody {
            tr.mat-row {
                td.matTableCell.mat-cell {
                    max-width: 170px;
                    overflow-x: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;

                    &.file-browse {
                        max-width: 280px !important;
                    }

                    &.mat-column-displayDetail:hover {
                        text-overflow: unset;
                        overflow: hidden;
                        cursor: pointer;
                        white-space: pre-wrap;
                    }

                    &.mat-column-requestId:hover {
                        text-overflow: unset;
                        overflow: hidden;
                        cursor: pointer;
                        white-space: pre-wrap;
                        max-width: 300px;
                    }

                    &.mat-column-checkSum:hover {
                        text-overflow: unset;
                        overflow: hidden;
                        cursor: pointer;
                        white-space: pre-wrap;
                        max-width: 430px;
                    }
                }
            }
        }
    }

    .mastersModalClass .row .masterData {
        padding: 0px 5px;
    }

    .mastersModalClass .row .masterItems,
    .mastersModalClass .row .masterData {
        overflow: auto;
    }

    app-masterstable tbody tr {
        cursor: unset !important;
    }

    app-masterstable tbody tr:hover {
        background: unset !important;
    }

    td.mat-column-File-Name {
        text-decoration: underline;
        color: $taxillaDarkGreen;
        max-width: 260px;
        max-height: 70px;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap;
        -webkit-transition: all 0.5s ease-in;
        -o-transition: all 0.5s ease-in;
        transition: all 0.5s ease-in;
    }

    td.mat-column-File-Name:hover {
        -o-text-overflow: unset;
        text-overflow: unset;
        overflow: visible;
        white-space: pre-wrap;
        word-break: break-all;
    }

    td.mat-column-Options {
        text-align: center !important;
        button {
            margin: 0 7px !important;
        }
    }

    .secretQuestionsSelectDrpwn {
        padding: 0px 30px;
    }

    .fieldAttachmentContainer {
        min-height: 67.5px;
        display: inline-block;
        line-height: 67.5px;
        color: #9f9f9f;

        span {
            display: inline-block;
        }

        &.gridLabelContainer {
            display: inline-block;
            width: 100%;

            .fieldAttachmentLabel {
                width: calc(100% - 50px);
                margin-right: 0;
            }
        }
    }

    .fieldAttachmentIcon {
        cursor: pointer;
    }

    .fieldAttachmentLabel {
        vertical-align: super;
        margin-right: 20px;

        &.requiredAttachment {
            vertical-align: middle;
        }

        .fieldAttachmentLabelText {
            line-height: 47px;
        }

        .fieldAttachmentRequiredText {
            line-height: 20px;
            font-size: 12px;
        }
    }

    .modal-content .close.noOutline {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .fieldLevelAttachmentModal {
        width: 70%;
    }

    .fieldAttachmentRow {
        margin: 10px 0;
        line-height: 36px;
    }

    .fieldAttachedFileName {
        line-height: 36px;

        .fileNameLabel {
            margin-left: 15px;
        }

        .fileNameValue {
            overflow: hidden;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-left: 10px;
        }
    }

    .fileTypeSupport {
        color: #a94442;

        span {
            margin-top: 5px;
            margin-bottom: 20px;
        }
    }

    .fieldFileUploadModal {
        .maxAllowedFileCount {
            margin: 0 30px;
        }
    }

    .maxAllowedFileCount {
        padding: 10px 0;

        .alreadyReached {
            color: #a94442;
        }
    }

    app-taxilla-header ul.navbar-nav {
        padding: 0;
    }

    .mat-step-header .mat-step-icon-selected,
    .mat-step-header .mat-step-icon-state-done {
        background-color: #00a84d;
        color: $cancelButtonBgColor;
    }

    .mat-step-header .mat-step-icon-state-edit {
        background-color: rgba(0, 0, 0, 0.54);
        color: $cancelButtonBgColor;
    }

    .cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
        -webkit-animation-name: cdk-text-field-autofill-end;
        animation-name: cdk-text-field-autofill-end;
    }

    textarea[matInput] {
        resize: vertical;
        overflow: auto;
        padding: 2px 0;
        margin: -2px 0;
    }

    textarea.mat-input-element.cdk-textarea-autosize {
        resize: auto !important;
        white-space: nowrap;
        overflow: auto;
    }

    textarea.cdk-textarea-autosize {
        resize: none;
    }

    .mat-form-field-infix textarea {
        resize: vertical;
        width: 100%;
        overflow: hidden;
        -webkit-appearance: none !important;
        outline: none;
        border: none;
        max-height: 300px;
    }

    .mat-tab-group.mat-primary .mat-ink-bar,
    .mat-tab-nav-bar.mat-primary .mat-ink-bar {
        background-color: #00a651;
    }

    .mat-form-field-outline {
        color: $taxillaDarkGreen;
    }

    .searchWidgets {
        margin-bottom: -20px;
        margin-top: -12px;
    }

    .trasformationListDiv {
        width: 25%;
        margin: 25px 30px 5px;
        font-size: 14px;
    }

    .noTrasformationSelectedCls {
        text-align: center;
        min-height: 50px;
        position: relative;
        margin-top: 40px;

        .renderContent {
            position: absolute;
            top: 30%;
            left: 115px;
            -webkit-transform: translateX(-50%) translateY(-50%);
            -ms-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
            display: block;
            font-size: 14px;
        }
    }

    .downloadLink {
        color: #4ba94e;
    }

    .preCaptureAttributesContainer {
        min-height: 250px;
        margin-bottom: 30px;
        font-size: 14px;
    }

    .noPreCaptureAttributes {
        font-size: 14px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
    }

    .filingAttributeSubmitButn {
        text-align: right;
        margin-top: 30px;
        padding: 0 20px 0 25px;
    }

    .bulkWorkflowExecutionModal {
        width: 90%;
    }

    .bulkWorkflowExecutionTable .table tbody td {
        padding: 0;
        line-height: 64px;
    }

    .mat-error.mat-has-warning {
        color: #ff7a00;
    }

    .mat-form-field.mat-form-field-invalid.mat-has-warning .mat-form-field-label {
        color: #ff7a00;
    }

    .mat-form-field.mat-form-field-invalid.mat-has-warning .mat-form-field-ripple,
    .mat-form-field.mat-form-field-invalid.mat-has-warning .mat-form-field-ripple.mat-accent {
        background-color: #ff7a00;
    }

    .appBreadCrumb {
        font-size: 14px;
        line-height: 34px;
        float: left;
        padding-left: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .appBreadCrumb span.material-icons {
        font-size: 11px;
    }

    .newRequirmentsContainer {
        width: 100%;
        padding: 9px 15px !important;
        height: 52px;

        .appActionItems {
            margin: 0 -11px;
            float: right;
            padding: 0;
            max-width: 75%;

            .actionItem {
                margin: 0 5px;
                cursor: pointer;

                &.inboundTransBtn {
                    margin: 0 6px 0 8px;
                    background-color: #616161;
                    max-width: 25px;
                    max-height: 25px;
                    line-height: 24px;
                    border-radius: 4px;

                    &:hover {
                        background: $taxillaDarkGreen !important;
                    }
                }
            }

            .goToRecordView {
                margin-right: 5px !important;

                i {
                    color: $taxillaRedColor !important;
                }
            }

            .moreActionsBtn {
                font-size: 24px !important;
                &:hover {
                    color: $taxillaDarkGreen !important;
                    background: $matTableRowBorderGrey;
                    border-radius: 50%;
                }

                &.highlightMoreActions {
                    border-radius: 10px;
                    animation: blinkAnimation 1s infinite;
                    color: #fff !important;
                }
            }
        }
    }

    .newRequirmentsContainer .appActionItems .mat-raised-button {
        line-height: 31px;
    }

    .newRequirmentsContainer .appActionItems span.material-icons.inboundTransmissions {
        /* transform: rotate(90deg); */
        color: #4fa753;
    }

    .newRequirmentsContainer img.inboundTransmissions {
        cursor: pointer;
    }

    .relatedSidebarApps {
        padding: 0.5em 13px;
        border-top: 1px solid #efefef;
        font-size: 15px;
        overflow: hidden;

        &:not(:first-child) {
            border-bottom: 1px solid #efefef;
        }

        &.relatedSidebarAppsTop {
            border-top: 1px solid #efefef;
        }
    }

    .workflowText {
        border: 1px solid $matIconGreyColor;
        padding: 0.34em;
        background: $matIconGreyColor;
        color: $cancelButtonBgColor;
    }

    .actualStage .actualText {
        color: #ff4c3f;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap;
        min-width: 195px;
        max-width: 195px;
        overflow-x: hidden;
        text-align: center;

        &.completed {
            color: #000;
        }

        &.activeWorkflowStage {
            color: $taxillaDarkGreen !important;
            font-weight: bold;
            max-width: 164px;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow-x: hidden;
        }
    }

    .workflowStageShow {
        border: 1px solid $matIconGreyColor;
        padding: 2.8px;
        cursor: pointer;
        min-width: 250px;
        background: $cancelButtonBgColor;
    }

    .workflowStage {
        margin: 0 auto;

        span.material-icons {
            font-size: 25px;
        }
    }

    .workflowStages {
        min-width: 326px;
        max-width: 485px;
        width: 100%;
        height: 30px;
        position: relative;
    }

    .uploadTypeLabel {
        margin-bottom: 15px;
    }

    .uploadTypeSelectionContainer mat-radio-button {
        display: block;
        margin-bottom: 10px;
        margin-left: 20px;
    }

    .uploadTypeSelectionContainer label.mat-radio-label {
        font-weight: normal;
    }

    #newRequestComponent .mat-tab-label.mat-tab-disabled,
    #newRequestComponent .mat-tab-link.mat-tab-disabled {
        color: inherit;
    }

    .tableFilesUploadContainer {
        display: inline-block;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        text-align: center;
    }

    .reviewFormHeader,
    .reviewPreCaptureHeader {
        font-weight: 600;
        margin-bottom: 5px;
        /* text-decoration: underline; */
        background: #ebebeb;
        padding: 10px;
        border-radius: 5px;
    }

    .requestReviewFieldLabel {
        /* min-width: 250px; */
        display: inline-block;
        color: #a1a1a1;
    }

    .requestReviewFieldContainer {
        margin-bottom: 10px;
        margin-top: 10px;
        font-size: 14px;
    }

    .organizationsListContainer .mat-menu-item {
        height: 30px;
        padding: 5px 5px;
        line-height: 24px;
        font-size: 14px;
    }

    .organizationSelect {
        margin-right: 10px;
    }

    .organizationsListContainer .tenantsList button {
        width: calc(100% - 40px);
        display: inline-block;
    }

    .reviewTenantNamesDisplayContainer {
        /* margin-left: 25px; */
        max-height: 200px;
        overflow-y: auto;
    }

    .outlookFlowOut {
        position: absolute;
        z-index: 998;
        width: 1200px;
        top: 0px;
        -webkit-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
        box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    }

    @media (min-width: 1024px) and (max-width: 1370px) {
        .outlookFlowOut {
            width: 900px;
        }
    }

    .mainSearchSearcher {
        padding: 3px;
        height: 42px;
        width: 26px;
    }

    .mainSearchSearcher span.material-icons {
        font-size: 20px;
        color: #939393;
    }

    .mainSearchSearcher span.material-icons:hover {
        background: $headerColor;
    }

    .mainSearchPrefix span.material-icons {
        padding: 0.5em;
        font-size: 1.5em;
    }

    .mainSearch,
    .mainSearchInput input {
        /* height: 42px; */
        padding: 0.2em 0.5em;
    }

    .mainSearchInput {
        margin-left: -5px;
        margin-right: 14px;
        width: calc(100%);
        position: relative;
    }

    .mainSearchInput span.material-icons {
        font-size: 20px;
    }

    .mainSearchInput input {
        padding: 0.5em 20px 0.5em 0.5em;
    }

    .searchModeSuffixCntr {
        padding: 0 0 0 10px;
    }

    .searchModeSuffixCntr .filterLabelHeading {
        padding-left: 5px;
        font-size: 15px;
        font-weight: bold;
        margin-left: 10px;
    }

    .searchModeSuffix,
    .clearSearchSuffix {
        font-size: 14px;
        padding: 0;
    }

    .clearSearchSuffix {
        color: #da534e;
    }

    .clearSearchSuffix:hover {
        border-bottom: 1px solid #da534e;
    }

    .mainSearchSuffix {
        color: #585859;
        margin-left: -5px;
        margin-right: 6px;
        width: 40px;
    }

    .mainSearchSuffix:hover {
        border-bottom: 1px solid #585859;
        margin-bottom: -1px;
    }

    .newFilterActive {
        visibility: hidden;
    }

    .newFilterActiveCancel {
        /* color: #da534e !important; */
        color: $matIconGreyColor !important;
        position: absolute;
        right: 10px;
    }

    span.material-icons.refreshIcon {
        padding-right: 3px;
        font-size: 20px;
    }

    span.material-icons.refreshIcon:hover {
        background: $headerColor;
    }

    .mainSearchInput,
    .mainSearchFilters {
        font-size: 14px;
    }

    .mainSearchFilters .searchType {
        margin-right: 1.5em;
        font-family: var(--newSiteFont);
    }

    .mainSearchFilters .searchType.mat-radio-checked .mat-radio-label-content {
        font-weight: 600;
        font-family: var(--newSiteFont);
    }

    .mainSearchFilters .mat-select-value {
        color: #585859;
    }

    .mainSearch {
        border-bottom: 1px solid #efefef;
    }

    .selectedFilterContainer,
    .buttonFields {
        padding: 1.2em 1.5em;
    }

    .selectedFilterContainer .customSearchContainer ul.q-tree {
        padding-left: 15px;
        padding-right: 17px;
    }

    .buttonFields {
        padding: 8px 1.5em;
        border-top: 1px solid #efefef;
    }

    .defaultFilterContainer,
    .ewayBillStatusContainer {
        width: 63%;
    }

    .ewayBillStatusContainer label,
    .defaultFilterContainer label {
        -webkit-box-flex: 1;
        -ms-flex: 1 50%;
        flex: 1 50%;
    }

    .mainSearchPrefix mat-checkbox {
        padding: 0.7em 0.59em 0.7em 2px;
    }

    .mat-checkbox-checked.mat-accent .mat-checkbox-background,
    .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
    .mat-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element,
    .mat-radio-button.mat-accent .mat-radio-inner-circle,
    .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
    .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
    .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
        background-color: $taxillaDarkGreen;
    }

    .mat-accent .mat-pseudo-checkbox-checked,
    .mat-accent .mat-pseudo-checkbox-indeterminate,
    .mat-pseudo-checkbox-checked,
    .mat-pseudo-checkbox-indeterminate {
        background: $taxillaDarkGreen !important;
    }

    .noReportRecord {
        color: rgba(0, 0, 0, 0.87);

        .mat-pseudo-checkbox-disabled {
            display: none !important;
        }
    }

    .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
        border-color: $taxillaDarkGreen;
    }

    .mat-button,
    .mat-fab,
    .mat-flat-button,
    .mat-icon-button,
    .mat-mini-fab,
    .mat-raised-button,
    .mat-stroked-button {
        font-family: var(--newSiteFont) !important;
    }

    .filterActive {
        color: $taxillaDarkGreen;
    }

    .entityBreadcrumb {
        font-weight: bold;
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
    }

    .mat-radio-label {
        font-weight: 400;
        font-size: inherit;
        font-family: inherit;
    }

    .entityBody {
        background: $cancelButtonBgColor;
        padding-bottom: 50px;
    }

    footer {
        padding: 8px 0 0 8px;
        background: $cancelButtonBgColor;
        position: fixed;
        bottom: 5px;
        left: 5px;
        z-index: 99999;
        visibility: visible;
        height: 30px;
        width: 31px;
        border-radius: 25px;
        margin: 0px 8px;
        transition: width 0.3s;

        .logo {
            padding: 0 5px 8px 0;
            height: 25px;
        }

        .content {
            font-size: 11px;
            margin-left: 0px;
        }

        .taxillaLogo {
            display: none;
        }

        &:hover {
            padding-right: 8px;
            width: 160px;

            & .taxillaLogo {
                display: block;
            }

            & .tLogo {
                display: none;
            }
        }
    }

    .materialAccordionHeader {
        font-family: var(--newSiteFont);
    }

    .mat-expansion-panel.mat-expanded .materialAccordionHeader {
        font-weight: bold;
    }

    .mat-tab-label.mat-tab-label-active {
        color: $blackColor !important;
        opacity: 1;
        font-weight: bold;
    }

    .mat-tab-label:hover {
        color: $blackColor !important;
        opacity: 1;
        font-weight: bold;
    }

    .mat-tab-label .mat-tab-label-content {
        font-weight: bold;
    }

    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header .mat-step-icon {
        font-size: 13px;
    }

    .modalDownloadIcon {
        margin-top: 5px;
        margin-right: 10px;
        top: 3px;
        position: relative;
    }

    .downloadSection a {
        color: $taxillaDarkGreen;
    }

    .uploadedFilesListContainer {
        font-weight: normal;
        font-size: 14px;
        margin-top: 30px;
    }

    .uploadedFilesListHeader > div {
        padding: 15px 0 15px 15px;
        padding-left: 15px;
        text-align: left;
        font-weight: 600;
        font-size: 15px;
        border: 1px solid #a5a0a0;
        border-right: 0;
    }

    .uploadedFilesListHeader > div:last-child,
    .uploadedFileContainer > div:last-child {
        border-right: 1px solid #a5a0a0;
    }

    .uploadedFileContainer > div {
        padding: 10px 0 10px 15px;
        text-align: left;
        font-size: 14px;
        border: 1px solid #a5a0a0;
        border-right: 0;
        border-top: 0;
        min-height: 42px;
    }

    .uploadedFileContainer {
        .fileNameValue {
            overflow: hidden;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .modal-content .uploadedFileContainer .close.noOutline {
        top: 50%;
        left: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
    }

    .actionsMenu:not(.tableActionsMenu) {
        background: $taxillaDarkGreen;
        color: $cancelButtonBgColor;
        padding: 0.34em 0.2em 0.34em 0.5em;
        border: 1px solid $taxillaDarkGreen;
    }

    .editModeActionsMenu {
        background: $taxillaDarkGreen;
        color: $cancelButtonBgColor;
        padding: 1px 7px;
        border: 1px solid $taxillaDarkGreen;
        border-radius: 3px;
    }

    .actionsMenu span.material-icons:not(.tableMatActionIcon) {
        color: $cancelButtonBgColor !important;
    }

    .editModeActionsMenu span.material-icons {
        color: $cancelButtonBgColor !important;
    }

    .inputTypeSpanCls {
        padding-left: 10px;
        font-size: 14px;
    }

    .preCaptureAttributeFieldsContainer {
        font-size: 14px;
    }

    .fieldInput {
        padding-bottom: 20px;
    }

    .reviewMainContainer {
        margin-top: 10px;
        font-size: 14px;
        min-height: 280px;
        padding: 0 25px;
    }

    .reviewPreCaptureAttrContainer {
        margin-bottom: 20px;
    }

    .reviewPreCaptureAttrContainer .requestReviewPreCaptureContainer {
        padding-left: 20px;
    }

    .reviewPreCaptureAttrContainer .noTrasformationSelectedCls,
    .requestReviewDataContainer .noTrasformationSelectedCls {
        text-align: left;
        min-height: 35px;
    }

    .reviewPreCaptureAttrContainer .noTrasformationSelectedCls .renderContent,
    .requestReviewDataContainer .noTrasformationSelectedCls .renderContent {
        position: relative;
        top: 8px;
        left: 18px;
        -webkit-transform: unset;
        -ms-transform: unset;
        transform: unset;
    }

    .requestReviewDataContainer {
        margin-bottom: 20px;
    }

    .btn-primary {
        background-color: $taxillaDarkGreen;
        border-color: $taxillaDarkGreen;
    }

    // query-builder ul li.q-row {
    //     overflow: auto;
    // }

    // query-builder ul li.q-row mat-form-field {
    //     min-width: 29%;
    //     float: left;
    // }

    .marginTopBottom20 {
        margin: 20px 0px;
    }

    .preCaptureAttributeFieldsContainer .mat-radio-label {
        padding-right: 10px;
    }

    .filteringAttributesDiv {
        font-size: 14px;
        margin-bottom: 15px;
    }

    .filterAttributesHeaderDiv,
    .orgSelectDivHeader {
        font-weight: bold;
        font-size: 14px;
        background: #ebebeb;
        padding: 7px;
        border-radius: 5px;
    }

    .filteringAttributesDiv,
    .organizationsListContainer {
        margin: 10px 0px;
    }

    .levelOneTenants,
    .searchTenants {
        padding-top: 15px;
    }

    .tenantFlatStructure,
    .searchSubTenants {
        padding-top: 15px;
    }

    .selectedAssetDataCntr {
        position: relative;
        display: block;
        width: 100%;
        height: calc(100% - 52px);
    }

    #newRequestComponent .materialStepperDiv {
        /* min-height: 613px; */
        background: $cancelButtonBgColor;
        padding: 5px 15px 15px;
        height: 100%;
    }

    .radioButnFieldLabel {
        padding: 0px;
    }

    .filingAttributeFieldRow {
        padding-top: 20px;
    }

    .bulkActnModalBody .entityFieldContainer {
        min-height: 68.75px;
    }

    .skippedWorkFlowStage {
        color: grey !important;
        font-weight: bold;
    }

    .skippedIcon {
        font-size: 19px !important;
        pointer-events: none;
        cursor: default;
        color: $taxillaDarkGreen !important;
    }

    .qbSection h6 {
        background: #ebebeb;
        padding: 7px;
        border-radius: 5px;
        font-weight: bold;
    }

    .newRequestComponentHeader {
        padding: 10px 15px;
        border-bottom: 1px solid #ccc;
        font-size: 15px;
        background: $cancelButtonBgColor;
    }

    .newRequestComponentHeader .createNewProcessSpan {
        font-weight: bold;
    }

    .newRequestComponentBody .mat-tab-label {
        font-weight: bold;
    }

    .addDataHeader {
        padding-bottom: 15px;
        padding-left: 30px;
        font-weight: 600;
    }

    .sourceTableHeaderClass {
        padding-top: 10px;
        padding-bottom: 25px;
        font-weight: 600;
    }

    #newRequestComponent .mat-horizontal-stepper-header-container {
        padding: 45px 15% 0px;
    }

    .mat-horizontal-content-container {
        padding-top: 35px;
    }

    #newRequestComponent .electronicForm {
        padding: 30px 25px 15px;
    }

    #newRequestComponent .mat-stepper-label-position-bottom .mat-horizontal-stepper-header {
        padding: 15px 24px 20px;
    }

    #newRequestComponent .mat-stepper-label-position-bottom .mat-horizontal-stepper-header .mat-step-label {
        padding: 5px 0 0 0;
    }

    #newRequestComponent .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::before,
    #newRequestComponent .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::after,
    #newRequestComponent [dir='rtl'] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::after,
    #newRequestComponent [dir='rtl'] .mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::before {
        top: 26px;
    }

    #newRequestComponent .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
        top: 26px;
    }

    .selectSearchFilter {
        margin: 0px 17px;
    }

    .inboundTransBtn {
        height: unset !important;
    }

    .eventLogNotifications {
        width: 80%;
        // height: 80%;
    }

    .notificationsContentContainer {
        .header-actions {
            display: flex;
            align-items: center;
            .refreshLogs {
                margin-right: 20px !important;
            }
        }

        .mat-dialog-content {
            max-height: unset;

            app-common-notifications-eventlogs {
                material-table {
                    .tableWrapper {
                        max-height: calc(75vh - 150px);
                        overflow: auto;
                    }
                }
            }
        }

        .closeNoticationIcon {
            position: relative;
            top: -13px;
            left: -24px;
            font-size: 26px;
            margin: -12px;
        }

        .eventFilterTypes {
            margin: auto;
        }

        .notifyContentContainer {
            padding: 10px;

            .eventLogHedderDiv {
                padding: 0 2px 10px;
            }
        }
    }

    @-moz-document url-prefix() {
        .notifyContentContainer {
            .eventTable {
                material-table {
                    .matTableContainer {
                        .tableContainerWithoutPaginator {
                            table {
                                tbody {
                                    tr {
                                        td {
                                            overflow: hidden !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    // .eventTable {
    //     overflow-y: auto;
    //     height: 70vh;
    //     padding: 10px;
    // }

    .inputRadioButton {
        margin-right: 10px;
    }

    .cdk-overlay-pane .mat-autocomplete-panel.mat-autocomplete-hidden {
        visibility: visible;
    }

    .cdk-overlay-pane .mat-autocomplete-panel.mat-autocomplete-hidden.autoCompleteMenu.hideMenu {
        display: none;
    }

    .guestBodyContainer {
        position: relative;
        background: $bodyBgColor;
    }

    .guestProcessContainer {
        padding: 10px 0 0;
        height: calc(100vh - 44px);

        .hierarchyDetailColumn {
            width: 100%;
        }

        .invoiceColumn {
            &.detailColumn {
                margin-left: 15%;
                height: calc(100% - 12px);
            }
        }
    }

    mat-icon.mat-icon.notranslate.material-icons {
        cursor: pointer;
    }

    .processCompletedContainer {
        position: relative;
        width: 100%;
    }

    .processCompletedText {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        color: $taxillaDarkGreen;
        font-size: 18px;
        font-weight: bold;
    }

    .reportsLink {
        color: $taxillaDarkGreen !important;
        border-bottom: 1px solid $taxillaDarkGreen;
        cursor: pointer;
    }

    .repotsGenrateRow {
        padding: 0 5px;
    }

    .masterSearchCloseButtonCls {
        position: absolute;
        top: 2px;
        right: 5px;
    }

    .closeIcon {
        color: $matIconGreyColor;
        cursor: pointer;
    }

    .appHomeButton {
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;

        .homeIcon {
            margin-top: -6px !important;
        }
    }

    .gridFieldModal {
        width: 90%;
        //    position: absolute;
        //    left: 50%;
        //    top: 50%;
        //    -webkit-transform: translateX(-50%) translateY(-50%) !important;
        //    -ms-transform: translateX(-50%) translateY(-50%) !important;
        //    transform: translateX(-50%) translateY(-50%) !important;
        //    margin: 0;
        //    line-height: 4.5;

        button.actionButtons {
            width: 24px;
            height: 24px;
            line-height: 21px;
        }

        eninvoice-table table tr .mat-column-firstRowColumn {
            text-align: center;
            background-color: $headerColor;
        }
    }

    .fade {
        -webkit-transition: unset;
        -o-transition: unset;
        transition: unset;
    }

    .workflowUserActionModal {
        width: 40%;

        .workflowFormFieldsContainer {
            display: block;
            width: 100%;
        }

        .workflowCommentsContainer {
            width: 100%;
            padding: 0 15px;
        }

        .mat-dialog-content {
            padding: 0;
        }

        material-group-checkbox {
            .materialGroupCheckboxContainer {
                line-height: 44px;

                &.mat-error {
                    padding-top: 0;

                    button {
                        border-bottom: 0;
                    }
                }

                button {
                    padding-right: 0;
                    border-radius: 0;
                    background-color: #fff !important;
                    color: #817f7f;

                    .mat-button-focus-overlay {
                        background-color: #fff !important;
                    }

                    .dropdowns {
                        right: 0;
                    }
                }
            }
        }
    }

    .processStatusViewContainer.statusBarOpened {
        .processViewMainContainer {
            .latestStatusMessage {
                &:before {
                    content: '\21D3';
                }
            }
        }
    }

    #editRequestContainer .processStatusViewContainer {
        width: 80%;
        left: 10%;
    }

    .processStatusViewContainer {
        position: fixed;
        top: calc(97% - 5px);
        width: 68%;
        left: 32%;
        padding: 10px;
        padding-top: 0;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
        z-index: 2;

        .processViewMainContainer {
            position: relative;
            width: 70%;
            margin: 0 15%;
            background: $cancelButtonBgColor;
            font-size: 14px;
            -webkit-transition: 0.3s;
            -o-transition: 0.3s;
            transition: 0.3s;
            padding: 0;
            -webkit-box-shadow: 0 5px 16px 3px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
            box-shadow: 0 5px 16px 3px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);

            .latestStatusMessage {
                position: relative;
                text-align: center;
                padding-top: 2px;
                border-top: 1px solid #47a652;

                &:before {
                    content: '⇑';
                    position: absolute;
                    top: -20px;
                    width: 20px;
                    left: 50%;
                    -webkit-transform: translateX(-50%);
                    -ms-transform: translateX(-50%);
                    transform: translateX(-50%);
                    background: #47a652;
                    color: $cancelButtonBgColor;
                    border-top-left-radius: 3px;
                    border-top-right-radius: 3px;
                    height: 20px;
                    cursor: pointer;
                }
            }

            .allProcessStatusMessagesContainer {
                padding: 10px 10px 10px;
                max-height: calc(100% - 30px);
                overflow-y: auto;
                color: rgba(255, 0, 0, 0.7);

                .processStatusMessageText {
                    margin: 5px 0;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .processStatusViewContainer.hideStatusBar {
        top: calc(100% + 20px) !important;
    }

    material-group-checkbox .materialGroupCheckboxContainer {
        line-height: 60px;
    }

    material-group-checkbox .materialGroupCheckboxContainer button {
        width: 100%;
        border-bottom: 1px solid #949494;
        text-align: left;
        padding-left: 0;
    }

    material-group-checkbox .materialGroupCheckboxContainer button .groupCheckboxSelectedText {
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        overflow-x: hidden;
        white-space: nowrap;
        max-width: calc(100% - 20px);
        display: inline-block;
    }

    material-group-checkbox .materialGroupCheckboxContainer button .dropdowns {
        top: 50%;
        position: absolute;
        right: 17px;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    material-group-checkbox .materialGroupCheckboxContainer button.descriptionButton {
        width: 24px;
        height: 24px;
        line-height: 20px;
        top: 50%;
        position: absolute;
        right: -5px;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    material-group-checkbox .materialGroupCheckboxContainer button .fulWidthMatMenuShowArrowIcon {
        display: none;
    }

    material-group-checkbox .materialGroupCheckboxContainer button[aria-expanded='true'] .defaultDropdownIcon {
        display: none;
    }

    material-group-checkbox .materialGroupCheckboxContainer button[aria-expanded='true'] .fulWidthMatMenuShowArrowIcon {
        display: inline-block;
    }

    material-group-checkbox .materialGroupCheckboxContainer .hintsContainer {
        line-height: 20px;
        font-size: 75%;
    }

    material-group-checkbox .materialGroupCheckboxContainer.mat-error {
        padding-top: 10px;
    }

    material-group-checkbox .materialGroupCheckboxContainer.mat-error .hintsContainer {
        border-top: 1px solid $taxillaRedColor;
    }

    material-group-checkbox .materialGroupCheckboxContainer.mat-warning .hintsContainer {
        border-top: 1px solid $taxillaRedColor;
    }

    .groupCheckboxContainer {
        width: 100%;
        min-width: 40vh !important;
        max-width: unset !important;
    }

    .groupCheckboxContainer .checkBoxContainer {
        padding-left: 10px;
        margin: 15px 0;
    }

    .relatedSidebarApps img {
        max-width: 19px;
        margin-right: 0.7em !important;
    }

    .fixToBottom {
        padding-top: 5px;
        min-height: 31px;

        & > div {
            .col-4 {
                line-height: 25px;

                .mat-icon-button {
                    width: 30px;
                    height: 28px;
                    line-height: 28px;
                }
            }
        }

        button.mat-icon-button {
            icon {
                position: relative;
                bottom: 3px;
                height: 24px;
                display: inline-block;
            }

            &[aria-expanded='true'] {
                .material-icons {
                    color: $taxillaDarkGreen;
                }
            }

            .mat-button-wrapper {
                height: 24px;
                display: inline-block;
                position: relative;
                line-height: 28px;
            }

            .material-icons {
                font-size: 24px;
                width: 24px;
                height: 24px;
                color: $matIconGreyColor;
            }

            &:hover {
                .material-icons {
                    color: $taxillaDarkGreen;
                }
            }
        }
    }

    .relatedAppsMenu {
        padding: 10px;
        min-width: 230px !important;
        position: fixed;
        bottom: 58px;
        left: 5px;
        max-height: 500px !important;
        overflow: hidden !important;
        border-bottom: 3px solid $taxillaDarkGreen;

        .tenantIcon {
            mat-icon {
                font-size: 21px;
            }
        }

        .menuTitle {
            font-size: 16px;
            font-weight: 600;
        }

        .invoiceCollapseHeader {
            padding-left: 0px;
        }

        .mat-menu-content:not(:empty) {
            max-height: unset;
        }

        .searchTenantsDiv {
            .searchEntities {
                .searchEntitiesIcons {
                    font-size: 19px !important;
                }
            }
        }
    }

    .consoliDatepicker {
        .mat-form-field-appearance-outline {
            .mat-form-field-wrapper {
                margin: 0 !important;
                padding-bottom: 0 !important;
            }

            .mat-form-field-infix {
                padding: 0 !important;
                border-top: 0 !important;
            }

            .mat-form-field-outline {
                color: #ccc !important;
            }

            .mat-form-field-outline-start {
                border-radius: 0 !important;
            }

            .mat-form-field-outline-end {
                border-radius: 0 !important;
            }
        }

        .mat-form-field {
            background: $cancelButtonBgColor !important;
            min-height: unset;
        }
    }

    .searchFilterOption {
        position: relative;

        .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
            color: $taxillaDarkGreen;
        }

        .searchFilterActions {
            position: absolute;
            top: 12px;
            right: 10px;

            i {
                cursor: pointer;
                color: green;
                font-size: 20px;
            }

            span.editSaveFilter:hover {
                color: #333;
            }

            span.deleteSaveFilter:hover {
                color: $taxillaDarkGreen;
            }
        }
    }

    .position-absolute {
        position: absolute !important;
    }

    .mat-form-field {
        min-height: 69px;
    }

    eninvoice-paginator mat-paginator .mat-paginator-outer-container .mat-paginator-container .mat-paginator-page-size mat-form-field {
        min-height: 54px !important;
    }

    .transformationTableDiv {
        padding: 20px 20px;

        .mat-row:hover {
            background-color: transparent !important;
        }

        source-table material-table tr.mat-row {
            td.matTableCell.mat-cell {
                padding-top: 5px;
                overflow-x: unset !important;
                max-width: fit-content;
            }
        }
    }

    .assetToAssetInBridgeCls {
        padding: 10px 25px;
        font-size: 14px;
    }

    .tableFilesName {
        padding-left: 5px;
        max-width: 124px;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow-x: hidden;
        top: 6px;
        position: relative;
        display: inline-block;
    }

    &.fileDragDetected .fileBrowseContainer {
        display: none;
        opacity: 1;
        color: #212529;
    }

    &.fileDragDetected .modal-dialog,
    &.fileDragDetected .modal-content {
        border: none;
    }

    &.fileDragDetected .fileDropZoneContainer {
        display: block;
        position: relative;
        top: 72%;
        left: 0%;
        transform: scale(1.5);
        padding-left: 14px;
        opacity: 0.5;
        margin: 10px 30px 10px 0px;
    }

    &.fileDragDetected .fileDropZoneContainer .fileDropZoneText {
        color: #212529;
        padding: 0px 28px;
        border: 1px dashed green;
        border-radius: 4px;
        text-align: center;
        line-height: 36px;
    }

    &.fileDragDetected .fileDropZoneContainer .fileDropZoneText:hover,
    &.fileDragDetected .fileDropZoneContainer .fileDropZoneText .fileHovered {
        -webkit-animation: border-dance 4s infinite linear;
        animation: border-dance 4s infinite linear;
        height: 36px;
        width: 138px;
        background: linear-gradient(90deg, $taxillaDarkGreen 50%, transparent 50%),
            linear-gradient(90deg, $taxillaDarkGreen 50%, transparent 50%), linear-gradient(0deg, $taxillaDarkGreen 50%, transparent 50%),
            linear-gradient(0deg, $taxillaDarkGreen 50%, transparent 50%);
        background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
        background-size: 12px 2px, 12px 2px, 2px 12px, 2px 12px;
        background-position: 0px 0px, 140px 38px, 0px 38px, 140px 0px;
        line-height: 36px;
        border: 0;
    }

    &.fileDragDetected #processView .processViewContainer .entitiesListColumn {
        -webkit-box-shadow: none;
        box-shadow: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    }

    &.fileDragDetected .file.mat-card {
        -webkit-box-shadow: none;
        box-shadow: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    }

    &.fileDragDetected .instanceBreadcrumb .packageDetailBreadcrumbItem .packageDetailBreadcrumbText {
        opacity: 0.4;
    }

    &.fileDragDetected .latestStatusMessage {
        opacity: 0.4;
    }

    &.fileDragDetected mat-icon.mat-icon {
        opacity: 0.4;
    }

    &.fileDragDetected button .mat-button,
    &.fileDragDetected button .mat-icon-button,
    &.fileDragDetected button .mat-button-base {
        opacity: 0.5;
    }

    &.fileDragDetected img {
        opacity: 0.4;
    }

    &.fileDragDetected .mat-form-field .mat-form-field-infix {
        border-color: transparent;
    }

    &.fileDragDetected {
        color: rgba(0, 0, 0, 0.4);
        border-color: rgba(0, 0, 0, 0.4);
    }

    .fileDropZoneContainer {
        display: none;
    }

    #headerContainer {
        width: 100%;
    }

    #routerContainer {
        min-height: 37rem;
        height: calc(100% - 50px);
        width: 100%;
    }

    #footerContainer {
        width: max-content;
    }

    .selectAssetPromptContainer {
        width: 100%;
        height: calc(100vh - (55px + 44px));
    }

    .navbar-nav {
        display: -webkit-box;
    }

    .processDetailsColumn {
        width: 70%;
        float: left;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 70%;
        flex: 0 0 70%;
        max-width: 70%;
    }

    ewb-detailheader {
        width: 100%;
    }

    .mat-content {
        overflow: visible;
    }

    .mat-raised-button[disabled] {
        cursor: default;
        background-color: rgba(0, 0, 0, 0.12) !important;
    }

    .arrayView {
        width: 100%;
    }

    .groupedFields {
        width: 100%;
    }

    .recordViewContainer {
        width: 100%;
    }

    mat-accordion {
        width: 100%;
    }

    asset-recordfields {
        width: 100%;
    }

    .modalCloseIcon {
        font-size: 26px;
    }

    .mainSearch span.material-icons.refreshIcon {
        width: 23px;
    }
    // this query can be used for targetting the (150 - 175)% zoom size, which is a very lower resolutions
    @media (min-width: 1024px) and (max-width: 1380px) {
        .mainSearch span.material-icons.refreshIcon {
            z-index: 1;
        }
    }

    .mainSearchPrefix {
        width: 25px;
    }

    .hide {
        display: none;
    }

    .requestReviewDataContainer .row {
        margin-left: 0;
        margin-right: 0;
    }

    .dateInputField {
        min-height: unset !important;
    }

    .material-icons-outlined {
        color: grey;
    }

    .fieldDescInfoIcon {
        font-size: 20px;
    }

    .downloadreportsModalDialog {
        width: 70% !important;

        .processTable {
            padding-bottom: 10px;
        }
    }

    .accordian_body_text {
        color: $taxillaDarkGreen;
        text-decoration: underline;
        cursor: pointer;
        max-width: 150px;
        overflow: hidden;
        white-space: nowrap;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
    }

    .filterDateLogs {
        position: relative;
        left: 5px;

        .searchRequestLogs {
            margin: -20px 16px;

            .filterLabelName {
                font-size: 15px;
            }

            .searchLogsFilter {
                padding: 2px 13px;
            }
        }

        .viewReportLogs {
            position: relative;

            .viewReportsButton {
                margin: 8px !important;
            }

            .refreshLogs {
                box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
            }
        }
    }

    .viewReportsButton {
        margin: 0 10px !important;
    }

    #viewReportBtn {
        button {
            background-color: #5c5c5c;
            color: #ffffff;
            top: 4px;
        }
    }

    .mat-card:not([class*='mat-elevation-z']) {
        -webkit-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
        box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    }

    .downloadReportModal,
    .downloadErrorReportModal {
        width: 60%;
    }

    @media (min-width: 576px) and (max-width: 768px) {
        .modal-dialog {
            max-width: 675px;
            margin: 1.75rem auto;
        }
    }

    .dateRange {
        .places {
            width: 100%;
        }
    }

    .modal-header .btn-link {
        cursor: pointer;
    }

    .dashboardNewReport.btn-link {
        .pull-right.d-flex {
            line-height: 36px;
            /* margin-right: 17px; */
        }
    }

    .modal-header .close {
        padding: 10px;
    }

    .successPanelClass {
        background: $taxillaDarkGreen;
        color: $cancelButtonBgColor;
        text-align: center !important;
    }

    .failurePanelClass {
        background: #e6140eb8;
        color: $cancelButtonBgColor;
        text-align: center !important;
    }

    .warningPanelClass {
        background: $taxillaOrangeColor;
        color: $cancelButtonBgColor;
    }

    .successPanelClass .mat-simple-snackbar,
    .failurePanelClass .mat-simple-snackbar,
    .notifyPanelClass .mat-simple-snackbar,
    .warningPanelClass .mat-simple-snackbar {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .word-break-all {
        word-break: break-word !important;
    }

    .mat-simple-snackbar-action {
        color: unset !important;
    }

    .mat-simple-snackbar-action button {
        padding-left: 0 !important;
    }

    .consolidatedEwbModal > div {
        width: 100%;
        margin-top: 0;
    }

    app-fieldsdisplay {
        width: 100%;
    }

    .primaryEntityDataView {
        width: 100%;
    }

    consolidated-detailheader {
        width: 100%;
    }

    .modalTitleLabel {
        font-weight: 600;
    }

    .cancelIcon {
        color: $matIconGreyColor;
        cursor: pointer;
    }

    .closeBtnIcon {
        position: relative;
        top: 7px;
    }

    .masterCloseBtnIcon {
        position: relative;
        top: 8px;
    }

    .enInvoiceReportsCloseIcon {
        position: relative;
        top: 10px !important;
        height: 21px;
        margin: 0 10px;
        cursor: pointer;

        &:last-child {
            margin-right: 0;
        }
    }

    .cdk-overlay-pane .mat-menu-panel {
        min-width: 220px;
    }

    .groupsMenuContainer .mat-optgroup .mat-option:not(.mat-option-multiple),
    .groupsMenuContainer .mat-optgroup .mat-option {
        padding: 0;
        line-height: 36px;
        height: 36px;
        font-size: 14px;
    }

    .mat-snack-bar-container {
        max-width: 50vw !important;
        margin: 40px !important;
    }

    .reportTitleText {
        max-width: 175px;
        overflow-x: hidden;
        white-space: nowrap;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
    }

    .tooltipContainer {
        word-break: break-word;
    }

    .changeLogsModalDialog {
        width: 70%;
    }

    .mat-autocomplete-panel .matTableMessages {
        text-align: left !important;
    }

    .snackBarMainDiv .actionText {
        margin-left: 5px;
    }

    .snackCloseBtnIcon {
        top: 3px;
        margin-left: 10px;
        font-size: 20px !important;
    }

    mat-expansion-panel-header .mat-content,
    mat-expansion-panel.mat-expansion-panel {
        overflow: unset;
    }

    .errorPanelClass {
        background: #f8d7da;
        color: #721c24;
        text-align: center !important;
    }

    mat-expansion-panel .mat-expansion-panel-content {
        overflow: hidden;
    }

    .fontSizeCls {
        font-size: 14px !important;
    }

    .fontSizeClsMx {
        font-size: 24px !important;
    }

    .refreshMatIcon {
        font-size: 18px !important;
        vertical-align: -webkit-baseline-middle !important;
    }

    .closeMatIcon {
        font-size: 14px !important;
        vertical-align: -webkit-baseline-middle !important;
        font-weight: bold;
    }

    .addMaterialIcon {
        vertical-align: middle !important;
        font-size: 20px !important;
        font-weight: bold !important;
    }

    .snackbarIcon {
        font-size: 12px !important;
        font-weight: bold !important;
    }

    .pull-left {
        float: left;
    }

    .ps__thumb-y {
        background-color: #827f7f !important;
        width: 8px !important;
    }

    .ps__rail-y {
        width: 13px !important;
    }

    .pullRight {
        float: right;
    }

    .consolidateDate {
        .mat-form-field-suffix {
            display: flex !important;

            .mat-icon-button {
                line-height: 15px !important;
            }
        }
    }

    .requiredCls {
        top: calc(100% - 1em) !important;
        line-height: 15px;
        border-top: 1px solid;
    }

    .booleanFieldContainer {
        line-height: 48.25px;
        position: relative;

        .booleanLabel {
            padding-right: 0;
            padding-left: 0;
        }

        .booleanValues {
            padding: 0;
            display: flex;

            .mat-radio-group {
                display: flex;
            }

            .mat-radio-button {
                flex: auto;
                margin: 0 5px 0 0;
            }
        }
    }

    .userNameContainer .col-12 {
        padding: 0;
    }

    .confirmationDialog material-button.mx-2 {
        margin: 0.25rem 0rem !important;
    }

    .requestStatusDiaog {
        width: 40%;

        .requestStatusBody {
            padding: 0 25px;

            .requestStatusContainer {
                margin: 25px 0;

                .requestStatus {
                    // text-align: center;
                    font-weight: 600;
                }
            }
        }
    }

    .selectedMasterNameCls {
        display: inline-block;
        position: relative;
        top: 0px;
    }

    .allMastersDropdownContainer {
        float: right;
        position: relative;
        top: -10px;
        right: 5px;
    }

    .table-icon-button {
        height: 24px;
        line-height: 20px;
        width: 24px;
        color: #5c5c5c;
    }

    #dashboardPageContainer {
        .manageWidthToggleContainer {
            .material-icons {
                padding: 0.5em;
            }
        }
    }

    .alertButtonCls {
        line-height: 10px;
        margin-right: 10px;
        cursor: pointer;
    }

    .bulkActionSummaryModal.matDialogContainer.reportsModalBox {
        width: 80vw;
    }

    .multiRecordEditModal.matDialogContainer {
        width: 90vw;
        max-width: 90vw !important;

        &.singleColumnUpdate {
            width: 60vw;
            max-width: 60vw !important;
        }
    }
}

.enInvoiceMainContainer {
    .manageSectionHeader {
        &.appContainer {
            padding-left: 7px;
            border-left: 5px solid #f6f6f6;

            &.selectedAsset {
                border-left-color: $taxillaDarkGreen;
            }
        }
    }
}

.eninvoiceNewTableContainer table.mat-table tbody tr.mat-row td.matTableCell.mat-cell.mat-column-table-actions {
    max-width: 230px;
}

.eninvoiceNewTableContainer table.mat-table tbody tr.mat-row td.matTableCell.mat-cell {
    max-width: 170px;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-left: 10px;
    padding-right: 10px;
    text-align: left;

    .eninvoiceAccordion {
        .mat-expansion-panel-header {
            .mat-content {
                display: block;
                width: 100%;

                .mat-expansion-panel-header-title {
                    display: inline-block;
                    width: 50%;
                    vertical-align: bottom;

                    .materialAccordionHeader {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: inline-block;
                        width: 100%;
                    }
                }

                .mat-expansion-panel-header-description {
                    display: inline-block;
                    width: calc(50% - 32px);
                }
            }
        }
    }
}

.eninvoiceNewTableContainer .disabled {
    cursor: default;
}

.eninvoiceNewTableContainer table.mat-table tbody tr.mat-row.disabled td.matTableCell.mat-cell {
    color: grey;
}

.eninvoiceNewTableContainer .actionButtons {
    line-height: 25px;
    width: 25px;
    height: 25px;
}

.eninvoiceNewTableContainer .actionButtons .matTableActionIcon {
    font-size: 18px;
    vertical-align: top;
}

.pull-right {
    float: right;
}

.auditTrailTable {
    width: 90% !important;

    .matDialogBody {
        height: 460px;
        max-height: 460px;
        overflow: auto;
    }
}

.auditTrailHeaderActionContainer {
    display: block;
    position: absolute;
    right: 0;
    top: 3px;

    .material-icons {
        margin-right: 0px !important;
    }
}

.auditListIconClass {
    position: relative;
    top: 2px;
    color: #868686;
}

.auditFormFieldsDialog {
    width: 70%;

    .fieldInReadOnly {
        height: 36px;
        width: 100%;

        .readOnlyFieldValues {
            display: flex;
            margin: 5px 0;

            .readOnlyLabel {
                color: #9c9c9c;
                flex: 0 0 40%;
                max-width: 40%;
                width: 40%;
                font-size: inherit;
                text-align: right;
                line-height: 24px;
                padding-right: 20px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            .readOnlyValue {
                font-size: 13px;
                line-height: 24px;
                white-space: nowrap;
                overflow-x: hidden;
                text-overflow: ellipsis;
                flex: 0 0 60%;
                max-width: 60%;
                width: 60%;
                padding-right: 15px;
            }
        }
    }
}

.zeroPaddingLeft {
    padding-left: 0px;
}

.zeroPaddingRight {
    padding-right: 0px;
}

.inAuditModeCls {
    background: #c9e6cb;
}

.noRecords {
    padding: 54px;
}

.searchFilterOption {
    .mat-option-text {
        max-width: 183px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow-x: hidden;
        display: inline-block;
    }
}

.enInvoiceMainContainer .changepassword .close.noOutline {
    position: absolute;
    top: 5px;
    right: 10px;
}

.enInvoiceMainContainer .editProfileModal.modal-dialog {
    max-width: 900px;
    margin: 1.75rem auto;
}

.filingAttributeRadioBtnCls {
    margin-left: 5px;
}

#reportsTemplateBodyContainer {
    padding: 1px 15px;
    margin: 14px 0 10px;
    position: relative;
    height: 100%;

    .mat-expansion-panel-header {
        padding: 0 10px;
        height: 50px !important;
        max-height: 50px;
        box-shadow: 0px 1px 4px #b1b1b1;

        &.mat-expanded {
            font-weight: 600;
            background: rgba(0, 0, 0, 0.02);
        }
    }

    .materialAccordionBody {
        .reportOrganizationContainer {
            .reportChainContainer {
                margin: 10px 0 25px;

                .reportChainLabel {
                    display: inline-block;
                }

                .reportChainNameContainer {
                    font-weight: bold;
                    line-height: 25px;
                    padding: 10px;
                    background: $bodyBgColor;
                    position: relative;

                    .reportRegenerateIcon {
                        background: $submitButtonColor;
                        color: #fff;
                        width: 94px;
                        text-align: center;
                        border-radius: 4px;
                        line-height: 33px;
                        height: 32px;
                        font-size: 11px;
                        position: absolute;
                        right: 5px;
                        top: 6px;
                        cursor: pointer;

                        mat-icon {
                            vertical-align: middle;
                            color: #fff;
                        }
                    }
                }

                .reportTransformationsContainer {
                    position: relative;
                    display: block;
                    white-space: normal;
                    word-break: break-all;
                    overflow: auto;
                    margin-top: 5px;

                    .reportTransformationContainer {
                        width: calc(100% - 10px);
                        padding: 10px 0 10px 10px;
                        position: relative;
                        float: left;
                        background: #eeffee;
                        margin: 5px;

                        .reportTransformationNameContainer {
                            padding-bottom: 5px;
                            max-width: 100%;
                            overflow-x: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;

                            .reportTransformationNameLabel {
                                display: inline-block;
                                margin-right: 10px;
                            }
                        }

                        .transformationReportMetaDataListContainer {
                            padding-left: 0%;
                            padding-top: 5px;

                            .reportTransformationNameLabel {
                                display: inline-block;
                                margin-right: 10px;
                            }

                            .reportsListContainer {
                                margin-left: 0px;

                                .reportDataListContainer {
                                    .reportsContainer {
                                        width: 100%;
                                        display: flex;
                                        flex-flow: wrap;
                                    }

                                    .reportDataContainer {
                                        width: 100%;
                                        margin: 10px 0;
                                        display: block;

                                        .reportFileIconContainer {
                                            height: 24px;
                                            display: inline-block;
                                            width: 24px;
                                            line-height: 22px;
                                            margin-right: 5px;
                                            vertical-align: middle;

                                            .mat-icon {
                                                height: 24px;
                                                width: 24px;
                                                font-size: 20px;
                                            }

                                            span.fa {
                                                font-size: 16px;
                                                width: 24px;
                                                text-align: center;
                                            }
                                        }

                                        .reportNameContainer {
                                            width: calc(100% - 87px);
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            white-space: nowrap;
                                            height: 24px;
                                            vertical-align: middle;
                                            display: inline-block;
                                        }

                                        .reportRegenerateIcon,
                                        .reportPrintContainer {
                                            height: 24px;
                                            vertical-align: middle;
                                            display: inline-block;
                                            width: 24px;
                                            line-height: 30px;
                                            margin-right: 5px;

                                            mat-icon {
                                                cursor: pointer;
                                            }
                                        }

                                        .reportDownloadLinkContainer {
                                            height: 24px;
                                            vertical-align: middle;
                                            display: inline-block;
                                            width: 24px;
                                            line-height: 30px;
                                            margin-right: 5px;
                                        }

                                        .reportDownloadLinkContainer.notGenerated {
                                            color: #b93535;
                                            line-height: 24px;
                                            width: auto;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .noReportsFound {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }
}

.isAuditSelected {
    color: #00a651 !important;
}

.checkBoxSpanCls {
    padding-left: 5px;
    position: relative;
    top: 1px;
}

.bulkReportSearchSpan {
    padding-left: 5px;

    .bulkinfoIcon {
        cursor: pointer;
        font-size: 20px;
        position: relative;
        top: 6px;
    }
}

.mat-slide-toggle.mat-primary.mat-checked {
    .mat-slide-toggle-thumb {
        background-color: $taxillaDarkGreen !important;
    }

    .mat-slide-toggle-bar {
        background-color: #00a65154 !important;
    }
}

.bulkReportCheckBoxCls {
    cursor: pointer;
}

.breadCrumbAppText {
    color: #00a651;
}

.breadCrumbAppIcon {
    position: relative;
    top: 12px;
    left: 3px;
}

.downloadBulkReportInfoCls {
    padding-left: 15px;
}

.downloadBulkReportModal {
    width: 40%;
}

.searchResultsSection:hover + #footer {
    display: none;
}

@media only screen and (min-width: 1056px) and (max-width: 1366px) {
    .bulkActionButton {
        margin: -21px;
    }

    .processDataAction button.bg-success {
        width: 78px;
        font-size: 11px;
    }

    .mainSearchSuffix {
        margin: auto 12px !important;
    }
}

#mastersModal {
    .relatedMasterTitle {
        padding-left: 5px;
        font-size: 18px;
        line-height: 36px;
    }

    .modal-header {
        padding: 5px;
    }

    .closeButnMaster {
        line-height: 36px;
    }
}

.instanceReportsActionContainer {
    .instanceReportActionButton {
        padding: 0px 3px 0px 10px;
        width: inherit;
        border: 1px solid #00a651;
        background: #00a651;
        color: white !important;
        margin-left: 5px;
        line-height: 27px !important;
        margin-right: 5px;
        box-shadow: none !important;
        border-radius: 0;
        height: 33px;
    }

    .reportsActivatedCls {
        color: white !important;
        position: relative;
        top: -1px;
    }

    .processReportIcon {
        color: white !important;
        position: relative;
        left: -2px;
    }
}

.instanceIconReportCls {
    font-size: 16px !important;
    width: 16px !important;
    height: 16px !important;
    margin: 0px !important;
}

.instanceIconReportCls:hover {
    color: #00a651 !important;
}

.instanceReportsMenu,
.reportsMenuGroup {
    width: 320px !important;
    min-width: 320px !important;

    &.uploadedFilesMenu {
        width: 100% !important;
        max-width: unset;
        padding: 5px;

        table {
            border-collapse: collapse;
            width: 100%;
        }

        td,
        th {
            border: 1px solid #dddddd;
            text-align: left;
            padding: 8px;
        }

        tr:nth-child(even) {
            background-color: #dddddd;
        }

        td {
            max-width: 150px;
            padding: 5px;
            overflow-x: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: center;

            a {
                cursor: pointer;
            }

            img {
                max-width: 24px;
                cursor: pointer;
            }
        }
    }
}

.instanceReportActionMainDiv {
    position: relative;
    top: -2px;
}

.noReportActionCls {
    position: relative;
    top: 3px;
    color: #bbb5b5;
}

.centerDiv {
    text-align: center;
    padding: 90px;
}

.transformationChainsDialog {
    width: 40%;
}

.consolidatedewbModals {
    width: 60%;
}

.timePickerContainer {
    width: 50%;
}

.workflowTextarea {
    textarea {
        height: 20px !important;
    }
}

.collaboratorsListDialog {
    width: 60%;
}

.timepicker-overlay {
    z-index: 10000000 !important;
}

.timepicker-backdrop-overlay {
    z-index: 10000000 !important;
}

.dowmtimeMessage {
    margin: 0px 0.4em 0.7em 0.7em;
}

.selectedAssetDataCntr {
    app-newrequest {
        .dowmtimeMessage {
            display: none;
        }
    }
}

.consolidatedFeedMainDiv {
    width: 100% !important;
    margin: 7px 0px;
}

.noMasterRecordDiv {
    height: 360px;
}

.recordDataEntityName {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 35%;
    flex: auto;
    display: inline-block;
    line-height: 28px;
    position: relative;
    padding-left: 5px;
    font-size: 15px;
    font-weight: 600;
}

.listCollboratorsBtn {
    position: relative;
    top: 3px;

    span.material-icons {
        font-size: 24px !important;
    }
}

.dateFilterSearchCls {
    margin-right: 0px !important;
    width: auto;
}

.noReports {
    text-align: center;
}

.primaryMessagesPopup {
    text-align: right;
    padding-bottom: 10px;
    margin-right: 10px;
}

.gridTable {
    .gridBooleanvalues {
        margin-right: 12px;
        line-height: 22px !important;
    }
}

.q-row {
    mat-form-field {
        width: auto;
        margin-right: 1em;
    }
}

.notifyPanelClass {
    background: #f9eacf;
    color: black;
    text-align: center !important;
}

/* Reconciliation new process page CSS */
.new-recon-process {
    margin: 0 !important;
    background-color: #fff;
    color: #000;
    font-family: $fontFamily;

    .process-header {
        padding: 18px 15px 0px 15px;
        font-size: 18px;
        font-weight: bold;
        color: #000;
    }

    .process-body {
        .process-steps {
            width: 100%;
            padding: 0 15px;
            margin-top: 30px;

            .process-step {
                width: 100%;
                display: flex;
                margin-top: 10px;

                .step-left-content {
                    width: 17%;
                    margin-left: 15px;

                    .name {
                        font-size: 16px;
                        font-weight: 600;
                        color: #000;
                    }

                    .info-icon {
                        font-size: 20px !important;
                        position: relative;
                        top: 4px;
                        margin-left: 7px;
                    }
                }

                .step-right-content {
                    width: 83%;
                    color: #707070;
                    margin-left: -20px;

                    .matches-container {
                        display: flex;

                        .match {
                            padding-right: 20px;
                            margin-bottom: 15px;

                            .mat-checkbox {
                                width: 100%;

                                label {
                                    width: inherit;

                                    .mat-checkbox-label {
                                        width: 99em;
                                        color: #707070;
                                        font-family: $fontFamily;
                                        font-weight: bold;
                                        font-size: 13px !important;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                    }
                                }
                            }
                        }
                    }

                    .sources-container {
                        margin-left: 15px;

                        mat-tab-group {
                            font-family: $fontFamily;

                            .mat-tab-header {
                                border-bottom: none;

                                .mat-tab-label {
                                    color: #000;
                                    font-family: $fontFamily;
                                    font-size: 13px;
                                    font-weight: bold;
                                    margin-right: 15px;
                                    border: 1px solid $lightGreyColor;
                                    border-bottom: hidden;
                                    border-top-left-radius: 4px;
                                    border-top-right-radius: 4px;

                                    &.mat-tab-label-active {
                                        background-color: $taxillaDarkGreen !important;
                                        border: 1px solid $taxillaDarkGreen;
                                        color: #fff !important;
                                    }
                                }
                            }

                            .mat-tab-body {
                                border: 1px solid #d8d8d8;
                                border-top-color: #00a651;
                                padding: 10px;

                                .mat-tab-body-content {
                                    .header {
                                        padding: 15px 0px;
                                        color: #707070;
                                        font-family: $fontFamily;
                                        font-size: 13px;
                                        font-weight: bold;
                                    }

                                    .source-transformations {
                                        width: 100%;
                                        display: flex;

                                        .transformations {
                                            mat-form-field.mat-form-field-appearance-outline {
                                                .mat-form-field-outline-start,
                                                .mat-form-field-outline-end {
                                                    border-radius: 0 !important;
                                                }

                                                .mat-form-field-infix {
                                                    mat-select {
                                                        font-family: $fontFamily;
                                                        font-size: 13px;
                                                        font-weight: bold;
                                                        color: #707070 !important;

                                                        .mat-select-placeholder {
                                                            color: #707070;
                                                        }

                                                        .mat-select-arrow {
                                                            border-left: 5px solid transparent;
                                                            border-right: 5px solid transparent;
                                                            border-top: 9px solid;
                                                            margin-top: 5px;
                                                            color: $taxillaDarkGreen;
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        .src-details {
                                            width: 100%;
                                            display: flex;
                                            margin-bottom: 5px;
                                            bottom: 15px;
                                            position: relative;

                                            .file-upload {
                                                text-align: center;
                                                padding-left: 10px;

                                                .invoiceUploadButton {
                                                    padding: 9px 8px;
                                                    background: $taxillaDarkGreen;
                                                    color: #fff;
                                                    border: 1px solid #00a651;
                                                    box-shadow: none !important;
                                                    font-size: 13px;
                                                    font-weight: bold;
                                                    font-family: $fontFamily;
                                                    width: max-content;
                                                }

                                                &.file-uploaded {
                                                    margin-top: 25px;

                                                    .invoiceUploadButton {
                                                        background: #fff;
                                                        color: #909090;
                                                        border: solid 1px #cfcfcf;
                                                    }
                                                }
                                            }

                                            .file-name {
                                                color: #000;
                                                font-size: 13px;
                                                font-weight: bold;
                                                font-family: $fontFamily;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                                white-space: nowrap;
                                                margin-top: 35px;
                                                margin-left: 30px;
                                                margin-right: auto;
                                            }

                                            .src-info {
                                                padding: 10px 15px;
                                                background-color: #fff7db;
                                                border-radius: 4px;
                                                color: #707070;
                                                font-size: 13px;
                                                font-weight: bold;
                                                font-family: $fontFamily;
                                                margin-left: auto;

                                                p {
                                                    margin-bottom: 5px !important;

                                                    &.elip-txt {
                                                        overflow: hidden;
                                                        text-overflow: ellipsis;
                                                        white-space: nowrap;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .source-filters {
                                        .header {
                                            display: flex;
                                            justify-content: space-between;
                                            padding-bottom: 0;

                                            .search {
                                                font-size: 14px;
                                                font-weight: bold;
                                                font-family: $fontFamily;
                                                color: #2b2b2b;
                                            }

                                            .check-tenants {
                                                font-size: 13px;
                                                font-family: $fontFamily;
                                                color: #000;
                                            }
                                        }

                                        mat-tab-group {
                                            .mat-tab-header {
                                                border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;

                                                .mat-tab-label {
                                                    font-size: 15px;
                                                    margin-right: 0 !important;
                                                    border: none !important;

                                                    &.mat-tab-label-active {
                                                        background-color: #fff !important;
                                                        color: #000 !important;
                                                    }
                                                }
                                            }

                                            .mat-tab-body {
                                                border: none;
                                                padding: 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .process-buttons {
            display: flex;
            justify-content: flex-end;
            margin-top: 20px;
            margin-right: 30px;

            material-button {
                margin-bottom: 20px;

                button {
                    height: 40px !important;
                    box-shadow: none !important;

                    .mat-button-wrapper {
                        font-weight: bold;
                        font-family: $fontFamily;
                    }
                }

                &.cancel-btn button {
                    border: 1px solid #d8d8d8 !important;

                    .mat-button-wrapper {
                        color: $significantRegFontColor !important;
                    }
                }
            }
        }
    }
}

.custom-mat-check-box {
    display: block;
    width: 98%;
    .mat-checkbox {
        width: inherit;
        .mat-checkbox-layout {
            width: inherit;
            .mat-checkbox-label {
                display: block;
                width: inherit;
            }
        }
    }
}

.textEllipsis {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
}

.newUiRelatedAppsMenu {
    padding: 10px;
    min-width: 230px !important;
    left: 5px;
    max-height: 500px !important;
    overflow: hidden !important;
    border-bottom: 3px solid $taxillaDarkGreen;

    .tenantIcon {
        mat-icon {
            font-size: 21px;
        }
    }

    .menuTitle {
        font-size: 16px;
        font-weight: 600;
    }

    .invoiceCollapseHeader {
        padding-left: 0px;
    }

    .mat-menu-content:not(:empty) {
        max-height: unset;
    }

    .searchTenantsDiv {
        .searchEntities {
            .searchEntitiesIcons {
                font-size: 19px !important;
            }
        }
    }
}
