.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}
.col-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.3333333333%;
    -ms-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
}
.col-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.6666666667%;
    -ms-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
}
.col-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}
.col-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.3333333333%;
    -ms-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
}
.col-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.6666666667%;
    -ms-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
}
.col-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}
.col-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.3333333333%;
    -ms-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
}
.col-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.6666666667%;
    -ms-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
}
.col-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
}
.col-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.3333333333%;
    -ms-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
}
.col-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.6666666667%;
    -ms-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
}
.col-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}
