/* flex classes for faster production */
@import './grid.utilities.scss';
@import './spacing.utilities.scss';
@import './position.utilities.scss';

.d-block {
    display: block;
}

.d-grid {
    display: grid;
}

.d-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-content {
    display: contents !important;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.w-100 {
    width: 100% !important;
}

.w-25 {
    width: 25% !important;
}

.w-75 {
    width: 75% !important;
}

.w-50 {
    width: 50% !important;
}

.grid-col-2 {
    grid-template-columns: 1fr 1fr;
}

.text-center {
    text-align: center !important;
}

.text-success {
    color: rgb(97, 217, 124) !important;
}

.tab-content > .tab-pane {
    display: none;
}

.tab-content > .tab-pane.active {
    display: block;
}

.word-break {
    word-break: break-all !important;
}
