@import '~perfect-scrollbar/css/perfect-scrollbar.css';

$lineColor: #eaeaea;
$taxillaDarkGreen: #00a651;
$taxillaRedColor: #d81844;
$taxillaOrangeColor: #af7813;
$taxillaLightGreen: #a4d5a7;
$significantRegFontColor: #363636;
$submitButtonColor: $taxillaDarkGreen;
$headerItemBorderColor: #f7f7f7;
$curtainBoxShadowColor: #c1c2c1;
$headerHeight: 44px;
$footerHeight: 32px;
$settingsPageBackgroundColor: #f0f0f0;
$placeholderHintColor: #565656;
$fontFieldHindColor: rgba(0, 0, 0, 0.4);
$cancelButtonBgColor: #fff;
$newTaxillaLighterGreen: #ddfede;
$lightGreyColor: #a2a2a2;
$lightRedColor: rgba(255, 0, 0, 0.5);
$hoverRedColor: rgba(255, 0, 0, 0.7);
$matIconGreyColor: #616161;
$oddItemBgColor: $headerItemBorderColor;
$evenItemBgColor: #f7f9ff; //Little Bluish
$sectionSeperator: #f4fef9; //A little more bluish
$inProgressColor: #6e6eff; //Medium Bluish
$canvasGridColor: #e3e3e3;
$fontFamily: Lato, San Francisco, Proxima Nova, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Ubuntu, Droid Sans, Helvetica Neue,
    Open Sans, sans-serif;

.enCollabMainContainer {
    mat-drawer-container {
        mat-drawer {
            overflow: auto;
            overflow: unset;
            border-left: none;
            height: -webkit-fit-content;
            height: -moz-fit-content;
            height: fit-content;
            width: 100%;
            background: transparent;

            .mat-drawer-inner-container {
                overflow: auto;
                overflow: unset;
                background: $cancelButtonBgColor;
            }

            .mat-drawer-content {
                background: transparent;
                height: -webkit-fill-available;
                min-height: inherit;
            }
        }

        .mat-drawer-content {
            .mat-drawer-container {
                background: transparent;
            }

            .mat-drawer-content {
                background: transparent;
                width: 100%;
            }
        }
    }

    .workflowContainer {
        width: 100%;
        height: 100%;
        background: #ffffff;
        -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;

        .row {
            width: 100%;
            height: 100%;
            overflow-y: hidden;
        }

        .innerContent {
            padding: 15px 30px;
        }

        .innerselectContent {
            padding: 15px 15px !important;
        }

        .workflowSaveButton {
            padding: 0 20px 40px;
            text-align: right;
        }
    }

    .accessApiRowContainer {
        .addIconDiv {
            text-align: right;
            cursor: pointer;
        }

        .removeIconDiv {
            align-self: flex-end;
            cursor: pointer;
        }
    }

    .ps__rail-y {
        z-index: 1000;
    }

    .ps__thumb-y {
        background-color: #827f7f !important;
        width: 8px !important;
    }

    .addIconColor {
        color: $taxillaDarkGreen !important;
    }

    .removeIconColor {
        color: $taxillaRedColor !important;
    }

    .q-row {
        border: unset !important;
        margin-bottom: 7px;
    }

    .q-row:nth-child(odd) {
        background: #f7f7f7 !important;
        border-radius: 7px;
    }

    // lib-app-collaboration {

    //     .row {
    //         margin-left: -15px;
    //         margin-right: -15px;
    //     }
    // }

    .encollabAppCardContainer {
        height: 100%;
        .mat-card {
            padding: 0px !important;
            height: 100%;

            .encollabAppCard {
                height: 100%;
                .encollabHeaderContainer {
                    background: #b4e4fe;
                    padding: 5px 10px;
                    border-bottom: 1px solid #fff;
                    font-weight: bold;

                    .headerActionItem {
                        padding-right: 10px;
                    }
                }

                .encollabActionsContainer {
                    background: #d2efff;
                }

                .encollabAppBodyContainer {
                    position: relative;
                    height: calc(100% - 47px - 38px - 1px);
                    overflow: auto;

                    .itemContainer {
                        padding: 5px;

                        .fieldDetails {
                            padding: 5px;
                            // text-align: center;

                            .valueCls {
                                font-weight: bold;
                                margin-left: 5px;
                            }
                        }

                        .detailsHeader {
                            padding: 10px;
                            // text-align: center;
                            font-weight: bold;
                        }
                    }
                }

                .iconContainer {
                    line-height: 10px;
                    background: none !important;
                    cursor: pointer;
                }
            }
        }
        button.appCardEndActions {
            background: #fff;
            border-radius: 5px;
            width: 100%;
            min-height: 200px;

            mat-icon {
                width: 100%;
                height: 100%;
                line-height: 200px;
                font-size: 100px;
                color: #616161;

                &:hover {
                    color: #fff;
                }
            }

            &:hover {
                background: #d1d1d1;
            }
        }
    }

    // Header-section component

    .appLogo {
        border-radius: 100%;
    }

    .actionMenuPanel {
        max-height: 300px !important;
    }

    .ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .selectedChart {
        color: $taxillaDarkGreen;
        background-color: #fff;
        border-left: 5px solid #00a651;

        .mat-icon {
            color: $taxillaDarkGreen !important;
        }
    }

    .locationCountCls {
        float: right;
        font-weight: bold;
    }

    .widgetLoader {
        height: 100%;
        width: 100%;
        z-index: 100;
        background-color: rgba(88, 255, 169, 0.19);
    }

    .loader {
        position: absolute;
        left: 50%;
        top: 50%;
        opacity: 1;
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
    }
}
