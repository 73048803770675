@import '@fontsource/poppins';
@import '@angular/material/theming';
@import '/node_modules/material-symbols';

$fontFamily: Poppins, sans-serif;
$custom-typography: mat-typography-config(
    $font-family: $fontFamily,
);

// @include angular-material-typography($custom-typography);
$green: #42a147;

.gstFilingMainContainer {
    // font-family: $fontFamily !important;

    .mat-form-field-appearance-standard .mat-form-field-flex {
        padding-top: 0;
    }

    .height100 {
        height: 100%;
    }

    &.onNewUI {
        .routerOutlet {
            &:before {
                content: none;
            }
        }
    }

    .routerOutlet {
        height: calc(100% - 45px);
        position: relative;

        &:before {
            content: ' ';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0.5;
            background-image: url(/assets/images/grid.png);
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    .mat-menu-panel {
        border-radius: 0;

        &.mat-menu-below {
            border-top: 3px solid $green;
        }

        &.mat-menu-above {
            border-bottom: 3px solid $green;
        }

        &.fulWidthMatMenu {
            &.mat-menu-below,
            &.mat-menu-above {
                border-bottom: 0;
                border-top: 0;
            }
        }
    }

    .filingReportsMenu {
        .reportNameContainer {
            display: inline-block;
            vertical-align: middle;
            width: calc(100% - 48px - 15px - 32px);
            height: 24px;
            line-height: 24px;
            text-overflow: ellipsis;
            overflow-x: hidden;
            white-space: nowrap;
        }

        .reportUrlLink {
            display: inline-block;
            vertical-align: middle;
            height: 24px;
            line-height: 24px;
            margin-left: 15px;
            width: 24px;
        }
    }

    .cdk-overlay-pane {
        .errorsMenu {
            min-width: 80vh;
            padding: 10px;
        }

        .errorsMenuHeader {
            border-bottom: 1px solid #c7c7c7;
            padding-bottom: 5px;
            margin-bottom: 20px;
        }

        .menuErrorItem {
            font-size: 11px;

            .errorText {
                margin-left: 5px;
            }
        }
    }

    .gstOtpDialog {
        width: 80%;
    }

    .filingReportsMenu.mat-menu-panel {
        min-width: 400px;
    }

    .otpAppFullMenu.mat-menu-panel {
        width: 68vw;
        max-width: unset;
        left: 20px;
        background-color: #f0f0f0;
        top: 5px;
    }

    &.fileDragDetected {
        .timelineColumn .timeline-content {
            .timeline-radio-button .mat-radio-label .mat-radio-container {
                .mat-radio-outer-circle {
                    border-color: rgba(0, 0, 0, 0.4) !important;
                }

                .mat-radio-inner-circle {
                    background-color: rgba(0, 0, 0, 0.4) !important;
                }
            }

            .appGroupContainer {
                &.activeGroup {
                    color: rgba(0, 0, 0, 0.4) !important;

                    .material-icons {
                        color: rgba(0, 0, 0, 0.4) !important;
                    }
                }
            }
        }

        .appsListContainer app-filing-list {
            .appTitle {
                color: rgba(0, 0, 0, 0.4) !important;
                border-color: rgba(0, 0, 0, 0.4) !important;
            }

            .mat-expansion-indicator::after {
                color: rgba(0, 0, 0, 0.4) !important;
                border-color: rgba(0, 0, 0, 0.4) !important;
            }
        }

        .newProcessContainer {
            .fileInputsContainer .input-border {
                border: 0.125em solid #000000 !important;

                &.fileHoverClass {
                    border: 3px solid green !important;
                    margin: 0px;
                }
            }
        }

        .chip {
            .avatar {
                color: rgba(0, 0, 0, 0.4) !important;
            }

            .viewText {
                color: rgba(0, 0, 0, 0.4) !important;
            }

            .back {
                background: rgba(0, 0, 0, 0.4) !important;
            }
        }
    }

    .mat-form-field {
        .mat-form-field-infix input {
            top: 0;
        }
    }

    app-gst-instance-data-dialog {
        display: block;
        height: 100%;
    }

    .searchModal {
        max-width: 90vw;
        width: 100%;
    }
}
